import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import Row from 'layout/Row';
import Column from 'layout/Column';
import CurrentPriceItem from './CurrentPriceItem';
import styles from './CurrentPriceSection.module.scss';

const propTypes = {};
const defaultProps = {};

const CurrentPriceSection = () => {
  const { bids, auctionMetric } = useSelector(state => state.auction);
  const { currency, startPrice, currentPrice, distance } = auctionMetric;
  const [isWinning, setIsWinning] = useState(false);

  useEffect(() => {
    bids && bids[0] && setIsWinning(bids[0].yourCompany);
  }, [bids]);

  return (
    <Row className={styles.current_price_section}>
      <Column>
        {/* TODO Vorbert -> wyekstraktować bordery do mixinsów i zrobić utility klasy */}
        <Row className={`${styles.content_row} ${styles.border_row}`}>
          <CurrentPriceItem
            rowClassName={styles.bigger_flex}
            icon={icons.money}
            iconClassName={styles.first_icon}
            label={lang.labels.StartingPrice()}
            value={startPrice}
            currency={currency}
          />

          <CurrentPriceItem
            icon={icons.route}
            label={lang.labels.Distance()}
            value={distance}
          />

          <CurrentPriceItem
            rowClassName={`justify-content justify-content-end ${styles.bigger_flex}`}
            icon={icons.coins}
            label={lang.labels.PriceForKilemeter()}
            value={startPrice / distance}
            currency={currency}
          />
        </Row>
        <Row className={styles.content_row}>
          <CurrentPriceItem
            rowClassName={styles.bigger_flex}
            icon={icons.money}
            iconClassName={styles.first_icon}
            valueClassName={`${styles.accent} text text_info`}
            label={lang.labels.CurrentPrice()}
            value={currentPrice}
            currency={currency}
            isWinning={isWinning}
          />

          {/* Pusty div, żeby dane w wierszu były rozmieszczone tak jak gdyby były tam 3 elementy */}
          <div />

          <CurrentPriceItem
            rowClassName={`justify-content justify-content-end ${styles.bigger_flex}`}
            icon={icons.coins}
            label={lang.labels.PriceForKilemeter()}
            value={currentPrice / distance}
            currency={currency}
            isWinning={isWinning}
          />
        </Row>
        <Row className={styles.content_row}>
          <CurrentPriceItem
            icon={icons.moneySack}
            iconClassName={styles.first_icon}
            label={lang.labels.Difference()}
            value={startPrice - currentPrice}
            currency={currency}
            isWinning={isWinning}
          />
        </Row>
      </Column>
    </Row>
  );
};

CurrentPriceSection.propTypes = propTypes;
CurrentPriceSection.defaultProps = defaultProps;

export default CurrentPriceSection;
