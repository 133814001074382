"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _resourceService = _interopRequireDefault(require("../services/resourceService"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _ = function _(path) {
  return function () {
    return _resourceService.default.get(path);
  };
};

var _default = {
  AcceptRodo: _('labels.AcceptRodo'),
  Accepted: _('labels.Accepted'),
  AcceptedForRealization: _('labels.AcceptedForRealization'),
  AcceptedTransportOrderStatus: _('labels.AcceptedTransportOrderStatus'),
  AcceptedTransportOrdersNumber: _('labels.AcceptedTransportOrdersNumber'),
  ActivateCompany: _('labels.ActivateCompany'),
  AddCompany_AttachmentInformation_CarrierInsurance: _('labels.AddCompany_AttachmentInformation_CarrierInsurance'),
  AddCompany_AttachmentInformation_ForwarderInsurance: _('labels.AddCompany_AttachmentInformation_ForwarderInsurance'),
  AddCompany_AttachmentInformation_License: _('labels.AddCompany_AttachmentInformation_License'),
  AddCompany_AttachmentInformation_Permission: _('labels.AddCompany_AttachmentInformation_Permission'),
  AddNewAuction: _('labels.AddNewAuction'),
  AddNewOrder: _('labels.AddNewOrder'),
  AddNew_Feminine: _('labels.AddNew_Feminine'),
  AddNew_Masculine: _('labels.AddNew_Masculine'),
  AddToFollowed: _('labels.AddToFollowed'),
  AddingNewAddress: _('labels.AddingNewAddress'),
  AddingNewAuction: _('labels.AddingNewAuction'),
  AddingNewAuctionBasedOnAuction: _('labels.AddingNewAuctionBasedOnAuction'),
  AddingNewCar: _('labels.AddingNewCar'),
  AddingNewCarType: _('labels.AddingNewCarType'),
  AddingNewCompany: _('labels.AddingNewCompany'),
  AddingNewCurrency: _('labels.AddingNewCurrency'),
  AddingNewDefaultPrice: _('labels.AddingNewDefaultPrice'),
  AddingNewDepartment: _('labels.AddingNewDepartment'),
  AddingNewDriver: _('labels.AddingNewDriver'),
  AddingNewFacility: _('labels.AddingNewFacility'),
  AddingNewRoute: _('labels.AddingNewRoute'),
  AddingNewTransportOrder: _('labels.AddingNewTransportOrder'),
  AddingNewTransportOrderBasedOnAuction: _('labels.AddingNewTransportOrderBasedOnAuction'),
  AddingNewUser: _('labels.AddingNewUser'),
  AddingZipCode: _('labels.AddingZipCode'),
  AdditionalInformations: _('labels.AdditionalInformations'),
  AdditionalRequirements: _('labels.AdditionalRequirements'),
  AdditionalUserInformations: _('labels.AdditionalUserInformations'),
  Address: _('labels.Address'),
  AddressFrom: _('labels.AddressFrom'),
  AddressInformations: _('labels.AddressInformations'),
  AddressTo: _('labels.AddressTo'),
  Addresses: _('labels.Addresses'),
  AfterPositiveConsiderationYouWillBeNotifiedByEmailAboutThePossibilityOfSubmittingOfferts: _('labels.AfterPositiveConsiderationYouWillBeNotifiedByEmailAboutThePossibilityOfSubmittingOfferts'),
  All: _('labels.All'),
  AnotherCompany: _('labels.AnotherCompany'),
  AttachNecessaryAttachmentsListedInFollowingList: _('labels.AttachNecessaryAttachmentsListedInFollowingList'),
  Attachments: _('labels.Attachments'),
  Auction: _('labels.Auction'),
  AuctionDraftEnumerator: _('labels.AuctionDraftEnumerator'),
  AuctionEndTime: _('labels.AuctionEndTime'),
  AuctionEnumerator: _('labels.AuctionEnumerator'),
  AuctionHasBeen: _('labels.AuctionHasBeen'),
  AuctionHasBeenCanceledBy: _('labels.AuctionHasBeenCanceledBy'),
  AuctionHasEnded: _('labels.AuctionHasEnded'),
  AuctionHasNotBeenStarted: _('labels.AuctionHasNotBeenStarted'),
  AuctionInformation: _('labels.AuctionInformation'),
  AuctionNumber: _('labels.AuctionNumber'),
  AuctionPage_AuctionCancelation: _('labels.AuctionPage_AuctionCancelation'),
  AuctionPage_CancelAuction: _('labels.AuctionPage_CancelAuction'),
  AuctionPage_CancelAuctionDialogDescription: _('labels.AuctionPage_CancelAuctionDialogDescription'),
  AuctionPage_CancelAuctionDialogErrorMessage: _('labels.AuctionPage_CancelAuctionDialogErrorMessage'),
  AuctionPage_ProviceReason: _('labels.AuctionPage_ProviceReason'),
  AuctionTimeDuration: _('labels.AuctionTimeDuration'),
  AuctionType: _('labels.AuctionType'),
  AuctionWillTakePlaceIn: _('labels.AuctionWillTakePlaceIn'),
  Auctions: _('labels.Auctions'),
  AuthorizedPickupPerson: _('labels.AuthorizedPickupPerson'),
  AuthorizedPickupPersonInformations: _('labels.AuthorizedPickupPersonInformations'),
  AuthorizedPickupPersonPhoneNumber: _('labels.AuthorizedPickupPersonPhoneNumber'),
  AuthorizedPickupPersonPhoneNumberPlaceholder: _('labels.AuthorizedPickupPersonPhoneNumberPlaceholder'),
  Automatically: _('labels.Automatically'),
  AutomaticallyExposed: _('labels.AutomaticallyExposed'),
  AutomaticallyGenerated: _('labels.AutomaticallyGenerated'),
  AvailablePlaceholders: _('labels.AvailablePlaceholders'),
  Avatar: _('labels.Avatar'),
  BackToMainPage: _('labels.BackToMainPage'),
  Bid: _('labels.Bid'),
  Bidded: _('labels.Bidded'),
  BidingByHHAllowed: _('labels.BidingByHHAllowed'),
  Blocked: _('labels.Blocked'),
  BlockingCompany: _('labels.BlockingCompany'),
  BoxPicture: _('labels.BoxPicture'),
  ButtonIsDisabled: _('labels.ButtonIsDisabled'),
  Cancel: _('labels.Cancel'),
  Canceled: _('labels.Canceled'),
  CanceledAuctionStatus: _('labels.CanceledAuctionStatus'),
  CanceledTransportOrderStatus: _('labels.CanceledTransportOrderStatus'),
  Capacity: _('labels.Capacity'),
  Car: _('labels.Car'),
  CarInformations: _('labels.CarInformations'),
  CarType: _('labels.CarType'),
  CarTypeInformations: _('labels.CarTypeInformations'),
  CarTypes: _('labels.CarTypes'),
  CarTypesManagement_Columns_Name: _('labels.CarTypesManagement_Columns_Name'),
  Cars: _('labels.Cars'),
  ChangeStatus: _('labels.ChangeStatus'),
  City: _('labels.City'),
  CityFrom: _('labels.CityFrom'),
  CityTo: _('labels.CityTo'),
  ClickHereToReadTheConditions: _('labels.ClickHereToReadTheConditions'),
  ClickHereToReadThePrinciples: _('labels.ClickHereToReadThePrinciples'),
  ClickToDisplayDataFromThisPeriod: _('labels.ClickToDisplayDataFromThisPeriod'),
  ClosedAuctionStatus: _('labels.ClosedAuctionStatus'),
  Code: _('labels.Code'),
  Comment: _('labels.Comment'),
  Companies: _('labels.Companies'),
  Company: _('labels.Company'),
  CompanyInformations: _('labels.CompanyInformations'),
  Completed: _('labels.Completed'),
  CompletedTransportOrderStatus: _('labels.CompletedTransportOrderStatus'),
  ConfigurableDiagramCompletedTransportOrders: _('labels.ConfigurableDiagramCompletedTransportOrders'),
  ConfigurableDiagramDataModificationTitle: _('labels.ConfigurableDiagramDataModificationTitle'),
  ConsentToTheProcessingOfPersonalData: _('labels.ConsentToTheProcessingOfPersonalData'),
  ContactPerson: _('labels.ContactPerson'),
  Content: _('labels.Content'),
  CopyThePhoneNumber: _('labels.CopyThePhoneNumber'),
  CreatedBy: _('labels.CreatedBy'),
  CreatedOn: _('labels.CreatedOn'),
  Currencies: _('labels.Currencies'),
  CurrenciesManagement_Columns_IsDefault: _('labels.CurrenciesManagement_Columns_IsDefault'),
  CurrenciesManagement_Columns_Name: _('labels.CurrenciesManagement_Columns_Name'),
  Currency: _('labels.Currency'),
  CurrencyInformations: _('labels.CurrencyInformations'),
  CurrentAuctionStatus: _('labels.CurrentAuctionStatus'),
  CurrentLoadingDateTime: _('labels.CurrentLoadingDateTime'),
  CurrentPassword: _('labels.CurrentPassword'),
  CurrentPrice: _('labels.CurrentPrice'),
  CurrentlyYouHave: _('labels.CurrentlyYouHave'),
  Dashboard: _('labels.Dashboard'),
  DashboardAcceptedTransportOrderStatus: _('labels.DashboardAcceptedTransportOrderStatus'),
  DashboardActions: _('labels.DashboardActions'),
  DashboardCompletedTransportOrderStatus: _('labels.DashboardCompletedTransportOrderStatus'),
  DashboardFinishedWithSuccess: _('labels.DashboardFinishedWithSuccess'),
  DashboardFinishedWithoutSuccess: _('labels.DashboardFinishedWithoutSuccess'),
  DashboardLoadedTransportOrderStatus: _('labels.DashboardLoadedTransportOrderStatus'),
  DashboardOngoing: _('labels.DashboardOngoing'),
  DashboardPlacedTransportOrderStatus: _('labels.DashboardPlacedTransportOrderStatus'),
  DataFix: _('labels.DataFix'),
  DataForInvoiceTransportOrder: _('labels.DataForInvoiceTransportOrder'),
  DataForTransportOrder: _('labels.DataForTransportOrder'),
  Date: _('labels.Date'),
  Day: _('labels.Day'),
  Days: _('labels.Days'),
  DeclaredLoadingRangeDateTime: _('labels.DeclaredLoadingRangeDateTime'),
  DeclaredUnloadingRangeDateTime: _('labels.DeclaredUnloadingRangeDateTime'),
  DefaultPriceInformations: _('labels.DefaultPriceInformations'),
  DefaultPrices: _('labels.DefaultPrices'),
  DefaultPricesManagement_Columns_FlatRate: _('labels.DefaultPricesManagement_Columns_FlatRate'),
  DefaultPricesManagement_Columns_MaxDistance: _('labels.DefaultPricesManagement_Columns_MaxDistance'),
  DefaultPricesManagement_Columns_MinDistance: _('labels.DefaultPricesManagement_Columns_MinDistance'),
  DeleteAuction: _('labels.DeleteAuction'),
  Delivery: _('labels.Delivery'),
  DeliveryExpense: _('labels.DeliveryExpense'),
  DepAdmin: _('labels.DepAdmin'),
  Department: _('labels.Department'),
  DepartmentInformations: _('labels.DepartmentInformations'),
  Departments: _('labels.Departments'),
  Description: _('labels.Description'),
  Dictionaries: _('labels.Dictionaries'),
  Difference: _('labels.Difference'),
  DifferentCompany: _('labels.DifferentCompany'),
  Distance: _('labels.Distance'),
  DownloadPdf: _('labels.DownloadPdf'),
  DownloadRaport: _('labels.DownloadRaport'),
  Draft: _('labels.Draft'),
  Driver: _('labels.Driver'),
  DriverPhoneNumber: _('labels.DriverPhoneNumber'),
  Drivers: _('labels.Drivers'),
  DriversQuantity: _('labels.DriversQuantity'),
  Edit: _('labels.Edit'),
  EditAuctionDraftEnumerator: _('labels.EditAuctionDraftEnumerator'),
  EditAuctionEnumerator: _('labels.EditAuctionEnumerator'),
  EditProfile: _('labels.EditProfile'),
  EditTransportOrderEnumerator: _('labels.EditTransportOrderEnumerator'),
  EditingAddress: _('labels.EditingAddress'),
  EditingAuction: _('labels.EditingAuction'),
  EditingCar: _('labels.EditingCar'),
  EditingCarType: _('labels.EditingCarType'),
  EditingCompany: _('labels.EditingCompany'),
  EditingCurrency: _('labels.EditingCurrency'),
  EditingDefaultPrice: _('labels.EditingDefaultPrice'),
  EditingDepartment: _('labels.EditingDepartment'),
  EditingDriver: _('labels.EditingDriver'),
  EditingFacilities: _('labels.EditingFacilities'),
  EditingHtmlTemplates: _('labels.EditingHtmlTemplates'),
  EditingRoute: _('labels.EditingRoute'),
  EditingTransportOrder: _('labels.EditingTransportOrder'),
  EditingUser: _('labels.EditingUser'),
  EditingZipCode: _('labels.EditingZipCode'),
  ElectricInvoice: _('labels.ElectricInvoice'),
  Email: _('labels.Email'),
  EmailNotification: _('labels.EmailNotification'),
  EmailOrLogin: _('labels.EmailOrLogin'),
  EmailSubject: _('labels.EmailSubject'),
  Employee: _('labels.Employee'),
  EndDateTime: _('labels.EndDateTime'),
  Ended: _('labels.Ended'),
  EndedAuctionStatus: _('labels.EndedAuctionStatus'),
  Expand: _('labels.Expand'),
  Expense: _('labels.Expense'),
  ExportNav: _('labels.ExportNav'),
  Exposed: _('labels.Exposed'),
  Facilities: _('labels.Facilities'),
  Facility: _('labels.Facility'),
  FacilityInformations: _('labels.FacilityInformations'),
  FieldRequired: _('labels.FieldRequired'),
  Finished: _('labels.Finished'),
  FirstName: _('labels.FirstName'),
  FlatRate: _('labels.FlatRate'),
  Fold: _('labels.Fold'),
  ForPublication: _('labels.ForPublication'),
  ForTodayYouHavePlaced: _('labels.ForTodayYouHavePlaced'),
  From: _('labels.From'),
  FullName: _('labels.FullName'),
  Generate: _('labels.Generate'),
  GoToTheEvent: _('labels.GoToTheEvent'),
  HistoricPrice: _('labels.HistoricPrice'),
  HomePage: _('labels.HomePage'),
  HtmlTemplateInformations: _('labels.HtmlTemplateInformations'),
  HtmlTemplates: _('labels.HtmlTemplates'),
  HtmlTemplatesManagement_Columns_Area: _('labels.HtmlTemplatesManagement_Columns_Area'),
  HtmlTemplatesManagement_Columns_Description: _('labels.HtmlTemplatesManagement_Columns_Description'),
  HtmlTemplatesManagement_Columns_Name: _('labels.HtmlTemplatesManagement_Columns_Name'),
  ImplementationDetails: _('labels.ImplementationDetails'),
  InApril: _('labels.InApril'),
  InAugust: _('labels.InAugust'),
  InDecember: _('labels.InDecember'),
  InFebruary: _('labels.InFebruary'),
  InJanuary: _('labels.InJanuary'),
  InJuly: _('labels.InJuly'),
  InJune: _('labels.InJune'),
  InMarch: _('labels.InMarch'),
  InMay: _('labels.InMay'),
  InNovember: _('labels.InNovember'),
  InOctober: _('labels.InOctober'),
  InProgressOfDateAndAcceptanceVerification: _('labels.InProgressOfDateAndAcceptanceVerification'),
  InSeptember: _('labels.InSeptember'),
  InformationObligation: _('labels.InformationObligation'),
  InformationsAboutTheAuction: _('labels.InformationsAboutTheAuction'),
  InsideYourClipboardIsLocatedPlaceholder: _('labels.InsideYourClipboardIsLocatedPlaceholder'),
  InternalNote: _('labels.InternalNote'),
  InvitationToPlatform: _('labels.InvitationToPlatform'),
  InvitedSuppliers: _('labels.InvitedSuppliers'),
  InvoiceEmailAddress: _('labels.InvoiceEmailAddress'),
  InvoiceSendToaddress: _('labels.InvoiceSendToaddress'),
  IsActive: _('labels.IsActive'),
  IsDefault: _('labels.IsDefault'),
  IsDriver: _('labels.IsDriver'),
  IsParticipant: _('labels.IsParticipant'),
  IsWon: _('labels.IsWon'),
  LackOfData: _('labels.LackOfData'),
  LackOfInformations: _('labels.LackOfInformations'),
  LastMonth: _('labels.LastMonth'),
  LastName: _('labels.LastName'),
  LastWeek: _('labels.LastWeek'),
  LastYear: _('labels.LastYear'),
  LinkToTheAuction: _('labels.LinkToTheAuction'),
  LiveAuctionStatus: _('labels.LiveAuctionStatus'),
  LoadData: _('labels.LoadData'),
  Loaded: _('labels.Loaded'),
  LoadedTransportOrderStatus: _('labels.LoadedTransportOrderStatus'),
  Loading: _('labels.Loading'),
  LoadingAddress: _('labels.LoadingAddress'),
  LoadingData: _('labels.LoadingData'),
  LoadingDateTime: _('labels.LoadingDateTime'),
  LoadingDetails: _('labels.LoadingDetails'),
  LoadingMethod: _('labels.LoadingMethod'),
  LogOut: _('labels.LogOut'),
  Login: _('labels.Login'),
  LoginPage: _('labels.LoginPage'),
  LoginPage_ForgotPassword: _('labels.LoginPage_ForgotPassword'),
  LoginPage_Login: _('labels.LoginPage_Login'),
  LoginPage_LoginDescription: _('labels.LoginPage_LoginDescription'),
  Logistician: _('labels.Logistician'),
  Lost: _('labels.Lost'),
  MainAdmin: _('labels.MainAdmin'),
  MainPage: _('labels.MainPage'),
  MandatoryCompany: _('labels.MandatoryCompany'),
  MaxDistance: _('labels.MaxDistance'),
  MinDistance: _('labels.MinDistance'),
  MyCompany: _('labels.MyCompany'),
  MySettings_Auctions: _('labels.MySettings_Auctions'),
  MySettings_Orders: _('labels.MySettings_Orders'),
  MySettings_System: _('labels.MySettings_System'),
  Name: _('labels.Name'),
  New: _('labels.New'),
  NewAddress: _('labels.NewAddress'),
  NewCar: _('labels.NewCar'),
  NewDriver: _('labels.NewDriver'),
  NewPassword: _('labels.NewPassword'),
  NewZipCode: _('labels.NewZipCode'),
  Nip: _('labels.Nip'),
  No: _('labels.No'),
  NoAdditionalInformations: _('labels.NoAdditionalInformations'),
  NoOffer: _('labels.NoOffer'),
  NotGiven: _('labels.NotGiven'),
  NotWon: _('labels.NotWon'),
  Notifications: _('labels.Notifications'),
  Number: _('labels.Number'),
  Observed: _('labels.Observed'),
  Offerts: _('labels.Offerts'),
  Ongoing: _('labels.Ongoing'),
  OnlyMyFacility: _('labels.OnlyMyFacility'),
  Order: _('labels.Order'),
  OrderNavNumber: _('labels.OrderNavNumber'),
  OrderSuffix: _('labels.OrderSuffix'),
  OrderedTransportOrdersNumber: _('labels.OrderedTransportOrdersNumber'),
  Orders: _('labels.Orders'),
  OrdersDashboard: _('labels.OrdersDashboard'),
  OrdinalNumber: _('labels.OrdinalNumber'),
  PageNotFound: _('labels.PageNotFound'),
  ParticipatedAuctionsNumber: _('labels.ParticipatedAuctionsNumber'),
  Password: _('labels.Password'),
  PasteItIntoAppropriatePlaceInsideTheTemplate: _('labels.PasteItIntoAppropriatePlaceInsideTheTemplate'),
  PaymentDeadline: _('labels.PaymentDeadline'),
  PhoneNumber: _('labels.PhoneNumber'),
  PhoneNumberWasntProvided: _('labels.PhoneNumberWasntProvided'),
  Placed: _('labels.Placed'),
  PlacedTransportOrderStatus: _('labels.PlacedTransportOrderStatus'),
  Preview: _('labels.Preview'),
  PriceForKilemeter: _('labels.PriceForKilemeter'),
  PriceLists: _('labels.PriceLists'),
  PricePerKilometer: _('labels.PricePerKilometer'),
  Prices: _('labels.Prices'),
  PricesManagement: _('labels.PricesManagement'),
  PricesManagement_Columns_BuyerRebateGroup: _('labels.PricesManagement_Columns_BuyerRebateGroup'),
  PricesManagement_Columns_Code: _('labels.PricesManagement_Columns_Code'),
  PricesManagement_Columns_Company: _('labels.PricesManagement_Columns_Company'),
  PricesManagement_Columns_CurrencyCode: _('labels.PricesManagement_Columns_CurrencyCode'),
  PricesManagement_Columns_Description: _('labels.PricesManagement_Columns_Description'),
  PricesManagement_Columns_FlatRate: _('labels.PricesManagement_Columns_FlatRate'),
  PricesManagement_Columns_LocalizationCode: _('labels.PricesManagement_Columns_LocalizationCode'),
  PricesManagement_Columns_MaxBasis: _('labels.PricesManagement_Columns_MaxBasis'),
  PricesManagement_Columns_MaxDistance: _('labels.PricesManagement_Columns_MaxDistance'),
  PricesManagement_Columns_MinBasis: _('labels.PricesManagement_Columns_MinBasis'),
  PricesManagement_Columns_MinDistance: _('labels.PricesManagement_Columns_MinDistance'),
  PricesManagement_Columns_Nip: _('labels.PricesManagement_Columns_Nip'),
  PricesManagement_Columns_No: _('labels.PricesManagement_Columns_No'),
  PricesManagement_Columns_RatePerBaseUnit: _('labels.PricesManagement_Columns_RatePerBaseUnit'),
  PricesManagement_Columns_ShipmentMethod: _('labels.PricesManagement_Columns_ShipmentMethod'),
  PricesManagement_Columns_ShippingAgentCode: _('labels.PricesManagement_Columns_ShippingAgentCode'),
  PricesManagement_Columns_ShippingAgentServiceCode: _('labels.PricesManagement_Columns_ShippingAgentServiceCode'),
  PricesManagement_Columns_Type: _('labels.PricesManagement_Columns_Type'),
  PricesManagement_Columns_ZoneNo: _('labels.PricesManagement_Columns_ZoneNo'),
  PrincipalPerson: _('labels.PrincipalPerson'),
  ReadConditions: _('labels.ReadConditions'),
  ReadPrinciples: _('labels.ReadPrinciples'),
  RealizationDate: _('labels.RealizationDate'),
  Realized: _('labels.Realized'),
  RealizedDetails: _('labels.RealizedDetails'),
  RealizedInYears: _('labels.RealizedInYears'),
  RegisterNumber: _('labels.RegisterNumber'),
  Regon: _('labels.Regon'),
  RepeatPassword: _('labels.RepeatPassword'),
  ReportCompany: _('labels.ReportCompany'),
  Reports: _('labels.Reports'),
  ResetPasswordInformation: _('labels.ResetPasswordInformation'),
  ReturnToTheHomePage: _('labels.ReturnToTheHomePage'),
  Roles: _('labels.Roles'),
  Route: _('labels.Route'),
  RouteInformations: _('labels.RouteInformations'),
  Routes: _('labels.Routes'),
  Save: _('labels.Save'),
  Settings: _('labels.Settings'),
  ShowPdf: _('labels.ShowPdf'),
  SmsNotification: _('labels.SmsNotification'),
  StartDateTime: _('labels.StartDateTime'),
  StartPrice: _('labels.StartPrice'),
  StartingPrice: _('labels.StartingPrice'),
  Status: _('labels.Status'),
  Street: _('labels.Street'),
  Subjects: _('labels.Subjects'),
  SuccessfullyAddedPhoneNumberToTheClipboard: _('labels.SuccessfullyAddedPhoneNumberToTheClipboard'),
  SuccessfullyCreatedAddress: _('labels.SuccessfullyCreatedAddress'),
  SuccessfullyCreatedAuction: _('labels.SuccessfullyCreatedAuction'),
  SuccessfullyCreatedCarType: _('labels.SuccessfullyCreatedCarType'),
  SuccessfullyCreatedCompany: _('labels.SuccessfullyCreatedCompany'),
  SuccessfullyCreatedCurrency: _('labels.SuccessfullyCreatedCurrency'),
  SuccessfullyCreatedDefaultPrice: _('labels.SuccessfullyCreatedDefaultPrice'),
  SuccessfullyCreatedDepartment: _('labels.SuccessfullyCreatedDepartment'),
  SuccessfullyCreatedDriver: _('labels.SuccessfullyCreatedDriver'),
  SuccessfullyCreatedFacility: _('labels.SuccessfullyCreatedFacility'),
  SuccessfullyCreatedOrder: _('labels.SuccessfullyCreatedOrder'),
  SuccessfullyCreatedRoute: _('labels.SuccessfullyCreatedRoute'),
  SuccessfullyCreatedUser: _('labels.SuccessfullyCreatedUser'),
  SuccessfullyCreatedVehicle: _('labels.SuccessfullyCreatedVehicle'),
  SuccessfullyCreatedZipCode: _('labels.SuccessfullyCreatedZipCode'),
  SuccessfullyDownloadedAttachment: _('labels.SuccessfullyDownloadedAttachment'),
  SuccessfullyModificatedAddress: _('labels.SuccessfullyModificatedAddress'),
  SuccessfullyModificatedAuction: _('labels.SuccessfullyModificatedAuction'),
  SuccessfullyModificatedCarType: _('labels.SuccessfullyModificatedCarType'),
  SuccessfullyModificatedCompany: _('labels.SuccessfullyModificatedCompany'),
  SuccessfullyModificatedCurrency: _('labels.SuccessfullyModificatedCurrency'),
  SuccessfullyModificatedDefaultPrice: _('labels.SuccessfullyModificatedDefaultPrice'),
  SuccessfullyModificatedDeparment: _('labels.SuccessfullyModificatedDeparment'),
  SuccessfullyModificatedDriver: _('labels.SuccessfullyModificatedDriver'),
  SuccessfullyModificatedFacility: _('labels.SuccessfullyModificatedFacility'),
  SuccessfullyModificatedHTMLTemplate: _('labels.SuccessfullyModificatedHTMLTemplate'),
  SuccessfullyModificatedOrder: _('labels.SuccessfullyModificatedOrder'),
  SuccessfullyModificatedRoute: _('labels.SuccessfullyModificatedRoute'),
  SuccessfullyModificatedUser: _('labels.SuccessfullyModificatedUser'),
  SuccessfullyModificatedVehicle: _('labels.SuccessfullyModificatedVehicle'),
  SuccessfullyModificatedZipCode: _('labels.SuccessfullyModificatedZipCode'),
  SuccessfullyRemovedAttachment: _('labels.SuccessfullyRemovedAttachment'),
  SuccessfullySendInvitationToPlatform: _('labels.SuccessfullySendInvitationToPlatform'),
  Suppliers: _('labels.Suppliers'),
  Supply: _('labels.Supply'),
  Templates: _('labels.Templates'),
  TheSiteDoesNotExistOrHasBeenRelocated: _('labels.TheSiteDoesNotExistOrHasBeenRelocated'),
  Time: _('labels.Time'),
  TimeLeft: _('labels.TimeLeft'),
  TimeToLoad: _('labels.TimeToLoad'),
  TimeToUnload: _('labels.TimeToUnload'),
  To: _('labels.To'),
  ToTheEndOfTheAuctionRemained: _('labels.ToTheEndOfTheAuctionRemained'),
  TodayAreEnding: _('labels.TodayAreEnding'),
  Transport: _('labels.Transport'),
  TransportCode: _('labels.TransportCode'),
  TransportOrder: _('labels.TransportOrder'),
  TransportOrderDialog_AddNote: _('labels.TransportOrderDialog_AddNote'),
  TransportOrderDialog_Content: _('labels.TransportOrderDialog_Content'),
  TransportOrderDialog_ErrorMessage: _('labels.TransportOrderDialog_ErrorMessage'),
  TransportOrderDialog_ProvideMessage: _('labels.TransportOrderDialog_ProvideMessage'),
  TransportOrderEnumerator: _('labels.TransportOrderEnumerator'),
  TransportOrderInformations: _('labels.TransportOrderInformations'),
  TransportOrderNavNumber: _('labels.TransportOrderNavNumber'),
  TransportOrderPage_CancelOrderDialogErrorMessage: _('labels.TransportOrderPage_CancelOrderDialogErrorMessage'),
  TransportOrderPage_CancelTransportOrderDialogDecription: _('labels.TransportOrderPage_CancelTransportOrderDialogDecription'),
  TransportOrderPage_OrderCancelation: _('labels.TransportOrderPage_OrderCancelation'),
  TransportOrderPage_ProviceReason: _('labels.TransportOrderPage_ProviceReason'),
  TransportOrders: _('labels.TransportOrders'),
  TransportOrdersManagement_Columns_AdditionalRequirements: _('labels.TransportOrdersManagement_Columns_AdditionalRequirements'),
  TransportOrdersManagement_Columns_AuctionNumber: _('labels.TransportOrdersManagement_Columns_AuctionNumber'),
  TransportOrdersManagement_Columns_AuthorizedPickupPerson: _('labels.TransportOrdersManagement_Columns_AuthorizedPickupPerson'),
  TransportOrdersManagement_Columns_AuthorizedPickupPersonPhoneNumber: _('labels.TransportOrdersManagement_Columns_AuthorizedPickupPersonPhoneNumber'),
  TransportOrdersManagement_Columns_Capacity: _('labels.TransportOrdersManagement_Columns_Capacity'),
  TransportOrdersManagement_Columns_Car: _('labels.TransportOrdersManagement_Columns_Car'),
  TransportOrdersManagement_Columns_Company: _('labels.TransportOrdersManagement_Columns_Company'),
  TransportOrdersManagement_Columns_Distance: _('labels.TransportOrdersManagement_Columns_Distance'),
  TransportOrdersManagement_Columns_Driver: _('labels.TransportOrdersManagement_Columns_Driver'),
  TransportOrdersManagement_Columns_Facility: _('labels.TransportOrdersManagement_Columns_Facility'),
  TransportOrdersManagement_Columns_LoadingAddress: _('labels.TransportOrdersManagement_Columns_LoadingAddress'),
  TransportOrdersManagement_Columns_LoadingMethod: _('labels.TransportOrdersManagement_Columns_LoadingMethod'),
  TransportOrdersManagement_Columns_LoadingTime: _('labels.TransportOrdersManagement_Columns_LoadingTime'),
  TransportOrdersManagement_Columns_Number: _('labels.TransportOrdersManagement_Columns_Number'),
  TransportOrdersManagement_Columns_Status: _('labels.TransportOrdersManagement_Columns_Status'),
  TransportOrdersManagement_Columns_UnloadingAddress: _('labels.TransportOrdersManagement_Columns_UnloadingAddress'),
  TransportOrdersManagement_Columns_UnloadingMethod: _('labels.TransportOrdersManagement_Columns_UnloadingMethod'),
  TransportOrdersManagement_Columns_UnloadingTime: _('labels.TransportOrdersManagement_Columns_UnloadingTime'),
  TransportOrdersManagement_Columns_Value: _('labels.TransportOrdersManagement_Columns_Value'),
  UnloadingAddress: _('labels.UnloadingAddress'),
  UnloadingData: _('labels.UnloadingData'),
  UnloadingDateTime: _('labels.UnloadingDateTime'),
  UnloadingMethod: _('labels.UnloadingMethod'),
  UnloadingTime: _('labels.UnloadingTime'),
  UserBanned: _('labels.UserBanned'),
  UserChangePassword: _('labels.UserChangePassword'),
  UserInformations: _('labels.UserInformations'),
  UserManagement_Columns_Name: _('labels.UserManagement_Columns_Name'),
  UserManagement_Columns_Roles: _('labels.UserManagement_Columns_Roles'),
  UserPermissions: _('labels.UserPermissions'),
  Users: _('labels.Users'),
  UsersManagement_Columns_Company: _('labels.UsersManagement_Columns_Company'),
  UsersManagement_Columns_Email: _('labels.UsersManagement_Columns_Email'),
  UsersManagement_Columns_Facility: _('labels.UsersManagement_Columns_Facility'),
  UsersManagement_Columns_FirstName: _('labels.UsersManagement_Columns_FirstName'),
  UsersManagement_Columns_IsDriver: _('labels.UsersManagement_Columns_IsDriver'),
  UsersManagement_Columns_LastName: _('labels.UsersManagement_Columns_LastName'),
  UsersManagement_Columns_Login: _('labels.UsersManagement_Columns_Login'),
  UsersManagement_Columns_PhoneNumber: _('labels.UsersManagement_Columns_PhoneNumber'),
  Value: _('labels.Value'),
  Version: _('labels.Version'),
  Welcome: _('labels.Welcome'),
  WinAuctionsNumber: _('labels.WinAuctionsNumber'),
  WithMyParticipation: _('labels.WithMyParticipation'),
  WithSuccess: _('labels.WithSuccess'),
  WithoutOffer: _('labels.WithoutOffer'),
  WithoutSuccess: _('labels.WithoutSuccess'),
  Won: _('labels.Won'),
  WriteInternalNote: _('labels.WriteInternalNote'),
  Yes: _('labels.Yes'),
  You: _('labels.You'),
  YouAreLosing: _('labels.YouAreLosing'),
  YouAreWinning: _('labels.YouAreWinning'),
  YouWin: _('labels.YouWin'),
  YourOffer: _('labels.YourOffer'),
  YourPasswordWasChanged: _('labels.YourPasswordWasChanged'),
  ZipCode: _('labels.ZipCode'),
  ZipCodeFrom: _('labels.ZipCodeFrom'),
  ZipCodeTo: _('labels.ZipCodeTo'),
  ZipCodes: _('labels.ZipCodes')
};
exports.default = _default;