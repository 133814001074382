import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import auctionStatusIds from 'hh-shared/dist/utils/auctionStatusesIds';
import { getForGrid as getForGridAuctionsManagement } from 'auctions/actions';
import auctionActionTypes from 'auctions/actionTypes';
import {
  auctionOngoingFilter,
  auctionAllFilter,
  auctionCanceledFilter,
  auctionNotWonFilter,
  auctionWonFilter,
  auctionForPublicationFilter,
} from 'auctions/filters';
import PropTypes from 'prop-types';
import AuctionStatusCell from './AuctionStatusCell';

const propTypes = {
  statusId: PropTypes.string.isRequired,
  isWon: PropTypes.bool.isRequired,
};

const defaultProps = {};

const AuctionStatusClickableCell = ({ statusId, isWon, ...others }) => {
  const { forPublication, live, closed, canceled } = auctionStatusIds;
  const dispatch = useDispatch();

  const auctionDataState = useSelector(state => state.auctions.dataState);

  const changeFilter = filter => {
    dispatch(
      getForGridAuctionsManagement({
        ...auctionDataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: auctionActionTypes.AUCTIONS_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchOngoing = () => changeFilter(auctionOngoingFilter);

  const dispatchAll = () => changeFilter(auctionAllFilter);

  const dispatchCanceled = () => changeFilter(auctionCanceledFilter);

  const dispatchWon = () => changeFilter(auctionWonFilter);

  const dispatchLost = () => changeFilter(auctionNotWonFilter);

  const dispatchForPublication = () =>
    changeFilter(auctionForPublicationFilter);

  const getBadgeFilter = () => {
    switch (statusId) {
      case forPublication:
        return dispatchForPublication;
      case live:
        return dispatchOngoing;
      case closed:
        return isWon ? dispatchWon : dispatchLost;
      case canceled:
        return dispatchCanceled;
      default:
        return dispatchAll;
    }
  };

  const [currentFilter, setCurrentFilter] = useState(() => getBadgeFilter());

  useEffect(() => {
    setCurrentFilter(() => getBadgeFilter());
    // eslint-disable-next-line
  }, [statusId]);

  return (
    <AuctionStatusCell
      onClick={currentFilter}
      {...{ statusId, isWon }}
      {...others}
    />
  );
};

AuctionStatusClickableCell.propTypes = propTypes;
AuctionStatusClickableCell.defaultProps = defaultProps;

export default AuctionStatusClickableCell;
