"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gridStoreResults = gridStoreResults;
exports.gridOnGroupChange = gridOnGroupChange;
exports.gridOnDataStateChange = gridOnDataStateChange;
exports.gridOnSetDefaultSort = gridOnSetDefaultSort;
exports.clearFilter = clearFilter;
exports.setNewOrder = setNewOrder;
exports.setColumnsSubmit = setColumnsSubmit;
exports.setColumnSizes = setColumnSizes;

function gridStoreResults(prefix) {
  return function (results) {
    return {
      type: "".concat(prefix, "_STORE_RESULTS"),
      results: results
    };
  };
}

function gridOnGroupChange(prefix) {
  return function (group) {
    return {
      type: "".concat(prefix, "_STORE_GROUP"),
      group: group
    };
  };
}

function gridOnDataStateChange(prefix) {
  return function (dataState) {
    return {
      type: "".concat(prefix, "_STORE_DATASTATE"),
      dataState: dataState
    };
  };
}

function gridOnSetDefaultSort(prefix) {
  return function (defaultSort) {
    return {
      type: "".concat(prefix, "_SET_DEFAULT_SORT"),
      defaultSort: defaultSort
    };
  };
}

function clearFilter(prefix) {
  return {
    type: "".concat(prefix, "_CLEAR_FILTERS")
  };
}

function setNewOrder(prefix) {
  return function (payload) {
    return {
      type: "".concat(prefix, "_SET_NEW_ORDER"),
      payload: payload
    };
  };
}

function setColumnsSubmit(prefix) {
  return function (payload) {
    return {
      type: "".concat(prefix, "_SET_COLUMNS_SUBMIT"),
      payload: payload
    };
  };
}

function setColumnSizes(prefix) {
  return function (payload) {
    return {
      type: "".concat(prefix, "_SET_COLUMNS_SIZES"),
      payload: payload
    };
  };
}