import React from 'react';
import PropTypes from 'prop-types';

import LabelCell from 'hh-shared/dist/components/grid/LabelCell';
import WinningLabel from 'hh-shared/dist/components/commons/WinningLabel';

const propTypes = {
  auctionId: PropTypes.string.isRequired,
  isCompeting: PropTypes.bool,
  isWinning: PropTypes.bool.isRequired,
};

const defaultProps = {
  isCompeting: true,
};

const WinningCell = ({ auctionId, isCompeting, isWinning, ...others }) => {
  return (
    <LabelCell
      {...others}
      displayLabel={isCompeting}
      opaque={false}
      withBorder
      numericValue
      customLabel={() => (
        <WinningLabel isWinning={isWinning} alignCenter auctionId={auctionId} />
      )}
    />
  );
};

WinningCell.defaultProps = defaultProps;
WinningCell.propTypes = propTypes;

export default WinningCell;
