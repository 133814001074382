"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _FoxSwitch = _interopRequireDefault(require("../components/FoxSwitch"));

var _wrapFormField = _interopRequireDefault(require("./wrapFormField"));

var _asReduxFormField = _interopRequireDefault(require("./asReduxFormField"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = (0, _wrapFormField.default)((0, _asReduxFormField.default)(_FoxSwitch.default), {
  isSwitchField: true
});

exports.default = _default;