"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _actions = require("./actions");

var _FoxGrid = _interopRequireDefault(require("./FoxGrid"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function createFoxGridContainer(prefix) {
  var mapStateToProps = function mapStateToProps(state, ownProps) {
    return _objectSpread({
      results: state[prefix].results,
      dataState: state[prefix].dataState,
      columnsInfo: state[prefix].columnsInfo
    }, ownProps);
  };

  var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
      onDataStateChange: function onDataStateChange(dataState) {
        return dispatch((0, _actions.gridOnDataStateChange)(prefix)(dataState));
      },
      onGroupChange: function onGroupChange(group) {
        return dispatch((0, _actions.gridOnGroupChange)(prefix)(group));
      },
      onSetDefaultSort: function onSetDefaultSort(defaultSort) {
        return dispatch((0, _actions.gridOnSetDefaultSort)(prefix)(defaultSort));
      },
      onResetFilters: function onResetFilters() {
        return dispatch((0, _actions.clearFilter)(prefix));
      },
      onColumnReorder: function onColumnReorder(newOrder) {
        return dispatch((0, _actions.setNewOrder)(prefix)(newOrder));
      },
      onColumnsSubmit: function onColumnsSubmit(columnsSubmit) {
        return dispatch((0, _actions.setColumnsSubmit)(prefix)(columnsSubmit));
      },
      onColumnResize: function onColumnResize(newSizes) {
        return dispatch((0, _actions.setColumnSizes)(prefix)(newSizes));
      }
    };
  };

  var foxGridContainer = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_FoxGrid.default);
  return foxGridContainer;
}

var _default = createFoxGridContainer;
exports.default = _default;