"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateCarInput = validateCarInput;
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _IconArea = _interopRequireDefault(require("hh-shared/dist/components/commons/IconArea"));

var _icons = _interopRequireDefault(require("hh-shared/dist/consts/icons"));

var _ContentRevealer = _interopRequireDefault(require("hh-shared/dist/components/commons/ContentRevealer"));

var _FoxComboBoxField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxComboBoxField"));

var _FoxSwitchField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxSwitchField"));

var _reduxForm = require("redux-form");

var _redux = require("redux");

var _formValidators = require("fox-react/dist/utils/formValidators");

var _NewCarForm = _interopRequireDefault(require("../commons/forms/NewCarForm"));

var _TransportFormModule = _interopRequireDefault(require("./TransportForm.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  showInputs: _propTypes.default.bool.isRequired,
  cars: _propTypes.default.array.isRequired,
  carTypes: _propTypes.default.array.isRequired,
  isNewCar: _propTypes.default.bool
};
var defaultProps = {
  isNewCar: false
};

function validateCarInput(errors, values) {
  if (!values.isNewCar) {
    (0, _formValidators.isRequired)(errors, values, 'car', _languageService.default.validationMessages.FieldRequired());
  } else {
    (0, _formValidators.isRequiredArray)(errors, values, ['newCar.registerNumber', 'newCar.carType'], _languageService.default.validationMessages.FieldRequired());
  }
}

function CarInput(_ref) {
  var showInputs = _ref.showInputs,
      cars = _ref.cars,
      isNewCar = _ref.isNewCar,
      carTypes = _ref.carTypes;
  return _react.default.createElement(_IconArea.default, {
    icon: _icons.default.fastTruck,
    iconClassName: "form-icon",
    iconTitle: _languageService.default.labels.Car(),
    alignTop: true
  }, _react.default.createElement(_ContentRevealer.default, {
    isVisible: !isNewCar
  }, _react.default.createElement(_FoxComboBoxField.default, {
    label: _languageService.default.labels.Car(),
    name: "car",
    required: true,
    items: cars,
    disabled: !showInputs
  })), _react.default.createElement(_ContentRevealer.default, {
    isVisible: showInputs,
    visibleClassName: _TransportFormModule.default.additional_top_offset
  }, _react.default.createElement(_FoxSwitchField.default, {
    label: _languageService.default.labels.NewCar(),
    placeholder: _languageService.default.labels.NewCar(),
    name: "isNewCar"
  })), _react.default.createElement(_ContentRevealer.default, {
    isVisible: isNewCar,
    visibleClassName: _TransportFormModule.default.additional_top_offset
  }, _react.default.createElement(_reduxForm.FormSection, {
    name: "newCar"
  }, _react.default.createElement(_NewCarForm.default, {
    carTypes: carTypes
  }))));
}

CarInput.propTypes = propTypes;
CarInput.defaultProps = defaultProps;

var _default = (0, _redux.compose)((0, _reduxForm.formValues)({
  isNewCar: 'isNewCar'
}), _react.memo)(CarInput);

exports.default = _default;