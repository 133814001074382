import { actionTypes } from './actionTypes';

const initialState = {
  transportOrderData: undefined,
};

const transportOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRANSPORT_ORDER_DATA:
      return {
        ...state,
        transportOrderData: action.payload,
      };
    case actionTypes.SET_TRANSPORT_ORDER_STATUS_HISTORY:
      return {
        ...state,
        transportOrderData: {
          ...state.transportOrderData,
          statusHistory: action.payload,
        },
      };
    default:
      return state;
  }
};

export default transportOrderReducer;
