import { displayToastr } from 'hh-shared/dist/toastr/toastrActions';
import lang from 'hh-shared/dist/language/services/languageService';
import ToastrTypes from 'hh-shared/dist/toastr/ToastrTypes';
import AuthSrv from './AuthSrv';
import actionTypes from './actionTypes';

export const storeUserInformations = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_STORE_USER_INFORMATIONS,
    data,
  });
};

export function storeTokens(accessToken, refreshToken) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
}

export const loginSuccess = (data) => (dispatch) => {
  storeTokens(data.accessToken, data.refreshToken);
  dispatch({
    type: actionTypes.AUTH_LOGIN_SUCCESS,
    data,
  });
};

export function setNewPasswordAsync(model) {
  return () => AuthSrv.setNewPasswordAsync(model);
}

export function resetPasswordAsync(model) {
  return (dispatch) =>
    AuthSrv.resetPassword(model).then(() => {
      dispatch(
        displayToastr(
          `${lang.validationMessages.NewPasswordWasSet()}`,
          ToastrTypes.success,
        ),
      );
    });
}

export function loginAsync(model) {
  return (dispatch) =>
    AuthSrv.login(model).then((data) => {
      dispatch(loginSuccess(data));
      dispatch(
        displayToastr(
          `${lang.validationMessages.Welcome()}
          ${data.firstName} ${data.lastName}`,
          ToastrTypes.success,
        ),
      );
    });
}

export function changePasswordFormAsync(model) {
  return (dispatch) =>
    AuthSrv.changePassword(model).then(() => {
      dispatch(
        displayToastr(
          lang.validationMessages.PasswordChanged(),
          ToastrTypes.success,
        ),
      );
    });
}

export const logout = () => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_LOGOUT,
  });
};

export function updateUserInformationAsync(model) {
  return (dispatch) =>
    AuthSrv.updateUserInformation(model).then((data) => {
      dispatch(storeUserInformations(data));
      dispatch(
        displayToastr(
          lang.validationMessages.UserInformationUpdated(),
          ToastrTypes.success,
        ),
      );
    });
}
