"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactDateinputs = require("@progress/kendo-react-dateinputs");

var _kendoReactIntl = require("@progress/kendo-react-intl");

var _numbers = _interopRequireDefault(require("cldr-numbers-full/main/es/numbers.json"));

var _caGregorian = _interopRequireDefault(require("cldr-dates-full/main/es/ca-gregorian.json"));

var _dateFields = _interopRequireDefault(require("cldr-dates-full/main/es/dateFields.json"));

var _timeZoneNames = _interopRequireDefault(require("cldr-dates-full/main/es/timeZoneNames.json"));

var _likelySubtags = _interopRequireDefault(require("cldr-core/supplemental/likelySubtags.json"));

var _currencyData = _interopRequireDefault(require("cldr-core/supplemental/currencyData.json"));

var _weekData = _interopRequireDefault(require("cldr-core/supplemental/weekData.json"));

var _controlUtils = require("../utils/controlUtils");

var _pl = _interopRequireDefault(require("./pl.json"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  label: _propTypes.default.string,
  value: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.object]).isRequired,
  placeholder: _propTypes.default.string,
  onChange: _propTypes.default.func.isRequired,
  error: _propTypes.default.string,
  invalid: _propTypes.default.bool,
  autoFocus: _propTypes.default.bool,
  format: _propTypes.default.string,
  formatPlaceholder: _propTypes.default.object,
  name: _propTypes.default.string.isRequired
};
var defaultProps = {
  label: '',
  placeholder: undefined,
  error: undefined,
  invalid: false,
  autoFocus: false,
  format: 'dd-MM-yyyy HH:mm',
  formatPlaceholder: {
    year: 'rrrr',
    month: 'mm',
    day: 'dd',
    hour: 'hh',
    minute: 'mm'
  }
};
(0, _kendoReactIntl.load)(_likelySubtags.default, _currencyData.default, _weekData.default, _numbers.default, _caGregorian.default, _dateFields.default, _timeZoneNames.default);
(0, _kendoReactIntl.loadMessages)(_pl.default, 'pl-PL');

function FoxDateTimePicker(_ref) {
  var label = _ref.label,
      value = _ref.value,
      placeholder = _ref.placeholder,
      onChange = _ref.onChange,
      error = _ref.error,
      invalid = _ref.invalid,
      autoFocus = _ref.autoFocus,
      format = _ref.format,
      formatPlaceholder = _ref.formatPlaceholder,
      name = _ref.name;

  var blurOnScroll = function blurOnScroll(event) {
    var target = event.target;
    target.blur();
  };

  var handleFocus = function handleFocus(event) {
    var target = event.target;
    target.addEventListener('wheel', blurOnScroll);
  };

  var handleChange = function handleChange(event) {
    var val = (0, _controlUtils.adjustDateToFormat)(event.target.value, format);
    return onChange({
      target: {
        value: val
      }
    });
  };

  return _react.default.createElement(_kendoReactIntl.LocalizationProvider, {
    language: "pl-PL"
  }, _react.default.createElement(_kendoReactIntl.IntlProvider, {
    locale: "pl"
  }, _react.default.createElement("div", {
    "data-name": name
  }, _react.default.createElement(_kendoReactDateinputs.DateTimePicker, {
    label: label,
    value: (0, _controlUtils.toDate)(value),
    placeholder: placeholder,
    onChange: handleChange,
    onFocus: handleFocus,
    validationMessage: error,
    valid: !invalid,
    autoFocus: autoFocus,
    format: format,
    formatPlaceholder: formatPlaceholder
  }))));
}

FoxDateTimePicker.propTypes = propTypes;
FoxDateTimePicker.defaultProps = defaultProps;

var _default = (0, _react.memo)(FoxDateTimePicker);

exports.default = _default;