"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _PageWrapperModule = _interopRequireDefault(require("./PageWrapper.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  additionalClassNames: _propTypes.default.string,
  roundedLeft: _propTypes.default.bool,
  roundedRight: _propTypes.default.bool,
  rounded: _propTypes.default.bool
};
var defaultProps = {
  additionalClassNames: '',
  roundedLeft: false,
  roundedRight: false,
  rounded: false
};

function PageWrapper(_ref) {
  var _cx;

  var children = _ref.children,
      additionalClassNames = _ref.additionalClassNames,
      roundedLeft = _ref.roundedLeft,
      roundedRight = _ref.roundedRight,
      rounded = _ref.rounded;
  var wrapperClassName = (0, _classnames.default)(_PageWrapperModule.default.page_wrapper, additionalClassNames, (_cx = {}, _defineProperty(_cx, _PageWrapperModule.default.rounded_left, roundedLeft), _defineProperty(_cx, _PageWrapperModule.default.rounded_right, roundedRight), _defineProperty(_cx, _PageWrapperModule.default.rounded, rounded), _cx));
  return _react.default.createElement("div", {
    className: wrapperClassName
  }, children);
}

PageWrapper.propTypes = propTypes;
PageWrapper.defaultProps = defaultProps;
var _default = PageWrapper;
exports.default = _default;