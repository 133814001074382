"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _get = _interopRequireDefault(require("lodash/get"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _FontAwesomeIcon = _interopRequireDefault(require("../commons/FontAwesomeIcon/FontAwesomeIcon"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _Column = _interopRequireDefault(require("../layout/Column"));

var _PersonDataBadge = _interopRequireDefault(require("../commons/PersonDataBadge"));

var _TimelineItemModule = _interopRequireDefault(require("./TimelineItem.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  statusHistory: _propTypes.default.exact({
    time: _propTypes.default.string,
    statusId: _propTypes.default.string,
    userFullName: _propTypes.default.string,
    userPhone: _propTypes.default.string,
    userAvatar: _propTypes.default.string,
    message: _propTypes.default.string
  }),
  lastActiveItem: _propTypes.default.bool
};
var defaultProps = {
  statusHistory: undefined,
  lastActiveItem: false
};

var TimelineItemUser = function TimelineItemUser(_ref) {
  var statusHistory = _ref.statusHistory,
      lastActiveItem = _ref.lastActiveItem;
  var completionDate = statusHistory && statusHistory.time;
  var verticalLineClassName = (0, _classnames.default)(_TimelineItemModule.default.vertical_line, _defineProperty({}, _TimelineItemModule.default.disabled, lastActiveItem));
  var letterIconClassName = (0, _classnames.default)(_TimelineItemModule.default.item_icon, _TimelineItemModule.default.item_icon_letter);
  return _react.default.createElement(_Column.default, {
    className: _TimelineItemModule.default.icon_column
  }, completionDate && (0, _get.default)(statusHistory, 'userFullName') ? _react.default.createElement(_PersonDataBadge.default, {
    fullName: statusHistory.userFullName,
    phoneNumber: statusHistory.userPhone ? statusHistory.userPhone : undefined,
    avatar: statusHistory.userAvatar,
    reverse: true
  }) : _react.default.createElement("div", {
    className: _TimelineItemModule.default.item_circle
  }, !completionDate ? _react.default.createElement(_FontAwesomeIcon.default, {
    icon: _icons.default.cross,
    className: _TimelineItemModule.default.item_icon
  }) : _react.default.createElement("span", {
    className: letterIconClassName,
    title: _languageService.default.labels.AutomaticallyExposed()
  }, "A")), _react.default.createElement("div", {
    className: verticalLineClassName
  }));
};

TimelineItemUser.propTypes = propTypes;
TimelineItemUser.defaultProps = defaultProps;
var _default = TimelineItemUser;
exports.default = _default;