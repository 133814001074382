"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _moment = _interopRequireDefault(require("moment"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _Column = _interopRequireDefault(require("../layout/Column"));

var _transportOrderStatusesIds = _interopRequireDefault(require("../../consts/transportOrderStatusesIds"));

var _TransportStatusLabel = _interopRequireDefault(require("./TransportStatusLabel"));

var _TimelineItemDescriptionModule = _interopRequireDefault(require("./TimelineItemDescription.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  statusHistory: _propTypes.default.shape({
    time: _propTypes.default.string,
    userFullName: _propTypes.default.string,
    userPhone: _propTypes.default.string,
    userAvatar: _propTypes.default.string,
    statusId: _propTypes.default.string,
    message: _propTypes.default.string
  }),
  children: _propTypes.default.node
};
var defaultProps = {
  statusHistory: undefined,
  children: undefined
};

var TimelineItemDescription = function TimelineItemDescription(_ref) {
  var statusHistory = _ref.statusHistory,
      children = _ref.children;

  var getStatusText = function getStatusText(statusId) {
    switch (statusId) {
      case _transportOrderStatusesIds.default.placed:
        return _languageService.default.labels.Exposed();

      case _transportOrderStatusesIds.default.accepted:
        return _languageService.default.labels.AcceptedForRealization();

      case _transportOrderStatusesIds.default.loaded:
        return _languageService.default.labels.Loaded();

      case _transportOrderStatusesIds.default.realized:
        return _languageService.default.labels.Completed();

      default:
        return _languageService.default.labels.TransportOrder();
    }
  };

  return _react.default.createElement(_Column.default, {
    className: _TimelineItemDescriptionModule.default.content
  }, statusHistory && _react.default.createElement(_react.default.Fragment, null, statusHistory.statusId && statusHistory.time ? _react.default.createElement(_TransportStatusLabel.default, {
    className: _TimelineItemDescriptionModule.default.label,
    statusId: statusHistory.statusId
  }) : _react.default.createElement("div", {
    className: _TimelineItemDescriptionModule.default.status_label
  }, getStatusText(statusHistory.statusId)), statusHistory.time && _react.default.createElement("div", {
    className: _TimelineItemDescriptionModule.default.date
  }, statusHistory.time && (0, _moment.default)(statusHistory.time).format('DD.MM.YYYY HH:mm'))), children && _react.default.createElement("div", {
    className: _TimelineItemDescriptionModule.default.children_container
  }, children));
};

TimelineItemDescription.propTypes = propTypes;
TimelineItemDescription.defaultProps = defaultProps;
var _default = TimelineItemDescription;
exports.default = _default;