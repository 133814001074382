import React from 'react';
import PropTypes from 'prop-types';

import auctionStatusesIds from 'hh-shared/dist/utils/auctionStatusesIds';
import Column from 'hh-shared/dist/components/layout/Column';
import PageWrapper from 'hh-shared/dist/components/layout/PageWrapper';

import moment from 'moment';
import MainInfo from './MainInfo/MainInfo';
import TransportInfo from './TransportInfo/TransportInfo';
import PriceSection from './PriceSection/PriceSection';
import styles from './AuctionPageLeftPane.module.scss';

const propTypes = {
  isObservedChange: PropTypes.func.isRequired,
  reloadPage: PropTypes.func.isRequired,
  auctionStatus: PropTypes.object,
  auctionId: PropTypes.string.isRequired,
  auctionEndTime: PropTypes.string.isRequired,
};

const defaultProps = {
  auctionStatus: undefined,
};

const AuctionPageLeftPane = ({
  isObservedChange,
  reloadPage,
  auctionStatus,
  auctionId,
  auctionEndTime,
}) => {
  const auctionHasLiveStatus =
    auctionStatus && auctionStatus.id === auctionStatusesIds.live;
  const isNotAfterEndTime = moment(auctionEndTime).isAfter(moment()); // TODO ppolak: komponent MainInfo.jsx też z tego kożysta żeby wyświetlić labelke zakończoną dobrze by było to wyciągnąc wyżej do komponentu
  const auctionIsActive = auctionHasLiveStatus && isNotAfterEndTime;
  return (
    <Column bigger>
      <PageWrapper roundedLeft additionalClassNames={styles.section_container}>
        <MainInfo {...{ isObservedChange, reloadPage }} />
        <TransportInfo />
        {auctionIsActive && <PriceSection auctionId={auctionId} />}
      </PageWrapper>
    </Column>
  );
};

AuctionPageLeftPane.propTypes = propTypes;
AuctionPageLeftPane.defaultProps = defaultProps;

export default AuctionPageLeftPane;
