"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  className: _propTypes.default.string,
  children: _propTypes.default.node,
  bigger: _propTypes.default.bool,
  smaller: _propTypes.default.bool,
  biggerPreview: _propTypes.default.bool,
  smallerPreview: _propTypes.default.bool,
  onClick: _propTypes.default.func,
  style: _propTypes.default.object,
  rounded: _propTypes.default.bool
};
var defaultProps = {
  className: '',
  children: undefined,
  bigger: false,
  smaller: false,
  biggerPreview: false,
  smallerPreview: false,
  onClick: function onClick() {},
  style: {},
  rounded: false
};

function Column(_ref) {
  var className = _ref.className,
      children = _ref.children,
      bigger = _ref.bigger,
      smaller = _ref.smaller,
      biggerPreview = _ref.biggerPreview,
      smallerPreview = _ref.smallerPreview,
      onClick = _ref.onClick,
      style = _ref.style,
      rounded = _ref.rounded;
  var columnClassName = (0, _classnames.default)(className, 'flex-wrapper', 'flex-wrapper-column', {
    'flex-wrapper-column-bigger': bigger,
    'flex-wrapper-column-smaller': smaller,
    'flex-wrapper-column-bigger-preview': biggerPreview,
    'flex-wrapper-column-smaller-preview': smallerPreview,
    rounded: rounded
  });
  return _react.default.createElement("div", {
    onClick: onClick,
    style: style,
    className: columnClassName
  }, children);
}

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;
var _default = Column;
exports.default = _default;