import React from 'react';
import PropTypes from 'prop-types';
import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import gridNames from 'common/gridNames';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import FoxButton from 'fox-react/dist/components/FoxButton';
import lang from 'hh-shared/dist/language/services/languageService';
import actionsColumnGridDefaults from 'hh-shared/dist/consts/actionsColumnGridDefaults';
import { getForGrid } from './actions';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.cars);

function CarsPage({ match }) {
  const dispatch = useDispatch();
  const onLoadData = dataState => dispatch(getForGrid(dataState));

  return (
    <InnerContentWrapper>
      <FoxGridContainer
        onLoadData={onLoadData}
        defaultSortField="registerNumber"
        renderButtons={
          <Link to={`${match.url}/add`}>
            <FoxButton icon="add" primary>
              {lang.buttons.Add()}
            </FoxButton>
          </Link>
        }
      >
        <FoxGridColumn
          {...actionsColumnGridDefaults}
          cell={({ dataItem }) => (
            <td>
              <Link to={`${match.url}/edit/${dataItem.instanceId}`}>
                <FoxButton icon="edit">{lang.buttons.Edit()}</FoxButton>
              </Link>
            </td>
          )}
        />
        <FoxGridColumn
          field="registerNumber"
          title={lang.labels.RegisterNumber()}
          filter="text"
        />
        <FoxGridColumn
          field="carType"
          title={lang.labels.CarType()}
          filter="text"
        />
      </FoxGridContainer>
    </InnerContentWrapper>
  );
}

CarsPage.propTypes = propTypes;
CarsPage.defaultProps = defaultProps;

export default CarsPage;
