"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactDropdowns = require("@progress/kendo-react-dropdowns");

var _kendoDataQuery = require("@progress/kendo-data-query");

var _kendoReactIntl = require("@progress/kendo-react-intl");

var _pl = _interopRequireDefault(require("./pl.json"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  label: _propTypes.default.string,
  value: _propTypes.default.any.isRequired,
  onChange: _propTypes.default.func.isRequired,
  error: _propTypes.default.string,
  invalid: _propTypes.default.bool,
  items: _propTypes.default.array,
  disabled: _propTypes.default.bool,
  name: _propTypes.default.string.isRequired
};
var defaultProps = {
  label: '',
  error: undefined,
  invalid: false,
  items: [],
  disabled: false
};

function filterData(filter, allData) {
  var data = allData.slice();
  return (0, _kendoDataQuery.filterBy)(data, filter);
}

var timeout;
var pageSize = 11;

function FoxComboBox(_ref) {
  var label = _ref.label,
      value = _ref.value,
      onChange = _ref.onChange,
      error = _ref.error,
      invalid = _ref.invalid,
      items = _ref.items,
      disabled = _ref.disabled,
      name = _ref.name;

  var _useState = (0, _react.useState)([]),
      _useState2 = _slicedToArray(_useState, 2),
      filteredData = _useState2[0],
      setFilteredData = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = _slicedToArray(_useState3, 2),
      data = _useState4[0],
      setData = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = _slicedToArray(_useState5, 2),
      loading = _useState6[0],
      setLoading = _useState6[1];

  var _useState7 = (0, _react.useState)(0),
      _useState8 = _slicedToArray(_useState7, 2),
      skip = _useState8[0],
      setSkip = _useState8[1];

  var _useState9 = (0, _react.useState)(items.length),
      _useState10 = _slicedToArray(_useState9, 2),
      total = _useState10[0],
      setTotal = _useState10[1];

  (0, _react.useEffect)(function () {
    var df = items.slice();
    setFilteredData(df);
    setData(df.slice(0, pageSize));
    setTotal(df.length);
  }, [items]);

  var filterChange = function filterChange(event) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      var fd = filterData(event.filter, items);
      setFilteredData(fd);
      setData(fd.slice(0, pageSize));
      setLoading(false);
      setTotal(fd.length);
    }, 500);
    setLoading(true);
  };

  var pageChange = function pageChange(event) {
    var newSubsetData = filteredData.slice(event.page.skip, event.page.skip + event.page.take);
    setData(newSubsetData);
    setSkip(event.page.skip);
  };

  (0, _kendoReactIntl.loadMessages)(_pl.default, 'pl-PL');
  return _react.default.createElement(_kendoReactIntl.LocalizationProvider, {
    language: "pl-PL"
  }, _react.default.createElement(_kendoReactIntl.IntlProvider, {
    locale: "pl"
  }, _react.default.createElement("div", {
    "data-name": name
  }, _react.default.createElement(_kendoReactDropdowns.ComboBox, {
    label: label,
    onChange: onChange,
    validationMessage: error,
    valid: !invalid,
    data: data,
    dataItemKey: "id",
    textField: "name",
    value: value,
    disabled: disabled,
    filterable: true,
    onFilterChange: filterChange,
    onPageChange: pageChange,
    loading: loading,
    popupSettings: {
      height: '210px'
    },
    virtual: {
      total: total,
      pageSize: pageSize,
      skip: skip
    },
    name: name
  }))));
}

FoxComboBox.propTypes = propTypes;
FoxComboBox.defaultProps = defaultProps;
var _default = FoxComboBox;
exports.default = _default;