"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _set = _interopRequireDefault(require("lodash/set"));

var _initialState = _interopRequireDefault(require("./initialState"));

var _utils = require("./utils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

var applyColumnsProps = function applyColumnsProps(currentColumnsInfo, newColumnsProps) {
  var newColumnsInfo = _toConsumableArray(currentColumnsInfo);

  var _loop = function _loop(i) {
    var toUpdate = newColumnsInfo.find(function (ci) {
      return ci.field === newColumnsProps[i].field;
    });

    if (toUpdate) {
      Object.assign(toUpdate, _objectSpread({}, newColumnsProps[i]));
    } else {
      newColumnsInfo.push(_objectSpread({}, newColumnsProps[i]));
    }
  };

  for (var i = 0; i < newColumnsProps.length; i++) {
    _loop(i);
  }

  return _toConsumableArray(newColumnsInfo);
};

var foxGridReducer = function foxGridReducer(prefix) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _initialState.default;
    var action = arguments.length > 1 ? arguments[1] : undefined;

    if (!state.results) {
      (0, _set.default)(state, 'results', _initialState.default.results);
    }

    if (!state.dataState) {
      (0, _set.default)(state, 'dataState', _initialState.default.dataState);
    }

    switch (action.type) {
      case "".concat(prefix, "_STORE_RESULTS"):
        return _objectSpread({}, state, {
          results: action.results
        });

      case "".concat(prefix, "_STORE_GROUP"):
        return _objectSpread({}, state, {
          dataState: _objectSpread({}, state.dataState, {
            group: action.group
          })
        });

      case "".concat(prefix, "_STORE_DATASTATE"):
        return _objectSpread({}, state, {
          dataState: action.dataState
        });

      case "".concat(prefix, "_SET_DEFAULT_SORT"):
        return _objectSpread({}, state, {
          dataState: _objectSpread({}, (0, _utils.setNewSort)(state.dataState, action.defaultSort))
        });

      case "".concat(prefix, "_CLEAR_FILTERS"):
        return _objectSpread({}, state, {
          dataState: _objectSpread({}, state.dataState, {
            filter: undefined
          })
        });

      case "".concat(prefix, "_SET_NEW_ORDER"):
      case "".concat(prefix, "_SET_COLUMNS_SIZES"):
      case "".concat(prefix, "_SET_COLUMNS_SUBMIT"):
        return _objectSpread({}, state, {
          columnsInfo: applyColumnsProps(state.columnsInfo, action.payload)
        });

      default:
        return state;
    }
  };
};

var _default = foxGridReducer;
exports.default = _default;