"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactInputs = require("@progress/kendo-react-inputs");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  value: _propTypes.default.string,
  onChange: _propTypes.default.func.isRequired,
  mask: _propTypes.default.string.isRequired,
  invalid: _propTypes.default.bool,
  name: _propTypes.default.string.isRequired,
  onBlur: _propTypes.default.func.isRequired,
  autoFocus: _propTypes.default.bool,
  disabled: _propTypes.default.bool
};
var defaultProps = {
  value: '',
  invalid: false,
  autoFocus: undefined,
  disabled: false
};

function FoxMaskedInputText(_ref) {
  var value = _ref.value,
      _onChange = _ref.onChange,
      mask = _ref.mask,
      invalid = _ref.invalid,
      disabled = _ref.disabled,
      name = _ref.name,
      autoFocus = _ref.autoFocus,
      _onBlur = _ref.onBlur;
  var invalidClassName = (0, _classnames.default)({
    'k-state-invalid': invalid
  });
  return _react.default.createElement(_kendoReactInputs.MaskedTextBox, {
    disabled: disabled,
    name: name,
    autoFocus: autoFocus,
    value: value,
    onBlur: function onBlur() {
      return _onBlur();
    },
    onChange: function onChange(event) {
      var fakeE = {
        target: {
          value: event.target.value
        }
      };

      _onChange(fakeE);
    },
    mask: mask,
    maskValidation: false,
    className: invalidClassName
  });
}

FoxMaskedInputText.propTypes = propTypes;
FoxMaskedInputText.defaultProps = defaultProps;

var _default = (0, _react.memo)(FoxMaskedInputText);

exports.default = _default;