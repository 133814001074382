"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // Default Grid filter operators:

var foxGridFilterOperators = {
  text: [{
    text: 'grid.filterContainsOperator',
    operator: 'contains'
  }, {
    text: 'grid.filterEqOperator',
    operator: 'eqs'
  }, {
    text: 'grid.filterNotEqOperator',
    operator: 'neq'
  }, {
    text: 'grid.filterStartsWithOperator',
    operator: 'startswith'
  }, {
    text: 'grid.filterEndsWithOperator',
    operator: 'endswith'
  }, {
    text: 'grid.filterIsNullOperator',
    operator: 'isnull'
  }, {
    text: 'grid.filterIsNotNullOperator',
    operator: 'isnotnull'
  }, {
    text: 'grid.filterIsEmptyOperator',
    operator: 'isempty'
  }, {
    text: 'grid.filterIsNotEmptyOperator',
    operator: 'isnotempty'
  }],
  numeric: [{
    text: 'grid.filterEqOperator',
    operator: 'eqn'
  }, {
    text: 'grid.filterNotEqOperator',
    operator: 'neq'
  }, {
    text: 'grid.filterIsNullOperator',
    operator: 'isnull'
  }, {
    text: 'grid.filterIsNotNullOperator',
    operator: 'isnotnull'
  }],
  date: [{
    text: 'grid.filterGtOperator',
    operator: 'dgt'
  }, {
    text: 'grid.filterLtOperator',
    operator: 'dlt'
  }]
};
var _default = foxGridFilterOperators;
exports.default = _default;