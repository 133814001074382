"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _FontAwesomeIcon = _interopRequireDefault(require("../commons/FontAwesomeIcon/FontAwesomeIcon"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _StarHeaderCellModule = _interopRequireDefault(require("./StarHeaderCell.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.node,
  onClick: _propTypes.default.func.isRequired
};
var defaultProps = {
  children: undefined
};

var StarHeaderCell = function StarHeaderCell(_ref) {
  var onClick = _ref.onClick,
      children = _ref.children;
  var starHeaderClass = (0, _classnames.default)(_StarHeaderCellModule.default.star_header, 'icon-cell');
  return _react.default.createElement("div", {
    onClick: onClick,
    className: starHeaderClass
  }, children, _react.default.createElement(_FontAwesomeIcon.default, {
    icon: _icons.default.filledStar,
    title: _languageService.default.labels.Observed(),
    className: _StarHeaderCellModule.default.icon
  }));
};

StarHeaderCell.propTypes = propTypes;
StarHeaderCell.defaultProps = defaultProps;
var _default = StarHeaderCell;
exports.default = _default;