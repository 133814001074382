"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeFormat = exports.dateFormat = exports.dateTimeFormat = void 0;
var dateTimeFormat = 'DD-MM-YYYY HH:mm';
exports.dateTimeFormat = dateTimeFormat;
var dateFormat = 'DD-MM-YYYY';
exports.dateFormat = dateFormat;
var timeFormat = 'HH:mm';
exports.timeFormat = timeFormat;