"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactInlinesvg = _interopRequireDefault(require("react-inlinesvg"));

var _classnames = _interopRequireDefault(require("classnames"));

require("./FontAwesomeIcon.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var propTypes = {
  icon: _propTypes.default.string.isRequired,
  size: _propTypes.default.string,
  className: _propTypes.default.string
};
var defaultProps = {
  size: undefined,
  className: undefined
};
var classesFromLibrary = 'svg-inline--fa fa-w-20';

var FontAwesomeIcon = function FontAwesomeIcon(_ref) {
  var icon = _ref.icon,
      size = _ref.size,
      className = _ref.className,
      rest = _objectWithoutProperties(_ref, ["icon", "size", "className"]);

  var svgClassName = (0, _classnames.default)(className, classesFromLibrary, _defineProperty({}, "fa-".concat(size), size));
  return _react.default.createElement(_reactInlinesvg.default, _extends({
    src: "".concat(icon, ".svg"),
    className: svgClassName
  }, rest));
};

FontAwesomeIcon.propTypes = propTypes;
FontAwesomeIcon.defaultProps = defaultProps;
var _default = FontAwesomeIcon;
exports.default = _default;