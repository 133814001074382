"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Row = _interopRequireDefault(require("layout/Row"));

var _Column = _interopRequireDefault(require("layout/Column"));

var _IconArea = _interopRequireDefault(require("../commons/IconArea"));

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _numberFormating = _interopRequireDefault(require("../../consts/numberFormating"));

var _scrollMethods = require("../../utils/scrollMethods");

var _SummarySectionModule = _interopRequireDefault(require("./SummarySection.module.scss"));

var _Timeline = _interopRequireDefault(require("./Timeline"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  data: _propTypes.default.object.isRequired,
  renderAdditionalContent: _propTypes.default.node,
  onAddMessageClick: _propTypes.default.func.isRequired
};
var defaultProps = {
  renderAdditionalContent: undefined
};

function SummarySection(_ref) {
  var data = _ref.data,
      renderAdditionalContent = _ref.renderAdditionalContent,
      onAddMessageClick = _ref.onAddMessageClick;
  var toMoneyFormat = _numberFormating.default.toMoneyFormat,
      insertThousandsSeparator = _numberFormating.default.insertThousandsSeparator;
  var firstRowClassName = (0, _classnames.default)('justify-content-space-evenly', _SummarySectionModule.default.first_row);
  (0, _react.useEffect)(function () {
    var timelineEl = document.querySelector(".".concat(_SummarySectionModule.default.timeline_container));
    timelineEl && (0, _scrollMethods.scrollToTheBottomOfTheElement)(timelineEl, 1250);
  }, []);
  return _react.default.createElement("div", {
    className: _SummarySectionModule.default.summary
  }, _react.default.createElement(_Row.default, {
    className: firstRowClassName
  }, _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.money,
    iconTitle: _languageService.default.labels.DeliveryExpense(),
    className: "align-self-start"
  }, _react.default.createElement("span", null, _languageService.default.labels.DeliveryExpense()), _react.default.createElement("div", null, toMoneyFormat(data.value), " ", data.currency))), _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.route,
    iconTitle: _languageService.default.labels.Distance(),
    className: "align-self-end"
  }, _react.default.createElement("span", null, _languageService.default.labels.Distance()), _react.default.createElement("div", null, insertThousandsSeparator(data.distance), " km"))), _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.coins,
    iconTitle: _languageService.default.labels.PriceForKilemeter(),
    className: "align-self-end"
  }, _react.default.createElement("span", null, _languageService.default.labels.PriceForKilemeter()), _react.default.createElement("div", null, toMoneyFormat(data.value / data.distance), " ", data.currency)))), _react.default.createElement(_Column.default, {
    className: _SummarySectionModule.default.timeline_container
  }, _react.default.createElement(_Timeline.default, {
    statusHistory: data.statusHistory,
    onAddMessageClick: onAddMessageClick
  })), _react.default.createElement(_Row.default, null, _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.letter,
    iconTitle: _languageService.default.labels.InvoiceSendToaddress(),
    alignTop: true
  }, _react.default.createElement("span", null, _languageService.default.labels.InvoiceSendToaddress(), ":"), _react.default.createElement("div", null, data.depInvoiceDestinationName), _react.default.createElement("div", null, data.depInvoiceDestinationStreet), _react.default.createElement("div", null, data.depInvoiceDestinationZipCode, ' ', data.depInvoiceDestinationCity))), _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.email,
    iconTitle: _languageService.default.labels.ElectricInvoice(),
    alignTop: true
  }, _react.default.createElement("span", null, _languageService.default.labels.ElectricInvoice(), ":"), _react.default.createElement("div", null, data.depEmailInvoiceAddress)))), renderAdditionalContent);
}

SummarySection.propTypes = propTypes;
SummarySection.defaultProps = defaultProps;
var _default = SummarySection;
exports.default = _default;