import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  isRequiredArray,
  areEquals,
  isValidPasswordArray,
} from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputPasswordField from 'fox-react/dist/reduxFormFields/FoxInputPasswordField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import icons from 'hh-shared/dist/consts/icons';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

function UserPasswordChangeForm({ handleSubmit, submitting }) {
  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.UserChangePassword()}
            titleIcon={icons.password}
            fullContentWidth
          >
            <FoxInputPasswordField
              label={lang.labels.CurrentPassword()}
              placeholder={lang.labels.CurrentPassword()}
              name={fieldNames.userPasswordChangeForm.currentPassword}
            />

            <FoxInputPasswordField
              label={lang.labels.NewPassword()}
              placeholder={lang.labels.NewPassword()}
              name={fieldNames.userPasswordChangeForm.newPassword}
            />

            <FoxInputPasswordField
              label={lang.labels.RepeatPassword()}
              placeholder={lang.labels.RepeatPassword()}
              name={fieldNames.userPasswordChangeForm.repeatPassword}
            />

            <FoxButton disabled={submitting} primary>
              {lang.buttons.Update()}
            </FoxButton>
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
    </Form>
  );
}

UserPasswordChangeForm.propTypes = propTypes;
UserPasswordChangeForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.userPasswordChangeForm.currentPassword,
      fieldNames.userPasswordChangeForm.newPassword,
      fieldNames.userPasswordChangeForm.repeatPassword,
    ],
    lang.validationMessages.FieldRequired(),
  );

  isValidPasswordArray(
    errors,
    values,
    [
      fieldNames.userPasswordChangeForm.newPassword,
      fieldNames.userPasswordChangeForm.repeatPassword,
    ],
    lang.validationMessages.NotValidPassword(),
  );

  areEquals(
    errors,
    values,
    [
      fieldNames.userPasswordChangeForm.newPassword,
      fieldNames.userPasswordChangeForm.repeatPassword,
    ],
    lang.validationMessages.PasswordsNotEquals(),
  );

  return errors;
};

export default reduxForm({
  form: formNames.userPasswordChangeForm,
  validate,
})(UserPasswordChangeForm);
