import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ContentWrapper from 'hh-shared/dist/components/layout/ContentWrapper';
import Footer from 'hh-shared/dist/components/layout/Footer';
import SideMenuContainer from './Menu/SideMenuContainer';
import styles from './DefaultLayout.module.scss';
import Header from './Header';
import Row from './Row';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const defaultProps = {};

function DefaultLayout({ children }) {
  const menuIsClosed = useSelector(state => !state.sideMenu.isOpened);

  return (
    <>
      <Header />
      <Row
        className={`${styles.layout_row} ${
          menuIsClosed ? styles.shrinked : ''
        }`}
      >
        <SideMenuContainer />
        <ContentWrapper additionalClassNames="flex-item padding padding-small padding-small-left">
          {children}
        </ContentWrapper>
      </Row>
      <Footer />
    </>
  );
}

DefaultLayout.propTypes = propTypes;
DefaultLayout.defaultProps = defaultProps;

export default DefaultLayout;
