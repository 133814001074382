"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRedux = require("react-redux");

var _FontAwesomeIcon = _interopRequireDefault(require("./FontAwesomeIcon/FontAwesomeIcon"));

var _classnames = _interopRequireDefault(require("classnames"));

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _copyToClipboard = _interopRequireDefault(require("../../utils/copyToClipboard"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _Row = _interopRequireDefault(require("../layout/Row"));

var _toastrActions = require("../../toastr/toastrActions");

var _ToastrTypes = _interopRequireDefault(require("../../toastr/ToastrTypes"));

var _PersonDataBadgeModule = _interopRequireDefault(require("./PersonDataBadge.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  fullName: _propTypes.default.string.isRequired,
  avatar: _propTypes.default.string,
  phoneNumber: _propTypes.default.string,
  reverse: _propTypes.default.bool,
  className: _propTypes.default.string
};
var defaultProps = {
  avatar: undefined,
  phoneNumber: undefined,
  reverse: false,
  className: ''
};

var PersonDataBadge = function PersonDataBadge(_ref) {
  var _cx;

  var fullName = _ref.fullName,
      avatar = _ref.avatar,
      phoneNumber = _ref.phoneNumber,
      reverse = _ref.reverse,
      className = _ref.className;
  var dispatch = (0, _reactRedux.useDispatch)();
  var fullNameRef = (0, _react.useRef)(null);
  var phoneNumberRef = (0, _react.useRef)(null);
  var defaultMaxTextWidth = 110;

  var _useState = (0, _react.useState)(defaultMaxTextWidth),
      _useState2 = _slicedToArray(_useState, 2),
      maxNameWidth = _useState2[0],
      setMaxNameWidth = _useState2[1];

  var _useState3 = (0, _react.useState)(defaultMaxTextWidth),
      _useState4 = _slicedToArray(_useState3, 2),
      maxPhoneNumberWidth = _useState4[0],
      setMaxPhoneNumberWidth = _useState4[1];

  var rowClassName = (0, _classnames.default)(_PersonDataBadgeModule.default.container, 'align-items-center', className, (_cx = {}, _defineProperty(_cx, _PersonDataBadgeModule.default.container_reverse, reverse), _defineProperty(_cx, _PersonDataBadgeModule.default.container_disabled, !phoneNumber), _cx));
  var avatarClassName = (0, _classnames.default)(_PersonDataBadgeModule.default.avatar, _defineProperty({}, _PersonDataBadgeModule.default.avatar_with_image, avatar));
  var informationClassName = (0, _classnames.default)(_PersonDataBadgeModule.default.information, _defineProperty({}, _PersonDataBadgeModule.default.information_reverse, reverse));

  var handleOnClick = function handleOnClick() {
    phoneNumber && function () {
      (0, _copyToClipboard.default)(phoneNumber);
      dispatch((0, _toastrActions.displayToastr)("".concat(_languageService.default.labels.SuccessfullyAddedPhoneNumberToTheClipboard(), ": ").concat(phoneNumber), _ToastrTypes.default.info));
    }();
  };

  (0, _react.useEffect)(function () {
    var fullNameElement = fullNameRef.current;
    var phoneNumberElement = phoneNumberRef.current;
    setMaxNameWidth(fullNameElement.scrollWidth || defaultMaxTextWidth);
    setMaxPhoneNumberWidth(phoneNumberElement.scrollWidth || defaultMaxTextWidth);
  }, []);
  return _react.default.createElement(_Row.default, {
    className: rowClassName,
    onClick: handleOnClick,
    title: phoneNumber ? _languageService.default.labels.CopyThePhoneNumber() : _languageService.default.labels.PhoneNumberWasntProvided(),
    style: {
      '--max-name-width': "".concat(maxNameWidth, "px"),
      '--max-phone-number-width': "".concat(maxPhoneNumberWidth, "px")
    }
  }, _react.default.createElement("div", {
    className: avatarClassName,
    "data-letter": fullName.charAt(0),
    style: {
      backgroundImage: avatar ? "url(".concat(avatar, ")") : ''
    }
  }, _react.default.createElement(_FontAwesomeIcon.default, {
    icon: phoneNumber ? _icons.default.copy : _icons.default.cross,
    className: _PersonDataBadgeModule.default.icon
  })), _react.default.createElement(_Row.default, {
    className: informationClassName
  }, _react.default.createElement("div", {
    className: _PersonDataBadgeModule.default.information_name,
    ref: fullNameRef
  }, fullName), _react.default.createElement("div", {
    className: _PersonDataBadgeModule.default.information_phone_number,
    ref: phoneNumberRef
  }, phoneNumber || _languageService.default.labels.NotGiven())));
};

PersonDataBadge.propTypes = propTypes;
PersonDataBadge.defaultProps = defaultProps;
var _default = PersonDataBadge;
exports.default = _default;