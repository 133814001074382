import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';

import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';
import { phoneMaskedDisplay } from 'hh-shared/dist/utils/phoneMask';

const propTypes = {};

const defaultProps = {};

const CompanyPagePreview = () => {
  const formValues = useSelector(
    state => getFormValues(formNames.companyForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={lang.labels.CompanyInformations()}
        icon={icons.company}
      >
        <FormPreviewItem label={lang.labels.Nip()} value={formValues.nip} />
        <FormPreviewItem label={lang.labels.Regon()} value={formValues.regon} />

        <FormPreviewItem label={lang.labels.Name()} value={formValues.name} />

        <FormPreviewItem
          label={lang.labels.Addresses()}
          value={formValues.address}
        />

        <FormPreviewItem
          label={lang.labels.ZipCode()}
          value={formValues.zipCode}
        />

        <FormPreviewItem label={lang.labels.City()} value={formValues.city} />

        <FormPreviewItem
          label={lang.labels.PhoneNumber()}
          value={phoneMaskedDisplay(formValues.phoneNumber)}
        />
      </FormPreviewSection>
      <FormPreviewSection
        title={`${lang.labels.Drivers()} ( ${(formValues.drivers &&
          formValues.drivers.length) ||
          0} )`}
        icon={icons.driver}
      >
        {formValues &&
          formValues.drivers &&
          formValues.drivers.map((driver, driverIndex) => (
            <React.Fragment key={driverIndex}>
              <FormPreviewItem
                label={`${lang.labels.Driver()} nr`}
                value={driverIndex + 1}
              />
              <FormPreviewItem
                label={lang.labels.Name()}
                value={driver.firstName}
              />
              <FormPreviewItem
                label={lang.labels.LastName()}
                value={driver.lastName}
              />
              <FormPreviewItem
                label={lang.labels.PhoneNumber()}
                value={driver.phoneNumber}
              />
            </React.Fragment>
          ))}
      </FormPreviewSection>

      <FormPreviewSection
        title={`${lang.labels.Cars()} ( ${(formValues.cars &&
          formValues.cars.length) ||
          0} )`}
        icon={icons.vehicle}
      >
        {formValues &&
          formValues.cars &&
          formValues.cars.map((car, carIndex) => (
            <React.Fragment key={carIndex}>
              <FormPreviewItem
                label={`${lang.labels.Car()} nr`}
                value={carIndex + 1}
              />
              <FormPreviewItem
                label={lang.labels.RegisterNumber()}
                value={car.registerNumber}
              />
              <FormPreviewItem
                label={lang.labels.CarType()}
                value={get(car, 'cartType.name')}
              />
              <FormPreviewItem
                label={lang.labels.Comment()}
                value={car.comment}
              />
            </React.Fragment>
          ))}
      </FormPreviewSection>
      <FormPreviewSection
        title={lang.labels.BidingByHHAllowed()}
        icon={icons.approval}
      >
        <FormPreviewItem
          label={lang.labels.Blocked()}
          value={
            formValues.bidingByHHAllowed ? lang.labels.Yes() : lang.labels.No()
          }
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

CompanyPagePreview.propTypes = propTypes;
CompanyPagePreview.defaultProps = defaultProps;

export default CompanyPagePreview;
