import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Badge from 'hh-shared/dist/components/commons/Badge';
import Row from 'hh-shared/dist/components/layout/Row';
import cx from 'classnames';
import lang from 'hh-shared/dist/language/services/languageService';
import PersonDataBadge from 'hh-shared/dist/components/commons/PersonDataBadge';
import styles from './BidsTable.module.scss';

const propTypes = {
  companyName: PropTypes.string.isRequired,
  user: PropTypes.object,
};

const defaultProps = {
  user: undefined,
};

function BidsTableSupplier({ companyName, user }) {
  const badgeRowClassName = cx(
    'justify-content-space-between',
    styles.badge_row,
  );

  return (
    <>
      <span>{companyName}</span>
      {user && (
        <Row className={badgeRowClassName}>
          <PersonDataBadge
            fullName={user.name}
            avatar={user.avatar}
            phoneNumber={user.phone || undefined}
            className={styles.bid_creator_label}
          />
          <Badge
            blue
            label={lang.labels.You()}
            smaller
            className={styles.badge}
          />
        </Row>
      )}
    </>
  );
}

BidsTableSupplier.propTypes = propTypes;
BidsTableSupplier.defaultProps = defaultProps;

export default memo(BidsTableSupplier);
