import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import toastrReducer from 'hh-shared/dist/toastr/toastrReducer';

import authReducer from 'auth/reducer';
import dictionaryReducer from 'dictionary/reducer';
import transportOrdersReducer from 'transport-orders/reducer';
import transportOrderReducer from 'transport-order/reducer';
import auctionsReducer from 'auctions/reducer';
import auctionReducer from 'auction/reducer';
import userSettingsReducer from 'user-settings/reducer';
import sideMenuReducer from 'layout/Menu/sideMenuReducer';
import driversReducer from 'drivers/reducer';
import carsReducer from 'cars/reducer';
import dashboardReducer from 'dashboard/reducer';

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  toastr: toastrReducer,
  dictionary: dictionaryReducer,
  auctions: auctionsReducer,
  auction: auctionReducer,
  sideMenu: sideMenuReducer,
  transportOrders: transportOrdersReducer,
  transportOrder: transportOrderReducer,
  userSettings: userSettingsReducer,
  drivers: driversReducer,
  cars: carsReducer,
  dashboard: dashboardReducer,
});
