"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _numberFormating = require("../../consts/numberFormating");

var _NumericCellModule = _interopRequireDefault(require("./NumericCell.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  value: _propTypes.default.number.isRequired,
  withoutDecimalSeparator: _propTypes.default.bool
};
var defaultProps = {
  withoutDecimalSeparator: false
};

var NumericCell = function NumericCell(_ref) {
  var value = _ref.value,
      withoutDecimalSeparator = _ref.withoutDecimalSeparator;
  var displayValue = !withoutDecimalSeparator ? (0, _numberFormating.toMoneyFormat)(value) : (0, _numberFormating.insertThousandsSeparator)(value);
  return _react.default.createElement("td", {
    className: _NumericCellModule.default.cell,
    colSpan: "1"
  }, displayValue);
};

NumericCell.propTypes = propTypes;
NumericCell.defaultProps = defaultProps;
var _default = NumericCell;
exports.default = _default;