const columnNames = {
  auctions: {
    number: 'number',
    zipCodeFrom: 'zipCodeFrom',
    cityFrom: 'cityFrom',
    zipCodeTo: 'zipCodeTo',
    cityTo: 'cityTo',
    timeLeft: 'timeLeft',
    currentPrice: 'currentPrice',
    unloadingTime: 'unloadingTime',
    distance: 'distance',
    unloadingMethod: 'unloadingMethod',
    capacity: 'capacity',
    isObserved: 'isObserved',
    isParticipant: 'isParticipant',
    isWon: 'isWon',
    status: 'status',
  },
  observedAuctions: {
    number: 'number',
    zipCodeFrom: 'zipCodeFrom',
    cityFrom: 'cityFrom',
    zipCodeTo: 'zipCodeTo',
    cityTo: 'cityTo',
    timeLeft: 'timeLeft',
    currentPrice: 'currentPrice',
    unloadingTime: 'unloadingTime',
    distance: 'distance',
    unloadingMethod: 'unloadingMethod',
    capacity: 'capacity',
    isObserved: 'isObserved',
  },
  transportOrders: {
    number: 'number',
    driver: 'driver',
    loadingTime: 'loadingTime',
    loadingAddress: 'loadingAddress',
    unloadingTime: 'unloadingTime',
    unloadingAddress: 'unloadingAddress',
    authorizedPickupPerson: 'authorizedPickupPerson',
    authorizedPickupPersonPhoneNumber: 'authorizedPickupPersonPhoneNumber',
    distance: 'distance',
    value: 'value',
    car: 'car',
    capacity: 'capacity',
    loadingMethod: 'loadingMethod',
    unloadingMethod: 'unloadingMethod',
    auctionNumber: 'auctionNumber',
    status: 'status',
  },
};

export default columnNames;
