"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ProgressBarTooltipModule = _interopRequireDefault(require("./ProgressBarTooltip.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  fillnessPercentage: _propTypes.default.number.isRequired
};
var defaultProps = {};

var ProgressBarTooltip = function ProgressBarTooltip(_ref) {
  var fillnessPercentage = _ref.fillnessPercentage;

  var roundUpPercentages = function roundUpPercentages(number) {
    return Math.floor(number);
  };

  return _react.default.createElement("div", {
    className: _ProgressBarTooltipModule.default.tooltip_container
  }, _react.default.createElement("div", {
    className: _ProgressBarTooltipModule.default.triangle
  }), _react.default.createElement("div", {
    className: _ProgressBarTooltipModule.default.label
  }, "".concat(roundUpPercentages(fillnessPercentage), "%")));
};

ProgressBarTooltip.propTypes = propTypes;
ProgressBarTooltip.defaultProps = defaultProps;
var _default = ProgressBarTooltip;
exports.default = _default;