"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Column = _interopRequireDefault(require("../layout/Column"));

var _GridCellsStylingModule = _interopRequireDefault(require("./GridCellsStyling.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  valueOnTop: _propTypes.default.string.isRequired,
  valueOnBottom: _propTypes.default.string.isRequired,
  withoutAccent: _propTypes.default.bool
};
var defaultProps = {
  withoutAccent: false
};

var DoubleRowDataCell = function DoubleRowDataCell(_ref) {
  var valueOnTop = _ref.valueOnTop,
      valueOnBottom = _ref.valueOnBottom,
      withoutAccent = _ref.withoutAccent;
  return _react.default.createElement("td", {
    colSpan: "1",
    className: _GridCellsStylingModule.default.cell_align_top
  }, _react.default.createElement(_Column.default, null, _react.default.createElement("span", {
    className: !withoutAccent ? _GridCellsStylingModule.default.outstanding_data : ''
  }, valueOnTop), _react.default.createElement("span", null, valueOnBottom)));
};

DoubleRowDataCell.propTypes = propTypes;
DoubleRowDataCell.defaultProps = defaultProps;
var _default = DoubleRowDataCell;
exports.default = _default;