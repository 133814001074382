import notificationActionTypes from './actionTypes';

const initialState = {
  loadingNotifications: true,
  notificationAreas: [],
};

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationActionTypes.NOTIFICATION_SET_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: false,
        notificationAreas: action.payload,
      };
    default:
      return state;
  }
};

export default userSettingsReducer;
