"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _FoxInputNumber = _interopRequireDefault(require("./FoxInputNumber"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var propTypes = {};
var defaultProps = {};

function FoxInputNumberDecimal(props) {
  return _react.default.createElement(_FoxInputNumber.default, _extends({}, props, {
    formatOptions: "n2"
  }));
}

FoxInputNumberDecimal.propTypes = propTypes;
FoxInputNumberDecimal.defaultProps = defaultProps;
var _default = FoxInputNumberDecimal;
exports.default = _default;