"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _buttons = _interopRequireDefault(require("./buttons"));

var _errors = _interopRequireDefault(require("./errors"));

var _labels = _interopRequireDefault(require("./labels"));

var _validationMessages = _interopRequireDefault(require("./validationMessages"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _default = {
  buttons: _buttons.default,
  errors: _errors.default,
  labels: _labels.default,
  validationMessages: _validationMessages.default
};
exports.default = _default;