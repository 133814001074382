import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { isRequired, isEmail } from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputEmailField from 'fox-react/dist/reduxFormFields/FoxInputEmailField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import styles from './ResetPasswordForm.module.scss';
import './ResetPasswordFormTheming.scss';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

const defaultProps = {};

function ResetPasswordForm({ handleSubmit, submitting, submitSucceeded }) {
  return (
    <div className={`${styles.form_container} reset_password_form_container`}>
      <Form handleSubmit={handleSubmit}>
        <fieldset>
          <legend>{lang.labels.ResetPasswordInformation()}</legend>
          <FoxInputEmailField
            label={lang.labels.Email()}
            placeholder={lang.labels.Email()}
            name={fieldNames.resetPasswordForm.email}
          />

          <FoxButton primary disabled={submitting}>
            {lang.buttons.ResetPassword()}
          </FoxButton>
        </fieldset>
      </Form>

      {submitSucceeded && (
        <>
          <p className="vertical-offset vertical-offset-big">
            {lang.validationMessages.ResetPasswordSend()}
          </p>
          <Link to="/" className="link bold bold_medium">
            {lang.labels.BackToMainPage()}
          </Link>
        </>
      )}
    </div>
  );
}

ResetPasswordForm.propTypes = propTypes;
ResetPasswordForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequired(
    errors,
    values,
    fieldNames.resetPasswordForm.email,
    lang.validationMessages.FieldRequired(),
  );

  isEmail(
    errors,
    values,
    fieldNames.resetPasswordForm.email,
    lang.validationMessages.FieldMustBeValidEmail(),
  );

  return errors;
};

export default reduxForm({
  form: formNames.resetPasswordForm,
  validate,
})(ResetPasswordForm);
