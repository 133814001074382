"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applayColumnInfo = applayColumnInfo;
exports.columnReorder = columnReorder;
exports.columResize = columResize;
exports.columnsSubmit = columnsSubmit;

var _react = _interopRequireDefault(require("react"));

var _FoxGridColumn = _interopRequireDefault(require("./FoxGridColumn"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function applayColumnInfo(cols) {
  var columnsInfo = this.props.columnsInfo;

  if (!columnsInfo || columnsInfo.length === 0) {
    return cols;
  }

  var newColumns = _toConsumableArray(cols).sort(function (prev, next) {
    var prevEq = columnsInfo.find(function (x) {
      return x.field === prev.props.field;
    });
    var nextEq = columnsInfo.find(function (x) {
      return x.field === next.props.field;
    });
    var prevEqOrderIndex = prevEq ? prevEq.orderIndex : 0;
    var nextEqOrderIndex = nextEq ? nextEq.orderIndex : 0;
    return prevEqOrderIndex > nextEqOrderIndex ? 1 : -1;
  });

  var _loop = function _loop(i) {
    var element = columnsInfo[i];
    var updateIndex = newColumns.findIndex(function (x) {
      return x.props.field === element.field;
    });

    if (updateIndex !== -1) {
      newColumns[updateIndex] = _react.default.createElement(_FoxGridColumn.default, _extends({}, newColumns[updateIndex].props, {
        hidden: element.hidden,
        width: element.width
      }));
    }
  };

  for (var i = 0; i < columnsInfo.length; i++) {
    _loop(i);
  }

  return newColumns;
}

function columnReorder(event) {
  var onColumnReorder = this.props.onColumnReorder;
  var newOrder = event.columns.map(function (x) {
    return {
      field: x.field,
      orderIndex: x.orderIndex
    };
  });
  onColumnReorder(newOrder);
}

function columResize(event) {
  if (!event.end) {
    return;
  }

  var onColumnResize = this.props.onColumnResize;
  var newSizes = event.columns.map(function (x) {
    return {
      field: x.field,
      width: x.width
    };
  });
  onColumnResize(newSizes);
}

function columnsSubmit(columnsState) {
  var onColumnsSubmit = this.props.onColumnsSubmit;
  this.setState({
    columns: columnsState
  });
  var hiddenColumnInfo = columnsState.map(function (x) {
    return {
      field: x.props.field,
      hidden: x.props.hidden
    };
  });
  onColumnsSubmit(hiddenColumnInfo);
}