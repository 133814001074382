import { connect } from 'react-redux';
import UserWidget from './UserWidget';
import { logout } from './actions';

const mapStateToProps = store => ({
  userName: store.auth.currentUser.email,
  lastName: store.auth.currentUser.lastName,
  firstName: store.auth.currentUser.firstName,
  avatar: store.auth.currentUser.avatar,
});

const mapDispatchToProps = dispatch => ({
  onLogOut: () => dispatch(logout()),
});

const UserWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserWidget);

export default UserWidgetContainer;
