import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import DriversSrv from './DriversSrv';
import DriverForm from './DriverForm';
import DriverPagePreview from './DriverPagePreview';

const propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

function EditDriverPage({ history, match }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const onSubmit = model =>
    DriversSrv.update({ ...model, instanceId: match.params.instanceId });

  useEffect(() => {
    setShowSpinner(true);
    DriversSrv.get(match.params.instanceId)
      .then(res => setInitialValues(res))
      .finally(() => setShowSpinner(false));
  }, [match.params.instanceId]);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/drivers',
            label: lang.labels.Drivers(),
          },
          {
            routePath: '/drivers/edit',
            label: lang.labels.EditingDriver(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <DriverForm
              onSubmit={onSubmit}
              title={lang.labels.EditingDriver()}
              history={history}
              initialValues={initialValues}
              isEditForm
            />
          </div>
          <DriverPagePreview editForm />
        </FormCardWrapper>
      </FormPreviewSectionContainer>
    </>
  );
}

EditDriverPage.propTypes = propTypes;
EditDriverPage.defaultProps = defaultProps;

export default EditDriverPage;
