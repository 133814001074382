import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import { fetchParticipantData } from 'auction/actions';
import get from 'lodash/get';
import BidsTableSupplier from './BidsTableSupplier';

const propTypes = {
  user: PropTypes.object,
  loadUserData: PropTypes.func.isRequired,
  company: PropTypes.object,
  currentUserCompanyId: PropTypes.string,
  companyInstanceId: PropTypes.string.isRequired,
};

const defaultProps = {
  user: undefined,
  company: undefined,
  currentUserCompanyId: undefined,
};

function BidsTableSupplierContainer({
  company,
  user,
  loadUserData,
  currentUserCompanyId,
  companyInstanceId,
}) {
  const isMyCompany = companyInstanceId === currentUserCompanyId;
  const userIsAvailable = user && !user.isLoading;

  useEffect(() => {
    if (!user && isMyCompany) {
      loadUserData();
    }
    // eslint-disable-next-line
  }, []);

  const companyName = company ? company.name : lang.labels.AnotherCompany();

  let userProp;
  if (userIsAvailable && isMyCompany) {
    userProp = user;
  }

  return <BidsTableSupplier companyName={companyName} user={userProp} />;
}

BidsTableSupplierContainer.propTypes = propTypes;
BidsTableSupplierContainer.defaultProps = defaultProps;

const mapStateToProps = (
  state,
  { companyInstanceId, initiatorUserInstanceId },
) => {
  return {
    company: state.auction.participantCompanies[companyInstanceId],
    user: state.auction.participantsUsers[initiatorUserInstanceId],
    currentUserCompanyId: get(state, 'auth.currentUser.companyInstanceId'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadUserData: () =>
    dispatch(fetchParticipantData(ownProps.initiatorUserInstanceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(BidsTableSupplierContainer));
