import axios from 'config/axios';

const prefix = 'auctions';

export default class AuctionsSrv {
  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static createBid(price, auctionId) {
    return axios.post(`${prefix}/${auctionId}/bids`, { price });
  }

  static getParticipants(id) {
    return axios
      .get(`${prefix}/${id}/participants`)
      .then(result => result.data);
  }

  static getUserParticipantData(id) {
    return axios
      .get(`${prefix}/${id}/getUserParticipantData`)
      .then(result => result.data);
  }

  static isObservedChange(id, isObserved) {
    return axios.put(`${prefix}/isObservedChange`, {
      auctionId: id,
      isObserved,
    });
  }
}
