import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, initialize } from 'redux-form';
import get from 'lodash/get';

import Row from 'layout/Row';
import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import PageWrapper from 'hh-shared/dist/components/layout/PageWrapper';
import Column from 'hh-shared/dist/components/layout/Column';
import MainInfo from 'hh-shared/dist/components/transport-order/MainInfo';
import TransportInfo from 'hh-shared/dist/components/transport-order/TransportInfo/TransportInfo';
import TransportForm from 'hh-shared/dist/components/transport-order/TransportForm';
import BottomSection from 'hh-shared/dist/components/transport-order/BottomSection';
import SummarySection from 'hh-shared/dist/components/transport-order/SummarySection';
import ButtonContainer from 'hh-shared/dist/components/transport-order/ButtonContainer';
import TransportOrderPageLoader from 'hh-shared/dist/components/transport-order/TransportOrderPageLoader';
import PreviewButton from 'hh-shared/dist/components/commons/PreviewButton';
import { downloadOrderPDF } from 'hh-shared/dist/utils/downloadMethods';
import { scrollTo } from 'hh-shared/dist/utils/scrollMethods';
import store from 'store/store';
import ImplementationDialog from 'hh-shared/dist/components/transport-order/ImplementationDialog';

import ActionButtons from './ActionButtons';
import TransportOrdersSrv from '../transport-orders/TransportOrdersSrv';
import actionTypes from './actionTypes';
import TransportOrderAddMessageDialog from './TransportOrderAddMessageDialog/TransportOrderAddMessageDialog';

import './TransportOrderPageTheming.scss';
import './TransportOrderPage.scss';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const formName = 'transportFormPreview';

const defaultProps = {};

const transformPreviewFormValueSelector = getFormValues('transportFormPreview');
function TransportOrderPage({ match }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [cars, setCars] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const transportOrder = useSelector(state => state.transportOrder);
  const { transportOrderData } = transportOrder;

  const dispatch = useDispatch();
  const [messageDialogIsOpen, setMessageDialogIsOpen] = useState(false);
  const [implementationDialogIsOpen, setImplementationDialogIsOpen] = useState(
    false,
  );

  const [
    implementationDetailsInitialValues,
    setImplementationDetailsInitialValues,
  ] = useState({});

  const refreshPage = () => {
    setShowSpinner(true);
    TransportOrdersSrv.get(match.params.id)
      .then(orderData => {
        dispatch({
          type: actionTypes.SET_TRANSPORT_ORDER_DATA,
          payload: orderData,
        });
        dispatch(
          initialize(formName, {
            car: orderData.car,
            driver: orderData.driver,
          }),
        );
      })
      .finally(() => setShowSpinner(false));
  };

  const onSendClick = message => {
    message &&
      TransportOrdersSrv.sendMessage(match.params.id, message).then(() => {
        setMessageDialogIsOpen(false);
        refreshPage();
      });
  };

  const onSubmitAcceptForImplementation = model => {
    setImplementationDialogIsOpen(false);
    return TransportOrdersSrv.acceptForImplementation({
      ...model,
      id: match.params.id,
    }).then(() => {
      refreshPage();
    });
  };

  useEffect(() => {
    setShowSpinner(true);

    Promise.all([
      TransportOrdersSrv.get(match.params.id),
      TransportOrdersSrv.getCars(),
      TransportOrdersSrv.getDrivers(),
      TransportOrdersSrv.getCarTypes(),
    ])
      .then(([orderData, carsRes, driversRes, carTypesRes]) => {
        setCars(carsRes);
        setDrivers(driversRes);
        setCarTypes(carTypesRes);
        dispatch({
          type: actionTypes.SET_TRANSPORT_ORDER_DATA,
          payload: orderData,
        });
        dispatch(
          initialize(formName, {
            car: orderData.car,
            driver: orderData.driver,
          }),
        );
      })
      .finally(() => setShowSpinner(false));
    // TODO -> ogarnąć czemu brakuje hook-owi dispatcha w array-u argumentów ;o
    // eslint-disable-next-line
  }, [match.params.id]);

  useEffect(() => {
    const pageContainer = document.querySelector('.transport_order_page');
    pageContainer && scrollTo(pageContainer);
  }, [showSpinner]);

  const downloadPDF = () => {
    setIsDownloadingPdf(true);

    TransportOrdersSrv.getCompletedTransportOrderPdf(match.params.id)
      .then(orderPDF => downloadOrderPDF(orderPDF, transportOrderData.number))
      .finally(() => setIsDownloadingPdf(false));
  };

  const showInputs =
    get(transportOrderData, 'status.id') === transportOrderStatusesIds.placed;

  const submitAcceptForImplementation = () => {
    const { loadingTimeTo } = transportOrderData;
    const values = transformPreviewFormValueSelector(store.getState());
    setImplementationDetailsInitialValues({ ...values, loadingTimeTo });
    setImplementationDialogIsOpen(true);
  };

  return (
    <>
      {showSpinner || !transportOrderData ? (
        <TransportOrderPageLoader />
      ) : (
        <Row className="transport_order_page">
          <Column rounded bigger className="lifted lifted-left">
            <PageWrapper rounded additionalClassNames="order_card">
              <MainInfo
                data={transportOrderData}
                auctionPreviewUrl="/auctions"
              />
              <TransportInfo
                data={transportOrderData}
                renderForm={
                  <TransportForm
                    drivers={drivers}
                    cars={cars}
                    carTypes={carTypes}
                    showInputs={showInputs}
                    transportOrderData={transportOrderData}
                  />
                }
              />
              <BottomSection
                data={transportOrderData}
                renderButtons={
                  <ActionButtons
                    data={transportOrderData}
                    submitAcceptForImplementation={
                      submitAcceptForImplementation
                    }
                  />
                }
              />
            </PageWrapper>
          </Column>
          <Column rounded smaller>
            <ButtonContainer
              renderButtons={
                <PreviewButton
                  label={lang.labels.DownloadPdf()}
                  icon={icons.download}
                  onClick={downloadPDF}
                  isProcessing={isDownloadingPdf}
                />
              }
            />
            <PageWrapper
              additionalClassNames="flex-size display-flex"
              roundedRight
            >
              <SummarySection
                data={transportOrderData}
                onAddMessageClick={() => setMessageDialogIsOpen(true)}
              />
            </PageWrapper>
          </Column>
        </Row>
      )}
      <TransportOrderAddMessageDialog
        isOpen={messageDialogIsOpen}
        onSendClick={message => onSendClick(message)}
        onCloseClick={() => setMessageDialogIsOpen(false)}
      />
      <ImplementationDialog
        drivers={drivers}
        cars={cars}
        carTypes={carTypes}
        onCloseClick={() => setImplementationDialogIsOpen(false)}
        isOpen={implementationDialogIsOpen}
        transportOrderData={transportOrderData}
        initialValues={implementationDetailsInitialValues}
        onSubmit={onSubmitAcceptForImplementation}
      />
    </>
  );
}

TransportOrderPage.propTypes = propTypes;
TransportOrderPage.defaultProps = defaultProps;

export default TransportOrderPage;
