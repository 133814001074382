import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  bigger: PropTypes.bool,
  smaller: PropTypes.bool,
  biggerPreview: PropTypes.bool,
  smallerPreview: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

const defaultProps = {
  className: '',
  bigger: false,
  smaller: false,
  biggerPreview: false,
  smallerPreview: false,
  onClick: () => {},
  style: {},
};

function Column({
  className,
  children,
  bigger,
  smaller,
  biggerPreview,
  smallerPreview,
  onClick,
  style,
}) {
  return (
    <div
      onClick={onClick}
      style={style}
      className={`${className} flex-wrapper flex-wrapper-column ${
        bigger ? 'flex-wrapper-column-bigger' : ''
      } ${smaller ? 'flex-wrapper-column-smaller' : ''} ${
        biggerPreview ? 'flex-wrapper-column-bigger-preview' : ''
      } ${smallerPreview ? 'flex-wrapper-column-smaller-preview' : ''}`}
    >
      {children}
    </div>
  );
}

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;

export default Column;
