"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _kendoReactDialogs = require("@progress/kendo-react-dialogs");

var _FoxButton = _interopRequireDefault(require("./FoxButton"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  isOpen: _propTypes.default.bool,
  title: _propTypes.default.string.isRequired,
  onCloseClick: _propTypes.default.func,
  onConfirmClick: _propTypes.default.func,
  noLabel: _propTypes.default.string,
  yesLabel: _propTypes.default.string,
  children: _propTypes.default.node.isRequired,
  className: _propTypes.default.string,
  bigger: _propTypes.default.bool
};
var defaultProps = {
  isOpen: false,
  onCloseClick: function onCloseClick() {},
  onConfirmClick: function onConfirmClick() {},
  noLabel: undefined,
  yesLabel: undefined,
  className: '',
  bigger: false
};

var FoxCustomDialog = function FoxCustomDialog(_ref) {
  var isOpen = _ref.isOpen,
      title = _ref.title,
      onCloseClick = _ref.onCloseClick,
      onConfirmClick = _ref.onConfirmClick,
      noLabel = _ref.noLabel,
      yesLabel = _ref.yesLabel,
      className = _ref.className,
      children = _ref.children,
      bigger = _ref.bigger;
  var dialogClassName = (0, _classnames.default)(className, {
    bigger: bigger
  });
  return _react.default.createElement(_react.Fragment, null, isOpen && _react.default.createElement(_kendoReactDialogs.Dialog, {
    title: title,
    onClose: onCloseClick,
    className: dialogClassName
  }, children, noLabel && yesLabel && _react.default.createElement(_kendoReactDialogs.DialogActionsBar, null, _react.default.createElement(_FoxButton.default, {
    type: "button",
    onClick: onCloseClick
  }, noLabel), _react.default.createElement(_FoxButton.default, {
    primary: true,
    type: "button",
    onClick: onConfirmClick
  }, yesLabel))));
};

FoxCustomDialog.propTypes = propTypes;
FoxCustomDialog.defaultProps = defaultProps;
var _default = FoxCustomDialog;
exports.default = _default;