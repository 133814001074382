"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToastProperties = void 0;

var _icons = _interopRequireDefault(require("../consts/icons"));

var _ToastrTypes = _interopRequireDefault(require("./ToastrTypes"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getToastProperties = function getToastProperties(currentType) {
  switch (currentType) {
    case _ToastrTypes.default.success:
      return {
        icon: _icons.default.solidCheck,
        color: 'success'
      };

    case _ToastrTypes.default.info:
      return {
        icon: _icons.default.solidInfo,
        color: 'info'
      };

    case _ToastrTypes.default.warning:
      return {
        icon: _icons.default.solidExclamation,
        color: 'warning'
      };

    case _ToastrTypes.default.error:
      return {
        icon: _icons.default.solidExclamation,
        color: 'error'
      };

    default:
      return {
        icon: _icons.default.solidBell,
        color: 'dark'
      };
  }
};

exports.getToastProperties = getToastProperties;