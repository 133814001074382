"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ContentWrapperModule = _interopRequireDefault(require("./ContentWrapper.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  additionalClassNames: _propTypes.default.string,
  anonymousContainer: _propTypes.default.bool
};
var defaultProps = {
  additionalClassNames: '',
  anonymousContainer: false
};
/* eslint-disable indent */

function ContentWrapper(_ref) {
  var children = _ref.children,
      additionalClassNames = _ref.additionalClassNames,
      anonymousContainer = _ref.anonymousContainer;
  var menuIsClosed = (0, _reactRedux.useSelector)(function (state) {
    return !state.sideMenu.isOpened;
  });
  return _react.default.createElement("div", {
    className: "\n        ".concat(_ContentWrapperModule.default.content_wrapper, " ").concat(additionalClassNames, " ").concat(menuIsClosed ? _ContentWrapperModule.default.wider : '', " ").concat(anonymousContainer ? _ContentWrapperModule.default.full_size : '', " content-wrapper")
  }, children);
}

ContentWrapper.propTypes = propTypes;
ContentWrapper.defaultProps = defaultProps;
var _default = ContentWrapper;
exports.default = _default;