"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _CloseButtonModule = _interopRequireDefault(require("./CloseButton.module.scss"));

var _customHooks = require("../utils/customHooks");

var _CircularProgressBar = _interopRequireDefault(require("./CircularProgressBar"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  onClose: _propTypes.default.func.isRequired,
  closeTime: _propTypes.default.number.isRequired,
  toastIsTouched: _propTypes.default.bool,
  counterDelay: _propTypes.default.number
};
var defaultProps = {
  toastIsTouched: false,
  counterDelay: 0
};

var CloseButton = function CloseButton(_ref) {
  var onClose = _ref.onClose,
      closeTime = _ref.closeTime,
      toastIsTouched = _ref.toastIsTouched,
      counterDelay = _ref.counterDelay;

  var _useState = (0, _react.useState)(closeTime),
      _useState2 = _slicedToArray(_useState, 2),
      timeLeft = _useState2[0],
      setTimeLeft = _useState2[1];

  var _useState3 = (0, _react.useState)(counterDelay),
      _useState4 = _slicedToArray(_useState3, 2),
      currentDelay = _useState4[0],
      setCurrentDelay = _useState4[1];

  var timeTick = 125;
  (0, _customHooks.useInterval)(function () {
    if (currentDelay <= 0) {
      !toastIsTouched && setTimeLeft(function (prevTimeLeft) {
        return prevTimeLeft - timeTick;
      });
      timeLeft <= 0 && setTimeLeft(0);
    } else {
      setCurrentDelay(function (prevDelay) {
        return prevDelay - timeTick;
      });
    }
  }, timeLeft >= 0 ? timeTick : null);
  (0, _react.useEffect)(function () {
    timeLeft <= 0 && onClose();
  }, [timeLeft]);
  (0, _react.useEffect)(function () {
    setTimeLeft(closeTime);
  }, [toastIsTouched]);

  var getCurrentProgress = function getCurrentProgress() {
    return Math.round((closeTime - timeLeft) / closeTime * 100, 2);
  };

  return _react.default.createElement("div", {
    className: _CloseButtonModule.default.button_container,
    onClick: onClose
  }, _react.default.createElement(_CircularProgressBar.default, {
    className: _CloseButtonModule.default.progress_bar,
    progress: getCurrentProgress()
  }), _react.default.createElement("div", {
    className: _CloseButtonModule.default.cross
  }));
};

CloseButton.propTypes = propTypes;
CloseButton.defaultProps = defaultProps;
var _default = CloseButton;
exports.default = _default;