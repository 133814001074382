"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var auctionStatusIds = {
  draft: '3c0df40f-6c41-46d2-beaa-da7ca5a788b2',
  live: '404b5cfe-edc3-425d-bbd6-23a9bc67e1cc',
  forPublication: '56d4b5e0-18f1-4ff8-9a96-10bd654dbe60',
  closed: '0545cc12-7b3e-472d-a0b7-5fb0f636a2c3',
  canceled: '895fdc66-81ac-4ec5-b289-c9da22b40d58'
};
var _default = auctionStatusIds;
exports.default = _default;