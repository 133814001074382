import { connect } from 'react-redux';
import SideMenu from './SideMenu';
import { open, close, toggle } from './sideMenuActions';

const mapStateToProps = state => ({
  isOpened: state.sideMenu.isOpened,
});

const mapDispatchToProps = dispatch => {
  return {
    open: () => dispatch(open()),
    close: () => dispatch(close()),
    toggle: () => dispatch(toggle()),
  };
};

const SideMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideMenu);

export default SideMenuContainer;
