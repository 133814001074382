"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _FormPreviewPane = _interopRequireDefault(require("./FormPreviewPane"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var mapStateToProps = function mapStateToProps(state) {
  return {
    isNarrowed: state.sideMenu.isOpened
  };
};

var FormPreviewPaneContainer = (0, _reactRedux.connect)(mapStateToProps, null)(_FormPreviewPane.default);
var _default = FormPreviewPaneContainer;
exports.default = _default;