"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Row = _interopRequireDefault(require("hh-shared/dist/components/layout/Row"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ButtonContainerModule = _interopRequireDefault(require("./ButtonContainer.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  renderButtons: _propTypes.default.node
};
var defaultProps = {
  renderButtons: undefined
};

var ButtonContainer = function ButtonContainer(_ref) {
  var renderButtons = _ref.renderButtons;
  return _react.default.createElement(_Row.default, {
    className: _ButtonContainerModule.default.container
  }, renderButtons);
};

ButtonContainer.propTypes = propTypes;
ButtonContainer.defaultProps = defaultProps;
var _default = ButtonContainer;
exports.default = _default;