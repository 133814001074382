"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactInputs = require("@progress/kendo-react-inputs");

var _kendoReactIntl = require("@progress/kendo-react-intl");

var _likelySubtags = _interopRequireDefault(require("cldr-core/supplemental/likelySubtags.json"));

var _numbers = _interopRequireDefault(require("cldr-numbers-full/main/pl/numbers.json"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

(0, _kendoReactIntl.load)(_likelySubtags.default, _numbers.default);
var propTypes = {
  label: _propTypes.default.string,
  value: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]).isRequired,
  placeholder: _propTypes.default.string,
  onChange: _propTypes.default.func.isRequired,
  onBlur: _propTypes.default.func,
  error: _propTypes.default.string,
  invalid: _propTypes.default.bool,
  autoFocus: _propTypes.default.bool,
  step: _propTypes.default.number,
  min: _propTypes.default.number,
  max: _propTypes.default.number,
  formatOptions: _propTypes.default.oneOf(['n', 'n2', 'n5', 'c', 'c3', 'c5', 'a', 'a3', 'p', 'p2', 'p5', 'e']),
  // and others, more info: https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
  disabled: _propTypes.default.bool,
  name: _propTypes.default.string,
  hasIncrementers: _propTypes.default.bool
};
var defaultProps = {
  label: '',
  placeholder: undefined,
  error: undefined,
  invalid: false,
  autoFocus: false,
  step: 1,
  min: 0,
  max: undefined,
  formatOptions: undefined,
  disabled: false,
  onBlur: function onBlur() {},
  name: undefined,
  hasIncrementers: true
};

function FoxInputNumber(_ref) {
  var label = _ref.label,
      value = _ref.value,
      placeholder = _ref.placeholder,
      onChange = _ref.onChange,
      _onBlur = _ref.onBlur,
      error = _ref.error,
      invalid = _ref.invalid,
      autoFocus = _ref.autoFocus,
      step = _ref.step,
      min = _ref.min,
      max = _ref.max,
      formatOptions = _ref.formatOptions,
      disabled = _ref.disabled,
      name = _ref.name,
      hasIncrementers = _ref.hasIncrementers;

  var onChangeHandle = function onChangeHandle(e) {
    onChange({
      target: {
        value: e.value
      }
    });
  };

  var inputClassName = (0, _classnames.default)({
    'remove-k-select': !hasIncrementers
  });
  return _react.default.createElement(_kendoReactIntl.LocalizationProvider, {
    language: "pl-PL"
  }, _react.default.createElement(_kendoReactIntl.IntlProvider, {
    locale: "pl"
  }, _react.default.createElement(_kendoReactInputs.NumericTextBox, {
    label: label,
    value: value || null,
    placeholder: placeholder,
    onChange: onChangeHandle,
    onBlur: function onBlur() {
      return _onBlur();
    },
    validationMessage: error,
    valid: !invalid,
    autoFocus: autoFocus,
    step: step,
    min: min,
    max: max,
    format: formatOptions,
    disabled: disabled,
    name: name,
    className: inputClassName
  })));
}

FoxInputNumber.propTypes = propTypes;
FoxInputNumber.defaultProps = defaultProps;
var _default = FoxInputNumber;
exports.default = _default;