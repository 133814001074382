import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import cx from 'classnames';

import lang from 'hh-shared/dist/language/services/languageService';
import Row from 'hh-shared/dist/components/layout/Row';
import DashboardTextInformation from 'hh-shared/dist/components/commons/DashboardTextInformation';

import OrderDashboardCard from './OrderDashboardCard';
import AuctionDashboardCard from './AuctionDashboardCard';
import { getData } from './actions';

const propTypes = {};

const defaultProps = {};

const DashboardPage = () => {
  const dispatch = useDispatch();
  const dashboardModel = useSelector(state => state.dashboard.dashboardModel);
  const [showSpinner, setShowSpinner] = useState(false);

  const hasCompany = !!useSelector(state =>
    get(state, 'auth.currentUser.companyInstanceId'),
  );

  const isCompanyActive = useSelector(state =>
    get(state, 'auth.currentUser.isCompanyActive'),
  );

  useEffect(() => {
    setShowSpinner(true);
    dispatch(getData()).finally(() => setShowSpinner(false));
  }, [dispatch]);

  return (
    <>
      {hasCompany && !isCompanyActive && (
        <DashboardTextInformation
          title={lang.labels.Status()}
          information={lang.labels.InProgressOfDateAndAcceptanceVerification()}
          description={`${lang.labels.AfterPositiveConsiderationYouWillBeNotifiedByEmailAboutThePossibilityOfSubmittingOfferts()}.`}
        />
      )}
      <Row>
        <AuctionDashboardCard
          auctionsData={dashboardModel && dashboardModel.auctions}
          className={cx({ fox_spinner: showSpinner })}
        />
        <OrderDashboardCard
          ordersData={dashboardModel && dashboardModel.transportOrders}
          className={cx({ fox_spinner: showSpinner })}
        />
      </Row>
    </>
  );
};

DashboardPage.propTypes = propTypes;
DashboardPage.defaultProps = defaultProps;

export default DashboardPage;
