"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _AvatarPlaceholderModule = _interopRequireDefault(require("./AvatarPlaceholder.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

var propTypes = {
  label: _propTypes.default.string,
  small: _propTypes.default.bool,
  additionalClassNames: _propTypes.default.string
};
var defaultProps = {
  label: 'Avatar',
  small: false,
  additionalClassNames: ''
};

function AvatarPlaceholder(_ref) {
  var label = _ref.label,
      small = _ref.small,
      additionalClassNames = _ref.additionalClassNames;
  return _react.default.createElement("span", {
    "data-label": label,
    className: [_AvatarPlaceholderModule.default.avatar_placeholder, small && _AvatarPlaceholderModule.default.small_placeholder].concat(_toConsumableArray(additionalClassNames.split(' '))).join(' ')
  });
}

AvatarPlaceholder.propTypes = propTypes;
AvatarPlaceholder.defaultProps = defaultProps;
var _default = AvatarPlaceholder;
exports.default = _default;