"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Column = _interopRequireDefault(require("../../layout/Column"));

var _Row = _interopRequireDefault(require("../../layout/Row"));

var _IconArea = _interopRequireDefault(require("../../commons/IconArea"));

var _icons = _interopRequireDefault(require("../../../consts/icons"));

var _numberFormating = _interopRequireDefault(require("../../../consts/numberFormating"));

var _languageService = _interopRequireDefault(require("../../../language/services/languageService"));

var _DataFixTimes = _interopRequireDefault(require("../../commons/DataFixTimes/DataFixTimes"));

var _TransportInfoModule = _interopRequireDefault(require("./TransportInfo.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  data: _propTypes.default.object.isRequired,
  renderForm: _propTypes.default.node
};
var defaultProps = {
  renderForm: undefined
};

function TransportInfo(_ref) {
  var data = _ref.data,
      renderForm = _ref.renderForm;
  var insertThousandsSeparator = _numberFormating.default.insertThousandsSeparator;
  var mainContainerClassName = (0, _classnames.default)('content_column', _TransportInfoModule.default.content_column);
  return _react.default.createElement("div", {
    className: _TransportInfoModule.default.content
  }, _react.default.createElement(_Column.default, {
    className: mainContainerClassName
  }, _react.default.createElement(_Row.default, null, _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.loadingMark,
    iconTitle: _languageService.default.labels.Loading()
  }, _react.default.createElement("span", null, _languageService.default.labels.Loading()), _react.default.createElement("div", {
    className: _TransportInfoModule.default.header_label
  }, data.loadingAddress.zipCode, " ", data.loadingAddress.city), _react.default.createElement("div", {
    className: _TransportInfoModule.default.header_label
  }, data.loadingAddress.street), _react.default.createElement("div", {
    className: _TransportInfoModule.default.description_label
  }, _react.default.createElement(_DataFixTimes.default, {
    from: data.loadingTimeFrom,
    to: data.loadingTimeTo
  })))), _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.deliverMark,
    iconTitle: _languageService.default.labels.Delivery()
  }, _react.default.createElement("span", null, _languageService.default.labels.Delivery()), _react.default.createElement("div", {
    className: _TransportInfoModule.default.header_label
  }, data.unloadingAddress.zipCode, " ", data.unloadingAddress.city), _react.default.createElement("div", {
    className: _TransportInfoModule.default.header_label
  }, data.unloadingAddress.street), _react.default.createElement("div", {
    className: _TransportInfoModule.default.description_label
  }, _react.default.createElement(_DataFixTimes.default, {
    from: data.unloadingTimeFrom,
    to: data.unloadingTimeTo
  }))))), _react.default.createElement(_Row.default, null, _react.default.createElement(_Column.default, null, renderForm)), _react.default.createElement(_Row.default, null, _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.loadingVehicle,
    iconTitle: _languageService.default.labels.LoadingMethod()
  }, _react.default.createElement("span", null, _languageService.default.labels.LoadingMethod()), _react.default.createElement("div", null, "".concat(insertThousandsSeparator(data.capacity), " kg / ").concat(data.loadingMethod)))), _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.loadingVehicle,
    iconTitle: _languageService.default.labels.UnloadingMethod()
  }, _react.default.createElement("span", null, _languageService.default.labels.UnloadingMethod()), _react.default.createElement("div", null, data.unloadingMethod)))), _react.default.createElement(_Row.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.information,
    iconTitle: _languageService.default.labels.AdditionalInformations()
  }, _react.default.createElement("span", null, _languageService.default.labels.AdditionalInformations()), _react.default.createElement("div", null, _react.default.createElement("span", {
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML: {
      __html: data.additionalRequirements
    } || undefined
  }))), _react.default.createElement("div", {
    className: _TransportInfoModule.default.bigger_empty_div
  }))));
}

TransportInfo.propTypes = propTypes;
TransportInfo.defaultProps = defaultProps;
var _default = TransportInfo;
exports.default = _default;