import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getForGrid as getForGridTransportOrder } from 'transport-orders/actions';
import transporOrderActionTypes from 'transport-orders/actionTypes';
import {
  transportOrderAllFilter,
  transportOrderCanceledFilter,
  transportOrderAcceptedFilter,
  transportOrderLoadedFilter,
  transportOrderCompletedFilter,
  transportOrderPlacedFilter,
} from 'transport-orders/filters';
import icons from 'hh-shared/dist/consts/icons';
import lang from 'hh-shared/dist/language/services/languageService';
import { useSelector, useDispatch } from 'react-redux';
import MenuLink from '../MenuLink';
import MenuSublinkGridFilter from '../MenuSublinkGridFilter';

const propTypes = {
  handleMenuOpen: PropTypes.func.isRequired,
  orderMenuIsOpen: PropTypes.bool.isRequired,
  setOrderMenuIsOpen: PropTypes.func.isRequired,
};

const defaultProps = {};

const commonProps = {
  routePath: '/transport-orders',
  stateFilterPath: 'transportOrders.dataState.filter',
};

function TransportOrderMenuPosition({
  handleMenuOpen,
  orderMenuIsOpen,
  setOrderMenuIsOpen,
}) {
  const dispatch = useDispatch();
  const { placedTransportOrders } = useSelector(state => state.sideMenu);

  const dataState = useSelector(state => state.transportOrders.dataState);

  const toggleOrderMenu = () => setOrderMenuIsOpen(!orderMenuIsOpen);

  const filterChange = filter => {
    dispatch(
      getForGridTransportOrder({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: transporOrderActionTypes.TRANSPORT_ORDER_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchAll = () => filterChange(transportOrderAllFilter);
  const dispatchCanceled = () => filterChange(transportOrderCanceledFilter);
  const dispatchAccepted = () => filterChange(transportOrderAcceptedFilter);
  const dispatchLoaded = () => filterChange(transportOrderLoadedFilter);
  const dispatchCompleted = () => filterChange(transportOrderCompletedFilter);
  const dispatchPlaced = () => filterChange(transportOrderPlacedFilter);

  return (
    <MenuLink
      icon={icons.order}
      label={lang.labels.Orders()}
      handleMenuOpen={handleMenuOpen}
      submenuIsOpen={orderMenuIsOpen}
      handleMenuItemToggle={toggleOrderMenu}
    >
      <MenuSublinkGridFilter
        label={lang.labels.All()}
        onClick={dispatchAll}
        filter={transportOrderAllFilter}
        color="darkGreen"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Canceled()}
        onClick={dispatchCanceled}
        filter={transportOrderCanceledFilter}
        color="orange"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Placed()}
        onClick={dispatchPlaced}
        filter={transportOrderPlacedFilter}
        count={placedTransportOrders}
        color="red"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.AcceptedForRealization()}
        onClick={dispatchAccepted}
        filter={transportOrderAcceptedFilter}
        color="blue"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Loaded()}
        onClick={dispatchLoaded}
        filter={transportOrderLoadedFilter}
        color="customGreen"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Completed()}
        onClick={dispatchCompleted}
        filter={transportOrderCompletedFilter}
        color="green"
        {...commonProps}
      />
    </MenuLink>
  );
}

TransportOrderMenuPosition.propTypes = propTypes;
TransportOrderMenuPosition.defaultProps = defaultProps;

export default memo(TransportOrderMenuPosition);
