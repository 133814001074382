"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateDriverInput = validateDriverInput;
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _IconArea = _interopRequireDefault(require("hh-shared/dist/components/commons/IconArea"));

var _icons = _interopRequireDefault(require("hh-shared/dist/consts/icons"));

var _ContentRevealer = _interopRequireDefault(require("hh-shared/dist/components/commons/ContentRevealer"));

var _FoxComboBoxField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxComboBoxField"));

var _FoxSwitchField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxSwitchField"));

var _reduxForm = require("redux-form");

var _redux = require("redux");

var _formValidators = require("fox-react/dist/utils/formValidators");

var _NewDriverForm = _interopRequireDefault(require("../commons/forms/NewDriverForm"));

var _TransportFormModule = _interopRequireDefault(require("./TransportForm.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  isNewDriver: _propTypes.default.bool,
  drivers: _propTypes.default.array.isRequired,
  showInputs: _propTypes.default.bool.isRequired
};
var defaultProps = {
  isNewDriver: false
};

function validateDriverInput(errors, values) {
  if (!values.isNewDriver) {
    (0, _formValidators.isRequired)(errors, values, 'driver', _languageService.default.validationMessages.FieldRequired());
  } else {
    (0, _formValidators.isRequiredArray)(errors, values, ['newDriver.firstName', 'newDriver.lastName'], _languageService.default.validationMessages.FieldRequired());
  }
}

function DriverInput(_ref) {
  var isNewDriver = _ref.isNewDriver,
      drivers = _ref.drivers,
      showInputs = _ref.showInputs;
  return _react.default.createElement(_IconArea.default, {
    icon: _icons.default.driver,
    iconTitle: _languageService.default.labels.Driver(),
    alignTop: true,
    className: "margin-bottom",
    iconClassName: "form-icon"
  }, _react.default.createElement(_ContentRevealer.default, {
    isVisible: !isNewDriver
  }, _react.default.createElement(_FoxComboBoxField.default, {
    label: _languageService.default.labels.Driver(),
    name: "driver",
    required: true,
    items: drivers,
    disabled: !showInputs
  })), _react.default.createElement(_ContentRevealer.default, {
    isVisible: showInputs,
    visibleClassName: _TransportFormModule.default.additional_top_offset
  }, _react.default.createElement("div", null, _react.default.createElement(_FoxSwitchField.default, {
    label: _languageService.default.labels.NewDriver(),
    placeholder: _languageService.default.labels.NewDriver(),
    name: "isNewDriver"
  }))), _react.default.createElement(_ContentRevealer.default, {
    isVisible: isNewDriver,
    visibleClassName: _TransportFormModule.default.additional_top_offset
  }, _react.default.createElement(_reduxForm.FormSection, {
    name: "newDriver"
  }, _react.default.createElement(_NewDriverForm.default, null))));
}

DriverInput.propTypes = propTypes;
DriverInput.defaultProps = defaultProps;

var _default = (0, _redux.compose)((0, _reduxForm.formValues)({
  isNewDriver: 'isNewDriver'
}), _react.memo)(DriverInput);

exports.default = _default;