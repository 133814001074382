"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reduxForm = require("redux-form");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _get = _interopRequireDefault(require("lodash/get"));

var _Form = _interopRequireDefault(require("fox-react/dist/components/Form"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _IconArea = _interopRequireDefault(require("hh-shared/dist/components/commons/IconArea"));

var _icons = _interopRequireDefault(require("hh-shared/dist/consts/icons"));

var _Row = _interopRequireDefault(require("../layout/Row"));

var _phoneMask = require("hh-shared/dist/utils/phoneMask");

var _TransportFormModule = _interopRequireDefault(require("./TransportForm.module.scss"));

var _DriverInput = _interopRequireWildcard(require("./DriverInput"));

var _CarInput = _interopRequireWildcard(require("./CarInput"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  transportOrderData: _propTypes.default.object.isRequired,
  drivers: _propTypes.default.array.isRequired,
  cars: _propTypes.default.array.isRequired,
  handleSubmit: _propTypes.default.func.isRequired,
  showInputs: _propTypes.default.bool.isRequired,
  carTypes: _propTypes.default.array.isRequired
};
var defaultProps = {};

function TransportForm(_ref) {
  var transportOrderData = _ref.transportOrderData,
      drivers = _ref.drivers,
      cars = _ref.cars,
      handleSubmit = _ref.handleSubmit,
      showInputs = _ref.showInputs,
      carTypes = _ref.carTypes;
  return _react.default.createElement(_Form.default, {
    handleSubmit: handleSubmit,
    className: _TransportFormModule.default.form
  }, _react.default.createElement(_Row.default, {
    className: _TransportFormModule.default.form_row
  }, _react.default.createElement(_DriverInput.default, {
    drivers: drivers,
    showInputs: showInputs
  }), _react.default.createElement(_IconArea.default, {
    icon: _icons.default.userData,
    iconTitle: _languageService.default.labels.AuthorizedPickupPerson(),
    alignTop: true
  }, _react.default.createElement("span", null, _languageService.default.labels.AuthorizedPickupPerson()), _react.default.createElement("div", null, (0, _get.default)(transportOrderData, 'authorizedPickupPerson') || _languageService.default.labels.LackOfInformations()))), _react.default.createElement(_Row.default, {
    className: _TransportFormModule.default.form_row
  }, _react.default.createElement(_CarInput.default, {
    cars: cars,
    carTypes: carTypes,
    showInputs: showInputs
  }), _react.default.createElement(_IconArea.default, {
    icon: _icons.default.phone,
    iconTitle: _languageService.default.labels.AuthorizedPickupPersonPhoneNumber(),
    alignTop: true
  }, _react.default.createElement("span", null, _languageService.default.labels.AuthorizedPickupPersonPhoneNumber()), _react.default.createElement("div", null, (0, _phoneMask.phoneMaskedDisplay)((0, _get.default)(transportOrderData, 'authorizedPickupPersonPhoneNumber')) || _languageService.default.labels.LackOfInformations()))));
}

TransportForm.propTypes = propTypes;
TransportForm.defaultProps = defaultProps;

var validate = function validate(values) {
  var errors = {};
  (0, _DriverInput.validateDriverInput)(errors, values);
  (0, _CarInput.validateCarInput)(errors, values);
  return errors;
};

var _default = (0, _reduxForm.reduxForm)({
  form: 'transportFormPreview',
  validate: validate
})((0, _react.memo)(TransportForm));

exports.default = _default;