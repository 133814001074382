"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getElementHeight = exports.getElementWidth = exports.getElementPropertyValue = exports.getElementPropertyValueString = void 0;

var getElementPropertyValueString = function getElementPropertyValueString(element, propertyName) {
  return window.getComputedStyle(element).getPropertyValue(propertyName);
};

exports.getElementPropertyValueString = getElementPropertyValueString;

var getElementPropertyValue = function getElementPropertyValue(element, propertyName) {
  return parseFloat(window.getComputedStyle(element).getPropertyValue(propertyName));
};

exports.getElementPropertyValue = getElementPropertyValue;

var getElementWidth = function getElementWidth(element) {
  return getElementPropertyValue(element, 'width');
};

exports.getElementWidth = getElementWidth;

var getElementHeight = function getElementHeight(element) {
  return getElementPropertyValue(element, 'height');
};

exports.getElementHeight = getElementHeight;