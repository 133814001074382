"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // Ogółem są dostępne te 4 typy toast-ów, ale defaultowym jest notification
// Jeśli omyłkowo zostanie wpisany jakiś inny typ, aniżeli ten z poniższych, to zostanie wyświetlony ciemny tost z ikonką powiadomienia

var _default = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
  notification: 'notification'
};
exports.default = _default;