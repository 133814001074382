import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, withRouter } from 'react-router-dom';

import SublinkCounter from 'hh-shared/dist/components/menu/SublinkCounter';

import styles from './MenuSublink.module.scss';

const propTypes = {
  routePath: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'red',
    'orange',
    'yellow',
    'blue',
    'darkBlue',
    'green',
    'darkGreen',
    'customGreen',
    'dark',
    'grey',
  ]),
  count: PropTypes.number,
};

const defaultProps = {
  className: '',
  onClick: undefined,
  isActive: undefined,
  color: 'darkGreen',
  count: undefined,
};

function MenuSublink({
  routePath,
  label,
  className,
  location,
  onClick,
  isActive,
  color,
  count,
}) {
  const getCurrentRoutePath = () => location.pathname;

  const markAsActive = () =>
    isActive !== undefined
      ? isActive
      : getCurrentRoutePath().includes(routePath);

  const sublinkClassName = cx(styles.link, className, styles[color], {
    [`active ${styles.link_active}`]: markAsActive(),
  });

  return (
    <Link
      onClick={onClick}
      className={sublinkClassName}
      to={routePath}
      title={label}
    >
      {label}
      {count >= 0 && <SublinkCounter count={count} color={color} />}
    </Link>
  );
}

MenuSublink.propTypes = propTypes;
MenuSublink.defaultProps = defaultProps;

export default withRouter(memo(MenuSublink));
