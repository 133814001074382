"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.maskedInputFieldValidator = exports.isRegonFieldValidator = exports.isNipFieldValidator = exports.isEmailFieldValidator = exports.isRequiredFieldValidator = void 0;

var _formValidators = require("fox-react/dist/utils/formValidators");

var _languageService = _interopRequireDefault(require("../language/services/languageService"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isRequiredFieldValidator = (0, _formValidators.isRequiredField)(_languageService.default.validationMessages.FieldRequired());
exports.isRequiredFieldValidator = isRequiredFieldValidator;
var isEmailFieldValidator = (0, _formValidators.isEmailField)(_languageService.default.validationMessages.FieldMustBeValidEmail());
exports.isEmailFieldValidator = isEmailFieldValidator;
var isNipFieldValidator = (0, _formValidators.isNipField)(_languageService.default.validationMessages.InvalidNip());
exports.isNipFieldValidator = isNipFieldValidator;
var isRegonFieldValidator = (0, _formValidators.isRegonField)(_languageService.default.validationMessages.InvalidRegon());
exports.isRegonFieldValidator = isRegonFieldValidator;
var maskedInputFieldValidator = (0, _formValidators.maskedInputField)(_languageService.default.validationMessages.FieldValueIncorrect());
exports.maskedInputFieldValidator = maskedInputFieldValidator;