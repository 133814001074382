import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import cx from 'classnames';

import lang from 'hh-shared/dist/language/services/languageService';
import Column from 'hh-shared/dist/components/layout/Column';
import icons from 'hh-shared/dist/consts/icons';

import MenuLink from '../MenuLink';
import styles from './NavigationMenu.module.scss';
import TransportOrderMenuPosition from './TransportOrderMenuPosition';
import AuctionsMenuPosition from './AuctionsMenuPosition';

const propTypes = {
  isOpened: PropTypes.bool,
  openMenu: PropTypes.func.isRequired,
};

const defaultProps = {
  isOpened: true,
};

function NavigationMenu({ isOpened, openMenu }) {
  const hasCompany = !!useSelector(state =>
    get(state, 'auth.currentUser.companyInstanceId'),
  );

  const isCompanyActive = useSelector(state =>
    get(state, 'auth.currentUser.isCompanyActive'),
  );

  const handleMenuOpen = () => openMenu();

  const [auctionMenuIsOpen, setAuctionMenuIsOpen] = useState(true);
  const [orderMenuIsOpen, setOrderMenuIsOpen] = useState(true);

  const closeAllMenuItems = () => {
    setAuctionMenuIsOpen(false);
    setOrderMenuIsOpen(false);
  };

  const menuClassName = cx('navigation_menu_container', styles.menu_container, {
    [styles.menu_container_toggled]: !isOpened,
  });

  useEffect(() => {
    !isOpened && closeAllMenuItems();
  }, [isOpened]);

  return (
    <Column className={menuClassName}>
      <MenuLink
        icon={icons.dashboard}
        label={lang.labels.Dashboard()}
        routePath="/"
        checkExactPath
      />

      {hasCompany && isCompanyActive && (
        <AuctionsMenuPosition
          auctionMenuIsOpen={auctionMenuIsOpen}
          setAuctionMenuIsOpen={setAuctionMenuIsOpen}
          handleMenuOpen={handleMenuOpen}
        />
      )}

      {hasCompany && isCompanyActive && (
        <TransportOrderMenuPosition
          orderMenuIsOpen={orderMenuIsOpen}
          setOrderMenuIsOpen={setOrderMenuIsOpen}
          handleMenuOpen={handleMenuOpen}
        />
      )}

      {!hasCompany && (
        <MenuLink
          icon={icons.company}
          label={lang.labels.ReportCompany()}
          routePath="/report-company"
          checkExactPath
        />
      )}

      <MenuLink
        icon={icons.driver}
        label={lang.labels.Drivers()}
        routePath="/drivers"
      />

      <MenuLink
        icon={icons.fastTruck}
        label={lang.labels.Cars()}
        routePath="/cars"
      />
    </Column>
  );
}

NavigationMenu.propTypes = propTypes;
NavigationMenu.defaultProps = defaultProps;

export default memo(NavigationMenu);
