import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Column from 'hh-shared/dist/components/layout/Column';
import PageWrapper from 'hh-shared/dist/components/layout/PageWrapper';
import { scrollTo } from 'hh-shared/dist/utils/scrollMethods';

import BidsSection from './BidsSection/BidsSection';

const propTypes = {
  auctionId: PropTypes.string.isRequired,
};

const defaultProps = {};

const AuctionPageRightPane = ({ auctionId }) => {
  const rightPaneClassName = cx('lifted', 'lifted-right', 'rounded');

  useEffect(() => {
    const rightPane = document.querySelector('.lifted-right');
    scrollTo(rightPane);
  }, []);

  return (
    <Column smaller className={rightPaneClassName}>
      <PageWrapper roundedLeft additionalClassNames="full-height">
        <BidsSection auctionId={auctionId} />
      </PageWrapper>
    </Column>
  );
};

AuctionPageRightPane.propTypes = propTypes;
AuctionPageRightPane.defaultProps = defaultProps;

export default AuctionPageRightPane;
