"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _InnerContentWrapperModule = _interopRequireDefault(require("./InnerContentWrapper.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  additionalClassNames: _propTypes.default.string,
  isWider: _propTypes.default.bool,
  fullWidth: _propTypes.default.bool
};
var defaultProps = {
  additionalClassNames: '',
  isWider: false,
  fullWidth: false
};

function InnerContentWrapper(_ref) {
  var children = _ref.children,
      additionalClassNames = _ref.additionalClassNames,
      isWider = _ref.isWider,
      fullWidth = _ref.fullWidth;
  return _react.default.createElement("div", {
    className: "".concat(_InnerContentWrapperModule.default.inner_content_wrapper, " ").concat(additionalClassNames, " ").concat(isWider ? _InnerContentWrapperModule.default.wider : '', " ").concat(fullWidth ? _InnerContentWrapperModule.default.full_width : '', " inner-content-wrapper")
  }, children);
}

InnerContentWrapper.propTypes = propTypes;
InnerContentWrapper.defaultProps = defaultProps;
var _default = InnerContentWrapper;
exports.default = _default;