import React, { useState, useEffect, memo } from 'react';
import { formValues } from 'redux-form';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';
import styles from 'hh-shared/dist/components/layout/AccordionItem.module.scss';
import Row from 'layout/Row';

const propTypes = {
  onRemove: PropTypes.func,
  children: PropTypes.any.isRequired,
  carIndex: PropTypes.number.isRequired,
  cars: PropTypes.array,
};

const defaultProps = {
  onRemove: undefined,
  cars: [],
};

const CarAccordionItem = ({ onRemove, children, carIndex, cars }) => {
  const currentCar = cars[carIndex];
  const [isExpanded, setIsExpanded] = useState(false);
  const [title, setTitle] = useState(lang.labels.NewCar());
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    const { registerNumber } = currentCar;
    setTitle(registerNumber || lang.labels.NewCar());
  }, [currentCar, carIndex]);

  return (
    <div className={styles.item_container}>
      <Row
        className={`${styles.header} justify-content-space-between align-items-center`}
        onClick={toggleExpanded}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.icon_container}>
          {onRemove && (
            <FontAwesomeIcon
              icon={icons.trash}
              title={lang.buttons.RemoveCar()}
              className={`${styles.icon} ${styles.icon_remove} clickable-icon`}
              onClick={onRemove}
            />
          )}
          <FontAwesomeIcon
            icon={icons.chevronDown}
            title={isExpanded ? lang.labels.Fold() : lang.labels.Expand()}
            className={`${styles.icon} ${
              isExpanded ? styles.icon_reversed : ''
            }`}
          />
        </div>
      </Row>
      <ContentRevealer
        isVisible={isExpanded}
        visibleClassName={styles.content}
        dontRemoveChildrenOnHide
      >
        {children}
      </ContentRevealer>
    </div>
  );
};

CarAccordionItem.propTypes = propTypes;
CarAccordionItem.defaultProps = defaultProps;

export default formValues('cars')(memo(CarAccordionItem));
