"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactButtons = require("@progress/kendo-react-buttons");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any,
  onClick: _propTypes.default.func,
  disabled: _propTypes.default.bool,
  primary: _propTypes.default.bool,
  icon: _propTypes.default.string,
  type: _propTypes.default.string,
  className: _propTypes.default.string,
  title: _propTypes.default.string,
  style: _propTypes.default.object
};
var defaultProps = {
  children: undefined,
  onClick: undefined,
  disabled: false,
  primary: false,
  icon: undefined,
  type: 'submit',
  className: '',
  title: '',
  style: {}
};

function FoxButton(_ref) {
  var children = _ref.children,
      onClick = _ref.onClick,
      disabled = _ref.disabled,
      primary = _ref.primary,
      icon = _ref.icon,
      type = _ref.type,
      className = _ref.className,
      title = _ref.title,
      style = _ref.style;
  return _react.default.createElement(_kendoReactButtons.Button, {
    onClick: onClick,
    disabled: disabled,
    primary: primary,
    icon: icon,
    type: type,
    className: className,
    title: title,
    style: style
  }, children);
}

FoxButton.propTypes = propTypes;
FoxButton.defaultProps = defaultProps;
var _default = FoxButton;
exports.default = _default;