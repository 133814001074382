import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import gridNames from 'common/gridNames';
import DriversSrv from './DriversSrv';

export function storeResults(results) {
  return gridStoreResults(gridNames.drivers)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return DriversSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}
