import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useCountUp } from 'react-countup';
import { withRouter } from 'react-router-dom';

import Column from 'layout/Column';

import styles from './DashboardCardBox.module.scss';

const propTypes = {
  quantity: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  delay: PropTypes.number,
  onClick: PropTypes.func,
  history: PropTypes.object.isRequired,
  link: PropTypes.string,
};

const defaultProps = {
  delay: 0.5,
  onClick: () => {},
  link: '/',
};

const DashboardCardBox = ({
  quantity,
  label,
  delay,
  onClick,
  history,
  link,
}) => {
  const boxLabelClassName = cx(
    styles.box_label,
    'justify-content-center',
    'flex-item',
  );

  const hasActiveCompany = useSelector(state =>
    get(state, 'auth.currentUser.isCompanyActive'),
  );

  const boxQuantity = useMemo(() => (hasActiveCompany ? quantity : 0), [
    quantity,
    hasActiveCompany,
  ]);

  const { countUp, update: updateValue } = useCountUp({
    start: 0,
    end: boxQuantity,
    duration: 2.75,
    delay,
  });

  useEffect(() => {
    updateValue(boxQuantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxQuantity]);

  const handleOnClick = () => {
    onClick();
    history.push(link);
  };

  return (
    <Column className={styles.box} onClick={handleOnClick}>
      <div className={styles.box_quantity}>{countUp}</div>
      <Column className={boxLabelClassName}>{label}</Column>
    </Column>
  );
};

DashboardCardBox.propTypes = propTypes;
DashboardCardBox.defaultProps = defaultProps;

export default withRouter(DashboardCardBox);
