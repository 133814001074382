"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = require("prop-types");

var _difference = _interopRequireDefault(require("lodash/difference"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _Column = _interopRequireDefault(require("../layout/Column"));

var _PreviewButton = _interopRequireDefault(require("../commons/PreviewButton"));

var _transportOrderStatusesIds = _interopRequireDefault(require("../../consts/transportOrderStatusesIds"));

var _TimelineModule = _interopRequireDefault(require("./Timeline.module.scss"));

var _TimelineItem = _interopRequireDefault(require("./TimelineItem"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  statusHistory: _propTypes.PropTypes.array.isRequired,
  onAddMessageClick: _propTypes.PropTypes.func.isRequired
};
var defaultProps = {};

var Timeline = function Timeline(_ref) {
  var statusHistory = _ref.statusHistory,
      onAddMessageClick = _ref.onAddMessageClick;
  var timeLineClassName = (0, _classnames.default)(_TimelineModule.default.timeline, 'justify-items-center');
  var statusArr = Object.values(_objectSpread({}, _transportOrderStatusesIds.default)).filter(function (val) {
    return val !== _transportOrderStatusesIds.default.canceled;
  });

  var isLastActiveItem = function isLastActiveItem(itemIndex) {
    return itemIndex === statusHistory.length - 1;
  };

  return _react.default.createElement(_Column.default, {
    className: timeLineClassName
  }, statusHistory.map(function (x, xIndex) {
    return _react.default.createElement(_TimelineItem.default, {
      statusHistory: x,
      key: x.time,
      lastActiveItem: isLastActiveItem(xIndex),
      renderCommentButton: _react.default.createElement(_PreviewButton.default, {
        label: _languageService.default.labels.TransportOrderDialog_AddNote(),
        buttonColor: "blue",
        onClick: function onClick() {
          return onAddMessageClick();
        },
        icon: _icons.default.plus,
        className: _TimelineModule.default.comment_button
      })
    });
  }), (0, _difference.default)(statusArr, statusHistory.map(function (status) {
    return status.statusId;
  }).filter(function (statusId) {
    return !!statusId;
  })).map(function (statusId) {
    return _react.default.createElement(_TimelineItem.default, {
      statusHistory: {
        statusId: statusId
      },
      key: statusId
    });
  }));
};

Timeline.propTypes = propTypes;
Timeline.defaultProps = defaultProps;
var _default = Timeline;
exports.default = _default;