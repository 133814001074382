import axios from 'config/axios';
import store from 'store/store';
import { loginSuccess } from 'auth/actions';

export default function refreshToken() {
  return axios
    .post('/auth/refreshToken', {
      token: localStorage.getItem('refreshToken'),
    })
    .then(res => {
      store.dispatch(loginSuccess(res.data));
    });
}
