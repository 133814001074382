"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _SectionWrapperModule = _interopRequireDefault(require("./SectionWrapper.module.scss"));

require("./SectionWrapper.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  additionalClassNames: _propTypes.default.string,
  bottomSpace: _propTypes.default.bool,
  columnOnBreak: _propTypes.default.bool
};
var defaultProps = {
  additionalClassNames: '',
  bottomSpace: false,
  columnOnBreak: false
};

function SectionWrapper(_ref) {
  var children = _ref.children,
      additionalClassNames = _ref.additionalClassNames,
      bottomSpace = _ref.bottomSpace,
      columnOnBreak = _ref.columnOnBreak;
  return _react.default.createElement("div", {
    className: "".concat(_SectionWrapperModule.default.section_wrapper, "\n        ").concat(additionalClassNames, "\n        ").concat(bottomSpace ? _SectionWrapperModule.default.bottom_space : '', " ").concat(columnOnBreak ? _SectionWrapperModule.default.column_on_break : '')
  }, children);
}

SectionWrapper.propTypes = propTypes;
SectionWrapper.defaultProps = defaultProps;
var _default = SectionWrapper;
exports.default = _default;