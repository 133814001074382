"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ClickablePanelModule = _interopRequireDefault(require("./ClickablePanel.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  onClick: _propTypes.default.func,
  children: _propTypes.default.any.isRequired,
  additionalClassNames: _propTypes.default.string,
  noAdditionalPadding: _propTypes.default.bool,
  dataCy: _propTypes.default.string
};
var defaultProps = {
  onClick: function onClick() {},
  additionalClassNames: '',
  noAdditionalPadding: false,
  dataCy: undefined
};

function ClickablePanel(_ref) {
  var onClick = _ref.onClick,
      children = _ref.children,
      additionalClassNames = _ref.additionalClassNames,
      noAdditionalPadding = _ref.noAdditionalPadding,
      dataCy = _ref.dataCy;
  return _react.default.createElement("div", {
    className: "".concat(_ClickablePanelModule.default.clickable_panel, " ").concat(additionalClassNames, " ").concat(noAdditionalPadding ? _ClickablePanelModule.default.no_padding : ''),
    role: "button",
    tabIndex: "0",
    onClick: onClick,
    "data-cy": dataCy
  }, children);
}

ClickablePanel.propTypes = propTypes;
ClickablePanel.defaultProps = defaultProps;
var _default = ClickablePanel;
exports.default = _default;