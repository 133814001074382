"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _FontAwesomeIcon = _interopRequireDefault(require("../commons/FontAwesomeIcon/FontAwesomeIcon"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _FormSectionTitleModule = _interopRequireDefault(require("./FormSectionTitle.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  icon: _propTypes.default.any.isRequired,
  title: _propTypes.default.any.isRequired,
  requiredTitle: _propTypes.default.bool,
  isInvalid: _propTypes.default.bool
};
var defaultProps = {
  requiredTitle: false,
  isInvalid: false
};

var FormSectionTitle = function FormSectionTitle(_ref) {
  var icon = _ref.icon,
      title = _ref.title,
      requiredTitle = _ref.requiredTitle,
      isInvalid = _ref.isInvalid; // TODO Vorbert zastanowić się nad zrobieniem jednej klasy dla stylów typu invalid

  var sectionIconClassName = (0, _classnames.default)(_FormSectionTitleModule.default.section_icon, 'section-icon', _defineProperty({}, _FormSectionTitleModule.default.section_icon_invalid, isInvalid));
  var requiredStarClassName = (0, _classnames.default)('k-required', _FormSectionTitleModule.default.title_star);
  var titleClassName = (0, _classnames.default)(_FormSectionTitleModule.default.title, _defineProperty({}, _FormSectionTitleModule.default.title_invalid, isInvalid));
  var invalidMessageClassName = (0, _classnames.default)('k-invalid-message', _FormSectionTitleModule.default.title_invalid_message);
  return _react.default.createElement("div", {
    className: _FormSectionTitleModule.default.title_container
  }, _react.default.createElement(_FontAwesomeIcon.default, {
    icon: icon,
    className: sectionIconClassName,
    title: title
  }), _react.default.createElement("div", {
    className: titleClassName
  }, title, requiredTitle && _react.default.createElement("span", {
    className: requiredStarClassName
  }, "*"), isInvalid && _react.default.createElement("span", {
    className: invalidMessageClassName
  }, _languageService.default.labels.FieldRequired())));
};

FormSectionTitle.propTypes = propTypes;
FormSectionTitle.defaultProps = defaultProps;
var _default = FormSectionTitle;
exports.default = _default;