"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _NotificationCircleModule = _interopRequireDefault(require("./NotificationCircle.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  numberOfNotifications: _propTypes.default.number
};
var defaultProps = {
  numberOfNotifications: 0
};

var NotificationCircle = function NotificationCircle(_ref) {
  var numberOfNotifications = _ref.numberOfNotifications;
  return _react.default.createElement("div", {
    className: "".concat(_NotificationCircleModule.default.circle, "  ").concat(numberOfNotifications === 0 ? _NotificationCircleModule.default.circle_grey : '')
  }, _react.default.createElement("span", {
    className: _NotificationCircleModule.default.circle_number
  }, numberOfNotifications));
};

NotificationCircle.propTypes = propTypes;
NotificationCircle.defaultProps = defaultProps;
var _default = NotificationCircle;
exports.default = _default;