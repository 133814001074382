import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import { useSelector, useDispatch } from 'react-redux';
import { getForGrid as getForGridAuctionsManagement } from 'auctions/actions';
import auctionActionTypes from 'auctions/actionTypes';
import { auctionOngoingFilter } from 'auctions/filters';

import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import Column from 'hh-shared/dist/components/layout/Column';
import Row from 'hh-shared/dist/components/layout/Row';

import AuctionCardBoxes from './AuctionCardBoxes';
import styles from './DashboardCard.module.scss';

const propTypes = {
  auctionsData: PropTypes.shape({
    liveCount: PropTypes.number,
    canceledCount: PropTypes.number,
    observedCount: PropTypes.number,
    endedSuccessfulCount: PropTypes.number,
    endedWithoutSuccessCount: PropTypes.number,
    endingTodayCount: PropTypes.number,
  }),
  className: PropTypes.string,
};

const defaultProps = {
  auctionsData: undefined,
  className: '',
};

const AuctionDashboardCard = ({ auctionsData, className }) => {
  const cardContainerClassName = cx(styles.card_container, className);
  const timeLeftClassName = cx(styles.event_time_left, 'align-items-end');

  const dispatch = useDispatch();
  const dataState = useSelector((state) => state.auctions.dataState);

  const hasActiveCompany = useSelector((state) =>
    get(state, 'auth.currentUser.isCompanyActive'),
  );

  const changeFilter = (filter) => {
    dispatch(
      getForGridAuctionsManagement({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: auctionActionTypes.AUCTIONS_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchOngoing = () => changeFilter(auctionOngoingFilter);

  const getQuantityForActiveCompany = (value) => (hasActiveCompany ? value : 0);

  const endingTodayCount =
    getQuantityForActiveCompany(
      auctionsData && auctionsData.endingTodayCount,
    ) || 0;

  return (
    <Column className={cardContainerClassName}>
      <Row className="align-items-center">
        <FontAwesomeIcon
          icon={icons.auction}
          title={lang.labels.Auctions()}
          className={styles.icon}
        />
        <div className={styles.title}>{lang.labels.Auctions()}</div>
      </Row>
      <Column className={styles.event}>
        <div className={styles.event_title}>
          {lang.labels.TodayAreEnding()}:
        </div>
        <Link
          title={lang.labels.GoToTheEvent()}
          className={timeLeftClassName}
          onClick={dispatchOngoing}
          to="/auctions"
        >
          {`${endingTodayCount} ${
            endingTodayCount !== 1
              ? lang.labels.Auctions()
              : lang.labels.Auction()
          }`}
          <FontAwesomeIcon
            icon={icons.arrowRight}
            title={lang.labels.GoToTheEvent()}
            className={styles.event_link_arrow}
          />
        </Link>
      </Column>
      <AuctionCardBoxes {...auctionsData} />
    </Column>
  );
};

AuctionDashboardCard.propTypes = propTypes;
AuctionDashboardCard.defaultProps = defaultProps;

export default AuctionDashboardCard;
