"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _FoxInputText = _interopRequireDefault(require("../components/FoxInputText"));

var _asReduxFormField = _interopRequireDefault(require("./asReduxFormField"));

var _wrapFormField = _interopRequireDefault(require("./wrapFormField"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = (0, _wrapFormField.default)((0, _asReduxFormField.default)(_FoxInputText.default));

exports.default = _default;