import axios from 'config/axios';

const prefix = 'notification';

export default class NotificationSrv {
  static get() {
    return axios.get(`${prefix}`).then(result => result.data);
  }

  static updateSms(id, value) {
    return axios
      .put(`${prefix}/sms/${id}`, { value })
      .then(result => result.data);
  }

  static updateEmail(id, value) {
    return axios
      .put(`${prefix}/email/${id}`, { value })
      .then(result => result.data);
  }
}
