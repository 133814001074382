import React from 'react';
import cx from 'classnames';
import Badge from 'hh-shared/dist/components/commons/Badge';
import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.string.isRequired,
  statusId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const OrdersStatusCell = ({ text, statusId, className, ...others }) => {
  const { placed, accepted, loaded, realized } = transportOrderStatusesIds;
  const orderCellClassName = cx('align-text-center', className);

  const getBadgeColor = () => {
    switch (statusId) {
      case placed:
        return { red: true };
      case accepted:
        return { blue: true };
      case loaded:
        return { customGreen: true };
      case realized:
        return { green: true };
      default:
        return { orange: true };
    }
  };

  return (
    <td colSpan="1" className={orderCellClassName}>
      <Badge
        label={text}
        {...others}
        {...getBadgeColor()}
        className="margin-auto"
        opaque
        smaller
      />
    </td>
  );
};

OrdersStatusCell.propTypes = propTypes;
OrdersStatusCell.defaultProps = defaultProps;

export default OrdersStatusCell;
