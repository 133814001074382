import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import CarsSrv from './CarsSrv';
import CarForm from './CarForm';
import CarPagePreview from './CarPagePreview';

const propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const defaultProps = {};

function EditCarPage({ history, match }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [carTypes, setCarTypes] = useState([]);

  const onSubmit = model =>
    CarsSrv.update({ ...model, instanceId: match.params.instanceId });

  useEffect(() => {
    setShowSpinner(true);
    Promise.all([
      CarsSrv.get(match.params.instanceId),
      CarsSrv.getCarTypesAsSelectItem(),
    ])
      .then(([carForEdit, carTypesRes]) => {
        setInitialValues(carForEdit);
        setCarTypes(carTypesRes);
      })
      .finally(() => setShowSpinner(false));
  }, [match.params.instanceId]);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/drivers',
            label: lang.labels.Drivers(),
          },
          {
            routePath: '/drivers/add',
            label: lang.labels.AddingNewDriver(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <CarForm
              onSubmit={onSubmit}
              title={lang.labels.EditingCar()}
              history={history}
              initialValues={initialValues}
              carTypes={carTypes}
              isEditForm
            />
          </div>
          <CarPagePreview editForm />
        </FormCardWrapper>
      </FormPreviewSectionContainer>
    </>
  );
}

EditCarPage.propTypes = propTypes;
EditCarPage.defaultProps = defaultProps;

export default EditCarPage;
