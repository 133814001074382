"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var propTypes = {
  className: _propTypes.default.string,
  children: _propTypes.default.node,
  onClick: _propTypes.default.func,
  style: _propTypes.default.object
};
var defaultProps = {
  className: '',
  children: undefined,
  onClick: function onClick() {},
  style: {}
};

function Row(_ref) {
  var className = _ref.className,
      children = _ref.children,
      onClick = _ref.onClick,
      style = _ref.style,
      others = _objectWithoutProperties(_ref, ["className", "children", "onClick", "style"]);

  return _react.default.createElement("div", _extends({}, others, {
    style: style,
    className: "flex-wrapper flex-wrapper-row ".concat(className),
    onClick: onClick
  }), children);
}

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;
var _default = Row;
exports.default = _default;