"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _resourceService = _interopRequireDefault(require("../services/resourceService"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _ = function _(path) {
  return function () {
    return _resourceService.default.get(path);
  };
};

var _default = {
  AccountNotFoundProbablyTokenHasExpiredOrIncorrectEmail: _('validationMessages.AccountNotFoundProbablyTokenHasExpiredOrIncorrectEmail'),
  AddressFromCannotBeEqualToAddressTo: _('validationMessages.AddressFromCannotBeEqualToAddressTo'),
  AuctionAlreadyPublished: _('validationMessages.AuctionAlreadyPublished'),
  AuctionIsNotLiveOrNotExists: _('validationMessages.AuctionIsNotLiveOrNotExists'),
  BoxPicture: _('validationMessages.BoxPicture'),
  CannotBePastDate: _('validationMessages.CannotBePastDate'),
  CannotDeleteAuctionDifferentThanDraftOrForPublication: _('validationMessages.CannotDeleteAuctionDifferentThanDraftOrForPublication'),
  CannotEditStartedAuction: _('validationMessages.CannotEditStartedAuction'),
  CannotSaveZipCodeAlreadyExist: _('validationMessages.CannotSaveZipCodeAlreadyExist'),
  CannotUpdateNonPlacedTransportOrder: _('validationMessages.CannotUpdateNonPlacedTransportOrder'),
  CannotUpdateRealizedTransportOrder: _('validationMessages.CannotUpdateRealizedTransportOrder'),
  CarNotFoundToEdit: _('validationMessages.CarNotFoundToEdit'),
  CompanyDidNotAllowToBidOnItsBehalf: _('validationMessages.CompanyDidNotAllowToBidOnItsBehalf'),
  CompanyNotFoundToEdit: _('validationMessages.CompanyNotFoundToEdit'),
  CompanyNotFoundWithThisNipNumber: _('validationMessages.CompanyNotFoundWithThisNipNumber'),
  CompanyWithThatNipAlreadyExists: _('validationMessages.CompanyWithThatNipAlreadyExists'),
  Currency: _('validationMessages.Currency'),
  CurrentPasswordIsNotCorrect: _('validationMessages.CurrentPasswordIsNotCorrect'),
  DepartmentRequiredForDepAdmin: _('validationMessages.DepartmentRequiredForDepAdmin'),
  DepartmentWithThisNameAlreadyExists: _('validationMessages.DepartmentWithThisNameAlreadyExists'),
  EmailAddressNotFound: _('validationMessages.EmailAddressNotFound'),
  EmailHasAlreadyBeenSent: _('validationMessages.EmailHasAlreadyBeenSent'),
  EmailNotFound: _('validationMessages.EmailNotFound'),
  Expense: _('validationMessages.Expense'),
  FacilityNotFoundToEdit: _('validationMessages.FacilityNotFoundToEdit'),
  FacilityRequiredForEmployee: _('validationMessages.FacilityRequiredForEmployee'),
  FieldMustBeValidEmail: _('validationMessages.FieldMustBeValidEmail'),
  FieldRequired: _('validationMessages.FieldRequired'),
  FieldValueIncorrect: _('validationMessages.FieldValueIncorrect'),
  FileExtensionIsNotSupported: _('validationMessages.FileExtensionIsNotSupported'),
  FileIsEmpty: _('validationMessages.FileIsEmpty'),
  GusDataLoaded: _('validationMessages.GusDataLoaded'),
  HtmlTemplateNotFound: _('validationMessages.HtmlTemplateNotFound'),
  ImportedWithoutInvalidNip: _('validationMessages.ImportedWithoutInvalidNip'),
  IncorrectLoginDetails: _('validationMessages.IncorrectLoginDetails'),
  InvalidNip: _('validationMessages.InvalidNip'),
  InvalidRegon: _('validationMessages.InvalidRegon'),
  LoadingData: _('validationMessages.LoadingData'),
  LoadingTimeCannotBeLaterThanUnloadingTime: _('validationMessages.LoadingTimeCannotBeLaterThanUnloadingTime'),
  LoadingUnloadingAddressesCannotBeTheSame: _('validationMessages.LoadingUnloadingAddressesCannotBeTheSame'),
  MissingIdOrInstanceId: _('validationMessages.MissingIdOrInstanceId'),
  NewPasswordWasSet: _('validationMessages.NewPasswordWasSet'),
  NoFile: _('validationMessages.NoFile'),
  NotFoundRoute: _('validationMessages.NotFoundRoute'),
  NotSupportedNotificationId: _('validationMessages.NotSupportedNotificationId'),
  NotValidPassword: _('validationMessages.NotValidPassword'),
  PasswordChanged: _('validationMessages.PasswordChanged'),
  PasswordsNotEquals: _('validationMessages.PasswordsNotEquals'),
  PriceCannotBeLessThan0: _('validationMessages.PriceCannotBeLessThan0'),
  PriceShouldBeLessThanLastBid: _('validationMessages.PriceShouldBeLessThanLastBid'),
  ProblemWithGus: _('validationMessages.ProblemWithGus'),
  ResetPasswordSend: _('validationMessages.ResetPasswordSend'),
  RouteAlreadyExists: _('validationMessages.RouteAlreadyExists'),
  RouteNotFoundToEdit: _('validationMessages.RouteNotFoundToEdit'),
  SaveSuccessful: _('validationMessages.SaveSuccessful'),
  SelectDate: _('validationMessages.SelectDate'),
  SelectedLoginIsAlreadyTakenSelectAnother: _('validationMessages.SelectedLoginIsAlreadyTakenSelectAnother'),
  SuccessUpdatedCommentOnTransportOrder: _('validationMessages.SuccessUpdatedCommentOnTransportOrder'),
  ThereIsNoSuchAuction: _('validationMessages.ThereIsNoSuchAuction'),
  ThereIsNoSuchCar: _('validationMessages.ThereIsNoSuchCar'),
  ThereIsNoSuchCarType: _('validationMessages.ThereIsNoSuchCarType'),
  ThereIsNoSuchCompanyOrCompanyIsInactive: _('validationMessages.ThereIsNoSuchCompanyOrCompanyIsInactive'),
  ThereIsNoSuchCurrency: _('validationMessages.ThereIsNoSuchCurrency'),
  ThereIsNoSuchDefaultPrice: _('validationMessages.ThereIsNoSuchDefaultPrice'),
  ThereIsNoSuchDepartment: _('validationMessages.ThereIsNoSuchDepartment'),
  ThereIsNoSuchDriver: _('validationMessages.ThereIsNoSuchDriver'),
  ThereIsNoSuchFacility: _('validationMessages.ThereIsNoSuchFacility'),
  ThereIsNoSuchHtmlTemplate: _('validationMessages.ThereIsNoSuchHtmlTemplate'),
  ThereIsNoSuchTransportOrder: _('validationMessages.ThereIsNoSuchTransportOrder'),
  ThereIsNoSuchUser: _('validationMessages.ThereIsNoSuchUser'),
  ThereIsNoSuchZipCode: _('validationMessages.ThereIsNoSuchZipCode'),
  TimeFromCannotBeLaterThanTimeTo: _('validationMessages.TimeFromCannotBeLaterThanTimeTo'),
  Transport: _('validationMessages.Transport'),
  TransportOrderAlreadyAccepted: _('validationMessages.TransportOrderAlreadyAccepted'),
  TransportOrderAlreadyCanceled: _('validationMessages.TransportOrderAlreadyCanceled'),
  TransportOrderCanceled: _('validationMessages.TransportOrderCanceled'),
  UnhandledErrorOccured: _('validationMessages.UnhandledErrorOccured'),
  UnloadingData: _('validationMessages.UnloadingData'),
  UserInformationUpdated: _('validationMessages.UserInformationUpdated'),
  UserWithThisEmailAlreadyExists: _('validationMessages.UserWithThisEmailAlreadyExists'),
  UserWithThisLoginAlreadyExists: _('validationMessages.UserWithThisLoginAlreadyExists'),
  Welcome: _('validationMessages.Welcome'),
  YouCanOnlyAcceptPlacedTransportOrderOrUpdate: _('validationMessages.YouCanOnlyAcceptPlacedTransportOrderOrUpdate'),
  YouCanOnlyLoadedAcceptedTransportOrder: _('validationMessages.YouCanOnlyLoadedAcceptedTransportOrder'),
  YouCanOnlyRealizeLoadedTransportOrder: _('validationMessages.YouCanOnlyRealizeLoadedTransportOrder'),
  YouCannotCancelNotLiveAuction: _('validationMessages.YouCannotCancelNotLiveAuction'),
  YouMustAcceptRodo: _('validationMessages.YouMustAcceptRodo'),
  YouMustCheckReadConditions: _('validationMessages.YouMustCheckReadConditions'),
  YouMustCheckReadPrinciples: _('validationMessages.YouMustCheckReadPrinciples'),
  YourAccountCannotLogin: _('validationMessages.YourAccountCannotLogin'),
  YourSessionHasExpired: _('validationMessages.YourSessionHasExpired'),
  ZipCodeAlreadyExist: _('validationMessages.ZipCodeAlreadyExist'),
  ZipCodeFromCannotBeEqualToZipCodeTo: _('validationMessages.ZipCodeFromCannotBeEqualToZipCodeTo')
};
exports.default = _default;