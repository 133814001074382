import axios from 'axios';
import { addBearerTokenFromLocalStorage } from 'fox-react/dist/utils/axios';
import ToastrTypes from 'hh-shared/dist/toastr/ToastrTypes';
import { displayToastr } from 'hh-shared/dist/toastr/toastrActions';
import store from 'store/store';
import authActionTypes from 'auth/actionTypes';
import { loginSuccess } from 'auth/actions';
import get from 'lodash/get';
import langs from 'hh-shared/dist/language/services/languageService';
import config from './config';

/* eslint-disable indent */

const refreshTokenEndpoint = '/auth/refreshToken';

function doNothing(conf) {
  return conf;
}

function handle500(response) {
  const errorCode = get(response, 'data.exceptionGuid');
  const errorMessage = `${langs.validationMessages.UnhandledErrorOccured()} ${errorCode}`;
  store.dispatch(displayToastr(errorMessage, ToastrTypes.error));
}

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? // eslint-disable-next-line no-restricted-globals
        `${location.origin}${config.apiUrl}`
      : config.apiUrl,
});

instance.interceptors.request.use(addBearerTokenFromLocalStorage);

instance.interceptors.response.use(doNothing, (error) => {
  const response = error.response || error;
  const originalRequest = error.config;

  if (response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return instance
      .post(refreshTokenEndpoint, {
        token: localStorage.getItem('refreshToken'),
      })
      .then((res) => {
        store.dispatch(loginSuccess(res.data));
        error.config.headers.Authorization = `Bearer ${res.data.accessToken}`;
        return instance(error.config);
      })
      .catch((catchedErr) => {
        if (catchedErr.config.url.endsWith(refreshTokenEndpoint)) {
          store.dispatch({
            type: authActionTypes.AUTH_LOGOUT,
          });
        }
        return Promise.reject(error);
      });
  }

  if (response.status === 400) {
    store.dispatch(displayToastr(response.data.message, ToastrTypes.warning));
  } else if (response.status >= 500 && response.status < 600) {
    handle500(response);
  } else {
    store.dispatch(displayToastr(response.message, ToastrTypes.error));
  }

  return Promise.reject(error);
});

export default instance;
