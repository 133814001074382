import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import refreshToken from 'auth/refreshToken';
import CompanyForm from './CompanyForm';
import CompanyPagePreview from './CompanyPagePreview';
import { addAsync, uploadAttachments } from './actions';
import CarsSrv from '../cars/CarsSrv';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function CompanyAddPage({ history }) {
  const [showSpinner, setShowSpinner] = useState(true);
  const [carTypes, setCarTypes] = useState([]);

  useEffect(() => {
    setShowSpinner(true);

    CarsSrv.getCarTypesAsSelectItem()
      .then(result => setCarTypes(result))
      .finally(() => setShowSpinner(false));
  }, []);

  const onSubmit = model => addAsync(model).then(() => refreshToken());

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/report-company',
            label: lang.labels.ReportCompany(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={cx({ fox_spinner: showSpinner })}>
            <CompanyForm
              onSubmit={onSubmit}
              history={history}
              carTypes={carTypes}
              onUploadAttachments={uploadAttachments}
            />
          </div>
        </FormCardWrapper>
        <CompanyPagePreview />
      </FormPreviewSectionContainer>
    </>
  );
}

CompanyAddPage.propTypes = propTypes;
CompanyAddPage.defaultProps = defaultProps;

export default CompanyAddPage;
