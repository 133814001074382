"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var initialState = {
  results: {
    items: [],
    totalCount: 0
  },
  columnsInfo: [],
  dataState: {
    skip: 0,
    take: 10,
    sort: [],
    group: []
  }
};
var _default = initialState;
exports.default = _default;