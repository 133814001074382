"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _FormSectionCardModule = _interopRequireDefault(require("./FormSectionCard.module.scss"));

var _FormSectionTitle = _interopRequireDefault(require("./FormSectionTitle"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  title: _propTypes.default.string.isRequired,
  titleIcon: _propTypes.default.any.isRequired,
  className: _propTypes.default.string,
  fullContentWidth: _propTypes.default.bool,
  lessPaddingLeft: _propTypes.default.bool,
  lessPaddingRight: _propTypes.default.bool,
  requiredTitle: _propTypes.default.bool,
  isInvalid: _propTypes.default.bool
};
var defaultProps = {
  className: '',
  fullContentWidth: false,
  lessPaddingLeft: false,
  lessPaddingRight: false,
  requiredTitle: false,
  isInvalid: false
};

var FormSection = function FormSection(_ref) {
  var children = _ref.children,
      title = _ref.title,
      titleIcon = _ref.titleIcon,
      className = _ref.className,
      fullContentWidth = _ref.fullContentWidth,
      lessPaddingLeft = _ref.lessPaddingLeft,
      lessPaddingRight = _ref.lessPaddingRight,
      requiredTitle = _ref.requiredTitle,
      isInvalid = _ref.isInvalid;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      singleCard = _useState2[0],
      setSingleCard = _useState2[1];

  var cardRef = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    cardRef.current && function () {
      var parentRef = cardRef.current.parentElement;
      var childrenQuantity = parentRef.children.length;
      setSingleCard(childrenQuantity <= 1);
    }();
  }, [cardRef]);
  return _react.default.createElement("div", {
    className: "".concat(_FormSectionCardModule.default.form_section_container, " section-card ").concat(className, " ").concat(singleCard ? "".concat(_FormSectionCardModule.default.single_card, " single-card") : '', " ").concat(fullContentWidth ? "".concat(_FormSectionCardModule.default.full_content_width, " full-width-card") : '', " ").concat(lessPaddingLeft ? _FormSectionCardModule.default.less_padding_left : '', " ").concat(lessPaddingRight ? _FormSectionCardModule.default.less_padding_right : ''),
    ref: cardRef
  }, _react.default.createElement("div", {
    className: singleCard ? _FormSectionCardModule.default.single_card_wrapper : ''
  }, _react.default.createElement(_FormSectionTitle.default, {
    icon: titleIcon,
    title: title,
    requiredTitle: requiredTitle,
    isInvalid: isInvalid
  }), children));
};

FormSection.propTypes = propTypes;
FormSection.defaultProps = defaultProps;
var _default = FormSection;
exports.default = _default;