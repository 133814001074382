"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _FontAwesomeIcon = _interopRequireDefault(require("./FontAwesomeIcon/FontAwesomeIcon"));

var _Column = _interopRequireDefault(require("../layout/Column"));

var _IconAreaModule = _interopRequireDefault(require("./IconArea.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  className: _propTypes.default.string,
  iconClassName: _propTypes.default.string,
  children: _propTypes.default.any.isRequired,
  icon: _propTypes.default.any.isRequired,
  iconTitle: _propTypes.default.string.isRequired,
  small: _propTypes.default.bool
};
var defaultProps = {
  className: '',
  iconClassName: '',
  small: false
};

function IconArea(_ref) {
  var className = _ref.className,
      iconClassName = _ref.iconClassName,
      children = _ref.children,
      icon = _ref.icon,
      iconTitle = _ref.iconTitle,
      small = _ref.small;
  var iconFieldClassName = (0, _classnames.default)(_IconAreaModule.default.icon_field, className);
  var componentIconClassName = (0, _classnames.default)(_IconAreaModule.default.icon, iconClassName, _defineProperty({}, _IconAreaModule.default.icon_small, small));
  return _react.default.createElement("div", {
    className: iconFieldClassName
  }, _react.default.createElement("div", {
    className: _IconAreaModule.default.icon_wrapper
  }, _react.default.createElement(_FontAwesomeIcon.default, {
    icon: icon,
    className: componentIconClassName,
    title: iconTitle
  })), _react.default.createElement(_Column.default, {
    className: _IconAreaModule.default.children_container
  }, children));
}

IconArea.propTypes = propTypes;
IconArea.defaultProps = defaultProps;
var _default = IconArea;
exports.default = _default;