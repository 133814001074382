"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  NotSupportedNotificationId: "Not supported notification id.",
  SaveSuccessful: "Save successful",
  SelectDate: "Select Date"
};
exports.default = _default;