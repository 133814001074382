"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _resourceService = _interopRequireDefault(require("../services/resourceService"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _ = function _(path) {
  return function () {
    return _resourceService.default.get(path);
  };
};

var _default = {
  AcceptForImplementation: _('buttons.AcceptForImplementation'),
  Add: _('buttons.Add'),
  AddDriver: _('buttons.AddDriver'),
  AddNewCar: _('buttons.AddNewCar'),
  AddNewDriver: _('buttons.AddNewDriver'),
  Back: _('buttons.Back'),
  Cancel: _('buttons.Cancel'),
  CancelOrder: _('buttons.CancelOrder'),
  CloneAuction: _('buttons.CloneAuction'),
  CreateTransportOrder: _('buttons.CreateTransportOrder'),
  Delete: _('buttons.Delete'),
  Download: _('buttons.Download'),
  Edit: _('buttons.Edit'),
  EditContent: _('buttons.EditContent'),
  EditEmailContent: _('buttons.EditEmailContent'),
  EditSmsContent: _('buttons.EditSmsContent'),
  Generate: _('buttons.Generate'),
  GetFromGus: _('buttons.GetFromGus'),
  GetPrice: _('buttons.GetPrice'),
  Loaded: _('buttons.Loaded'),
  LogIn: _('buttons.LogIn'),
  LogOut: _('buttons.LogOut'),
  Realized: _('buttons.Realized'),
  RemoveCar: _('buttons.RemoveCar'),
  RemoveCompanyAttachment: _('buttons.RemoveCompanyAttachment'),
  RemoveDriver: _('buttons.RemoveDriver'),
  Reset: _('buttons.Reset'),
  ResetPassword: _('buttons.ResetPassword'),
  Save: _('buttons.Save'),
  SaveAndPublish: _('buttons.SaveAndPublish'),
  SaveAsDraft: _('buttons.SaveAsDraft'),
  SaveAsNew: _('buttons.SaveAsNew'),
  Search: _('buttons.Search'),
  Send: _('buttons.Send'),
  SendApplicationForApproval: _('buttons.SendApplicationForApproval'),
  SendInvitationToPlatform: _('buttons.SendInvitationToPlatform'),
  SetNewPassword: _('buttons.SetNewPassword'),
  Show: _('buttons.Show'),
  Update: _('buttons.Update'),
  UpdateAndPublish: _('buttons.UpdateAndPublish'),
  UpdateImplementation: _('buttons.UpdateImplementation')
};
exports.default = _default;