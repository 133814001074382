import axios from 'config/axios';

const prefix = 'companies';

export default class CompanySrv {
  static add(model) {
    return axios.post(prefix, model);
  }

  static uploadAttachments(model) {
    return axios.post(`${prefix}/uploadAttachments`, model, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  }
}
