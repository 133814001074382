"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any,
  label: _propTypes.default.string,
  required: _propTypes.default.bool,
  renderLink: _propTypes.default.node,
  isSwitchField: _propTypes.default.bool,
  withExtraErrorSpace: _propTypes.default.bool
};
var defaultProps = {
  label: undefined,
  required: false,
  children: undefined,
  renderLink: undefined,
  isSwitchField: false,
  withExtraErrorSpace: false
};

function FormField(_ref) {
  var children = _ref.children,
      label = _ref.label,
      renderLink = _ref.renderLink,
      required = _ref.required,
      isSwitchField = _ref.isSwitchField,
      withExtraErrorSpace = _ref.withExtraErrorSpace;
  var FieldContainerTag = isSwitchField ? 'div' : 'label';
  var containerClassName = (0, _classnames.default)('k-form-field', {
    'k-state-invalid--large': withExtraErrorSpace
  });
  return _react.default.createElement(FieldContainerTag, {
    className: containerClassName
  }, _react.default.createElement("span", null, label, renderLink, required && _react.default.createElement("span", {
    className: "k-required"
  }, "*")), children);
}

FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;
var _default = FormField;
exports.default = _default;