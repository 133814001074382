import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import DefaultAnonymousLayout from 'layout/DefaultAnonymousLayout';

const mapStateToProps = (state, ownProps) => ({
  isLogged: !!state.auth.currentUser,
  ...ownProps,
});

const propTypes = {
  isLogged: PropTypes.bool.isRequired,
  component: PropTypes.any.isRequired,
};

const defaultProps = {};

function OnlyAnonymousRoute({ component: Component, isLogged, ...others }) {
  return (
    <Route
      {...others}
      render={props =>
        isLogged ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <DefaultAnonymousLayout>
            <Component {...props} />
          </DefaultAnonymousLayout>
        )
      }
    />
  );
}

OnlyAnonymousRoute.propTypes = propTypes;
OnlyAnonymousRoute.defaultProps = defaultProps;

export default connect(mapStateToProps)(OnlyAnonymousRoute);
