import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';

import NotificationSrv from './NotificationSrv';
import notificationActionTypes from './actionTypes';
import NotificationContainer from './Notifications/NotificationContainer';

const propTypes = {
  match: PropTypes.object.isRequired,
};
const defaultProps = {};

const MySettingsPage = props => {
  const { loadingNotifications } = useSelector(state => state.userSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    NotificationSrv.get().then(x => {
      const setNotifications = notifications =>
        dispatch({
          type: notificationActionTypes.NOTIFICATION_SET_NOTIFICATIONS,
          payload: notifications,
        });
      setNotifications(x);
    });
  }, [props.match.params, dispatch]);

  return (
    <InnerContentWrapper additionalClassNames="margin margin-auto">
      <div className={loadingNotifications ? 'fox_spinner' : undefined}>
        <NotificationContainer />
      </div>
    </InnerContentWrapper>
  );
};

MySettingsPage.propTypes = propTypes;
MySettingsPage.defaultProps = defaultProps;

export default MySettingsPage;
