import DashboardSrv from './DashboardSrv';
import actionTypes from './actionTypes';

export function getData() {
  return function(dispatch) {
    return DashboardSrv.get().then(results => {
      dispatch({
        type: actionTypes.STORE_DASHBOARD_DATA,
        data: results,
      });
    });
  };
}
