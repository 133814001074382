"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _IconArea = _interopRequireDefault(require("hh-shared/dist/components/commons/IconArea"));

var _icons = _interopRequireDefault(require("hh-shared/dist/consts/icons"));

var _numberFormating = _interopRequireDefault(require("hh-shared/dist/consts/numberFormating"));

var _Row = _interopRequireDefault(require("layout/Row"));

var _BottomSectionModule = _interopRequireDefault(require("./BottomSection.module.scss"));

require("./BottomSectionTheming.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  data: _propTypes.default.object.isRequired,
  renderButtons: _propTypes.default.node
};
var defaultProps = {
  renderButtons: undefined
};

function BottomSection(_ref) {
  var data = _ref.data,
      renderButtons = _ref.renderButtons;
  var toMoneyFormat = _numberFormating.default.toMoneyFormat;
  var bottomSectionClassName = (0, _classnames.default)(_BottomSectionModule.default.bottom_section, 'bottom_section');
  var moneyLabelClassName = (0, _classnames.default)('label', 'label_orange', 'label_bold', _BottomSectionModule.default.label);
  var buttonSectionClassName = (0, _classnames.default)(_BottomSectionModule.default.bottom_section_item, _BottomSectionModule.default.button_section);
  return _react.default.createElement(_Row.default, {
    className: bottomSectionClassName
  }, _react.default.createElement(_Row.default, {
    className: _BottomSectionModule.default.bottom_section_item
  }, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.money,
    iconTitle: _languageService.default.labels.DeliveryExpense()
  }, _react.default.createElement("span", null, _languageService.default.labels.DeliveryExpense(), ":"), _react.default.createElement("div", {
    className: moneyLabelClassName
  }, toMoneyFormat(data.value), " ", data.currency))), _react.default.createElement(_Row.default, {
    className: _BottomSectionModule.default.bottom_section_item
  }, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.calendar,
    iconTitle: _languageService.default.labels.PaymentDeadline()
  }, _react.default.createElement("span", null, _languageService.default.labels.PaymentDeadline(), ":"), _react.default.createElement("div", null, "30 dni"))), _react.default.createElement(_Row.default, {
    className: buttonSectionClassName
  }, renderButtons));
}

BottomSection.propTypes = propTypes;
BottomSection.defaultProps = defaultProps;
var _default = BottomSection;
exports.default = _default;