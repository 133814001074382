"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.phoneMaskedDisplay = phoneMaskedDisplay;
exports.default = exports.mask = void 0;

var _formValidators = require("./formValidators");

var mask = '000 000 000';
exports.mask = mask;

function phoneMaskedDisplay(value) {
  if (!value) {
    return undefined;
  }

  value = value.replace(/[^0-9]+/g, '');

  var numberPartWithSeparator = function numberPartWithSeparator(numberPart) {
    var partLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
    var seperator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ' ';
    return numberPart.length >= partLength ? "".concat(numberPart).concat(seperator) : numberPart;
  };

  var firstNumberPart = numberPartWithSeparator(value.substring(0, 3));
  var secondNumberPart = numberPartWithSeparator(value.substring(3, 6));
  var thirdNumberPart = value.substring(6, 9);
  return "".concat(firstNumberPart).concat(secondNumberPart).concat(thirdNumberPart);
}

var _default = {
  mask: mask,
  validate: _formValidators.maskedInputFieldValidator
};
exports.default = _default;