"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _moment = _interopRequireDefault(require("moment"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Column = _interopRequireDefault(require("../layout/Column"));

var _TimelineItemCommentModule = _interopRequireDefault(require("./TimelineItemComment.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  statusHistory: _propTypes.default.shape({
    time: _propTypes.default.string,
    userFullName: _propTypes.default.string,
    userPhone: _propTypes.default.string,
    userAvatar: _propTypes.default.string,
    statusId: _propTypes.default.string,
    message: _propTypes.default.string
  }),
  children: _propTypes.default.node
};
var defaultProps = {
  statusHistory: undefined,
  children: undefined
};

var TimelineItemComment = function TimelineItemComment(_ref) {
  var statusHistory = _ref.statusHistory,
      children = _ref.children;
  var textareaRef = (0, _react.useRef)(null);

  var _useState = (0, _react.useState)(null),
      _useState2 = _slicedToArray(_useState, 2),
      textareaHeight = _useState2[0],
      setTextareaHeight = _useState2[1];

  (0, _react.useEffect)(function () {
    var textarea = textareaRef.current;
    setTextareaHeight(textarea.scrollHeight);
  }, []);
  return _react.default.createElement(_Column.default, {
    className: _TimelineItemCommentModule.default.content
  }, _react.default.createElement("div", {
    className: _TimelineItemCommentModule.default.status_label
  }, _react.default.createElement("textarea", {
    rows: 1,
    disabled: true,
    className: _TimelineItemCommentModule.default.textarea,
    defaultValue: statusHistory.message,
    ref: textareaRef,
    style: {
      height: "".concat(textareaHeight, "px")
    }
  })), _react.default.createElement("div", {
    className: _TimelineItemCommentModule.default.date
  }, statusHistory.time && (0, _moment.default)(statusHistory.time).format('DD.MM.YYYY HH:mm')), children && _react.default.createElement("div", {
    className: _TimelineItemCommentModule.default.children_container
  }, children));
};

TimelineItemComment.propTypes = propTypes;
TimelineItemComment.defaultProps = defaultProps;
var _default = TimelineItemComment;
exports.default = _default;