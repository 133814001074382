import AuctionsSrv from './AuctionsSrv';
import auctionActionTypes from './actionTypes';

export function getAuctionData(id) {
  return function(dispatch) {
    return AuctionsSrv.get(id).then(res =>
      dispatch({
        type: auctionActionTypes.ACTION_SET_ACTION_DATA,
        payload: res,
      }),
    );
  };
}

export function clearAuctionData() {
  return {
    type: auctionActionTypes.CLEAR_AUCTION_DATA,
  };
}

export function addActionBid(data) {
  return {
    type: auctionActionTypes.ACTION_ADD_ACTION_BID,
    payload: data,
  };
}

export function fetchParticipantData(instanceId) {
  return function(dispatch) {
    dispatch({
      type: auctionActionTypes.SET_LOADING_USER_PARTICIPANT,
      payload: instanceId,
    });

    return AuctionsSrv.getUserParticipantData(instanceId).then(res =>
      dispatch({
        type: auctionActionTypes.SET_USER_PARTICIPANT_DATA,
        payload: res,
      }),
    );
  };
}
