"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _FoxButton = _interopRequireDefault(require("fox-react/dist/components/FoxButton"));

var _transportOrderStatusesIds = _interopRequireDefault(require("../../consts/transportOrderStatusesIds"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _Spinner = _interopRequireDefault(require("../commons/Spinner"));

var _ChangeStatusButtonModule = _interopRequireDefault(require("./ChangeStatusButton.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  renderButtonContent: _propTypes.default.node.isRequired,
  onClickButton: _propTypes.default.func.isRequired,
  orderStatusId: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.bool]),
  description: _propTypes.default.string,
  disabled: _propTypes.default.bool,
  color: _propTypes.default.oneOf(['info', 'custom-green', 'success', 'error'])
};
var defaultProps = {
  orderStatusId: undefined,
  description: undefined,
  disabled: false,
  color: undefined
};

function ChangeStatusButton(_ref) {
  var renderButtonContent = _ref.renderButtonContent,
      onClickButton = _ref.onClickButton,
      orderStatusId = _ref.orderStatusId,
      disabled = _ref.disabled,
      description = _ref.description,
      color = _ref.color;

  var getButtonColor = function getButtonColor() {
    switch (orderStatusId) {
      case _transportOrderStatusesIds.default.placed:
        return 'info';

      case _transportOrderStatusesIds.default.accepted:
        return 'custom-green';

      case _transportOrderStatusesIds.default.loaded:
        return 'success';

      case _transportOrderStatusesIds.default.realized:
        return 'success';

      default:
        return 'success';
    }
  };

  var buttonDescription = description || _languageService.default.labels.ChangeStatus();

  var buttonClassName = (0, _classnames.default)("button_".concat(color || getButtonColor()));
  return _react.default.createElement("div", {
    className: _ChangeStatusButtonModule.default.button_container
  }, _react.default.createElement("span", {
    className: _ChangeStatusButtonModule.default.button_label
  }, buttonDescription), _react.default.createElement("div", null, _react.default.createElement(_FoxButton.default, {
    type: "button",
    onClick: onClickButton,
    className: buttonClassName,
    disabled: disabled
  }, renderButtonContent, disabled && _react.default.createElement(_Spinner.default, {
    offset: "left"
  }))));
}

ChangeStatusButton.propTypes = propTypes;
ChangeStatusButton.defaultProps = defaultProps;

var _default = (0, _react.memo)(ChangeStatusButton);

exports.default = _default;