const fieldNames = {
  loginForm: {
    emailOrLogin: 'emailOrLogin',
    password: 'password',
  },
  resetPasswordForm: {
    email: 'email',
  },
  setNewPasswordForm: {
    email: 'email',
    newPassword: 'newPassword',
    repeatPassword: 'repeatPassword',
    isReadConditions: 'isReadConditions',
    isReadPrinciples: 'isReadPrinciples',
    isAcceptedRodo: 'isAcceptedRodo',
  },
  myProfileUserInformationForm: {
    login: 'login',
    avatar: 'avatar',
  },
  userPasswordChangeForm: {
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    repeatPassword: 'repeatPassword',
  },
  companyForm: {
    name: 'name',
    address: 'address',
    zipCode: 'zipCode',
    city: 'city',
    nip: 'nip',
    phoneNumber: 'phoneNumber',
    street: 'street',
    regon: 'regon',
    bidingByHHAllowed: 'bidingByHHAllowed',
    companyAttachments: 'companyAttachments',
  },
  newAddressForm: {
    street: 'street',
    zipCode: 'zipCode',
    city: 'city',
  },
  newDriverForm: {
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
  },
  newCarForm: {
    registerNumber: 'registerNumber',
    carType: 'carType',
    comment: 'comment',
  },
};

export default fieldNames;
