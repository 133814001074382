"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouter = require("react-router");

var _FontAwesomeIcon = _interopRequireDefault(require("./FontAwesomeIcon/FontAwesomeIcon"));

var _PreviewButtonModule = _interopRequireDefault(require("./PreviewButton.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  label: _propTypes.default.string.isRequired,
  onClick: _propTypes.default.func,
  icon: _propTypes.default.object,
  className: _propTypes.default.string,
  isProcessing: _propTypes.default.bool,
  link: _propTypes.default.string,
  history: _propTypes.default.shape({
    push: _propTypes.default.func.isRequired
  }).isRequired,
  buttonColor: _propTypes.default.oneOf(['red', 'orange', 'blue', 'dark-blue', 'green', 'dark-green', 'custom-green', 'grey', 'dark'])
};
var defaultProps = {
  onClick: function onClick() {},
  className: '',
  icon: undefined,
  isProcessing: false,
  link: '',
  buttonColor: 'dark-blue'
};

var PreviewButton = function PreviewButton(_ref) {
  var label = _ref.label,
      onClick = _ref.onClick,
      className = _ref.className,
      icon = _ref.icon,
      isProcessing = _ref.isProcessing,
      link = _ref.link,
      history = _ref.history,
      buttonColor = _ref.buttonColor;
  var buttonClassName = (0, _classnames.default)(_PreviewButtonModule.default.button, className, _defineProperty({}, _PreviewButtonModule.default["button_".concat(buttonColor)], buttonColor));

  var handleClick = function handleClick() {
    !!link && history.push(link);
    onClick();
  };

  return _react.default.createElement("button", {
    type: "button",
    className: buttonClassName,
    onClick: handleClick,
    disabled: isProcessing
  }, icon && _react.default.createElement("div", {
    className: _PreviewButtonModule.default.icon_container
  }, _react.default.createElement(_FontAwesomeIcon.default, {
    title: label,
    icon: icon,
    className: _PreviewButtonModule.default.icon
  }), isProcessing && _react.default.createElement("div", {
    className: _PreviewButtonModule.default.loader
  })), label);
};

PreviewButton.propTypes = propTypes;
PreviewButton.defaultProps = defaultProps;

var _default = (0, _reactRouter.withRouter)(PreviewButton);

exports.default = _default;