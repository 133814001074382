"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownLink = _interopRequireDefault(require("./DropdownLink"));

var _DropdownLinkCellModule = _interopRequireDefault(require("./DropdownLinkCell.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  link: _propTypes.default.string,
  linkTitle: _propTypes.default.string.isRequired,
  text: _propTypes.default.string,
  buttons: _propTypes.default.arrayOf(_propTypes.default.shape({
    label: _propTypes.default.string,
    onClick: _propTypes.default.func,
    link: _propTypes.default.string,
    icon: _propTypes.default.object,
    buttonColor: _propTypes.default.oneOf(['red', 'orange', 'blue', 'dark-blue', 'green', 'dark-green', 'custom-green', 'grey', 'dark'])
  })),
  className: _propTypes.default.string,
  displayAsLink: _propTypes.default.bool
};
var defaultProps = {
  link: undefined,
  buttons: undefined,
  className: '',
  displayAsLink: true,
  text: undefined
};

var DropdownLinkCell = function DropdownLinkCell(_ref) {
  var link = _ref.link,
      linkTitle = _ref.linkTitle,
      text = _ref.text,
      buttons = _ref.buttons,
      className = _ref.className,
      displayAsLink = _ref.displayAsLink;
  return _react.default.createElement("td", {
    colSpan: "1",
    className: "overflow-visible ".concat(_DropdownLinkCellModule.default.cell, " ").concat(className)
  }, _react.default.createElement(_DropdownLink.default, {
    link: link,
    text: text,
    buttons: buttons,
    linkTitle: linkTitle,
    displayAsLink: displayAsLink
  }));
};

DropdownLinkCell.propTypes = propTypes;
DropdownLinkCell.defaultProps = defaultProps;
var _default = DropdownLinkCell;
exports.default = _default;