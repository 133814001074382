"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactInputs = require("@progress/kendo-react-inputs");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  label: _propTypes.default.string,
  value: _propTypes.default.oneOfType([_propTypes.default.bool, _propTypes.default.string]),
  onChange: _propTypes.default.func.isRequired,
  labelOn: _propTypes.default.string,
  labelOff: _propTypes.default.string,
  disabled: _propTypes.default.bool,
  name: _propTypes.default.string
};
var defaultProps = {
  label: '',
  labelOn: '',
  labelOff: '',
  disabled: false,
  value: false,
  name: undefined
};

function FoxSwitch(_ref) {
  var label = _ref.label,
      value = _ref.value,
      onChange = _ref.onChange,
      labelOn = _ref.labelOn,
      labelOff = _ref.labelOff,
      disabled = _ref.disabled,
      name = _ref.name;
  return _react.default.createElement("div", {
    "data-name": name
  }, _react.default.createElement(_kendoReactInputs.Switch, {
    label: label,
    checked: !!value,
    onChange: onChange,
    disabled: disabled,
    onLabel: labelOn,
    offLabel: labelOff,
    name: name
  }));
}

FoxSwitch.propTypes = propTypes;
FoxSwitch.defaultProps = defaultProps;
var _default = FoxSwitch;
exports.default = _default;