"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Row = _interopRequireDefault(require("../layout/Row"));

var _DashboardTextInformationModule = _interopRequireDefault(require("./DashboardTextInformation.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  title: _propTypes.default.string.isRequired,
  information: _propTypes.default.string.isRequired,
  description: _propTypes.default.string
};
var defaultProps = {
  description: ''
};

var DashboardTextInformation = function DashboardTextInformation(_ref) {
  var title = _ref.title,
      information = _ref.information,
      description = _ref.description;
  return _react.default.createElement(_Row.default, {
    className: _DashboardTextInformationModule.default.dashboard_text_container
  }, _react.default.createElement("h3", {
    className: _DashboardTextInformationModule.default.dashboard_text_title
  }, title, ":"), _react.default.createElement("div", {
    className: _DashboardTextInformationModule.default.dashboard_text_information_container
  }, _react.default.createElement("h3", {
    className: _DashboardTextInformationModule.default.dashboard_text_information
  }, information), _react.default.createElement("p", {
    className: _DashboardTextInformationModule.default.dashboard_text_description
  }, description)));
};

DashboardTextInformation.propTypes = propTypes;
DashboardTextInformation.defaultProps = defaultProps;

var _default = (0, _react.memo)(DashboardTextInformation);

exports.default = _default;