import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useCountUp } from 'react-countup';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';

import FoxInputNumberDecimal from 'fox-react/dist/components/FoxInputNumberDecimal';
import FoxButton from 'fox-react/dist/components/FoxButton';

import IconArea from 'hh-shared/dist/components/commons/IconArea';
import auctionStatusIds from 'hh-shared/dist/utils/auctionStatusesIds';
import icons from 'hh-shared/dist/consts/icons';
import numberFormating from 'hh-shared/dist/consts/numberFormating';
import Row from 'layout/Row';
import Column from 'layout/Column';
import lang from 'hh-shared/dist/language/services/languageService';
import get from 'lodash/get';

import AuctionsSrv from '../AuctionsSrv';
import styles from './PriceSection.module.scss';

import './PriceSectionTheming.scss';

const propTypes = {
  auctionId: PropTypes.string.isRequired,
};

/* eslint-disable indent */

const PriceSection = (props) => {
  const auctionState = useSelector((state) => state.auction);
  const myCompanyInstanceId = useSelector((state) =>
    get(state, 'auth.currentUser.companyInstanceId'),
  );
  const { auctionMetric, bids, isAuctionStarted } = auctionState;
  const { toMoneyFormat } = numberFormating;

  const [maxPrice, setMaxPrice] = useState(0);
  const [bidValue, setBidValue] = useState(0);
  const [isWinning, setIsWinning] = useState(false);
  const [bidPossible, setBidPossible] = useState(false);
  const [lockChangeBidValue, setLockChangeBidValue] = useState(false);
  const [increaseBidValuePossible, setIncreaseBidValuePossible] = useState(
    false,
  );
  const [decreaseBidValuePossible, setDecreaseBidValuePossible] = useState(
    false,
  );

  useEffect(() => {
    const isPossible =
      isAuctionStarted &&
      auctionMetric.status &&
      auctionMetric.status.id === auctionStatusIds.live &&
      bidValue > 0 &&
      (!bids[0]
        ? bidValue <= auctionMetric.currentPrice
        : bidValue < auctionMetric.currentPrice);

    setBidPossible(isPossible);
  }, [
    bids,
    bidValue,
    auctionMetric.currentPrice,
    auctionMetric.status,
    isAuctionStarted,
  ]);

  useEffect(() => {
    const increasePossible = !bids[0]
      ? bidValue + auctionMetric.priceStep > auctionMetric.currentPrice
      : bidValue + auctionMetric.priceStep >= auctionMetric.currentPrice;
    const decreasePossible = bidValue - auctionMetric.priceStep <= 0;

    setDecreaseBidValuePossible(decreasePossible);
    setIncreaseBidValuePossible(increasePossible);
  }, [bidValue, auctionMetric.priceStep, auctionMetric.currentPrice, bids]);

  // Start i koniec jest tutaj taki sam, żeby animacja następowała tylko po zmianie ceny, przy jej update
  const { countUp, update: updateCurrentPrice } = useCountUp({
    start: auctionMetric.currentPrice,
    end: auctionMetric.currentPrice,
    duration: 0.2,
    decimals: 1,
  });

  const youWin = bids[0] && bids[0].companyInstanceId === myCompanyInstanceId;

  useEffect(() => {
    setMaxPrice(auctionMetric.currentPrice);
    let calculatedBidValue;

    const noOfferOrYouWin = !bids[0] || (bids[0] && youWin);
    if (noOfferOrYouWin) {
      calculatedBidValue = auctionMetric.currentPrice;
    } else {
      calculatedBidValue =
        auctionMetric.currentPrice - auctionMetric.priceStep > 0
          ? auctionMetric.currentPrice - auctionMetric.priceStep
          : 0;
    }
    if (!lockChangeBidValue) {
      setBidValue(calculatedBidValue);
    }
    // eslint-disable-next-line
  }, [auctionMetric, bids, youWin]);

  useEffect(() => {
    bids && bids[0] && setIsWinning(youWin);
  }, [bids, youWin]);

  useEffect(() => {
    updateCurrentPrice(auctionMetric.currentPrice);
  }, [auctionMetric.currentPrice, updateCurrentPrice]);

  const changeManualBidValue = (val) => {
    setLockChangeBidValue(true);
    setBidValue(val);
  };

  const onClickHandler = () => {
    setBidPossible(false);
    setLockChangeBidValue(false);
    AuctionsSrv.createBid(bidValue, props.auctionId);
  };

  return (
    <div
      className={`${styles.price_section_container} price-section-container`}
    >
      <Row className="align-content-center justify-content-space-between">
        <IconArea
          icon={icons.money}
          iconTitle={lang.labels.CurrentPrice()}
          className="align-self-center height-fit-content"
          alignTop
        >
          <Column>
            <span>{lang.labels.CurrentPrice()}</span>
            <div
              className={`${styles.label} ${isWinning ? 'shining-text' : ''}`}
            >
              {toMoneyFormat(countUp)} {auctionMetric.currency}
            </div>
          </Column>
        </IconArea>
        <div>
          <Row>
            <FoxInputNumberDecimal
              label={`${lang.labels.YourOffer()} ${auctionMetric.currency}:`}
              placeholder={`${lang.labels.YourOffer()} ${
                auctionMetric.currency
              }`}
              required
              value={bidValue}
              onChange={(e) => changeManualBidValue(+e.target.value)}
              className={styles.price_input}
              max={maxPrice}
              min={0}
              hasIncrementers={false}
            />
            <div className={styles.input_controls}>
              <FontAwesomeIcon
                className={`${styles.icon} ${styles.icon_up} ${
                  increaseBidValuePossible ? styles.icon_disabled : ''
                }`}
                icon={icons.chevronUp}
                onClick={() =>
                  changeManualBidValue(bidValue + auctionMetric.priceStep)
                }
              />
              <FontAwesomeIcon
                className={`${styles.icon} ${styles.icon_down}  ${
                  decreaseBidValuePossible ? styles.icon_disabled : ''
                }`}
                icon={icons.chevronDown}
                onClick={() =>
                  changeManualBidValue(bidValue - auctionMetric.priceStep)
                }
              />
            </div>
          </Row>
        </div>
        <div className={styles.button_container}>
          {bids.length > 0 &&
            youWin &&
            bidValue === auctionMetric.currentPrice && (
              <div className={styles.winning_label}>
                <div>{lang.labels.YouWin()}</div>
                <FontAwesomeIcon
                  icon={icons.smile}
                  title={lang.labels.YouWin()}
                />
              </div>
            )}
          {(bids.length === 0 ||
            (bids.length > 0 && !youWin) ||
            bidValue < auctionMetric.currentPrice) && (
            <FoxButton
              primary
              onClick={onClickHandler}
              disabled={!bidPossible}
              className="background background_info"
              title={lang.labels.ButtonIsDisabled()}
            >
              {lang.labels.Bid()}
            </FoxButton>
          )}
        </div>
      </Row>
    </div>
  );
};

PriceSection.propTypes = propTypes;
export default PriceSection;
