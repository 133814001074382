import { gridStoreResults } from 'fox-react/dist/components/FoxGrid/actions';
import gridNames from 'common/gridNames';
import AuctionsSrv from './AuctionsSrv';
import actionTypes from './actionTypes';

export function storeResults(results) {
  return gridStoreResults(gridNames.auctions)(results);
}

export function getForGrid(dataState) {
  return function(dispatch) {
    return AuctionsSrv.getForGrid(dataState).then(results => {
      dispatch(storeResults(results));
    });
  };
}

export function isObservedChange(id, isObserved) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.CHANGE_OBSERVED_AUCTION,
      data: { id, isObserved },
    });
    return AuctionsSrv.isObservedChange(id, isObserved);
  };
}
