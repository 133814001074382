import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'hh-shared/dist/language/services/languageService';
import auctionStatusesIds from 'hh-shared/dist/utils/auctionStatusesIds';

import AuctionStatusLabel from './AuctionStatusLabel';

const propTypes = {
  text: PropTypes.string,
  badgeText: PropTypes.string.isRequired,
  statusId: PropTypes.string.isRequired,
  isWon: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  text: '',
  className: '',
};

const AuctionStatusCell = ({
  text,
  badgeText,
  statusId,
  isWon,
  className,
  ...others
}) => {
  const { closed } = auctionStatusesIds;
  const getTimeData = () =>
    text && text.split(' ').length > 1 && text.split(' ');

  const [timeToDisplay, setTimeToDisplay] = useState(() => getTimeData());
  const isGrey = () => timeToDisplay[0].charAt(0) === '-';

  const getCurrentStatusText = () =>
    statusId === closed ? lang.labels.Ended() : text;

  const statusCellClassName = cx('align-text-center', className);
  const timeNumberClassName = (isTimeGrey, isSmall) =>
    cx('time-number', {
      'time-number-grey': isTimeGrey,
      'time-number-small': isSmall,
    });
  const timeDescriptionClassName = isTimeGrey =>
    cx('time-description', {
      'time-description-grey': isTimeGrey,
    });

  useEffect(() => {
    setTimeToDisplay(() => getTimeData());
    // eslint-disable-next-line
  }, [text]);

  return (
    <td colSpan="1" className={statusCellClassName}>
      <span className="display-block align-text-center">
        {timeToDisplay && statusId !== closed
          ? timeToDisplay.map((timeText, timeTextIndex) => (
              <React.Fragment key={timeTextIndex}>
                {timeTextIndex % 2 === 0 ? (
                  <span
                    className={timeNumberClassName(
                      isGrey(),
                      timeTextIndex === timeToDisplay.length - 2,
                    )}
                  >
                    {timeTextIndex !== 0 && ' '}
                    {timeText}
                  </span>
                ) : (
                  <span
                    className={timeDescriptionClassName(isGrey())}
                  >{` ${timeText}`}</span>
                )}
              </React.Fragment>
            ))
          : getCurrentStatusText()}
      </span>
      <AuctionStatusLabel
        label={badgeText}
        {...{ statusId, isWon }}
        {...others}
      />
    </td>
  );
};

AuctionStatusCell.propTypes = propTypes;
AuctionStatusCell.defaultProps = defaultProps;

export default AuctionStatusCell;
