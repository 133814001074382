import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getForGrid as getForGridAuctionsManagement } from 'auctions/actions';
import auctionActionTypes from 'auctions/actionTypes';
import {
  auctionAllFilter,
  auctionOngoingFilter,
  auctionCanceledFilter,
  auctionObservedFilter,
  auctionWithMyParticipationFilter,
  auctionWonFilter,
  auctionNotWonFilter,
  auctionWithoutOfferFilter,
} from 'auctions/filters';
import { useSelector, useDispatch } from 'react-redux';
import icons from 'hh-shared/dist/consts/icons';
import lang from 'hh-shared/dist/language/services/languageService';
import MenuLink from '../MenuLink';
import MenuSublinkGridFilter from '../MenuSublinkGridFilter';

const propTypes = {
  auctionMenuIsOpen: PropTypes.bool.isRequired,
  setAuctionMenuIsOpen: PropTypes.func.isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
};

const defaultProps = {};

const commonProps = {
  routePath: '/auctions',
  stateFilterPath: 'auctions.dataState.filter',
};

function AuctionsMenuPosition({
  auctionMenuIsOpen,
  setAuctionMenuIsOpen,
  handleMenuOpen,
}) {
  const dispatch = useDispatch();
  const dataState = useSelector(state => state.auctions.dataState);
  const { onGoingAuctions } = useSelector(state => state.sideMenu);

  const toggleAuctionMenu = () => setAuctionMenuIsOpen(!auctionMenuIsOpen);

  const changeFilter = filter => {
    dispatch(
      getForGridAuctionsManagement({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: auctionActionTypes.AUCTIONS_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchAll = () => changeFilter(auctionAllFilter);
  const dispatchOngoing = () => changeFilter(auctionOngoingFilter);
  const dispatchWithoutOffer = () => changeFilter(auctionWithoutOfferFilter);
  const dispatchWithMyParticipation = () =>
    changeFilter(auctionWithMyParticipationFilter);
  const dispatchCanceled = () => changeFilter(auctionCanceledFilter);
  const dispatchObserved = () => changeFilter(auctionObservedFilter);
  const dispatchWon = () => changeFilter(auctionWonFilter);
  const dispatchNotWon = () => changeFilter(auctionNotWonFilter);

  return (
    <MenuLink
      icon={icons.auction}
      label={lang.labels.Auctions()}
      handleMenuOpen={handleMenuOpen}
      submenuIsOpen={auctionMenuIsOpen}
      handleMenuItemToggle={toggleAuctionMenu}
    >
      <MenuSublinkGridFilter
        label={lang.labels.All()}
        onClick={dispatchAll}
        filter={auctionAllFilter}
        color="darkGreen"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Ongoing()}
        onClick={dispatchOngoing}
        count={onGoingAuctions}
        filter={auctionOngoingFilter}
        color="blue"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.WithoutOffer()}
        onClick={dispatchWithoutOffer}
        filter={auctionWithoutOfferFilter}
        color="orange"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.WithMyParticipation()}
        onClick={dispatchWithMyParticipation}
        filter={auctionWithMyParticipationFilter}
        color="blue"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Canceled()}
        onClick={dispatchCanceled}
        filter={auctionCanceledFilter}
        color="orange"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Observed()}
        onClick={dispatchObserved}
        filter={auctionObservedFilter}
        color="yellow"
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.Won()}
        onClick={dispatchWon}
        filter={auctionWonFilter}
        {...commonProps}
      />

      <MenuSublinkGridFilter
        label={lang.labels.NotWon()}
        onClick={dispatchNotWon}
        filter={auctionNotWonFilter}
        color="red"
        {...commonProps}
      />
    </MenuLink>
  );
}

AuctionsMenuPosition.propTypes = propTypes;
AuctionsMenuPosition.defaultProps = defaultProps;

export default memo(AuctionsMenuPosition);
