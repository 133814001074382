"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DoubleRowDataCell = _interopRequireDefault(require("./DoubleRowDataCell"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  field: _propTypes.default.string.isRequired,
  dataItem: _propTypes.default.object.isRequired,
  dataIndex: _propTypes.default.number
};
var defaultProps = {
  dataIndex: undefined
};

var AddressCell = function AddressCell(_ref) {
  var dataItem = _ref.dataItem,
      field = _ref.field,
      dataIndex = _ref.dataIndex;

  if (dataIndex === -1) {
    return _react.default.createElement("td", null);
  }

  var address = dataItem[field];
  var zipCode = address.substring(0, address.indexOf(' '));
  var restOfTheAddress = address.substring(address.indexOf(' ')).trim();
  return _react.default.createElement(_DoubleRowDataCell.default, {
    valueOnTop: zipCode,
    valueOnBottom: restOfTheAddress
  });
};

AddressCell.propTypes = propTypes;
AddressCell.defaultProps = defaultProps;
var _default = AddressCell;
exports.default = _default;