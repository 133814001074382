"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHeaderOptimalWidth = getHeaderOptimalWidth;
exports.setColumnWidth = setColumnWidth;
exports.getColumnHeaderHeight = getColumnHeaderHeight;
exports.getFilterResetVerticalOffset = getFilterResetVerticalOffset;
exports.scrollPageToGrid = scrollPageToGrid;
exports.addMissingTitleAttributes = addMissingTitleAttributes;

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function getHeaderOptimalWidth(headerWidth) {
  var minColWidth = 140; // Dodatkowa szerokość na ikonki z lewej strony nagłówka grida, czy też trzy kropki, który znajdują się po jego prawej stronie

  var additionallWidth = 40;
  var defaultIconWidth = 83;
  if (!headerWidth) return defaultIconWidth;
  return headerWidth + additionallWidth > minColWidth ? headerWidth + additionallWidth : minColWidth;
}

function setColumnWidth(wrappedGridRef) {
  var _this = this;

  wrappedGridRef && function () {
    var colGroups = _toConsumableArray(wrappedGridRef.querySelectorAll('colgroup'));

    var widthOfTheHeaders = _this.getColumnHeaderHeight();

    var headerContainerWidth = parseFloat(window.getComputedStyle(document.querySelector('.k-grid > .k-grid-header')).getPropertyValue('width'));
    widthOfTheHeaders && // Kalkulowanie szerości tekstów w każdej kolumnie grida oraz sprawdzanie czy suma tych szerokości mieści się w szerokości grida.
    colGroups.forEach(function (colGroup) {
      var rowCells = _toConsumableArray(colGroup.querySelectorAll('col'));

      var allColumnWidth = rowCells.map(function (rowCell, rowCellIndex) {
        return getHeaderOptimalWidth(widthOfTheHeaders[rowCellIndex]);
      }).reduce(function (prev, next) {
        return prev + next;
      }); // Dostosowywanie szerokości kolumn, w razie gdy ich tekst nie mieści się w kontenerze grida

      allColumnWidth > headerContainerWidth && function () {
        rowCells.forEach(function (rowCell, rowCellIndex) {
          rowCell.setAttribute('width', "".concat(getHeaderOptimalWidth(widthOfTheHeaders[rowCellIndex]), "px"));
        });
      }();
    });
  }();
}

function getColumnHeaderHeight() {
  var wrappedGridRef = this.wrappedGridRef;
  var isIcon = undefined;

  var headerLinks = _toConsumableArray(wrappedGridRef.querySelectorAll('.k-grid-header a, .k-grid-header .icon-cell'));

  var widthOfTheHeaders = headerLinks.map(function (headerLink) {
    var spanWrapper = document.createElement('span');

    var textNode = _toConsumableArray(headerLink.childNodes).filter(function (childNode) {
      return childNode.nodeType === 3;
    })[0];

    if (!textNode) return isIcon; // Wstawianie textNode do spana, by odczytać szerokość tekstu

    headerLink.insertBefore(spanWrapper, textNode);
    spanWrapper.appendChild(textNode);
    var textNodeWidth = spanWrapper.scrollWidth; // Nie usuwam tutaj powyżej tworzonego spana, żeby móc dodać feature (używając jego znaczników) trzykropka dla  tekstu, który nie mieści się w kolumnie grida

    return textNodeWidth;
  });
  return widthOfTheHeaders;
}

function getFilterResetVerticalOffset() {
  var getElementTopOffset = function getElementTopOffset(elementQuery) {
    var element = document.querySelector(elementQuery);
    var elementTopOffset = element ? element.getBoundingClientRect().top : 0;
    return elementTopOffset;
  };

  var filterSecionTopOffset = getElementTopOffset('.k-filtercell');
  var gridContainerTopOffset = getElementTopOffset('.grid_container');
  var filterOffsetFromGrid = filterSecionTopOffset - gridContainerTopOffset;
  this.setState({
    filterButtonOffset: filterOffsetFromGrid
  });
}

function scrollPageToGrid() {
  var gridActionButton = document.querySelector('.inner-content-wrapper > a');
  var scrollAnchorElement = gridActionButton || document.querySelector('.inner-content-wrapper > div');
  scrollAnchorElement && scrollAnchorElement.scrollIntoView({
    behavior: 'smooth'
  });
}

function addMissingTitleAttributes() {
  var gridColumnHeaderLinks = document.querySelectorAll('.k-grid-header table th .k-link');
  gridColumnHeaderLinks.forEach(function (link) {
    return link.setAttribute('title', link.innerText);
  });
}