"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  AcceptForImplementation: "Zaawizuj",
  Add: "Dodaj",
  AddDriver: "Dodaj kierowcę",
  AddNewDriver: "Dodaj nowego kierowcę",
  Back: "Powrót",
  Cancel: "Anuluj",
  CloneAuction: "Powiel aukcje",
  CreateTransportOrder: "Utwórz zamówienie",
  Delete: "Usuń",
  Edit: "Edytuj",
  EditContent: "Edytuj treść",
  EditEmailContent: "Edytuj treść e-mail",
  EditSmsContent: "Edytuj treść sms",
  GetFromGus: "Pobierz z GUS",
  GetPrice: "Pobierz cenę",
  Loaded: "Załadowane",
  LogIn: "Zaloguj się",
  LogOut: "Wyloguj",
  Realized: "Zrealizowane",
  RemoveDriver: "Usuń kierowcę",
  Reset: "Resetuj",
  ResetPassword: "Resetuj hasło",
  Save: "Zapisz",
  SaveAndPublish: "Zapisz i opublikuj",
  SaveAsDraft: "Zapisz jako draft",
  SaveAsNew: "Zapisz jako nowa wersja",
  Search: "Szukaj",
  SetNewPassword: "Ustaw nowe hasło",
  Show: "Pokaż",
  Update: "Aktualizuj",
  UpdateAndPublish: "Aktualizuj i opublikuj",
  CancelOrder: "Anuluj zamówienie",
  AddNewCar: "Dodaj nowy pojazd",
  RemoveCar: "Usuń pojazd",
  SendInvitationToPlatform: "Wyślij zaproszenie do platformy",
  SendApplicationForApproval: "Wyślij zgłoszenie do akceptacji",
  Send: "Wyślij",
  Download: "Pobierz",
  RemoveCompanyAttachment: "Usuń załącznik",
  UpdateImplementation: "Aktualizuj awizację",
  Generate: "Generuj"
};
exports.default = _default;