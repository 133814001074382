import actionTypes from './actionTypes';

const initialState = {};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_DASHBOARD_DATA:
      return {
        ...state,
        dashboardModel: {
          ...state,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
