import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IconArea from 'hh-shared/dist/components/commons/IconArea';
import Row from 'layout/Row';
import numberFormating from 'hh-shared/dist/consts/numberFormating';
import { useCountUp } from 'react-countup';

const propTypes = {
  value: PropTypes.number.isRequired,
  isAnimating: PropTypes.bool,
  rowClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  icon: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  currency: PropTypes.string,
  isWinning: PropTypes.bool,
};

const defaultProps = {
  rowClassName: '',
  iconClassName: '',
  valueClassName: '',
  isAnimating: false,
  currency: undefined,
  isWinning: false,
};

const CurrentPriceItem = ({
  value,
  isAnimating,
  rowClassName,
  iconClassName,
  valueClassName,
  icon,
  label,
  currency,
  isWinning,
}) => {
  const { toMoneyFormat, getTwoDigitRepresentation } = numberFormating;
  const { countUp, update: updateValue } = useCountUp({
    start: value,
    end: value,
    decimals: 2,
    duration: 0.2,
  });

  useEffect(() => {
    updateValue(value);
  }, [updateValue, value]);

  return (
    <Row className={rowClassName}>
      <IconArea icon={icon} iconTitle={label} iconClassName={iconClassName}>
        <span>{label}:</span>
        <div
          className={`${valueClassName} ${isAnimating ? 'shaking-text' : ''} ${
            isWinning ? 'text_success' : ''
          }`}
        >
          {currency
            ? toMoneyFormat(countUp)
            : getTwoDigitRepresentation(countUp)}{' '}
          {currency || 'km'}
        </div>
      </IconArea>
    </Row>
  );
};

CurrentPriceItem.propTypes = propTypes;
CurrentPriceItem.defaultProps = defaultProps;

export default CurrentPriceItem;
