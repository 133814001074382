"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _DriverInput = _interopRequireDefault(require("hh-shared/dist/components/transport-order/DriverInput"));

var _Form = _interopRequireDefault(require("fox-react/dist/components/Form"));

var _CarInput = _interopRequireDefault(require("hh-shared/dist/components/transport-order/CarInput"));

var _FoxInputTextareaField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxInputTextareaField"));

var _FoxDateTimePickerField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxDateTimePickerField"));

var _ColoredTime = _interopRequireDefault(require("hh-shared/dist/components/commons/DataFixTimes/ColoredTime"));

var _IconArea = _interopRequireDefault(require("hh-shared/dist/components/commons/IconArea"));

var _icons = _interopRequireDefault(require("hh-shared/dist/consts/icons"));

var _ImplementationDialogModule = _interopRequireDefault(require("./ImplementationDialog.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  transportOrderData: _propTypes.default.object.isRequired,
  handleSubmit: _propTypes.default.func.isRequired,
  drivers: _propTypes.default.array.isRequired,
  cars: _propTypes.default.array.isRequired,
  carTypes: _propTypes.default.array.isRequired
};
var defaultProps = {};

function DialogForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      transportOrderData = _ref.transportOrderData,
      drivers = _ref.drivers,
      cars = _ref.cars,
      carTypes = _ref.carTypes;
  var isFixDate = !transportOrderData.loadingTimeFrom;
  var loadingDateTimeLabel = isFixDate ? _languageService.default.labels.LoadingDateTime() : _languageService.default.labels.CurrentLoadingDateTime();
  return _react.default.createElement(_Form.default, {
    handleSubmit: handleSubmit
  }, _react.default.createElement("div", {
    className: _ImplementationDialogModule.default.row
  }, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.unfilledClock,
    alignTop: true
  }, !isFixDate && _react.default.createElement("div", {
    className: _ImplementationDialogModule.default.row_small
  }, _react.default.createElement("div", null, _languageService.default.labels.DeclaredLoadingRangeDateTime()), _react.default.createElement("div", null, "".concat(_languageService.default.labels.From(), ": "), _react.default.createElement(_ColoredTime.default, {
    time: transportOrderData.loadingTimeFrom
  })), _react.default.createElement("div", null, "".concat(_languageService.default.labels.To(), ": "), _react.default.createElement(_ColoredTime.default, {
    time: transportOrderData.loadingTimeTo
  }))), _react.default.createElement("div", {
    className: _ImplementationDialogModule.default.row_small
  }, _react.default.createElement(_FoxDateTimePickerField.default, {
    label: loadingDateTimeLabel,
    name: "loadingTimeTo",
    required: true
  })))), _react.default.createElement("div", {
    className: _ImplementationDialogModule.default.row
  }, _react.default.createElement(_DriverInput.default, {
    showInputs: true,
    drivers: drivers
  })), _react.default.createElement("div", {
    className: _ImplementationDialogModule.default.row
  }, _react.default.createElement(_CarInput.default, {
    showInputs: true,
    cars: cars,
    carTypes: carTypes
  })), _react.default.createElement("div", {
    className: _ImplementationDialogModule.default.row
  }, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.comment,
    iconTitle: _languageService.default.labels.Comment(),
    alignTop: true
  }, _react.default.createElement(_FoxInputTextareaField.default, {
    name: "message",
    label: _languageService.default.labels.Comment()
  }))));
}

DialogForm.propTypes = propTypes;
DialogForm.defaultProps = defaultProps;

var _default = (0, _react.memo)(DialogForm);

exports.default = _default;