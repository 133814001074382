import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FoxSwitch from 'fox-react/dist/components/FoxSwitch';
import Row from 'layout/Row';
import Column from 'layout/Column';

import NotificationSrv from '../NotificationSrv';
import styles from './NotificationRow.module.scss';

const propTypes = {
  notification: PropTypes.object.isRequired,
};

const defaultProps = {};

const NotificationRow = props => {
  const { notification } = props;

  const [sms, setSms] = useState(notification.sms);
  const [email, setEmail] = useState(notification.email);
  const [smsLocked, setSmsLocked] = useState(false);
  const [emailLocked, setEmailLocked] = useState(false);

  // TODO Tomek -> wyrzucić ify w metodaych -> onSmsChange oraz onEmailChange, gdy zostaną usunięte mockup-owe powiadomienia
  const onSmsChange = e => {
    if (notification.templateId.includes('todotodo')) return;
    setSmsLocked(true);
    setSms(e.target.value);
    NotificationSrv.updateSms(
      notification.templateId,
      e.target.value,
    ).then(() => setSmsLocked(false));
  };

  const onEmailChange = e => {
    if (notification.templateId.includes('todotodo')) return;
    setEmailLocked(true);
    setEmail(e.target.value);
    NotificationSrv.updateEmail(
      notification.templateId,
      e.target.value,
    ).then(() => setEmailLocked(false));
  };

  return (
    <Row className={`${styles.notification_row} triple_row triple_row_inner`}>
      <Column className="align-self align-self-center">
        <span className={styles.notification_name}>{notification.name}</span>
      </Column>
      <Row className="align-items align-items-center">
        <FoxSwitch disabled={smsLocked} value={sms} onChange={onSmsChange} />
      </Row>
      <Row className="align-items align-items-center">
        <FoxSwitch
          disabled={emailLocked}
          value={email}
          onChange={onEmailChange}
        />
      </Row>
    </Row>
  );
};

NotificationRow.propTypes = propTypes;
NotificationRow.defaultProps = defaultProps;

export default NotificationRow;
