import axios from 'config/axios';

export default class GusSrv {
  static getByNip(nip) {
    return axios.get(`gus/getByNip/${nip}`).then(result => result.data);
  }

  static getByRegon(regon) {
    return axios.get(`gus/getByRegon/${regon}`).then(result => result.data);
  }
}
