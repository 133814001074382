"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _moment = _interopRequireDefault(require("moment"));

var _formats = require("../../consts/formats");

var _DoubleRowDataCell = _interopRequireDefault(require("./DoubleRowDataCell"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  field: _propTypes.default.string.isRequired,
  dataItem: _propTypes.default.object.isRequired
};
var defaultProps = {};

function DateTimeColumnCell(_ref) {
  var dataItem = _ref.dataItem,
      field = _ref.field;
  var passedTime = (0, _moment.default)(dataItem[field]);
  var date = passedTime.format(_formats.dateFormat);
  var time = passedTime.format(_formats.timeFormat);
  return _react.default.createElement(_DoubleRowDataCell.default, {
    valueOnTop: time,
    valueOnBottom: date
  });
}

DateTimeColumnCell.propTypes = propTypes;
DateTimeColumnCell.defaultProps = defaultProps;
var _default = DateTimeColumnCell;
exports.default = _default;