"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asReduxFormField = _interopRequireDefault(require("./asReduxFormField"));

var _FoxAvatarEdit = _interopRequireDefault(require("../components/FoxAvatarEdit"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = (0, _asReduxFormField.default)(_FoxAvatarEdit.default);

exports.default = _default;