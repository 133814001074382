"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  CompanyNotFoundToEdit: "Nie znaleziono firmy do aktualizacji",
  AccountNotFoundProbablyTokenHasExpiredOrIncorrectEmail: "Nie odnaleziono konta, prawdopodobnie Twój token wygasł lub wprowadzony adres e-mail jest niepoprawny",
  CurrentPasswordIsNotCorrect: "Aktualne hasło jest nie poprawne",
  DepartmentWithThisNameAlreadyExists: "Istnieje już oddział o takiej nazwie",
  EmailAddressNotFound: "Nie znaleziono takiego adresu e-mail",
  EmailHasAlreadyBeenSent: "E-mail został już wysłany",
  EmailNotFound: "Nie znaleziono e-maila",
  FieldMustBeValidEmail: "Pole musi być poprawnym adresem e-mail",
  FieldRequired: "Pole wymagane",
  MissingIdOrInstanceId: "Brakuje identyfikatora, skontaktuj się z administratorem",
  HtmlTemplateNotFound: "Nie znaleziono szablonu HTML",
  IncorrectLoginDetails: "Niepoprawne dane logowania",
  NewPasswordWasSet: "Nowe hasło zostało ustawione",
  NotValidPassword: "Hasło powinno zawierać dużą, małą literę, cyfrę oraz znak specjalny",
  PasswordChanged: "Zmieniono hasło",
  PasswordsNotEquals: "Wprowadzone hasła nie pasują do siebie",
  ResetPasswordSend: "Na podany adres e-mail została wysłana instrukcja resetowania hasła",
  SaveSuccessful: "Zapisano pomyślnie",
  SelectDate: "Wybierz datę",
  SelectedLoginIsAlreadyTakenSelectAnother: "Wybrany login jest już zajęty wybierz inny",
  ThereIsNoSuchCarType: "Nie isnieje taki typ pojazdu",
  ThereIsNoSuchDepartment: "Nie isnieje taki oddział",
  ThereIsNoSuchHtmlTemplate: "Nie istnieje taki szablon HTML",
  ThereIsNoSuchUser: "Nie istnieje taki użytkownik",
  UserInformationUpdated: "Zaktualizowano dane użytkownika",
  UserWithThisEmailAlreadyExists: "Istnieje już użytkownik o takim adresie e-mail",
  UserWithThisLoginAlreadyExists: "Istnieje już użytkownik o takim loginie",
  Welcome: "Witaj",
  InvalidNip: "Podana wartość nie jest poprawnym nipem.",
  CarNotFoundToEdit: "Nie znaleziono pojazdu do aktualizacji",
  FacilityNotFoundToEdit: "Nie znaleziono zakładu do aktualizacji",
  RouteNotFoundToEdit: "Nie znaleziono trasy do aktualizacji",
  ThereIsNoSuchCurrency: "Nie istnieje taka waluta",
  ThereIsNoSuchTransportOrder: "Nie istnieje takie zamówienie",
  ThereIsNoSuchAuction: "Nie istnieje taka aukcja",
  InvalidRegon: "Podana wartość nie jest poprawnym regonem.",
  ProblemWithGus: "Wystąpił problem z połączeniem z GUS",
  GusDataLoaded: "Załadowano dane z GUS",
  YourAccountCannotLogin: "Twoje konto nie może się zalogować",
  DepartmentRequiredForDepAdmin: "Oddział jest wymagany dla roli administrator departamentu",
  FacilityRequiredForEmployee: "Zakład jest wymagany dla roli pracownika",
  AddressFromCannotBeEqualToAddressTo: "Adres od nie może być taki sam jak adres do",
  NotFoundRoute: "Nie znaleziono takie trasy",
  RouteAlreadyExists: "Trasa o podanych adresach już istnieje",
  FileExtensionIsNotSupported: "Rozszerzenie pliku jest nie obsługiwane",
  FileIsEmpty: "Plik jest pusty",
  CompanyNotFoundWithThisNipNumber: "Nie znaleziono firmy z takim numerem NIP",
  LoadingUnloadingAddressesCannotBeTheSame: "Adres załadunku i rozładunku nie może być taki sam",
  TimeFromCannotBeLaterThanTimeTo: "Czas od nie może być później niż czas do",
  LoadingTimeCannotBeLaterThanUnloadingTime: "Czas załadunku nie może być późniejszy niż czas rozładunku",
  CompanyWithThatNipAlreadyExists: "Isnieje już inna firma o podanym nipie",
  ThereIsNoSuchDefaultPrice: "Nie istnieje taka cena domyślna",
  BoxPicture: "Obrazek pudełka",
  Currency: "Waluta",
  Expense: "Koszt",
  LoadingData: "Dane załadunku",
  Transport: "Transport",
  UnloadingData: "Dane rozładunku",
  NotSupportedNotificationId: "Niewspierany typ notyfikacji",
  YourSessionHasExpired: "Twoja sesja wygasła",
  TransportOrderAlreadyAccepted: "Zamówienie zostało już zaakceptowane",
  ZipCodeAlreadyExist: "Taki kod pocztowy już istnieje",
  ThereIsNoSuchZipCode: "Nie ma takiego kodu pocztowego",
  ZipCodeFromCannotBeEqualToZipCodeTo: "Kod pocztowy od nie może być taki sam jak kod pocztowy do",
  PriceCannotBeLessThan0: "Cena nie może być mniejsza niż 0",
  PriceShouldBeLessThanLastBid: "Cena musi być mniejsza od ostatniej oferty",
  ThereIsNoSuchDriver: "Nie ma kierowcy o podanym identyfikatorze",
  ThereIsNoSuchCar: "Nie ma auta o podanym identyfikatorze",
  ThereIsNoSuchFacility: "Nie istnieje taki zakład",
  CannotEditStartedAuction: "Nie można edytować aukcji która już wystartowała",
  AuctionAlreadyPublished: "Nie można opublikować aukcji innej niż w statusie draft",
  CannotDeleteAuctionDifferentThanDraftOrForPublication: "Nie można usunąc aukcji ponieważ jest w innym statusie niż draft i do publikacji.",
  CannotSaveZipCodeAlreadyExist: "Zdefiniowany kod pocztowy już istnieje",
  AuctionIsNotLiveOrNotExists: "Aukcja nie istnieje lub nie jest aktywna.",
  CompanyDidNotAllowToBidOnItsBehalf: "Firma nie wyraziła zgody na licytację w jej imieniu.",
  TransportOrderAlreadyCanceled: "Zlecenie zostało już wcześniej anulowane",
  TransportOrderCanceled: "Zlecenie zostało anulowane",
  YouCanOnlyAcceptPlacedTransportOrderOrUpdate: "Możesz zaakceptować tylko zlecenie które jest w statusie złożone lub aktualizować zaakceptowane.",
  YouCanOnlyLoadedAcceptedTransportOrder: "Możesz ząładować tylko zlecenie które jest w statusie przyjęte do akceptacji.",
  YouCanOnlyRealizeLoadedTransportOrder: "Możesz zrealizować tylko zlecenie które jest w statusie załadowane.",
  CannotBePastDate: "Data nie może być datą przeszłą",
  YouCannotCancelNotLiveAuction: "Nie można anulować aukcji, która nie jest trwająca.",
  SuccessUpdatedCommentOnTransportOrder: "Zaktualizowano komentarz.",
  YouMustAcceptRodo: "Musisz zaakceptować zasady RODO",
  YouMustCheckReadConditions: "Musisz zapoznać się z warunkami załadunku, rozładunku i przechowywania wyrobów z betonu komórkowego H+H Polska",
  YouMustCheckReadPrinciples: "Musisz zapoznać się z zasadami bezpiecznego prowadzenia załadunku wyrobów na samochody odbiorców",
  CannotUpdateNonPlacedTransportOrder: "Nie można akutalizować zlecenia które jest w statusie innym niż wystawione",
  CannotUpdateRealizedTransportOrder: "Nie można akutalizować zrealizowanego zlecenia",
  ThereIsNoSuchCompanyOrCompanyIsInactive: "Nie ma takiej firmy lub firma jest nieaktywna",
  NoFile: "Nie przesłano żadnego pliku",
  FieldValueIncorrect: "Pole ma nie poprawną wartość",
  UnhandledErrorOccured: "Wystapił błąd o identyfikatorze:",
  ImportedWithoutInvalidNip: "Zaimportowano z wyjątkiem nie poprawnych nipów"
};
exports.default = _default;