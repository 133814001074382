"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Badge = _interopRequireDefault(require("../commons/Badge"));

var _Column = _interopRequireDefault(require("../layout/Column"));

var _numberFormating = require("../../consts/numberFormating");

var _LabelCellContainer = _interopRequireDefault(require("./LabelCellContainer"));

var _LabelCellModule = _interopRequireDefault(require("./LabelCell.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var propTypes = {
  value: _propTypes.default.any,
  label: _propTypes.default.string,
  numericValue: _propTypes.default.bool,
  className: _propTypes.default.string,
  opaque: _propTypes.default.bool,
  displayLabel: _propTypes.default.bool,
  customLabel: _propTypes.default.func,
  displayAsNumericValue: _propTypes.default.bool,
  displayText: _propTypes.default.bool,
  displayWithoutTd: _propTypes.default.bool
};
var defaultProps = {
  value: '',
  numericValue: false,
  className: '',
  opaque: true,
  displayLabel: true,
  customLabel: undefined,
  displayAsNumericValue: false,
  displayText: true,
  displayWithoutTd: false,
  label: ''
};

var LabelCell = function LabelCell(_ref) {
  var value = _ref.value,
      label = _ref.label,
      numericValue = _ref.numericValue,
      className = _ref.className,
      opaque = _ref.opaque,
      displayLabel = _ref.displayLabel,
      customLabel = _ref.customLabel,
      displayAsNumericValue = _ref.displayAsNumericValue,
      displayText = _ref.displayText,
      displayWithoutTd = _ref.displayWithoutTd,
      others = _objectWithoutProperties(_ref, ["value", "label", "numericValue", "className", "opaque", "displayLabel", "customLabel", "displayAsNumericValue", "displayText", "displayWithoutTd"]);

  var currentValue = numericValue ? (0, _numberFormating.toMoneyFormat)(value) : value;
  return _react.default.createElement(_LabelCellContainer.default, {
    className: className,
    displayWithoutTd: displayWithoutTd
  }, _react.default.createElement(_Column.default, null, displayText && _react.default.createElement("span", {
    className: "align-text-".concat(numericValue || displayAsNumericValue ? 'right' : 'center')
  }, currentValue), displayLabel && (!customLabel ? _react.default.createElement(_Badge.default, _extends({
    label: label,
    alignRight: numericValue,
    smaller: true,
    offsetTop: true,
    className: numericValue ? _LabelCellModule.default.cell_badge : '',
    opaque: true
  }, others)) : customLabel())));
};

LabelCell.propTypes = propTypes;
LabelCell.defaultProps = defaultProps;
var _default = LabelCell;
exports.default = _default;