"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  handleSubmit: _propTypes.default.func.isRequired,
  children: _propTypes.default.any.isRequired,
  className: _propTypes.default.string
};
var defaultProps = {
  className: ''
};

function Form(_ref) {
  var handleSubmit = _ref.handleSubmit,
      children = _ref.children,
      className = _ref.className;
  var formRef = (0, _react.useRef)(null);
  var formClassName = (0, _classnames.default)('k-form', className);
  (0, _react.useEffect)(function () {
    var current = formRef.current;
    !!current && function () {
      var firstInput = current.querySelectorAll('input')[0];
      firstInput && firstInput.focus();
    }();
  }, []);
  return _react.default.createElement("form", {
    className: formClassName,
    noValidate: true,
    onSubmit: handleSubmit,
    ref: formRef
  }, children);
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;
var _default = Form;
exports.default = _default;