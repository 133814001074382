"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  Edit: "Edycja",
  EditProfile: "Edycja profilu",
  EmailOrLogin: "E-mail lub login",
  LoginPage_ForgotPassword: "Zapomniałem hasła",
  LoginPage_Login: "Zaloguj się",
  LoginPage_LoginDescription: "Po autoryzacji uzyskasz dostęp do panelu zarządzania",
  LogOut: "Wyloguj się",
  Password: "Hasło",
  Welcome: "Witaj",
  Email: "E-mail",
  ResetPasswordInformation: "Na podany adres e-mail zostanie wysłany link do resetowania hasła",
  NewPassword: "Nowe hasło",
  RepeatPassword: "Powtórz hasło",
  BackToMainPage: "Powrót na stronę główną",
  LoginPage: "Strona logowania",
  UsersManagement_Columns_Email: "E-mail",
  UsersManagement_Columns_Login: "Login",
  YourPasswordWasChanged: "Twoje hasło zostało zmienione",
  HtmlTemplatesManagement_Columns_Description: "Opis",
  HtmlTemplatesManagement_Columns_Name: "Nazwa",
  AddingNewUser: "Dodawanie nowego użytkownika",
  UserInformations: "Dane użytkownika",
  Login: "Login",
  Roles: "Rola",
  EditingUser: "Edycja użytkownika",
  UserManagement_Columns_Roles: "Role",
  AddingNewDepartment: "Dodawanie nowego oddziału",
  DepartmentInformations: "Dane oddziału",
  Name: "Nazwa",
  UserManagement_Columns_Name: "Nazwa",
  EditingDepartment: "Edycja oddziału",
  Content: "Treść",
  Description: "Opis",
  EditingHtmlTemplates: "Edycja szablonu HTML",
  HtmlTemplateInformations: "Dane szablonu HTML",
  FirstName: "Imię",
  LastName: "Nazwisko",
  UsersManagement_Columns_FirstName: "Imię",
  UsersManagement_Columns_LastName: "Nazwisko",
  CurrentPassword: "Aktualne hasło",
  UserChangePassword: "Zmiana hasła",
  Companies: "Firmy",
  AddingNewCompany: "Dodawanie nowej firmy",
  Address: "Adres",
  City: "Miasto",
  CompanyInformations: "Dane firmy",
  EditingCompany: "Edycja firmy",
  Nip: "NIP",
  PhoneNumber: "Numer telefonu",
  ZipCode: "Kod pocztowy",
  Version: "Wersja",
  AvailablePlaceholders: "Dostępne placeholdery",
  AddingNewCar: "Dodawanie nowego pojazdu",
  AddingNewCarType: "Dodawanie nowego typu pojazdu",
  CarInformations: "Dane pojazdu",
  CarTypeInformations: "Dane typu pojazdu",
  EditingCar: "Edycja pojazdu",
  EditingCarType: "Edycja typu pojazdu",
  Cars: "Pojazdy",
  CarType: "Typ pojazdu",
  CarTypes: "Typy pojazdów",
  CarTypesManagement_Columns_Name: "Nazwa",
  Comment: "Komentarz",
  Company: "Firma",
  RegisterNumber: "Numer rejestracyjny",
  AddingNewFacility: "Dodawanie nowego zakładu",
  Department: "Oddział",
  EditingFacilities: "Edycja zakładu",
  Facilities: "Zakłady",
  FacilityInformations: "Dane zakładu",
  AdditionalUserInformations: "Dodatkowe dane użytkownika",
  AddingNewRoute: "Dodawanie nowej trasy",
  CityFrom: "Miasto początkowe",
  CityTo: "Miasto docelowe",
  Distance: "Dystans",
  EditingRoute: "Edycja trasy",
  RouteInformations: "Dane trasy",
  Routes: "Trasy",
  ZipCodeFrom: "Kod pocztowy początkowy",
  ZipCodeTo: "Kod pocztowy docelowy",
  Departments: "Oddziały",
  HtmlTemplates: "Szablony HTML",
  Users: "Użytkownicy",
  Avatar: "Avatar",
  AddingNewAuction: "Dodawanie nowej aukcji",
  AuctionInformation: "Dane aukcji",
  AuctionNumber: "Numer aukcji",
  Auctions: "Aukcje",
  AdditionalRequirements: "Dodatkowe wymagania",
  AdditionalInformations: "Dodatkowe informacje",
  NoAdditionalInformations: "Brak dodatkowych informacji",
  AuctionType: "Typ aukcji",
  AuthorizedPickupPerson: "Firma/Osoba upoważniona do odbioru",
  AuthorizedPickupPersonInformations: "Dane osoby upoważnionej do odbioru",
  Capacity: "Ładowność",
  EndDateTime: "Data i godzina zakończenia",
  Facility: "Zakład",
  LoadingDateTime: "Data i godzina załadunku",
  LoadingMethod: "Sposób załadunku",
  LoadingData: "Dane załadunku",
  PricePerKilometer: "Cena za kilometr",
  StartDateTime: "Data i godzina rozpoczęcia",
  StartPrice: "Cena wywoławcza",
  StartingPrice: "Cena wywoławcza",
  UnloadingData: "Dane rozładunku",
  UnloadingDateTime: "Data i godzina rozładunku",
  UnloadingMethod: "Sposób rozładunku",
  AddingNewCurrency: "Dodawanie nowej waluty",
  Currencies: "Waluty",
  CurrenciesManagement_Columns_IsDefault: "Czy domyślna",
  CurrenciesManagement_Columns_Name: "Nazwa",
  CurrencyInformations: "Dane waluty",
  EditingCurrency: "Edycja waluty",
  IsDefault: "Czy domyślna",
  PrincipalPerson: "Zleceniodawca",
  Route: "Trasa",
  Subjects: "Podmioty",
  AddingNewTransportOrder: "Dodawanie nowego zamówienia",
  Car: "Pojazd",
  Driver: "Kierowca",
  EditingTransportOrder: "Edycja zamówienia",
  RealizationDate: "Data realizacji",
  TransportOrders: "Zamówienia",
  TransportOrdersManagement_Columns_AuctionNumber: "Numer aukcji",
  TransportOrdersManagement_Columns_Company: "Firma",
  TransportOrdersManagement_Columns_Driver: "Kierowca",
  TransportOrdersManagement_Columns_Distance: "Dystans",
  TransportOrdersManagement_Columns_LoadingTime: "Data i godzina załadunku",
  TransportOrdersManagement_Columns_Number: "Numer",
  TransportOrdersManagement_Columns_Value: "Wartość",
  TransportOrdersManagement_Columns_Capacity: "Ładowność",
  TransportOrdersManagement_Columns_UnloadingMethod: "Sposób rozładunku",
  TransportOrdersManagement_Columns_LoadingMethod: "Sposób załadunku",
  TransportOrdersManagement_Columns_Status: "Status",
  TransportOrdersManagement_Columns_UnloadingAddress: "Adres rozładunku",
  TransportOrdersManagement_Columns_AuthorizedPickupPerson: "Osoba upoważniona do odbioru",
  TransportOrderInformations: "Dane zamówienia",
  IsDriver: "Czy kierowca",
  UsersManagement_Columns_Company: "Firma",
  UsersManagement_Columns_Facility: "Zakład",
  UsersManagement_Columns_IsDriver: "Czy kierowca",
  UsersManagement_Columns_PhoneNumber: "Numer telefonu",
  TransportOrdersManagement_Columns_Car: "Pojazd",
  TransportOrdersManagement_Columns_LoadingAddress: "Adres załadunku",
  TransportOrdersManagement_Columns_Facility: "Zakład",
  TransportOrdersManagement_Columns_UnloadingTime: "Data i godzina rozładunku",
  LoadingAddress: "Adres załadunku",
  UnloadingAddress: "Adres rozładunku",
  CurrentPrice: "Cena aktualna",
  Observed: "Obserwowane",
  TimeLeft: "Do końca",
  UnloadingTime: "Data i godzina rozładunku",
  AuctionEndTime: "Zakończenie aukcji",
  ContactPerson: "Osoba kontaktowa",
  NewAddress: "Nowy adres",
  Street: "Ulica",
  AddressFrom: "Adres z",
  AddressTo: "Adres do",
  LoadData: "Data załadunku",
  NewCar: "Nowy pojazd",
  NewDriver: "Nowy kierowca",
  Value: "Wartość",
  TransportOrdersManagement_Columns_AdditionalRequirements: "Dodatkowe wymagania",
  Regon: "Regon",
  EditingAuction: "Edycja aukcji",
  AuctionDraftEnumerator: "Numerator aukcji draft",
  AuctionEnumerator: "Numerator aukcji",
  DepAdmin: "Administrator oddziału",
  Employee: "Pracownik",
  Logistician: "Logistyk",
  MainAdmin: "Główny administrator",
  UserPermissions: "Uprawnienia użytkownika",
  EditAuctionDraftEnumerator: "Edytuj enumerator aukcji draft",
  EditAuctionEnumerator: "Edytuj enumerator aukcji",
  AddingNewAddress: "Dodawanie nowego adresu",
  Addresses: "Adresy",
  AddressInformations: "Dane adresu",
  Prices: "Ceny",
  PricesManagement_Columns_BuyerRebateGroup: "Grupa rabat. nabywcy",
  PricesManagement_Columns_Code: "Code",
  PricesManagement_Columns_Company: "Firma",
  PricesManagement_Columns_CurrencyCode: "Kod waluty",
  PricesManagement_Columns_Description: "Description",
  PricesManagement_Columns_FlatRate: "Flat rate",
  PricesManagement_Columns_LocalizationCode: "Kod lokalizacji",
  PricesManagement_Columns_MaxBasis: "Max. Basis",
  PricesManagement_Columns_MaxDistance: "Max. Distance",
  PricesManagement_Columns_MinBasis: "Min. Basis",
  PricesManagement_Columns_MinDistance: "Min. Distance",
  PricesManagement_Columns_Nip: "Nip",
  PricesManagement_Columns_No: "No.",
  PricesManagement_Columns_RatePerBaseUnit: "Rate per. Base unit",
  PricesManagement_Columns_ShipmentMethod: "Shipment method",
  PricesManagement_Columns_ShippingAgentCode: "Shipping Agent Code",
  PricesManagement_Columns_ShippingAgentServiceCode: "Shipping Agent Service Code",
  PricesManagement_Columns_Type: "Type",
  PricesManagement_Columns_ZoneNo: "Zone no.",
  PricesManagement: "Zarządzanie cenami",
  YourOffer: "Twoja oferta",
  Bid: "Licytuj",
  NoOffer: "Brak oferty",
  You: "Ty",
  OrdinalNumber: "Lp.",
  HistoricPrice: "Cena historyczna",
  Offerts: "Oferty",
  Time: "Czas",
  Suppliers: "Dostawcy",
  InvitedSuppliers: "Zaproszeni dostawcy",
  Loading: "Załadunek",
  Supply: "Dostawa",
  PriceForKilemeter: "Cena za km",
  Difference: "Różnica",
  AuctionHasNotBeenStarted: "Aukcja nie została rozpoczęta!",
  AuctionHasEnded: "Aukcja została zakończona!",
  Auction: "Aukcja",
  AuctionTimeDuration: "Czas trwania aukcji",
  UserBanned: "Zbanowany",
  Templates: "Szablony",
  Dictionaries: "Słowniki",
  AddingNewDefaultPrice: "Dodawanie nowej ceny domyślnej",
  DefaultPriceInformations: "Dane ceny domyślnej",
  DefaultPrices: "Ceny domyślne",
  DefaultPricesManagement_Columns_FlatRate: "Stawka",
  DefaultPricesManagement_Columns_MaxDistance: "Dystans maksymalny",
  DefaultPricesManagement_Columns_MinDistance: "Dystans minimalny",
  EditingDefaultPrice: "Edycja ceny domyślnej",
  FlatRate: "Stawka",
  MaxDistance: "Dystans maksymalny",
  MinDistance: "Dystans minimalny",
  Drivers: "Kierowcy",
  PriceLists: "Cenniki",
  Blocked: "Zablokowana",
  BlockingCompany: "Blokowanie firmy",
  Orders: "Zamówienia",
  OrdersDashboard: "Zamówień",
  BoxPicture: "Obrazek pudełka",
  Currency: "Waluta",
  Expense: "Koszt",
  Transport: "Transport",
  TimeToLoad: "Czas do załadunku",
  TransportOrder: "Zamówienie transportu",
  TransportOrderNavNumber: "Nr zamówienia NAV",
  MandatoryCompany: "Zleceniobiorca",
  ChangeStatus: "Zmiana statusu",
  Delivery: "Dostawa",
  DeliveryExpense: "Koszt dostawy",
  PaymentDeadline: "Termin płatności",
  EditingAddress: "Edycja adresu",
  Yes: "Tak",
  No: "Nie",
  DriversQuantity: "Ilość kierowców",
  OrderNavNumber: "Nr zamówienia NAV",
  ZipCodes: "Kody pocztowe",
  MainPage: "Strona główna",
  SmsNotification: "Powiadomienie SMS",
  EmailNotification: "Powiadomienie E-mail",
  Day: "Dzień",
  Days: "Dni",
  AddingZipCode: "Dodawanie nowego kodu pocztowego",
  EditingZipCode: "Edycja kodu pocztowego",
  NewZipCode: "Nowy kod pocztowy",
  Exposed: "Wystawione",
  AcceptedForRealization: "Zaawizowane",
  Loaded: "Załadowane",
  Completed: "Zrealizowane",
  TimeToUnload: "Czas do rozładunku",
  AddToFollowed: "Dodaj do obserwowanych",
  ButtonIsDisabled: "Przycisk jest wyłączony",
  AcceptedTransportOrdersNumber: "Ilość zaakceptowanych zamówień",
  OrderedTransportOrdersNumber: "Ilość zleconych zamówień",
  ParticipatedAuctionsNumber: "Ilość aukcji, w których brano udział",
  WinAuctionsNumber: "Ilość wygranych aukcji",
  EditTransportOrderEnumerator: "Edytuj enumerator zamówienia",
  TransportOrderEnumerator: "Numerator zamówienia",
  ToTheEndOfTheAuctionRemained: "Do końca aukcji pozostało",
  AuctionWillTakePlaceIn: "Aukcja rozpocznie się za",
  New: "Nowe",
  AuthorizedPickupPersonPhoneNumber: "Numer telefonu osoby/firmy uprawnionej do odbioru",
  AuthorizedPickupPersonPhoneNumberPlaceholder: "do osoby upoważnionej do odbioru",
  TransportOrdersManagement_Columns_AuthorizedPickupPersonPhoneNumber: "Telefon kontaktowy do osoby upoważnionej do odbioru",
  DifferentCompany: "Inna firma",
  Expand: "Rozwiń",
  Fold: "Zwiń",
  ElectricInvoice: "Dla faktur elektronicznych",
  InvoiceSendToaddress: "Fakturę prosze przesłać na adres",
  YouWin: "Ty wygrywasz",
  DownloadPdf: "Pobierz PDF",
  ShowPdf: "Pokaż PDF",
  Order: "Zamówienie",
  AddingNewDriver: "Dodawanie nowego kierowcy",
  EditingDriver: "Edycja kierowcy",
  Settings: "Ustawienia",
  Ongoing: "Trwające",
  DashboardOngoing: "Trwających",
  DashboardFinishedWithSuccess: "Zakonczonych z sukcesem",
  DashboardFinishedWithoutSuccess: "Zakonczonych bez sukcesu",
  WithMyParticipation: "Licytujemy",
  Canceled: "Anulowane",
  Finished: "Zakończone",
  Won: "Wygrane",
  Lost: "Przegrane",
  NotWon: "Nie wygrane",
  All: "Wszystkie",
  Accepted: "Przyjęte",
  OrderSuffix: "Suffix numeru zamówienia",
  DataForInvoiceTransportOrder: "Dane do przesłania faktury",
  DataForTransportOrder: "Dane dla zamówienia",
  FullName: "Pełna nazwa",
  InvoiceEmailAddress: "Adres e-mail do wysyłki faktury",
  Date: "Data",
  GoToTheEvent: "Przejdź do wydarzenia",
  Draft: "Draft",
  Ended: "Zakończona",
  SuccessfullyCreatedAuction: "Pomyślnie utworzono nową aukcję",
  SuccessfullyModificatedAuction: "Pomyślnie zmodyfikowano aukcję",
  SuccessfullyCreatedOrder: "Pomyślnie utworzono nowe zamówienie",
  SuccessfullyModificatedOrder: "Pomyślnie zmodyfikowano zamówienie",
  SuccessfullyCreatedUser: "Pomyślnie utworzono nowego użytkownika",
  SuccessfullyModificatedUser: "Pomyślnie zmodyfikowano użytkownika",
  SuccessfullyCreatedDepartment: "Pomyślnie utworzono nowy oddział",
  SuccessfullyModificatedDeparment: "Pomyślnie zmodyfikowano oddział",
  SuccessfullyCreatedFacility: "Pomyślnie utworzono nowy zakład",
  SuccessfullyModificatedFacility: "Pomyślnie zmodyfikowano zakład",
  SuccessfullyCreatedCompany: "Pomyślnie utworzono nową firmę",
  SuccessfullyModificatedCompany: "Pomyślnie zmodyfikowano firmę",
  SuccessfullyCreatedAddress: "Pomyślnie utworzono nowy adres",
  SuccessfullyModificatedAddress: "Pomyślnie zmodyfikowano adres",
  SuccessfullyCreatedRoute: "Pomyślnie utworzono nową trase",
  SuccessfullyModificatedRoute: "Pomyślnie zmodyfikowano trase",
  SuccessfullyCreatedVehicle: "Pomyślnie utworzono nowy pojazd",
  SuccessfullyModificatedVehicle: "Pomyślnie zmodyfikowano pojazd",
  SuccessfullyCreatedZipCode: "Pomyślnie utworzono nowy kod pocztowy",
  SuccessfullyModificatedZipCode: "Pomyślnie zmodyfikowano kod pocztowy",
  SuccessfullyCreatedDefaultPrice: "Pomyślnie utworzono nową cene domyślną",
  SuccessfullyModificatedDefaultPrice: "Pomyślnie zmodyfikowano cene domyślną",
  SuccessfullyModificatedHTMLTemplate: "Pomyślnie zmodyfikowano szablon HTML",
  SuccessfullyCreatedCarType: "Pomyślnie utworzono nowy typ pojazdu",
  SuccessfullyModificatedCarType: "Pomyślnie zmodyfikowano typ pojazdu",
  SuccessfullyCreatedCurrency: "Pomyślnie dodano nową walute",
  SuccessfullyModificatedCurrency: "Pomyślnie zmodyfikowano walutę",
  SuccessfullyCreatedDriver: "Pomyślnie dodano nowego kierowcę",
  SuccessfullyModificatedDriver: "Pomyślnie zmodyfikowano kierowcę",
  CanceledTransportOrderStatus: "Anulowane",
  AcceptedTransportOrderStatus: "Zaawizowane",
  DashboardPlacedTransportOrderStatus: "Wystawionych",
  DashboardAcceptedTransportOrderStatus: "Zaawizowanych",
  CompletedTransportOrderStatus: "Zrealizowane",
  DashboardCompletedTransportOrderStatus: "Zrealizowanych",
  ConfigurableDiagramCompletedTransportOrders: "Zrealizowano",
  LoadedTransportOrderStatus: "Załadowane",
  DashboardLoadedTransportOrderStatus: "Załadowanych",
  CanceledAuctionStatus: "Anulowana",
  LiveAuctionStatus: "Trwająca",
  Status: "Status",
  IsParticipant: "Licytujemy",
  IsWon: "Wygrywam",
  WithSuccess: "Z sukcesem",
  WithoutSuccess: "Bez sukcesu",
  WithoutOffer: "Bez oferty",
  EmailSubject: "Temat maila",
  HomePage: "Strona główna",
  Preview: "Podgląd",
  BidingByHHAllowed: "Pozwolenie na licytowanie przez pracownika HH",
  NotGiven: "Nie podano",
  LackOfInformations: "Brak informacji",
  LackOfData: "Brak danych",
  EndedAuctionStatus: "Zakończona",
  Placed: "Wystawione",
  PlacedTransportOrderStatus: "Wystawione",
  YouAreWinning: "Wygrywasz",
  YouAreLosing: "Przegrywasz",
  AddingNewTransportOrderBasedOnAuction: "Dodawanie zamówienia na podstawie aukcji",
  ClosedAuctionStatus: "Zakończona",
  InJanuary: "W styczniu",
  InFebruary: "W lutym",
  InMarch: "W marcu",
  InApril: "W kwietniu",
  InMay: "W maju",
  InJune: "W czerwcu",
  InJuly: "W lipcu",
  InAugust: "W sierpniu",
  InSeptember: "We wrześniu",
  InOctober: "W październiku",
  InNovember: "W listopadzie",
  InDecember: "W grudniu",
  ForPublication: "Do publikacji",
  Dashboard: "Ekran główny",
  AuctionHasBeen: "Aukcja została",
  CurrentAuctionStatus: "Aktualny status aukcji",
  InsideYourClipboardIsLocatedPlaceholder: "W twoim schowku znajduje się placeholder",
  PasteItIntoAppropriatePlaceInsideTheTemplate: "Wklej go w odpowiednie miejsce na szablonie",
  AuctionPage_AuctionCancelation: "Anulowanie aukcji",
  AuctionPage_CancelAuction: "Anuluj aukcję",
  AuctionPage_CancelAuctionDialogDescription: "Podaj powód anulowania aukcji, który zostanie wysłany do wszystkich oferentów",
  AuctionPage_ProviceReason: "Podaj powód...",
  AuctionPage_CancelAuctionDialogErrorMessage: "Wartość nie może być pusta",
  CopyThePhoneNumber: "Skopiuj numer telefonu",
  SuccessfullyAddedPhoneNumberToTheClipboard: "Pomyślnie dodano do schowka numer telefonu",
  Notifications: "Powiadomienia",
  PageNotFound: "Nie odnaleziono strony",
  TheSiteDoesNotExistOrHasBeenRelocated: "Strona nie istnieje lub została przeniesiona",
  ReturnToTheHomePage: "Wróć na stronę główną",
  Realized: "Zrealizowane",
  AddingNewAuctionBasedOnAuction: "Dodawanie aukcji na podstawie aukcji",
  InternalNote: "Notatka wewnętrzna",
  WriteInternalNote: "Napisz notatkę wewnętrzną",
  PhoneNumberWasntProvided: "Numer telefonu nie został podany",
  MySettings_Auctions: "Aukcje",
  MySettings_Orders: "Zlecenia transportowe",
  MySettings_System: "System",
  HtmlTemplatesManagement_Columns_Area: "Obszar",
  TodayAreEnding: "Dzisiaj kończy się",
  ForTodayYouHavePlaced: "Na dzień dzisiejszy masz wystawione",
  DataFix: "Data Fix",
  From: "Od",
  To: "Do",
  DeleteAuction: "Usuń aukcję",
  InvitationToPlatform: "Zaproszenie do platformy",
  SuccessfullySendInvitationToPlatform: "Pomyślnie wysłano zaproszenie do platformy",
  AcceptRodo: "Akceptuję zasady RODO",
  ReadConditions: "Zapoznałem się z warunkami załadunki, rozładunku i przechowywania wyrobów z betonu komórkowego H+H Polska",
  ReadPrinciples: "Zapoznałem się z zasadami bezpiecznego prowadzenia załadunku wyrobów na samochody odbiorców",
  MyCompany: "Moja firma",
  ClickHereToReadTheConditions: "Kliknij tutaj, aby przeczytać warunki",
  ClickHereToReadThePrinciples: "Kliknij tutaj, aby przeczytać zasady",
  AddNew_Feminine: "Dodaj nową",
  AddNew_Masculine: "Dodaj nowe",
  AddNewAuction: "Dodaj nową aukcje",
  AddNewOrder: "Dodaj nowe zlecenie",
  AuctionHasBeenCanceledBy: "Aukcja została anulowana przez",
  InformationsAboutTheAuction: "Informacje dotyczące aukcji",
  ActivateCompany: "Aktywacja firmy",
  IsActive: "Czy aktywna",
  ReportCompany: "Zgłoś firmę",
  TransportOrderDialog_Content: "Dodaj uwagę do zlecenia.",
  TransportOrderDialog_ErrorMessage: "Wiadomość nie może być pusta.",
  TransportOrderDialog_ProvideMessage: "Napisz wiadomość...",
  TransportOrderDialog_AddNote: "Dodaj uwagę do zamówienia",
  AttachNecessaryAttachmentsListedInFollowingList: "Załącz niezbędne załączniki wymienione na poniższej liście",
  Attachments: "Załączniki",
  InProgressOfDateAndAcceptanceVerification: "W trakcie procesu weryfikacji danych i akceptacji",
  AfterPositiveConsiderationYouWillBeNotifiedByEmailAboutThePossibilityOfSubmittingOfferts: "Po pozytywnym rozpatrzeniu zostaną państwo poinformowani wiadomością mailową o możliwości składania ofert",
  Bidded: "Zalicytowało",
  LinkToTheAuction: "Link do aukcji",
  InformationObligation: "Obowiązek informacyjny",
  ConsentToTheProcessingOfPersonalData: "Zgoda na przetwarzanie danych",
  Cancel: "Anuluj",
  Save: "Zapisz",
  AddCompany_AttachmentInformation_License: "Licencje na wykonywanie transportu drogowego w zakresie pośrednictwa przy przewozie rzeczy",
  AddCompany_AttachmentInformation_Permission: " Zezwolenie na wykonywanie zawodu przewoźnika",
  AddCompany_AttachmentInformation_CarrierInsurance: "Ubezpieczenie OC przewoźnika",
  AddCompany_AttachmentInformation_ForwarderInsurance: "Ubezpieczenie OC spedytora",
  SuccessfullyDownloadedAttachment: "Pomyślnie pobrano załącznik",
  SuccessfullyRemovedAttachment: "Pomyślnie usunięto załącznik",
  Automatically: "Automatycznie",
  AutomaticallyGenerated: "Automatycznie Wygenerowane",
  AutomaticallyExposed: "Automatycznie wystawione",
  TransportOrderPage_CancelOrderDialogErrorMessage: "Wartość nie może być pusta",
  LastMonth: "Ostatni miesiąc",
  TransportOrderPage_CancelTransportOrderDialogDecription: "Podaj powód anulowania zlecenia, który zostanie wysłany do użytkowników",
  LastWeek: "Ostatni tydzień",
  TransportOrderPage_OrderCancelation: "Anulowanie zlecenia transportowego",
  LastYear: "Ostatni rok",
  TransportOrderPage_ProviceReason: "Podaj powód",
  FieldRequired: "Pole wymagane",
  DriverPhoneNumber: "Numer telefonu kierowcy",
  OnlyMyFacility: "Tylko mój zakład",
  AnotherCompany: "Inna firma",
  ImplementationDetails: "Szczegóły awizacji",
  DeclaredLoadingRangeDateTime: "Zadeklarowany zakres daty i godziny załadunku",
  CurrentLoadingDateTime: "Aktualna data i godzina załadunku",
  LoadingDetails: "Szczegóły załadunku",
  DashboardActions: "Aukcji",
  CurrentlyYouHave: "Aktualnie masz",
  RealizedInYears: "Zrealizowane w latach",
  ConfigurableDiagramDataModificationTitle: "Zmień zakres danych, wyświetlanych na wykresie",
  DownloadRaport: "Pobierz raport",
  ClickToDisplayDataFromThisPeriod: "Kliknij żeby wyświetlić dane tego okresu",
  DeclaredUnloadingRangeDateTime: "Zadeklarowany zakres daty i godziny rozładunku",
  RealizedDetails: "Szczegóły realizacji",
  Code: "Kod",
  TransportCode: "Kod transportu",
  Reports: "Raporty",
  ExportNav: "Export NAV",
  Number: "Numer",
  CreatedBy: "Utworzone przez",
  CreatedOn: "Data utworzenia",
  Generate: "Generuj"
};
exports.default = _default;