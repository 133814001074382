import foxGridReducer from 'fox-react/dist/components/FoxGrid/reducer';
import gridNames from 'common/gridNames';
import { initialState } from 'fox-react/dist/components/FoxGrid/initialState';
import { actionTypes } from './actionTypes';

const gridReducer = foxGridReducer(gridNames.transportOrders);

const transportOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSPORT_ORDER_SET_FILTER:
      return {
        ...state,
        dataState: {
          ...state.dataState,
          filter: action.payload,
        },
      };
    default:
      return gridReducer(state, action);
  }
};

export default transportOrdersReducer;
