"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBearerTokenFromLocalStorage = addBearerTokenFromLocalStorage; // add to axios.interceptors.request.use(...)
// eslint-disable-next-line import/prefer-default-export

function addBearerTokenFromLocalStorage(config) {
  var token = localStorage.getItem('accessToken');

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = "Bearer ".concat(token);
  }

  return config;
}