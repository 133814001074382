export function setCookie(name, value) {
  document.cookie =
    name +
    '=' +
    encodeURIComponent(value) +
    '; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
}

export function getCookie(name) {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
}
