"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _FormPreviewSectionWrapper = _interopRequireDefault(require("./FormPreviewSectionWrapper"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var mapStateToProps = function mapStateToProps(state) {
  return {
    isWider: !state.sideMenu.isOpened
  };
};

var FormPreviewSectionContainer = (0, _reactRedux.connect)(mapStateToProps, null)(_FormPreviewSectionWrapper.default);
var _default = FormPreviewSectionContainer;
exports.default = _default;