import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';

import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';

const propTypes = {
  editForm: PropTypes.bool,
};

const defaultProps = {
  editForm: false,
};

const CarPagePreview = ({ editForm }) => {
  const formValues = useSelector(
    state => getFormValues(formNames.carForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={editForm ? lang.labels.EditingCar() : lang.labels.AddingNewCar()}
        icon={icons.fastTruck}
      >
        <FormPreviewItem
          label={lang.labels.RegisterNumber()}
          value={
            (formValues.registerNumber &&
              formValues.registerNumber.toUpperCase()) ||
            undefined
          }
        />
        <FormPreviewItem
          label={lang.labels.CarType()}
          value={get(formValues, 'carType.name')}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

CarPagePreview.propTypes = propTypes;
CarPagePreview.defaultProps = defaultProps;

export default CarPagePreview;
