import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isSubmitting } from 'redux-form';
import get from 'lodash/get';

import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';
import ChangeStatusButton from 'hh-shared/dist/components/transport-order/ChangeStatusButton';
import lang from 'hh-shared/dist/language/services/languageService';

const formName = 'transportFormPreview';

const propTypes = {
  data: PropTypes.object.isRequired,
  submitAcceptForImplementation: PropTypes.func.isRequired,
};

const defaultProps = {};

function ActionButtons({ data, submitAcceptForImplementation }) {
  const isFormSubmitting = useSelector(state => isSubmitting(formName)(state));

  const statusId = get(data, 'status.id');

  const showAcceptForImplementationButton =
    statusId === transportOrderStatusesIds.placed ||
    statusId === transportOrderStatusesIds.accepted;

  const acceptImplementationLabel =
    statusId === transportOrderStatusesIds.placed
      ? lang.buttons.AcceptForImplementation()
      : lang.buttons.UpdateImplementation();

  const acceptImplementationLabels =
    statusId === transportOrderStatusesIds.placed
      ? {
          button: lang.buttons.AcceptForImplementation(),
          description: lang.labels.ChangeStatus(),
        }
      : {
          button: lang.buttons.UpdateImplementation(),
          description: lang.labels.ImplementationDetails(),
        };

  return (
    <>
      {showAcceptForImplementationButton && (
        <ChangeStatusButton
          onClickButton={submitAcceptForImplementation}
          orderStatusId={statusId}
          disabled={isFormSubmitting}
          renderButtonContent={acceptImplementationLabel}
          description={acceptImplementationLabels.description}
          color={statusId === transportOrderStatusesIds.accepted && 'info'}
        />
      )}
    </>
  );
}

ActionButtons.propTypes = propTypes;
ActionButtons.defaultProps = defaultProps;

export default memo(ActionButtons);
