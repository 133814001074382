import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import get from 'lodash/get';

import DefaultLayout from 'layout/DefaultLayout';

const mapStateToProps = (state, ownProps) => ({
  isLogged: !!state.auth.currentUser,
  ...ownProps,
});

const propTypes = {
  isLogged: PropTypes.bool.isRequired,
  component: PropTypes.any.isRequired,
  location: PropTypes.any,
  requireActiveCompany: PropTypes.bool,
  requireAbsenceOfCompany: PropTypes.bool,
};

const defaultProps = {
  location: undefined,
  requireActiveCompany: false,
  requireAbsenceOfCompany: false,
};

function ProtectedRoute({
  component: Component,
  isLogged,
  requireActiveCompany,
  requireAbsenceOfCompany,
  ...others
}) {
  // TODO przerobić tak komponent, by była możliwość redirectowania url-a w zależności od dowolnego statementu
  const hasCompany = useSelector(state =>
    get(state, 'auth.currentUser.companyInstanceId'),
  );

  const hasActiveCompany = useSelector(state =>
    get(state, 'auth.currentUser.isCompanyActive'),
  );

  let allowRoute = isLogged;

  if (requireActiveCompany) {
    allowRoute = isLogged && hasActiveCompany;
  } else if (requireAbsenceOfCompany) {
    allowRoute = isLogged && !hasCompany;
  }

  return (
    <Route
      {...others}
      render={props =>
        allowRoute ? (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

ProtectedRoute.propTypes = propTypes;
ProtectedRoute.defaultProps = defaultProps;

export default connect(mapStateToProps)(ProtectedRoute);
