"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _FontAwesomeIcon = _interopRequireDefault(require("./FontAwesomeIcon/FontAwesomeIcon"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _StarToggleModule = _interopRequireDefault(require("./StarToggle.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  value: _propTypes.default.bool,
  onChange: _propTypes.default.func.isRequired,
  disabled: _propTypes.default.bool,
  className: _propTypes.default.string,
  starClassName: _propTypes.default.string,
  small: _propTypes.default.bool
};
var defaultProps = {
  disabled: false,
  value: false,
  className: '',
  starClassName: '',
  small: false
};

var StarToggle = function StarToggle(_ref) {
  var value = _ref.value,
      onChange = _ref.onChange,
      disabled = _ref.disabled,
      className = _ref.className,
      starClassName = _ref.starClassName,
      small = _ref.small;
  return _react.default.createElement("label", {
    className: "".concat(_StarToggleModule.default.toggle_container, " ").concat(className)
  }, _react.default.createElement("input", {
    type: "checkbox",
    checked: value,
    onChange: onChange,
    disabled: disabled
  }), _react.default.createElement("div", {
    className: "".concat(_StarToggleModule.default.star_container, " ").concat(starClassName, " ").concat(small ? _StarToggleModule.default.small_icon : '')
  }, _react.default.createElement(_FontAwesomeIcon.default, {
    icon: _icons.default.unfilledStar,
    title: _languageService.default.labels.AddToFollowed(),
    className: "".concat(_StarToggleModule.default.star_icon, " ").concat(_StarToggleModule.default.unfilled)
  }), _react.default.createElement(_FontAwesomeIcon.default, {
    icon: _icons.default.filledStar,
    title: _languageService.default.labels.AddToFollowed(),
    className: "".concat(_StarToggleModule.default.star_icon, " ").concat(_StarToggleModule.default.filled)
  })));
};

StarToggle.propTypes = propTypes;
StarToggle.defaultProps = defaultProps;
var _default = StarToggle;
exports.default = _default;