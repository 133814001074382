import actionTypes from './sideMenuActionTypes';

export const open = () => {
  return {
    type: actionTypes.MENU_OPEN,
  };
};

export const close = () => {
  return {
    type: actionTypes.MENU_CLOSE,
  };
};

export const toggle = () => {
  return {
    type: actionTypes.MENU_TOGGLE,
  };
};
