import axios from 'config/axios';

const prefix = 'auctions';

export default class AuctionsSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, dataState)
      .then(result => result.data);
  }

  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static isObservedChange(id, isObserved) {
    return axios.put(`${prefix}/isObservedChange`, {
      auctionId: id,
      isObserved,
    });
  }
}
