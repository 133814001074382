import React from 'react';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import UserInformationFormContainer from './UserInformationFormContainer';
import UserPasswordChangeFormContainer from './UserPasswordChangeFormContainer';

const propTypes = {};

const defaultProps = {};

const MyProfilePage = () => {
  return (
    <InnerContentWrapper additionalClassNames="margin margin-auto">
      <UserInformationFormContainer />
      <UserPasswordChangeFormContainer />
    </InnerContentWrapper>
  );
};

MyProfilePage.propTypes = propTypes;
MyProfilePage.defaultProps = defaultProps;

export default MyProfilePage;
