"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _HamburgerButtonModule = _interopRequireDefault(require("./HamburgerButton.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

var propTypes = {
  additionalClassNames: _propTypes.default.string,
  isActive: _propTypes.default.bool,
  onMenuToggle: _propTypes.default.func
};
var defaultProps = {
  additionalClassNames: '',
  isActive: false,
  onMenuToggle: function onMenuToggle() {}
};

var HamburgerButton = function HamburgerButton(_ref) {
  var additionalClassNames = _ref.additionalClassNames,
      isActive = _ref.isActive,
      onMenuToggle = _ref.onMenuToggle;
  return _react.default.createElement("button", {
    className: [_HamburgerButtonModule.default.hamburger_container, isActive ? _HamburgerButtonModule.default.active : ''].concat(_toConsumableArray(additionalClassNames.split(' '))).join(' '),
    type: "button",
    tabIndex: "0",
    onClick: onMenuToggle
  }, _react.default.createElement("div", {
    className: _HamburgerButtonModule.default.hamburger_strip
  }), _react.default.createElement("div", {
    className: _HamburgerButtonModule.default.hamburger_strip
  }), _react.default.createElement("div", {
    className: _HamburgerButtonModule.default.hamburger_strip
  }));
};

HamburgerButton.propTypes = propTypes;
HamburgerButton.defaultProps = defaultProps;
var _default = HamburgerButton;
exports.default = _default;