import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import { loginAsync } from './actions';

const mapDispatchToProps = dispatch => ({
  onSubmit: model => dispatch(loginAsync(model)),
});

const LoginPageContainer = connect(undefined, mapDispatchToProps)(LoginPage);

export default LoginPageContainer;
