"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _CircularProgressBarModule = _interopRequireDefault(require("./CircularProgressBar.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  size: _propTypes.default.number,
  strokeWidth: _propTypes.default.number,
  className: _propTypes.default.string,
  progress: _propTypes.default.number
};
var defaultProps = {
  strokeWidth: 2,
  size: 32,
  className: '',
  progress: 0
};

var CircularProgressBar = function CircularProgressBar(_ref) {
  var size = _ref.size,
      strokeWidth = _ref.strokeWidth,
      className = _ref.className,
      progress = _ref.progress;
  var radius = (size - strokeWidth) / 2;
  var circumference = radius * 2 * Math.PI;

  var getPercentage = function getPercentage() {
    return circumference - progress / 100 * circumference;
  };

  return _react.default.createElement("svg", {
    className: "".concat(_CircularProgressBarModule.default.progress_ring, " ").concat(className),
    width: size,
    height: size
  }, _react.default.createElement("circle", {
    r: radius,
    cx: size * 0.5,
    cy: size * 0.5,
    className: _CircularProgressBarModule.default.circle,
    strokeWidth: strokeWidth,
    strokeDasharray: "".concat(circumference, " ").concat(circumference),
    strokeDashoffset: getPercentage(progress)
  }));
};

CircularProgressBar.propTypes = propTypes;
CircularProgressBar.defaultProps = defaultProps;
var _default = CircularProgressBar;
exports.default = _default;