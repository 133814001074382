"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _BadgeModule = _interopRequireDefault(require("./Badge.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var propTypes = {
  label: _propTypes.default.string,
  className: _propTypes.default.string,
  red: _propTypes.default.bool,
  orange: _propTypes.default.bool,
  yellow: _propTypes.default.bool,
  blue: _propTypes.default.bool,
  darkBlue: _propTypes.default.bool,
  green: _propTypes.default.bool,
  darkGreen: _propTypes.default.bool,
  customGreen: _propTypes.default.bool,
  grey: _propTypes.default.bool,
  darkGrey: _propTypes.default.bool,
  smaller: _propTypes.default.bool,
  offsetTop: _propTypes.default.bool,
  alignRight: _propTypes.default.bool,
  opaque: _propTypes.default.bool,
  inverted: _propTypes.default.bool,
  invisible: _propTypes.default.bool,
  withBorder: _propTypes.default.bool,
  onClick: _propTypes.default.func
};
var defaultProps = {
  className: '',
  red: false,
  orange: false,
  yellow: false,
  blue: true,
  darkBlue: false,
  green: false,
  darkGreen: false,
  customGreen: false,
  grey: false,
  darkGrey: false,
  smaller: false,
  offsetTop: false,
  alignRight: false,
  opaque: false,
  inverted: false,
  invisible: false,
  withBorder: false,
  onClick: undefined,
  label: undefined
};

var Badge = function Badge(_ref) {
  var label = _ref.label,
      className = _ref.className,
      red = _ref.red,
      orange = _ref.orange,
      yellow = _ref.yellow,
      blue = _ref.blue,
      darkBlue = _ref.darkBlue,
      green = _ref.green,
      darkGreen = _ref.darkGreen,
      customGreen = _ref.customGreen,
      grey = _ref.grey,
      darkGrey = _ref.darkGrey,
      smaller = _ref.smaller,
      offsetTop = _ref.offsetTop,
      alignRight = _ref.alignRight,
      opaque = _ref.opaque,
      inverted = _ref.inverted,
      invisible = _ref.invisible,
      withBorder = _ref.withBorder,
      onClick = _ref.onClick,
      others = _objectWithoutProperties(_ref, ["label", "className", "red", "orange", "yellow", "blue", "darkBlue", "green", "darkGreen", "customGreen", "grey", "darkGrey", "smaller", "offsetTop", "alignRight", "opaque", "inverted", "invisible", "withBorder", "onClick"]); // TODO Vorbert -> ogarnąc jak w classnames używac podkreśleń w stylach, po czym zoptymalizuj te style z pomocą użycia tej libki


  return _react.default.createElement("div", _extends({}, others, {
    className: "".concat(_BadgeModule.default.badge, " ").concat(className, " ").concat(red ? _BadgeModule.default.red : '', " ").concat(yellow ? _BadgeModule.default.yellow : '', " ").concat(orange ? _BadgeModule.default.orange : '', " ").concat(blue ? _BadgeModule.default.blue : '', " ").concat(darkBlue ? _BadgeModule.default.blue_dark : '', " ").concat(green ? _BadgeModule.default.green : '', " ").concat(darkGreen ? _BadgeModule.default.green_dark : '', " ").concat(grey ? _BadgeModule.default.grey : '', " ").concat(darkGrey ? _BadgeModule.default.grey_dark : '', " ").concat(smaller ? _BadgeModule.default.smaller : '', " ").concat(offsetTop ? _BadgeModule.default.offset_top : '', " align-self-").concat(alignRight ? 'end' : 'center', " ").concat(opaque ? _BadgeModule.default.opaque : '', " ").concat(customGreen ? _BadgeModule.default.green_custom : '', " ").concat(inverted ? _BadgeModule.default.inverted : '', "\n        ").concat(invisible ? _BadgeModule.default.invisible : '', " ").concat(onClick ? _BadgeModule.default.clickable : '', " ").concat(withBorder ? _BadgeModule.default.with_border : ''),
    onClick: onClick
  }), label);
};

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;
var _default = Badge;
exports.default = _default;