"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  className: _propTypes.PropTypes.string
};
var defaultProps = {
  className: undefined
};

function Logo(_ref) {
  var className = _ref.className;
  return _react.default.createElement("img", {
    className: className,
    src: "".concat(process.env.PUBLIC_URL, "/img/hh_logo.png"),
    alt: "Logo H+H"
  });
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;
var _default = Logo;
exports.default = _default;