import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Toastr from 'hh-shared/dist/toastr/Toastr';

import OnlyAnonymousRoute from 'commons/OnlyAnonymousRoute';
import ProtectedRoute from 'commons/ProtectedRoute';
import DashboardPage from 'dashboard/DashboardPage';
import LoginPageContainer from 'auth/LoginPageContainer';
import ResetPasswordPageContainer from 'auth/ResetPassword/ResetPasswordPageContainer';
import SetNewPasswordPageContainer from 'auth/SetNewPassword/SetNewPasswordPageContainer';
import RouteWithLayout from 'hh-shared/dist/components/commons/RouteWithLayout';
import TransportOrdersPage from 'transport-orders/TransportOrdersPage';
import TransportOrderPage from 'transport-order/TransportOrderPage';
import DriversPage from 'drivers/DriversPage';
import AddDriverPage from 'drivers/AddDriverPage';
import EditDriverPage from 'drivers/EditDriverPage';
import EditCarPage from 'cars/EditCarPage';
import AddCarPage from 'cars/AddCarPage';
import CarsPage from 'cars/CarsPage';
import Page404 from 'hh-shared/dist/components/commons/Page404';
import MyProfilePage from './auth/MyProfile/MyProfilePage';
import UserSettings from './user-settings/UserSettings';
import DefaultAnonymousLayout from './layout/DefaultAnonymousLayout';
import AuctionsPage from './auctions/AuctionsPage';
import AuctionPageContainer from './auction/AuctionPage';
import CompanyAddPage from 'add-company/CompanyAddPage';

function App() {
  return (
    <div className="App">
      <Switch>
        <ProtectedRoute exact path="/" component={DashboardPage} />
        <OnlyAnonymousRoute path="/login" component={LoginPageContainer} />
        <OnlyAnonymousRoute
          path="/reset-password"
          component={ResetPasswordPageContainer}
        />
        <RouteWithLayout
          layout={DefaultAnonymousLayout}
          path="/set-new-password/:token"
          component={SetNewPasswordPageContainer}
        />
        <ProtectedRoute exact path="/my-profile" component={MyProfilePage} />
        <ProtectedRoute exact path="/my-settings" component={UserSettings} />
        <ProtectedRoute
          exact
          path="/auctions"
          component={AuctionsPage}
          requireActiveCompany
        />
        <ProtectedRoute
          exact
          path="/auctions/:id"
          component={AuctionPageContainer}
          requireActiveCompany
        />

        <ProtectedRoute
          exact
          path="/transport-orders"
          component={TransportOrdersPage}
          requireActiveCompany
        />
        <ProtectedRoute
          exact
          path="/transport-orders/:id"
          component={TransportOrderPage}
          requireActiveCompany
        />

        <ProtectedRoute
          exact
          path="/report-company"
          component={CompanyAddPage}
          requireAbsenceOfCompany
        />
        <ProtectedRoute exact path="/drivers" component={DriversPage} />
        <ProtectedRoute exact path="/drivers/add" component={AddDriverPage} />
        <ProtectedRoute
          exact
          path="/drivers/edit/:instanceId"
          component={EditDriverPage}
        />

        <ProtectedRoute exact path="/cars" component={CarsPage} />
        <ProtectedRoute exact path="/cars/add" component={AddCarPage} />
        <ProtectedRoute
          exact
          path="/cars/edit/:instanceId"
          component={EditCarPage}
        />

        <Route component={Page404} />
      </Switch>

      <Toastr />
    </div>
  );
}

export default App;
