"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateAuctionTime = calculateAuctionTime;

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var translateTime = function translateTime(time) {
  var seconds = Math.floor(time / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  return {
    seconds: seconds % 60,
    minutes: minutes % 60,
    hours: hours % 24,
    days: days
  };
};

function calculateAuctionTime(startTime, endTime) {
  var fromTime = _moment.default.now(); // eslint-disable-next-line no-underscore-dangle


  var auctionStartTime = (0, _moment.default)(startTime)._d.getTime(); // eslint-disable-next-line no-underscore-dangle


  var auctionEndTime = (0, _moment.default)(endTime)._d.getTime();

  var currentTimeDiff;
  var sign = '';

  if (fromTime >= auctionEndTime) {
    return undefined;
  }

  if (fromTime < auctionStartTime) {
    sign = '-';
    currentTimeDiff = auctionStartTime - fromTime;
  } else {
    currentTimeDiff = auctionEndTime - fromTime;
  }

  return _objectSpread({}, translateTime(currentTimeDiff), {
    sign: sign
  });
}