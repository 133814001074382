import React, { useState, useEffect } from 'react';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import PropTypes from 'prop-types';
import CarsSrv from './CarsSrv';
import CarForm from './CarForm';
import CarPagePreview from './CarPagePreview';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function AddCarPage({ history }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [carTypes, setCarTypes] = useState([]);

  const onSubmit = model => CarsSrv.add(model);

  useEffect(() => {
    setShowSpinner(true);
    CarsSrv.getCarTypesAsSelectItem()
      .then(res => setCarTypes(res))
      .finally(() => setShowSpinner(false));
  }, []);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/drivers',
            label: lang.labels.Drivers(),
          },
          {
            routePath: '/drivers/add',
            label: lang.labels.AddingNewDriver(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <div className={showSpinner ? 'fox_spinner' : undefined}>
            <CarForm
              onSubmit={onSubmit}
              title={lang.labels.AddingNewCar()}
              history={history}
              carTypes={carTypes}
            />
          </div>
          <CarPagePreview />
        </FormCardWrapper>
      </FormPreviewSectionContainer>
    </>
  );
}

AddCarPage.propTypes = propTypes;
AddCarPage.defaultProps = defaultProps;

export default AddCarPage;
