"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _FoxInputTextField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxInputTextField"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _FoxComboBoxField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxComboBoxField"));

var _reduxFormUtils = require("fox-react/dist/utils/reduxFormUtils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  carTypes: _propTypes.default.array.isRequired
};
var defaultProps = {};

function NewCarForm(_ref) {
  var carTypes = _ref.carTypes;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_FoxInputTextField.default, {
    label: _languageService.default.labels.RegisterNumber(),
    placeholder: _languageService.default.labels.RegisterNumber(),
    name: "registerNumber",
    required: true,
    format: _reduxFormUtils.toUpper
  }), _react.default.createElement(_FoxComboBoxField.default, {
    label: _languageService.default.labels.CarType(),
    name: "carType",
    items: carTypes,
    required: true
  }));
}

NewCarForm.propTypes = propTypes;
NewCarForm.defaultProps = defaultProps;
var _default = NewCarForm;
exports.default = _default;