"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _TableRowRevealerModule = _interopRequireDefault(require("./TableRowRevealer.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  className: _propTypes.default.string
};
var defaultProps = {
  className: ''
};

var TableRowRevealer = function TableRowRevealer(_ref) {
  var children = _ref.children,
      className = _ref.className,
      others = _objectWithoutProperties(_ref, ["children", "className"]);

  var rowContainerClassName = (0, _classnames.default)(_TableRowRevealerModule.default.revealer_container, className);
  return _react.default.createElement("tr", _extends({
    className: rowContainerClassName
  }, others), children, _react.default.createElement("div", {
    className: _TableRowRevealerModule.default.revealer
  }));
};

TableRowRevealer.propTypes = propTypes;
TableRowRevealer.defaultProps = defaultProps;
var _default = TableRowRevealer;
exports.default = _default;