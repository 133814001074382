import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import FormPreviewPane from 'hh-shared/dist/components/layout/FormPreview/FormPreviewPaneContainer';
import FormPreviewSection from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSection';
import FormPreviewItem from 'hh-shared/dist/components/layout/FormPreview/FormPreviewItem';
import { phoneMaskedDisplay } from 'hh-shared/dist/utils/phoneMask';

const propTypes = {
  editForm: PropTypes.bool,
};

const defaultProps = {
  editForm: false,
};

const DriverPagePreview = ({ editForm }) => {
  const formValues = useSelector(
    state => getFormValues(formNames.driverForm)(state) || {},
  );

  return (
    <FormPreviewPane>
      <FormPreviewSection
        title={
          editForm ? lang.labels.EditingDriver() : lang.labels.AddingNewDriver()
        }
        icon={icons.driver}
      >
        <FormPreviewItem
          label={lang.labels.FirstName()}
          value={formValues.firstName}
        />
        <FormPreviewItem
          label={lang.labels.LastName()}
          value={formValues.lastName}
        />
        <FormPreviewItem
          label={lang.labels.PhoneNumber()}
          value={phoneMaskedDisplay(formValues.phoneNumber)}
        />
      </FormPreviewSection>
    </FormPreviewPane>
  );
};

DriverPagePreview.propTypes = propTypes;
DriverPagePreview.defaultProps = defaultProps;

export default DriverPagePreview;
