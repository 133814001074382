import React from 'react';
import PropTypes from 'prop-types';
import SetNewPasswordForm from './SetNewPasswordForm';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {};

const SetNewPasswordPage = ({ onSubmit }) => {
  return <SetNewPasswordForm onSubmit={onSubmit} />;
};

SetNewPasswordPage.propTypes = propTypes;
SetNewPasswordPage.defaultProps = defaultProps;

export default SetNewPasswordPage;
