"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _FoxCustomDialog = _interopRequireDefault(require("fox-react/dist/components/FoxCustomDialog"));

var _redux = require("redux");

var _reduxForm = require("redux-form");

var _DriverInput = require("hh-shared/dist/components/transport-order/DriverInput");

var _CarInput = require("hh-shared/dist/components/transport-order/CarInput");

var _formValidators = require("fox-react/dist/utils/formValidators");

var _transportOrderStatusesIds = _interopRequireDefault(require("hh-shared/dist/consts/transportOrderStatusesIds"));

var _get = _interopRequireDefault(require("lodash/get"));

var _ImplementationDialogModule = _interopRequireDefault(require("./ImplementationDialog.module.scss"));

var _DialogForm = _interopRequireDefault(require("./DialogForm"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  isOpen: _propTypes.default.bool.isRequired,
  drivers: _propTypes.default.array.isRequired,
  onCloseClick: _propTypes.default.func.isRequired,
  cars: _propTypes.default.array.isRequired,
  carTypes: _propTypes.default.array.isRequired,
  transportOrderData: _propTypes.default.object,
  handleSubmit: _propTypes.default.func.isRequired
};
var defaultProps = {
  transportOrderData: {}
};

function ImplementationDialog(_ref) {
  var isOpen = _ref.isOpen,
      drivers = _ref.drivers,
      onCloseClick = _ref.onCloseClick,
      cars = _ref.cars,
      carTypes = _ref.carTypes,
      transportOrderData = _ref.transportOrderData,
      handleSubmit = _ref.handleSubmit;
  var statusId = (0, _get.default)(transportOrderData, 'status.id');
  var acceptImplementationLabel = statusId === _transportOrderStatusesIds.default.placed ? _languageService.default.buttons.AcceptForImplementation() : _languageService.default.buttons.UpdateImplementation();
  return _react.default.createElement(_FoxCustomDialog.default, {
    title: _languageService.default.labels.ImplementationDetails(),
    isOpen: isOpen,
    onCloseClick: onCloseClick,
    className: _ImplementationDialogModule.default.container,
    noLabel: _languageService.default.labels.Cancel(),
    yesLabel: acceptImplementationLabel,
    onConfirmClick: handleSubmit,
    bigger: true
  }, _react.default.createElement(_DialogForm.default, {
    handleSubmit: handleSubmit,
    transportOrderData: transportOrderData,
    drivers: drivers,
    cars: cars,
    carTypes: carTypes
  }));
}

ImplementationDialog.propTypes = propTypes;
ImplementationDialog.defaultProps = defaultProps;

var validate = function validate(values) {
  var errors = {};
  (0, _formValidators.isRequired)(errors, values, 'loadingTimeTo', _languageService.default.validationMessages.FieldRequired());
  (0, _CarInput.validateCarInput)(errors, values);
  (0, _DriverInput.validateDriverInput)(errors, values);
  return errors;
};

var _default = (0, _redux.compose)((0, _reduxForm.reduxForm)({
  form: 'implementationDialogForm',
  enableReinitialize: true,
  validate: validate
}), _react.memo)(ImplementationDialog);

exports.default = _default;