"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _FormField = _interopRequireDefault(require("../components/FormField"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var _default = function _default(WrappedComponent, wrappedProps) {
  return function (props) {
    return (// eslint-disable-next-line react/prop-types
      _react.default.createElement(_FormField.default, _extends({}, props, wrappedProps, {
        required: props.required && !props.disabled
      }), _react.default.createElement(WrappedComponent, _extends({}, props, {
        label: undefined
      })))
    );
  };
};

exports.default = _default;