import React from 'react';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import PropTypes from 'prop-types';
import DriverForm from './DriverForm';
import DriversSrv from './DriversSrv';
import DriverPagePreview from './DriverPagePreview';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

function AddDriverPage({ history }) {
  const onSubmit = model => DriversSrv.add(model);

  return (
    <>
      <BreadcrumbsContainer
        links={[
          {
            routePath: '/',
            label: lang.labels.HomePage(),
          },
          {
            routePath: '/drivers',
            label: lang.labels.Drivers(),
          },
          {
            routePath: '/drivers/add',
            label: lang.labels.AddingNewDriver(),
          },
        ]}
      />
      <FormPreviewSectionContainer>
        <FormCardWrapper>
          <DriverForm
            onSubmit={onSubmit}
            title={lang.labels.AddingNewDriver()}
            history={history}
          />
          <DriverPagePreview />
        </FormCardWrapper>
      </FormPreviewSectionContainer>
    </>
  );
}

AddDriverPage.propTypes = propTypes;
AddDriverPage.defaultProps = defaultProps;

export default AddDriverPage;
