"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Row = _interopRequireDefault(require("layout/Row"));

var _FormPreviewSectionWrapperModule = _interopRequireDefault(require("./FormPreviewSectionWrapper.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  className: _propTypes.default.string,
  isWider: _propTypes.default.bool
};
var defaultProps = {
  className: '',
  isWider: false
};

function FormPreviewSectionWrapper(_ref) {
  var children = _ref.children,
      className = _ref.className,
      isWider = _ref.isWider;
  return _react.default.createElement(_Row.default, {
    className: "".concat(_FormPreviewSectionWrapperModule.default.form_preview_section_wrapper, " ").concat(className, " ").concat(isWider ? _FormPreviewSectionWrapperModule.default.wider : '')
  }, children);
}

FormPreviewSectionWrapper.propTypes = propTypes;
FormPreviewSectionWrapper.defaultProps = defaultProps;
var _default = FormPreviewSectionWrapper;
exports.default = _default;