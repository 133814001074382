"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _FoxInputTextField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxInputTextField"));

var _FoxMaskedInputTextField = _interopRequireDefault(require("fox-react/dist/reduxFormFields/FoxMaskedInputTextField"));

var _phoneMask = _interopRequireDefault(require("../../../utils/phoneMask"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var propTypes = {};
var defaultProps = {};

function NewDriverForm() {
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_FoxInputTextField.default, {
    label: _languageService.default.labels.FirstName(),
    placeholder: _languageService.default.labels.FirstName(),
    name: "firstName",
    required: true
  }), _react.default.createElement(_FoxInputTextField.default, {
    label: _languageService.default.labels.LastName(),
    placeholder: _languageService.default.labels.LastName(),
    name: "lastName",
    required: true
  }), _react.default.createElement(_FoxMaskedInputTextField.default, _extends({}, _phoneMask.default, {
    label: _languageService.default.labels.PhoneNumber(),
    placeholder: _languageService.default.labels.PhoneNumber(),
    name: "phoneNumber"
  })));
}

NewDriverForm.propTypes = propTypes;
NewDriverForm.defaultProps = defaultProps;
var _default = NewDriverForm;
exports.default = _default;