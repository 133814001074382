"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _DropdownButtonIcon = _interopRequireDefault(require("./DropdownButtonIcon"));

var _DropdownButtonModule = _interopRequireDefault(require("./DropdownButton.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var propTypes = {
  label: _propTypes.default.string.isRequired,
  onClick: _propTypes.default.func,
  link: _propTypes.default.string,
  icon: _propTypes.default.object,
  buttonColor: _propTypes.default.oneOf(['red', 'orange', 'blue', 'dark-blue', 'green', 'dark-green', 'custom-green', 'grey', 'dark'])
};
var defaultProps = {
  onClick: function onClick() {},
  link: '',
  icon: undefined,
  buttonColor: 'blue'
};

var DropdownButton = function DropdownButton(_ref) {
  var label = _ref.label,
      onClick = _ref.onClick,
      link = _ref.link,
      buttonColor = _ref.buttonColor,
      icon = _ref.icon,
      others = _objectWithoutProperties(_ref, ["label", "onClick", "link", "buttonColor", "icon"]);

  var buttonClassName = (0, _classnames.default)(_DropdownButtonModule.default.dropdown_button, _defineProperty({}, _DropdownButtonModule.default["dropdown_button_".concat(buttonColor)], buttonColor));
  return _react.default.createElement(_react.default.Fragment, null, link ? _react.default.createElement(_reactRouterDom.Link, _extends({
    to: link,
    className: buttonClassName
  }, others), icon && _react.default.createElement(_DropdownButtonIcon.default, {
    icon: icon,
    label: label
  }), label) : _react.default.createElement("button", _extends({
    type: "button",
    className: buttonClassName,
    onClick: onClick
  }, others), icon && _react.default.createElement(_DropdownButtonIcon.default, {
    icon: icon,
    label: label
  }), label));
};

DropdownButton.propTypes = propTypes;
DropdownButton.defaultProps = defaultProps;
var _default = DropdownButton;
exports.default = _default;