"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reduxForm = require("redux-form");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var _default = function _default(WrappedComponent) {
  function renderComponent(_ref) {
    var input = _ref.input,
        meta = _ref.meta,
        other = _objectWithoutProperties(_ref, ["input", "meta"]);

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(WrappedComponent, _extends({}, input, {
      onChange: function onChange(e) {
        return input.onChange(e.target.value);
      },
      invalid: meta.invalid && (meta.touched || meta.submitFailed)
    }, other, {
      meta: meta
    })), meta.invalid && (meta.touched || meta.submitFailed) && _react.default.createElement("span", {
      className: "k-invalid-message"
    }, meta.error));
  }

  return function (props) {
    return _react.default.createElement(_reduxForm.Field, _extends({}, props, {
      component: renderComponent
    }));
  };
};

exports.default = _default;