import lang from 'hh-shared/dist/language/services/languageService';

export const transportOrderAllFilter = undefined;

export const transportOrderCanceledFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.CanceledTransportOrderStatus(),
    },
  ],
};

export const transportOrderAcceptedFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.AcceptedTransportOrderStatus(),
    },
  ],
};

export const transportOrderLoadedFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.LoadedTransportOrderStatus(),
    },
  ],
};

export const transportOrderCompletedFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.CompletedTransportOrderStatus(),
    },
  ],
};

export const transportOrderPlacedFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.PlacedTransportOrderStatus(),
    },
  ],
};
