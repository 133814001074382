import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Logo from 'hh-shared/dist/components/commons/Logo';
import HamburgerButton from 'hh-shared/dist/components/layout/HamburgerButton';
import styles from './Header.module.scss';
import { toggle } from './Menu/sideMenuActions';
import AuthWidgetContainer from '../auth/UserWidgetContainer';

const propTypes = {};
const defaultProps = {};

const Header = () => {
  const enlarge = useSelector(state => state.sideMenu.isOpened);
  const dispatch = useDispatch();
  const onMenuToggle = () => dispatch(toggle());

  return (
    <div
      className={`${styles.header} ${!enlarge &&
        styles.header_shrinked} header`}
    >
      <div className={styles.logo_container}>
        <HamburgerButton
          additionalClassNames={styles.hamburger_menu}
          isActive={!enlarge}
          onMenuToggle={onMenuToggle}
        />
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <AuthWidgetContainer />
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
