import axios from 'config/axios';

const prefix = 'transportOrders';

export default class TransportOrdersSrv {
  static getForGrid(dataState) {
    return axios
      .post(`${prefix}/getForGrid`, dataState)
      .then(result => result.data);
  }

  static sendMessage(id, message) {
    return axios.post(`${prefix}/${id}/message`, { message });
  }

  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static getCars() {
    return axios.get(`${prefix}/getCars`).then(result => result.data);
  }

  static getDrivers() {
    return axios.get(`${prefix}/getDrivers`).then(result => result.data);
  }

  static getCarTypes() {
    return axios.get(`${prefix}/getCarTypes`).then(result => result.data);
  }

  static acceptForImplementation(model) {
    return axios
      .post(`${prefix}/acceptForImplementation`, model)
      .then(result => result.data);
  }

  static getCompletedTransportOrderPdf(id) {
    return axios
      .get(`${prefix}/getCompletedTransportOrderPdf/${id}`, {
        responseType: 'blob',
        timeout: 30000,
      })
      .then(result => result.data);
  }
}
