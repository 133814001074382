"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var actionsColumnGridDefaults = {
  groupable: false,
  field: 'actions',
  sortable: false,
  title: ' ',
  filterable: false
};
var _default = actionsColumnGridDefaults;
exports.default = _default;