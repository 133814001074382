"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toUpper = exports.zipCodeMask = exports.formatZipCode = exports.parseZipCode = void 0;

var parseZipCode = function parseZipCode(value) {
  if (!value) {
    return undefined;
  }

  return value.slice(0, 6);
};

exports.parseZipCode = parseZipCode;

var formatZipCode = function formatZipCode(value) {
  if (!value) {
    return undefined;
  }

  if (value.length !== 3) {
    if (Number.isNaN(parseInt(value[value.length - 1], 10))) {
      return value.slice(0, -1);
    }
  }

  if (value.length > 2 && value[2] !== '-') {
    value = "".concat(value.slice(0, 2), "-").concat(value.slice(2));
  }

  if (value.length >= 6) {
    return value.slice(0, 6);
  }

  return value;
};

exports.formatZipCode = formatZipCode;
var zipCodeMask = {
  format: formatZipCode,
  parse: parseZipCode
};
exports.zipCodeMask = zipCodeMask;

var toUpper = function toUpper(value) {
  if (!value) {
    return undefined;
  }

  return value.toUpperCase();
};

exports.toUpper = toUpper;