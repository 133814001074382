"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ProgressiveImgModule = _interopRequireDefault(require("./ProgressiveImg.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  alt: _propTypes.default.string,
  imageSrc: _propTypes.default.string.isRequired,
  placeholderSrc: _propTypes.default.string.isRequired,
  classNames: _propTypes.default.string
};
var defaultProps = {
  alt: '',
  classNames: ''
};

function ProgressiveImg(_ref) {
  var alt = _ref.alt,
      imageSrc = _ref.imageSrc,
      placeholderSrc = _ref.placeholderSrc,
      classNames = _ref.classNames;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      imageHasLoaded = _useState2[0],
      setImageHasLoaded = _useState2[1];

  var handleOnLoad = function handleOnLoad() {
    setImageHasLoaded(true);
  };

  return _react.default.createElement(_react.Fragment, null, _react.default.createElement("img", {
    src: imageSrc,
    alt: alt,
    onLoad: handleOnLoad,
    className: classNames,
    draggable: false
  }), _react.default.createElement("img", {
    src: placeholderSrc,
    alt: "".concat(alt, " placeholder"),
    className: "".concat(_ProgressiveImgModule.default.placeholder_image, " ").concat(classNames, " ").concat(imageHasLoaded && 'hidden'),
    draggable: false
  }));
}

ProgressiveImg.propTypes = propTypes;
ProgressiveImg.defaultProps = defaultProps;
var _default = ProgressiveImg;
exports.default = _default;