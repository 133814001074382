import { connect } from 'react-redux';
import { resetPasswordAsync } from 'auth/actions';
import ResetPasswordPage from './ResetPasswordPage';

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: model => dispatch(resetPasswordAsync(model)),
  };
};

const ResetPasswordPageContainer = connect(
  undefined,
  mapDispatchToProps,
)(ResetPasswordPage);

export default ResetPasswordPageContainer;
