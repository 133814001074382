"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.closeToastr = exports.displayToastr = void 0;

var _toastrActionTypes = _interopRequireDefault(require("./toastrActionTypes"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var displayToastr = function displayToastr(message, type) {
  var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20000;
  return function (dispatch) {
    dispatch({
      type: _toastrActionTypes.default.TOASTR_DISPLAY,
      message: message,
      toastrType: type,
      duration: duration
    });
  };
};

exports.displayToastr = displayToastr;

var closeToastr = function closeToastr(id) {
  return function (dispatch) {
    dispatch({
      type: _toastrActionTypes.default.TOASTR_CLOSE,
      id: id
    });
  };
};

exports.closeToastr = closeToastr;