import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  getFormSyncErrors,
  formValueSelector,
  FieldArray,
  formValues,
  change,
} from 'redux-form';
import { connect, useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import FoxAutocompleteField from 'fox-react/dist/reduxFormFields/FoxAutocompleteField';
import FoxUploadFiles from 'fox-react/dist/components/FoxUploadFiles';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { isNip, isRegon } from 'fox-react/dist/utils/formValidators';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import { zipCodeMask } from 'fox-react/dist/utils/reduxFormUtils';
import { displayToastr } from 'hh-shared/dist/toastr/toastrActions';
import ToastrTypes from 'hh-shared/dist/toastr/ToastrTypes';
import lang from 'hh-shared/dist/language/services/languageService';
import { scrollToTheFirstError } from 'hh-shared/dist/consts/reduxFormMethods';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import FormSectionButtonRow from 'hh-shared/dist/components/layout/FormSectionButtonRow';
import InformationList from 'hh-shared/dist/components/commons/InformationList';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import phoneMask from 'hh-shared/dist/utils/phoneMask';
import FoxMaskedInputTextField from 'fox-react/dist/reduxFormFields/FoxMaskedInputTextField';

import CompanyFormDriver from './CompanyFormDriver';
import CompanyFormCar from './CompanyFormCar';
import CompanyAttachment from './CompanyAttachment';
import { getFromGusByRegonAsync, getFromGusByNipAsync } from './actions';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onGetFromGusByNip: PropTypes.func.isRequired,
  onGetFromGusByRegon: PropTypes.func.isRequired,
  nipHasError: PropTypes.bool.isRequired,
  regonHasError: PropTypes.bool.isRequired,
  carTypes: PropTypes.array.isRequired,
  onUploadAttachments: PropTypes.func.isRequired,
  companyAttachments: PropTypes.array,
  submitFailed: PropTypes.bool,
};

const defaultProps = {
  companyAttachments: [],
  submitFailed: false,
};

function CompanyForm({
  handleSubmit,
  submitting,
  onGetFromGusByNip,
  onGetFromGusByRegon,
  nipHasError,
  regonHasError,
  carTypes,
  onUploadAttachments,
  companyAttachments,
  submitFailed,
}) {
  const zipCodeCodes = useSelector(
    (state) => state.dictionary.static.zipCodeCodes,
  );

  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();

  const uploadAttachments = (newCompanyAttachments) => {
    setShowSpinner(true);
    onUploadAttachments(newCompanyAttachments)
      .then(() => {
        dispatch(
          change(
            formNames.companyForm,
            fieldNames.companyForm.companyAttachments,
            [
              ...companyAttachments,
              ...newCompanyAttachments.affectedFiles.map((x) => {
                return { id: x.uid, name: x.name, content: x.getRawFile() };
              }),
            ],
          ),
        );
      })
      .finally(() => setShowSpinner(false));
  };

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.CompanyInformations()}
            titleIcon={icons.company}
          >
            <FormSectionButtonRow>
              <FoxInputTextField
                label={lang.labels.Nip()}
                placeholder={lang.labels.Nip()}
                name={fieldNames.companyForm.nip}
                validate={isRequiredFieldValidator}
                required
              />

              <FoxButton
                type="button"
                disabled={nipHasError}
                onClick={onGetFromGusByNip}
                className="k-button k-primary"
              >
                {lang.buttons.GetFromGus()}
              </FoxButton>
            </FormSectionButtonRow>
            <FormSectionButtonRow>
              <FoxInputTextField
                label={lang.labels.Regon()}
                placeholder={lang.labels.Regon()}
                name={fieldNames.companyForm.regon}
                validate={isRequiredFieldValidator}
                required
              />

              <FoxButton
                type="button"
                disabled={regonHasError}
                onClick={onGetFromGusByRegon}
                className="k-button k-primary"
              >
                {lang.buttons.GetFromGus()}
              </FoxButton>
            </FormSectionButtonRow>

            <FoxInputTextField
              label={lang.labels.Name()}
              placeholder={lang.labels.Name()}
              name={fieldNames.companyForm.name}
              validate={isRequiredFieldValidator}
              required
            />

            <FoxInputTextField
              label={lang.labels.Address()}
              placeholder={lang.labels.Address()}
              name={fieldNames.companyForm.address}
              validate={isRequiredFieldValidator}
              required
            />

            <FoxAutocompleteField
              label={lang.labels.ZipCode()}
              placeholder={lang.labels.ZipCode()}
              name={fieldNames.newAddressForm.zipCode}
              validate={isRequiredFieldValidator}
              required
              items={zipCodeCodes}
              {...zipCodeMask}
            />

            <FoxInputTextField
              label={lang.labels.City()}
              placeholder={lang.labels.City()}
              name={fieldNames.companyForm.city}
              validate={isRequiredFieldValidator}
              required
            />

            <FoxMaskedInputTextField
              {...phoneMask}
              label={lang.labels.PhoneNumber()}
              placeholder={lang.labels.PhoneNumber()}
              name={fieldNames.companyForm.phoneNumber}
              validate={isRequiredFieldValidator}
              required
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper bottomSpace>
          <FormSectionCard
            title={lang.labels.Drivers()}
            titleIcon={icons.driver}
            fullContentWidth
          >
            <FieldArray name="drivers" component={CompanyFormDriver} />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper bottomSpace>
          <FormSectionCard
            title={lang.labels.Cars()}
            titleIcon={icons.vehicle}
            fullContentWidth
          >
            <FieldArray
              name="cars"
              carTypes={carTypes}
              component={CompanyFormCar}
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.BidingByHHAllowed()}
            titleIcon={icons.approval}
          >
            <FoxSwitchField
              labels={lang.labels.Blocked()}
              name={fieldNames.companyForm.bidingByHHAllowed}
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.AttachNecessaryAttachmentsListedInFollowingList()}
            titleIcon={icons.fileData}
            requiredTitle
            isInvalid={submitFailed && isEmpty(companyAttachments)}
          >
            <InformationList>
              <li>{lang.labels.AddCompany_AttachmentInformation_License()}</li>
              <li>
                {lang.labels.AddCompany_AttachmentInformation_Permission()}
              </li>
              <li>
                {lang.labels.AddCompany_AttachmentInformation_CarrierInsurance()}
              </li>
              <li>
                {lang.labels.AddCompany_AttachmentInformation_ForwarderInsurance()}
              </li>
            </InformationList>
            <div className={cx({ fox_spinner: showSpinner })}>
              <FoxUploadFiles batch={false} onAdd={uploadAttachments} />
              <FieldArray
                name={fieldNames.companyForm.companyAttachments}
                companyAttachments={companyAttachments}
                component={CompanyAttachment}
              />
            </div>
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.SendApplicationForApproval()}
      </FoxButton>
    </Form>
  );
}

CompanyForm.propTypes = propTypes;
CompanyForm.defaultProps = defaultProps;

const validate = (values) => {
  const errors = {};

  isNip(
    errors,
    values,
    fieldNames.companyForm.nip,
    lang.validationMessages.InvalidNip(),
  );

  isRegon(
    errors,
    values,
    fieldNames.companyForm.regon,
    lang.validationMessages.InvalidRegon(),
  );
  return errors;
};

const formSelector = formValueSelector(formNames.companyForm);

const mapStateToProps = (state) => {
  const errors = getFormSyncErrors(formNames.companyForm)(state);
  return {
    nipHasError: !!errors[fieldNames.companyForm.nip],
    regonHasError: !!errors[fieldNames.companyForm.regon],
    nipValue: formSelector(state, fieldNames.companyForm.nip),
    regonValue: formSelector(state, fieldNames.companyForm.regon),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFromGusByNip: (nip) => dispatch(getFromGusByNipAsync(nip)),
    onGetFromGusByRegon: (regon) => dispatch(getFromGusByRegonAsync(regon)),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return {
    ...propsFromState,
    ...ownProps,
    ...propsFromDispatch,
    onGetFromGusByNip: () =>
      propsFromDispatch.onGetFromGusByNip(propsFromState.nipValue),
    onGetFromGusByRegon: () =>
      propsFromDispatch.onGetFromGusByRegon(propsFromState.regonValue),
  };
};

const connectedCompanyForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CompanyForm);

const onSubmitSuccess = (_, dispatch) =>
  dispatch(
    displayToastr(lang.labels.SuccessfullyCreatedCompany(), ToastrTypes.info),
  );

export default reduxForm({
  form: formNames.companyForm,
  validate,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(formValues('companyAttachments')(goBackAfterSuccess(connectedCompanyForm)));
