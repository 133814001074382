"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var transportOrderStatusesIds = {
  placed: 'b99e412e-2cba-4136-896f-74dace00859c',
  accepted: '2b1f2d52-51f8-4fcb-8f52-dd893c5fcdc9',
  loaded: '628b6320-e64d-4d37-b4ca-b67b11280135',
  realized: 'c33153a0-90d1-4ab6-a65e-6c2f3a1cb9e9',
  canceled: 'da548d52-989d-448e-a2c8-493ebd1a8a85'
};
var _default = transportOrderStatusesIds;
exports.default = _default;