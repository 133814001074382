import lang from 'hh-shared/dist/language/services/languageService';

export const auctionObservedFilter = {
  logic: 'and',
  filters: [
    {
      field: 'isObserved',
      operator: 'eq',
      value: true,
    },
  ],
};

export const auctionOngoingFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.LiveAuctionStatus(),
    },
  ],
};

export const auctionCanceledFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.CanceledAuctionStatus(),
    },
  ],
};

export const auctionAllFilter = undefined;

export const auctionWithMyParticipationFilter = {
  logic: 'and',
  filters: [
    {
      field: 'isParticipant',
      operator: 'eq',
      value: true,
    },
  ],
};

const endedAuctionFilter = {
  field: 'status',
  operator: 'contains',
  value: lang.labels.EndedAuctionStatus(),
};

export const auctionWonFilter = {
  logic: 'and',
  filters: [
    {
      field: 'isWon',
      operator: 'eq',
      value: true,
    },
    endedAuctionFilter,
  ],
};

export const auctionNotWonFilter = {
  logic: 'and',
  filters: [
    {
      field: 'isWon',
      operator: 'eq',
      value: false,
    },
    endedAuctionFilter,
  ],
};

export const auctionWithoutOfferFilter = {
  logic: 'and',
  filters: [
    {
      field: 'currentPrice',
      operator: 'contains',
      value: lang.labels.WithoutOffer(),
    },
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.LiveAuctionStatus(),
    },
  ],
};

export const auctionForPublicationFilter = {
  logic: 'and',
  filters: [
    {
      field: 'status',
      operator: 'contains',
      value: lang.labels.ForPublication(),
    },
    endedAuctionFilter,
  ],
};
