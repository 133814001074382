import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Column from 'layout/Column';
import Row from 'layout/Row';
import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';

import './Notifications.scss';
import NotificationRow from './NotificationRow';
import styles from './NotificationContainer.module.scss';
import './NotificationContainerTheming.scss';
import NotificationSectionTitle from './NotificationSectionTitle';

const propTypes = {};
const defaultProps = {};

const NotificationContainer = () => {
  const userSettings = useSelector(state => state.userSettings);

  const getNotificationIcon = notificationName => {
    switch (notificationName) {
      case lang.labels.MySettings_Auctions():
        return icons.auction;
      case lang.labels.MySettings_Orders():
        return icons.order;
      case lang.labels.MySettings_System():
        return icons.settings;
      default:
        return icons.template;
    }
  };

  return (
    <>
      <SectionWrapper
        additionalClassNames={`${styles.notification_container} notification_container`}
      >
        <Column className="full-width">
          <Row className={`${styles.description_row} triple_row `}>
            <div />
            <div>{lang.labels.SmsNotification()}</div>
            <div>{lang.labels.EmailNotification()}</div>
          </Row>
          {userSettings.notificationAreas.map((area, areaId) => (
            <Fragment key={areaId}>
              <NotificationSectionTitle
                title={area.label}
                icon={getNotificationIcon(area.label)}
              />
              <Column>
                {area.notifications &&
                  area.notifications.map(auctionNotification => (
                    <NotificationRow
                      notification={auctionNotification}
                      key={auctionNotification.templateId}
                    />
                  ))}
              </Column>
            </Fragment>
          ))}
        </Column>
      </SectionWrapper>
    </>
  );
};

NotificationContainer.propTypes = propTypes;
NotificationContainer.defaultProps = defaultProps;

export default NotificationContainer;
