import { change } from 'redux-form';
import { displayToastr } from 'hh-shared/dist/toastr/toastrActions';
import ToastrTypes from 'hh-shared/dist/toastr/ToastrTypes';
import { prepareFormData } from 'hh-shared/dist/utils/filesHelper';
import lang from 'hh-shared/dist/language/services/languageService';
import GusSrv from 'common/GusSrv';
import formNames from 'common/formNames';
import fieldNames from 'common/fieldNames';
import CompanySrv from './CompanySrv';

export function addAsync(model) {
  model.companyAttachmentIds = model.companyAttachments.map((x) => x.id);
  return CompanySrv.add(model);
}

function populateResponse(dispatch, res) {
  const formName = formNames.companyForm;
  const ff = fieldNames.companyForm;
  dispatch(change(formName, ff.name, res.name));
  dispatch(change(formName, ff.nip, res.nip));
  dispatch(change(formName, ff.regon, res.regon));
  dispatch(change(formName, ff.phoneNumber, res.phoneNumber.substring(0, 9)));
  dispatch(change(formName, ff.city, res.city));
  dispatch(change(formName, ff.address, res.address));
  dispatch(change(formName, ff.zipCode, res.zipCode));
}

export function getFromGusByRegonAsync(regon) {
  return function (dispatch) {
    return GusSrv.getByRegon(regon).then((res) => {
      populateResponse(dispatch, res);
      dispatch(
        displayToastr(
          `${lang.validationMessages.GusDataLoaded()}`,
          ToastrTypes.success,
        ),
      );
    });
  };
}

export function getFromGusByNipAsync(nip) {
  return function (dispatch) {
    return GusSrv.getByNip(nip).then((res) => {
      populateResponse(dispatch, res);
      dispatch(
        displayToastr(
          `${lang.validationMessages.GusDataLoaded()}`,
          ToastrTypes.success,
        ),
      );
    });
  };
}

export function uploadAttachments(files) {
  return CompanySrv.uploadAttachments(prepareFormData(files));
}
