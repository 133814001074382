"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _languageService = _interopRequireDefault(require("../../../language/services/languageService"));

var _FormPreviewItemModule = _interopRequireDefault(require("./FormPreviewItem.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  label: _propTypes.default.string.isRequired,
  value: _propTypes.default.any,
  isNew: _propTypes.default.bool,
  isNewValue: _propTypes.default.bool,
  withoutMarginRestriction: _propTypes.default.bool
};
var defaultProps = {
  value: '-',
  isNew: false,
  isNewValue: false,
  withoutMarginRestriction: false
};

var FormPreviewItem = function FormPreviewItem(_ref) {
  var label = _ref.label,
      value = _ref.value,
      isNew = _ref.isNew,
      isNewValue = _ref.isNewValue,
      withoutMarginRestriction = _ref.withoutMarginRestriction;
  var keyValueSeparator = ': ';
  return _react.default.createElement("div", {
    className: "".concat(_FormPreviewItemModule.default.preview_item, " ").concat(withoutMarginRestriction ? _FormPreviewItemModule.default.have_margin : '')
  }, _react.default.createElement("div", {
    className: _FormPreviewItemModule.default.label
  }, "".concat(label, " "), !isNewValue && isNew && _react.default.createElement("div", {
    className: _FormPreviewItemModule.default.badge
  }, _languageService.default.labels.New()), keyValueSeparator), _react.default.createElement("span", {
    className: _FormPreviewItemModule.default.value
  }, !isNewValue ? value : _react.default.createElement("div", {
    className: _FormPreviewItemModule.default.badge
  }, _languageService.default.labels.New())));
};

FormPreviewItem.propTypes = propTypes;
FormPreviewItem.defaultProps = defaultProps;
var _default = FormPreviewItem;
exports.default = _default;