"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _reactRouterDom = require("react-router-dom");

var _FontAwesomeIcon = _interopRequireDefault(require("./FontAwesomeIcon/FontAwesomeIcon"));

var _BreadcrumbsContainerModule = _interopRequireDefault(require("./BreadcrumbsContainer.module.scss"));

var _Row = _interopRequireDefault(require("../layout/Row"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  links: _propTypes.default.arrayOf(_propTypes.default.exact({
    routePath: _propTypes.default.string,
    label: _propTypes.default.string
  })).isRequired
};
var defaultProps = {};

var BreadcrumbsContainer = function BreadcrumbsContainer(_ref) {
  var links = _ref.links;

  var handleClick = function handleClick(e, index) {
    index === links.length - 1 && function () {
      e.preventDefault();
      document.location.reload();
    }();
  };

  return _react.default.createElement(_Row.default, {
    className: "".concat(_BreadcrumbsContainerModule.default.breadcrumbs_container, " breadcrumbs")
  }, links.map(function (link, linkIndex) {
    return _react.default.createElement(_Row.default, {
      className: _BreadcrumbsContainerModule.default.link_container,
      key: linkIndex
    }, _react.default.createElement(_FontAwesomeIcon.default, {
      icon: _icons.default.longRightArrow
    }), _react.default.createElement(_reactRouterDom.Link, {
      to: link.routePath,
      onClick: function onClick(e) {
        return handleClick(e, linkIndex);
      }
    }, link.label));
  }));
};

BreadcrumbsContainer.propTypes = propTypes;
BreadcrumbsContainer.defaultProps = defaultProps;
var _default = BreadcrumbsContainer;
exports.default = _default;