"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

var _ContentRevealerModule = _interopRequireDefault(require("./ContentRevealer.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  isVisible: _propTypes.default.bool,
  children: _propTypes.default.any.isRequired,
  className: _propTypes.default.string,
  visibleClassName: _propTypes.default.string,
  onHide: _propTypes.default.func,
  onShow: _propTypes.default.func,
  dontRemoveChildrenOnHide: _propTypes.default.bool,
  mutableValues: _propTypes.default.array
};
var defaultProps = {
  isVisible: false,
  className: '',
  visibleClassName: '',
  onHide: function onHide() {},
  onShow: function onShow() {},
  dontRemoveChildrenOnHide: false,
  mutableValues: []
};

var ContentRevealer = function ContentRevealer(_ref) {
  var _cx;

  var isVisible = _ref.isVisible,
      children = _ref.children,
      className = _ref.className,
      visibleClassName = _ref.visibleClassName,
      onHide = _ref.onHide,
      onShow = _ref.onShow,
      dontRemoveChildrenOnHide = _ref.dontRemoveChildrenOnHide,
      mutableValues = _ref.mutableValues;

  var _useState = (0, _react.useState)('children_4'),
      _useState2 = _slicedToArray(_useState, 2),
      currentClassName = _useState2[0],
      setCurrentClassName = _useState2[1];

  var _useState3 = (0, _react.useState)(0),
      _useState4 = _slicedToArray(_useState3, 2),
      maxContainerHeight = _useState4[0],
      setMaxContainerHeight = _useState4[1];

  var _useState5 = (0, _react.useState)(true),
      _useState6 = _slicedToArray(_useState5, 2),
      renderChildren = _useState6[0],
      setRenderChildren = _useState6[1];

  var containerRef = (0, _react.useRef)(null);
  var timeoutRef = (0, _react.useRef)(null);
  var containerClassName = (0, _classnames.default)(_ContentRevealerModule.default.reveal_container, _ContentRevealerModule.default[currentClassName], className, (_cx = {}, _defineProperty(_cx, _ContentRevealerModule.default.hidden, !isVisible), _defineProperty(_cx, visibleClassName, isVisible && visibleClassName), _cx));
  var displayingAnimationDuration = 1;
  var containerTransitionTime = 550;
  var itemTransitionTime = 150;
  var itemTransitionDelay = 50;

  var _useState7 = (0, _react.useState)(1250),
      _useState8 = _slicedToArray(_useState7, 2),
      hidingAnimationDuration = _useState8[0],
      setHidingAnimationDuration = _useState8[1];

  var getContainerHeight = function getContainerHeight() {
    var childrenElements = _toConsumableArray(containerRef.current.children);

    return childrenElements.length > 0 ? childrenElements.map(function (childrenElement) {
      var childrenElementDimensions = window.getComputedStyle(childrenElement);
      var childrenHeight = ['height', 'padding-top', 'padding-bottom', 'margin-top', 'margin-bottom'].map(function (value) {
        return Number.parseFloat(childrenElementDimensions.getPropertyValue(value));
      }).reduce(function (previousValue, nextValue) {
        return previousValue + nextValue;
      });
      return childrenHeight;
    }).reduce(function (previousValue, nextValue) {
      return previousValue + nextValue;
    }) : 0;
  };

  var updateContainerHeight = function updateContainerHeight() {
    var currentContainerHeight = getContainerHeight();
    currentContainerHeight > maxContainerHeight && function () {
      var childrenQuantity = _toConsumableArray(containerRef.current.children).length;

      var currentHidingAnimationDelay = containerTransitionTime + itemTransitionDelay * 1.5 + itemTransitionTime * childrenQuantity;
      setMaxContainerHeight(currentContainerHeight);
      setHidingAnimationDuration(currentHidingAnimationDelay);
    }();
  };

  var enableInputs = function enableInputs(inputElements) {
    inputElements.forEach(function (inputElement) {
      inputElement.removeAttribute('disabled');
    });
  };

  var disableInputs = function disableInputs(inputElements) {
    inputElements.forEach(function (inputElement) {
      inputElement.setAttribute('disabled', 'disabled');
    });
  };

  (0, _react.useEffect)(function () {
    var elReference = containerRef.current;

    var childrenElements = _toConsumableArray(elReference.children);

    var childrenQuantity = childrenElements.length;
    updateContainerHeight();
    setCurrentClassName("children_".concat(childrenQuantity));
  }, []);
  (0, _react.useEffect)(function () {
    var elReference = containerRef.current;

    var currentInputs = _toConsumableArray(elReference.querySelectorAll('input'));

    isVisible ? enableInputs(currentInputs) : disableInputs(currentInputs);
    isVisible ? onShow() : onHide();
    updateContainerHeight();
  }, [isVisible]);
  (0, _react.useEffect)(function () {
    !(0, _isEmpty.default)(mutableValues) && updateContainerHeight();
  }, _toConsumableArray(mutableValues));
  (0, _react.useEffect)(function () {
    var activeTimeout = timeoutRef.current;
    activeTimeout && clearTimeout(activeTimeout);
    timeoutRef.current = setTimeout(function () {
      setRenderChildren(isVisible);
    }, isVisible ? displayingAnimationDuration : hidingAnimationDuration);
  }, [isVisible]);
  return _react.default.createElement("div", {
    className: containerClassName,
    ref: containerRef,
    style: {
      height: "".concat(isVisible ? maxContainerHeight : 0, "px")
    }
  }, (dontRemoveChildrenOnHide || renderChildren || maxContainerHeight === 0) && children);
};

ContentRevealer.propTypes = propTypes;
ContentRevealer.defaultProps = defaultProps;

var _default = (0, _react.memo)(ContentRevealer);

exports.default = _default;