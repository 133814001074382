"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _get = _interopRequireDefault(require("lodash/get"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Row = _interopRequireDefault(require("../layout/Row"));

var _transportOrderStatusesIds = _interopRequireDefault(require("hh-shared/dist/consts/transportOrderStatusesIds"));

var _TimelineItemComment = _interopRequireDefault(require("./TimelineItemComment"));

var _TimelineItemDescription = _interopRequireDefault(require("./TimelineItemDescription"));

var _TimelineItemModule = _interopRequireDefault(require("./TimelineItem.module.scss"));

var _TimelineItemUser = _interopRequireDefault(require("./TimelineItemUser"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  className: _propTypes.default.string,
  statusHistory: _propTypes.default.exact({
    time: _propTypes.default.string,
    statusId: _propTypes.default.string,
    userFullName: _propTypes.default.string,
    userPhone: _propTypes.default.string,
    userAvatar: _propTypes.default.string,
    message: _propTypes.default.string
  }),
  lastActiveItem: _propTypes.default.bool,
  renderCommentButton: _propTypes.default.node
};
var defaultProps = {
  className: '',
  statusHistory: undefined,
  lastActiveItem: false,
  renderCommentButton: undefined
};

var TimelineItem = function TimelineItem(_ref) {
  var className = _ref.className,
      statusHistory = _ref.statusHistory,
      lastActiveItem = _ref.lastActiveItem,
      renderCommentButton = _ref.renderCommentButton;
  var completionDate = statusHistory && statusHistory.time;
  var classNameForIdentification = 'timeline-item-container';
  var itemContainerClassName = (0, _classnames.default)(_TimelineItemModule.default.item_container, className, classNameForIdentification, _defineProperty({}, _TimelineItemModule.default.completed, completionDate));
  return _react.default.createElement(_react.default.Fragment, null, statusHistory && (0, _get.default)(statusHistory, 'statusId') && _react.default.createElement(_Row.default, {
    className: itemContainerClassName
  }, _react.default.createElement(_TimelineItemUser.default, {
    statusHistory: statusHistory,
    lastActiveItem: lastActiveItem
  }), _react.default.createElement(_TimelineItemDescription.default, {
    statusHistory: statusHistory
  }, lastActiveItem && statusHistory.statusId !== _transportOrderStatusesIds.default.canceled && renderCommentButton)), statusHistory && (0, _get.default)(statusHistory, 'message') && _react.default.createElement(_Row.default, {
    className: itemContainerClassName
  }, _react.default.createElement(_TimelineItemUser.default, {
    statusHistory: statusHistory,
    lastActiveItem: lastActiveItem
  }), _react.default.createElement(_TimelineItemComment.default, {
    statusHistory: statusHistory
  }, lastActiveItem && renderCommentButton)));
};

TimelineItem.propTypes = propTypes;
TimelineItem.defaultProps = defaultProps;
var _default = TimelineItem;
exports.default = _default;