"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _Row = _interopRequireDefault(require("../layout/Row"));

var _Column = _interopRequireDefault(require("../layout/Column"));

var _AuctionPreviewPageLoaderModule = _interopRequireDefault(require("./AuctionPreviewPageLoader.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {};
var defaultProps = {};

var AuctionPreviewPageLoader = function AuctionPreviewPageLoader() {
  var leftPaneClassName = (0, _classnames.default)('fox_spinner', _AuctionPreviewPageLoaderModule.default.pane_left);
  var rightPaneClassName = (0, _classnames.default)('lifted', 'lifted-right', 'fox_spinner', _AuctionPreviewPageLoaderModule.default.pane_right);
  return _react.default.createElement(_Row.default, null, _react.default.createElement(_Column.default, {
    bigger: true,
    className: leftPaneClassName
  }, _react.default.createElement("section", {
    className: _AuctionPreviewPageLoaderModule.default.pane_left_top
  }), _react.default.createElement("section", {
    className: _AuctionPreviewPageLoaderModule.default.pane_left_mid
  }), _react.default.createElement("section", {
    className: _AuctionPreviewPageLoaderModule.default.pane_left_bottom
  })), _react.default.createElement(_Column.default, {
    smaller: true,
    className: rightPaneClassName
  }));
};

AuctionPreviewPageLoader.propTypes = propTypes;
AuctionPreviewPageLoader.defaultProps = defaultProps;

var _default = (0, _react.memo)(AuctionPreviewPageLoader);

exports.default = _default;