"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isRequired = isRequired;
exports.isRequiredField = isRequiredField;
exports.isRequiredArray = isRequiredArray;
exports.isEmail = isEmail;
exports.isEmailField = isEmailField;
exports.areEquals = areEquals;
exports.areNotEquals = areNotEquals;
exports.isValidPassword = isValidPassword;
exports.isValidPasswordArray = isValidPasswordArray;
exports.isNip = isNip;
exports.isNipField = isNipField;
exports.isRegon = isRegon;
exports.isRegonField = isRegonField;
exports.isDateFromTo = isDateFromTo;
exports.isNotPastDateField = isNotPastDateField;
exports.maskedInputField = maskedInputField;

var _get = _interopRequireDefault(require("lodash/get"));

var _set = _interopRequireDefault(require("lodash/set"));

var _moment = _interopRequireDefault(require("moment"));

var _isString = _interopRequireDefault(require("lodash/isString"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function isRequired(errors, values, fieldName, validationMessage) {
  var valueToCheck = (0, _get.default)(values, fieldName);

  if (valueToCheck === null || valueToCheck === undefined) {
    (0, _set.default)(errors, fieldName, validationMessage);
    return;
  }

  if (typeof valueToCheck === 'string') {
    if (valueToCheck.trim() === '') {
      (0, _set.default)(errors, fieldName, validationMessage);
    }
  }
}

function isRequiredField(errorMessage) {
  return function (value) {
    if (value === null || value === undefined || Array.isArray(value) && !value.length || typeof value === 'string' && value.trim() === '') {
      return errorMessage;
    }

    return undefined;
  };
}

function isRequiredArray(errors, values, fieldNamesArray, validationMessage) {
  fieldNamesArray.forEach(function (x) {
    isRequired(errors, values, x, validationMessage);
  });
}

function isEmail(errors, values, fieldName, validationMessage) {
  var valueToCheck = values[fieldName];

  if (!valueToCheck) {
    return;
  } // eslint-disable-next-line no-useless-escape


  var patt = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!patt.test(valueToCheck)) {
    errors[fieldName] = validationMessage;
  }
}

function isEmailField(errorMessage) {
  return function (value) {
    // eslint-disable-next-line no-useless-escape
    var patt = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value && !patt.test(value)) {
      return errorMessage;
    }

    return undefined;
  };
}

function areEquals(errors, values, fieldNamesArray, validationMessage) {
  var firstValue = values[fieldNamesArray[0]];

  for (var i = 0; i < fieldNamesArray.length; i++) {
    var fieldName = fieldNamesArray[i];

    if (values[fieldName] !== firstValue) {
      fieldNamesArray.forEach(function (errorName) {
        errors[errorName] = validationMessage;
      });
      return;
    }
  }
}

function areNotEquals(errors, values, fieldNamesArray, validationMessage) {
  var firstValue = values[fieldNamesArray[0]];

  for (var i = 1; i < fieldNamesArray.length; i++) {
    var fieldName = fieldNamesArray[i];

    if (values[fieldName] === firstValue) {
      fieldNamesArray.forEach(function (errorName) {
        errors[errorName] = validationMessage;
      });
      return;
    }
  }
}

function isValidPassword(errors, values, fieldName, validationMessage) {
  var valueToCheck = values[fieldName];

  if (!/[a-z]/.test(valueToCheck) || !/[A-Z]/.test(valueToCheck) || !/\d/.test(valueToCheck) || !/[^a-zA-Z\d\s:]/.test(valueToCheck)) {
    errors[fieldName] = validationMessage;
  }
}

function isValidPasswordArray(errors, values, fieldNamesArray, validationMessage) {
  fieldNamesArray.forEach(function (x) {
    isValidPassword(errors, values, x, validationMessage);
  });
}

function isValidNip(value) {
  var nipWithoutDashes = value.trim().replace(/-/g, '');
  var reg = /^[0-9]{10}$/;

  if (!reg.test(nipWithoutDashes)) {
    return false;
  }

  if (nipWithoutDashes === '0000000000') {
    return false;
  }

  var digits = nipWithoutDashes.split('').map(function (s) {
    return parseInt(s, 10);
  });
  var control = (6 * digits[0] + 5 * digits[1] + 7 * digits[2] + 2 * digits[3] + 3 * digits[4] + 4 * digits[5] + 5 * digits[6] + 6 * digits[7] + 7 * digits[8]) % 11;
  return digits[9] === control;
}

function isNip(errors, values, fieldName, validationMessage) {
  var valueToCheck = values[fieldName];

  if (!valueToCheck) {
    return;
  }

  if (!isValidNip(valueToCheck)) {
    errors[fieldName] = validationMessage;
  }
}

function isNipField(errorMessage) {
  return function (value) {
    if (!value) {
      return undefined;
    }

    if (!isValidNip(value)) {
      return errorMessage;
    }

    return undefined;
  };
}

function isValidRegon(value) {
  // REGON is a 9 or 14 digit number. Last digit is control digit from equation:
  // [ sum from 1 to (9 or 14) (x[i]*w[i]) ] mod 11; where x[i] is pointed NIP digit and w[i] is pointed digit
  // from [8 9 2 3 4 5 6 7] for 9 and [2 4 8 5 0 9 7 3 6 1 2 4 8] for 14 digits.
  var n = value.length;
  var w;
  var cd = 0; // Control digit (last digit)

  var isOnlyDigit = /^\d+$/.test(value);

  if (n !== 9 && n !== 14 && !isOnlyDigit) {
    return false; // error
  }

  if (n === 9) {
    w = [8, 9, 2, 3, 4, 5, 6, 7];
  } else {
    w = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
  }

  for (var i = 0; i < n - 1; i++) {
    // eslint-disable-next-line radix
    cd += w[i] * parseInt(value.charAt(i));
  }

  cd %= 11;

  if (cd === 10) {
    cd = 0;
  } // eslint-disable-next-line radix


  if (cd !== parseInt(value.charAt(n - 1))) {
    return false; // not valid
  }

  return true; // valid
}

function isRegon(errors, values, fieldName, validationMessage) {
  var valueToCheck = values[fieldName];

  if (!valueToCheck) {
    return;
  }

  if (!isValidRegon(valueToCheck)) {
    errors[fieldName] = validationMessage;
  }
}

function isRegonField(errorMessage) {
  return function (value) {
    if (!value) {
      return undefined;
    }

    if (!isValidRegon(value)) {
      return errorMessage;
    }

    return undefined;
  };
}

function isDateFromTo(errors, values, fieldNameFrom, fieldNameTo, validationMessage) {
  var valueFrom = values[fieldNameFrom];
  var valueTo = values[fieldNameTo];

  if (!valueFrom || !valueTo) {
    return;
  }

  if (!(0, _moment.default)(valueFrom).isBefore((0, _moment.default)(valueTo), 'minute')) {
    errors[fieldNameFrom] = validationMessage;
    errors[fieldNameTo] = validationMessage;
  }
}

function isNotPastDateField(errorMessage) {
  return function (value) {
    if (!value) {
      return undefined;
    }

    if ((0, _moment.default)(value).isBefore((0, _moment.default)())) {
      return errorMessage;
    }

    return undefined;
  };
}

function maskedInputField(errorMessage) {
  return function (value) {
    if (!value || (0, _isString.default)(value) && !value.trim()) {
      return undefined;
    }

    if (value[value.length - 1] === ' ') {
      return errorMessage;
    }

    return undefined;
  };
}