"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _SpinnerModule = _interopRequireDefault(require("./Spinner.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  className: _propTypes.default.string,
  offset: _propTypes.default.oneOf(['top', 'right', 'bottom', 'left'])
};
var defaultProps = {
  className: '',
  offset: ''
};

var Spinner = function Spinner(_ref) {
  var className = _ref.className,
      offset = _ref.offset;
  var spinnerClassName = (0, _classnames.default)(_SpinnerModule.default.spinner, className, _defineProperty({}, _SpinnerModule.default["offset_".concat(offset)], offset));
  return _react.default.createElement("div", {
    className: spinnerClassName
  });
};

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;
var _default = Spinner;
exports.default = _default;