"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _ToastrModule = _interopRequireDefault(require("./Toastr.module.scss"));

var _Toast = _interopRequireDefault(require("./Toast"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {};
var defaultProps = {};

var Toastr = function Toastr() {
  var notifications = (0, _reactRedux.useSelector)(function (state) {
    return state.toastr.notifications.sort(function (current, next) {
      return current.creationTime - next.creationTime;
    });
  });
  return _react.default.createElement("div", {
    className: _ToastrModule.default.toast_container
  }, notifications.map(function (notification) {
    return _react.default.createElement(_Toast.default, {
      key: notification.id,
      type: notification.type,
      closeTime: notification.duration,
      id: notification.id
    }, notification.message);
  }));
};

Toastr.propTypes = propTypes;
Toastr.defaultProps = defaultProps;
var _default = Toastr;
exports.default = _default;