"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ColoredTime = _interopRequireDefault(require("./ColoredTime"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  from: _propTypes.default.string,
  to: _propTypes.default.string
};
var defaultProps = {
  from: undefined,
  to: undefined
};

function DataFixTimes(_ref) {
  var from = _ref.from,
      to = _ref.to;
  var isFix = !from;
  return _react.default.createElement(_react.default.Fragment, null, isFix ? _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("span", null, _react.default.createElement("span", {
    className: "display-inline-block"
  }, "Data"), ' ', _react.default.createElement("span", {
    className: "label label_orange label_bold display-inline-block"
  }, "FIX")), _react.default.createElement("span", null, _react.default.createElement(_ColoredTime.default, {
    time: to
  }))) : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("span", null, "Od: ", _react.default.createElement(_ColoredTime.default, {
    time: from
  })), _react.default.createElement("span", null, "Do: ", _react.default.createElement(_ColoredTime.default, {
    time: to
  }))));
}

DataFixTimes.propTypes = propTypes;
DataFixTimes.defaultProps = defaultProps;

var _default = (0, _react.memo)(DataFixTimes);

exports.default = _default;