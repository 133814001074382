import foxGridReducer from 'fox-react/dist/components/FoxGrid/reducer';
import gridNames from 'common/gridNames';
import initialState from 'fox-react/dist/components/FoxGrid/initialState';
import actionTypes from './actionTypes';

const gridReducer = foxGridReducer(gridNames.auctions);

const changeIsObserved = (id, isObserved, items) => {
  const itemIndex = items.findIndex(x => x.id === id);

  return items.map((item, index) => {
    if (itemIndex === index) {
      return { ...item, isObserved };
    }

    return item;
  });
};

const updateOneItemInItemsArray = (item, data) => {
  if (data.auctionId !== item.id) return item;
  return {
    ...item,
    currentPrice: data.price,
    lastBidCompanyInstanceId: data.companyInstanceId,
  };
};
const auctionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_OBSERVED_AUCTION:
      return {
        ...state,
        results: {
          ...state.results,
          items: [
            ...changeIsObserved(
              action.data.id,
              action.data.isObserved,
              state.results.items,
            ),
          ],
        },
      };
    case actionTypes.AUCTIONS_SET_FILTER:
      return {
        ...state,
        dataState: {
          ...state.dataState,
          filter: action.payload,
          skip: 0,
        },
      };
    case actionTypes.AUCTIONS_LIVE_AUCTIONS_UPDATE:
      return {
        ...state,
        results: {
          ...state.results,
          items: state.results.items.map(x =>
            updateOneItemInItemsArray(x, action.payload),
          ),
        },
      };
    default:
      return gridReducer(state, action);
  }
};

export default auctionsReducer;
