import { decodeJwt, isExpired } from 'fox-react/dist/utils/jwt';
import { logout } from './actions';
import refreshToken from './refreshToken';

export default function checkLoginValidity(store) {
  const jwtToken = localStorage.getItem('accessToken');
  if (!jwtToken) {
    if (store.getState().auth.currentUser) {
      store.dispatch(logout());
    }
    return;
  }
  const decodedToken = decodeJwt(jwtToken);
  if (isExpired(decodedToken.exp)) {
    refreshToken().catch(() => {
      store.dispatch(logout());
    });
  }
}
