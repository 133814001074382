"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _FormSectionButtonRowModule = _interopRequireDefault(require("./FormSectionButtonRow.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  additionalClassNames: _propTypes.default.string
};
var defaultProps = {
  additionalClassNames: ''
};

function FormSectionButtonRow(_ref) {
  var children = _ref.children,
      additionalClassNames = _ref.additionalClassNames;
  return _react.default.createElement("div", {
    className: "".concat(_FormSectionButtonRowModule.default.button_row).concat(additionalClassNames)
  }, children);
}

FormSectionButtonRow.propTypes = propTypes;
FormSectionButtonRow.defaultProps = defaultProps;
var _default = FormSectionButtonRow;
exports.default = _default;