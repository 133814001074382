const formNames = {
  loginForm: 'loginForm',
  resetPasswordForm: 'resetPasswordForm',
  setNewPasswordForm: 'setNewPasswordForm',
  userInformationForm: 'userInformationForm',
  userPasswordChangeForm: 'userPasswordChangeForm',
  myProfileForm: 'myProfileForm',
  transportForm: 'transportForm',
  userSettingsNotificationsForm: 'userSettingsNotificationsForm',
  driverForm: 'driverForm',
  carForm: 'carForm',
  companyForm: 'companyForm',
  implementationDialogForm: 'implementationDialogForm',
};

export default formNames;
