"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  label: _propTypes.default.string,
  value: _propTypes.default.string,
  placeholder: _propTypes.default.string,
  onChange: _propTypes.default.func.isRequired,
  onBlur: _propTypes.default.func.isRequired,
  invalid: _propTypes.default.bool,
  autoFocus: _propTypes.default.bool,
  type: _propTypes.default.string,
  required: _propTypes.default.bool,
  disabled: _propTypes.default.bool,
  name: _propTypes.default.string.isRequired
};
var defaultProps = {
  label: '',
  placeholder: undefined,
  invalid: false,
  autoFocus: false,
  type: 'text',
  required: false,
  disabled: false,
  value: undefined
};

function FoxInputText(_ref) {
  var label = _ref.label,
      value = _ref.value,
      placeholder = _ref.placeholder,
      onChange = _ref.onChange,
      _onBlur = _ref.onBlur,
      invalid = _ref.invalid,
      autoFocus = _ref.autoFocus,
      type = _ref.type,
      required = _ref.required,
      disabled = _ref.disabled,
      name = _ref.name;
  var inputClassName = (0, _classnames.default)('k-textbox', {
    'k-state-invalid': invalid
  });
  return _react.default.createElement("input", {
    type: type,
    label: label,
    value: value,
    placeholder: placeholder,
    onChange: onChange,
    onBlur: function onBlur() {
      return _onBlur();
    },
    autoFocus: autoFocus,
    required: required,
    className: inputClassName,
    disabled: disabled,
    name: name
  });
}

FoxInputText.propTypes = propTypes;
FoxInputText.defaultProps = defaultProps;
var _default = FoxInputText;
exports.default = _default;