import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getForGrid as getForGridTransportOrder } from 'transport-orders/actions';
import transporOrderActionTypes from 'transport-orders/actionTypes';
import {
  transportOrderLoadedFilter,
  transportOrderAcceptedFilter,
  transportOrderCompletedFilter,
} from 'transport-orders/filters';
import lang from 'hh-shared/dist/language/services/languageService';
import Row from 'hh-shared/dist/components/layout/Row';
import DashboardCardBox from './DashboardCardBox';
import styles from './DashboardCardBoxes.module.scss';

const propTypes = {
  acceptedCount: PropTypes.number,
  loadedCount: PropTypes.number,
  realizedCount: PropTypes.number,
};

const defaultProps = {
  acceptedCount: 0,
  loadedCount: 0,
  realizedCount: 0,
};

const OrdersCardBoxes = ({ acceptedCount, loadedCount, realizedCount }) => {
  const dispatch = useDispatch();
  const dataState = useSelector(state => state.transportOrders.dataState);
  const boxContainerClassName = cx(
    styles.box_container,
    'justify-content-start',
  );

  const filterChange = filter => {
    dispatch(
      getForGridTransportOrder({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: transporOrderActionTypes.TRANSPORT_ORDER_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchAccepted = () => filterChange(transportOrderAcceptedFilter);
  const dispatchLoaded = () => filterChange(transportOrderLoadedFilter);
  const dispatchCompleted = () => filterChange(transportOrderCompletedFilter);

  return (
    <Row className={boxContainerClassName}>
      <DashboardCardBox
        quantity={acceptedCount}
        label={lang.labels.AcceptedTransportOrderStatus()}
        onClick={dispatchAccepted}
        link="/transport-orders"
      />
      <DashboardCardBox
        quantity={loadedCount}
        label={lang.labels.LoadedTransportOrderStatus()}
        onClick={dispatchLoaded}
        link="/transport-orders"
      />
      <DashboardCardBox
        quantity={realizedCount}
        label={lang.labels.CompletedTransportOrderStatus()}
        onClick={dispatchCompleted}
        link="/transport-orders"
      />
    </Row>
  );
};

OrdersCardBoxes.propTypes = propTypes;
OrdersCardBoxes.defaultProps = defaultProps;

export default OrdersCardBoxes;
