"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayCompletionToast = exports.displayCompletionToasts = exports.scrollToTheFirstError = void 0;

var _toastrActions = require("../toastr/toastrActions");

var _ToastrTypes = _interopRequireDefault(require("../toastr/ToastrTypes"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var elementIsVisible = function elementIsVisible(element) {
  var elRect = element.getBoundingClientRect();
  var currentWindowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(elRect.bottom < 0 || elRect.top - currentWindowHeight >= 0);
};

var scrollToTheFirstError = function scrollToTheFirstError() {
  setTimeout(function () {
    var firstError = document.querySelector('.k-state-invalid');
    firstError && function () {
      var errorFieldLabel = firstError.closest('.k-form-field');
      errorFieldLabel && !elementIsVisible(errorFieldLabel) && errorFieldLabel.scrollIntoView({
        behavior: 'smooth'
      });
    }();
  }, 250);
};

exports.scrollToTheFirstError = scrollToTheFirstError;

var displayCompletionToasts = function displayCompletionToasts(dispatch, isEditForm, editFormText) {
  var addFormText = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  var toastMessage = isEditForm ? editFormText : addFormText;
  dispatch((0, _toastrActions.displayToastr)(toastMessage, _ToastrTypes.default.info));
};

exports.displayCompletionToasts = displayCompletionToasts;

var displayCompletionToast = function displayCompletionToast(dispatch, toastMessage) {
  dispatch((0, _toastrActions.displayToastr)(toastMessage, _ToastrTypes.default.info));
};

exports.displayCompletionToast = displayCompletionToast;