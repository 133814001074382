import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import lang from 'hh-shared/dist/language/services/languageService';
import FoxCustomDialog from 'fox-react/dist/components/FoxCustomDialog';
import FoxInputTextarea from 'fox-react/dist/components/FoxInputTextarea';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSendClick: PropTypes.func.isRequired,
};

const defaultProps = {};

const TransportOrderAddMessageDialog = ({
  isOpen,
  onCloseClick,
  onSendClick,
}) => {
  const [message, setMessage] = useState('');

  return (
    <FoxCustomDialog
      title={lang.labels.TransportOrderDialog_AddNote()}
      isOpen={isOpen}
      onCloseClick={onCloseClick}
      className="blue"
      noLabel={lang.labels.Cancel()}
      yesLabel={lang.labels.Save()}
      onConfirmClick={() => onSendClick(message.trim())}
      bigger
    >
      <FoxInputTextarea
        placeholder={lang.labels.TransportOrderDialog_ProvideMessage()}
        value={message}
        onChange={e => setMessage(e.target.value)}
        className="full-width margin-top"
      />
      {!message.trim() && (
        <div className="invalid-message">
          {lang.labels.TransportOrderDialog_ErrorMessage()}
        </div>
      )}
    </FoxCustomDialog>
  );
};

TransportOrderAddMessageDialog.propTypes = propTypes;
TransportOrderAddMessageDialog.defaultProps = defaultProps;

export default TransportOrderAddMessageDialog;
