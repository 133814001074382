"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Column = _interopRequireDefault(require("layout/Column"));

var _FormCardWrapperModule = _interopRequireDefault(require("./FormCardWrapper.module.scss"));

require("./FormCardWrapperTheming.scss");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  className: _propTypes.default.string
};
var defaultProps = {
  className: ''
};

var FormCardWrapper = function FormCardWrapper(_ref) {
  var children = _ref.children,
      className = _ref.className;
  return _react.default.createElement(_Column.default, {
    biggerPreview: true
  }, _react.default.createElement("div", {
    className: "".concat(_FormCardWrapperModule.default.card_wrapper, " card-wrapper ").concat(className)
  }, children));
};

FormCardWrapper.propTypes = propTypes;
FormCardWrapper.defaultProps = defaultProps;
var _default = FormCardWrapper;
exports.default = _default;