"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRouterDom = require("react-router-dom");

var _Column = _interopRequireDefault(require("layout/Column"));

var _Row = _interopRequireDefault(require("layout/Row"));

var _languageService = _interopRequireDefault(require("hh-shared/dist/language/services/languageService"));

var _IconArea = _interopRequireDefault(require("hh-shared/dist/components/commons/IconArea"));

var _icons = _interopRequireDefault(require("hh-shared/dist/consts/icons"));

var _TransportStatusLabel = _interopRequireDefault(require("hh-shared/dist/components/transport-order/TransportStatusLabel"));

var _MainInfoModule = _interopRequireDefault(require("./MainInfo.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  data: _propTypes.default.object.isRequired,
  auctionPreviewUrl: _propTypes.default.string.isRequired
};
var defaultProps = {};

function MainInfo(_ref) {
  var data = _ref.data,
      auctionPreviewUrl = _ref.auctionPreviewUrl; // TODO Vorbert -> Zredukować ilość inline-class

  var inlineSmallLabelClassName = (0, _classnames.default)('display-inline', _MainInfoModule.default.label_small);
  var tripleRowClassName = (0, _classnames.default)('justify-content-space-between', 'triple-row');
  var transportOrderRowClassName = (0, _classnames.default)(tripleRowClassName, _MainInfoModule.default.header__row);

  var getAuctionPreviewUrl = function getAuctionPreviewUrl(auctionId) {
    return "".concat(auctionPreviewUrl, "/").concat(auctionId);
  };

  var companyNameClassName = (0, _classnames.default)(_MainInfoModule.default.company_data, _MainInfoModule.default.company_data_name);
  var companyLocationClassName = (0, _classnames.default)(_MainInfoModule.default.company_data, _MainInfoModule.default.company_data_location);
  var colorfullLabel = (0, _classnames.default)('label', 'label_orange', 'label_bold', _MainInfoModule.default.label_small);
  var statusBadgeClassName = (0, _classnames.default)('align-self', 'align-self-start', 'flex-unset', _MainInfoModule.default.left_offset);
  return _react.default.createElement("div", {
    className: _MainInfoModule.default.header
  }, _react.default.createElement(_Column.default, {
    className: "content_column"
  }, _react.default.createElement(_Row.default, {
    className: transportOrderRowClassName
  }, _react.default.createElement(_Column.default, null, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.order,
    iconTitle: _languageService.default.labels.TransportOrder()
  }, _react.default.createElement("span", null, _languageService.default.labels.TransportOrder(), ":"), _react.default.createElement("div", {
    className: _MainInfoModule.default.label_header
  }, data.number))), _react.default.createElement("div", null), data.auctionNumber && _react.default.createElement(_IconArea.default, {
    icon: _icons.default.auction,
    iconTitle: _languageService.default.labels.AuctionNumber()
  }, _react.default.createElement("span", null, _languageService.default.labels.AuctionNumber()), _react.default.createElement("div", {
    className: _MainInfoModule.default.label_header
  }, data.auctionId ? _react.default.createElement(_reactRouterDom.Link, {
    className: "link white",
    to: getAuctionPreviewUrl(data.auctionId),
    title: "".concat(_languageService.default.labels.LinkToTheAuction(), " ").concat(data.auctionNumber)
  }, data.auctionNumber) : data.auctionNumber))), _react.default.createElement(_Row.default, {
    className: tripleRowClassName
  }, _react.default.createElement("div", null, _react.default.createElement(_TransportStatusLabel.default, {
    statusId: data.status.id,
    className: statusBadgeClassName
  })), _react.default.createElement("div", null), data.navNumber && _react.default.createElement(_IconArea.default, {
    icon: _icons.default.fileData,
    iconTitle: _languageService.default.labels.TransportOrderNavNumber(),
    className: "align-self-start"
  }, _react.default.createElement("span", null, _languageService.default.labels.TransportOrderNavNumber(), ":"), _react.default.createElement("div", {
    className: _MainInfoModule.default.label_header
  }, data.navNumber))), _react.default.createElement(_Row.default, {
    className: tripleRowClassName
  }, _react.default.createElement(_IconArea.default, {
    icon: _icons.default.facility,
    iconTitle: _languageService.default.labels.PrincipalPerson(),
    alignTop: true
  }, _react.default.createElement("span", null, "".concat(_languageService.default.labels.PrincipalPerson(), ":")), _react.default.createElement("div", {
    className: companyNameClassName
  }, data.depFullName), _react.default.createElement("div", {
    className: companyLocationClassName
  }, data.depZipCode, " ", data.depCity), _react.default.createElement("div", {
    className: companyLocationClassName
  }, data.depStreet), _react.default.createElement("div", null, _react.default.createElement("span", {
    className: colorfullLabel
  }, "NIP: "), _react.default.createElement("span", {
    className: inlineSmallLabelClassName
  }, data.depNip))), _react.default.createElement("div", null), _react.default.createElement(_IconArea.default, {
    icon: _icons.default.company,
    iconTitle: _languageService.default.labels.MandatoryCompany(),
    alignTop: true
  }, _react.default.createElement("span", null, _languageService.default.labels.MandatoryCompany(), ":"), _react.default.createElement("div", {
    className: companyNameClassName
  }, data.mandatoryCompany.name), _react.default.createElement("div", {
    className: companyLocationClassName
  }, data.mandatoryCompany.zipCode, " ", data.mandatoryCompany.city), _react.default.createElement("div", {
    className: companyLocationClassName
  }, data.mandatoryCompany.street), _react.default.createElement("div", null, _react.default.createElement("span", {
    className: colorfullLabel
  }, "NIP: "), _react.default.createElement("span", {
    className: inlineSmallLabelClassName
  }, data.mandatoryCompany.nip))))));
}

MainInfo.propTypes = propTypes;
MainInfo.defaultProps = defaultProps;
var _default = MainInfo;
exports.default = _default;