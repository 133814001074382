import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './BidsSection.module.scss';
import BidsTable from './BidsTable';
import CurrentPriceSection from './CurrentPriceSection';

const propTypes = {};

const BidsSection = () => {
  const { auction } = useSelector(state => state);
  const { bids } = auction;
  const sectionRef = useRef();
  const { auctionMetric } = auction;

  // TODO -> otrzymać dane dotyczące osób zaproszonych do udziału w licytacji oraz flagi, czy już chociaz raz złożyli ofertę licytacji w aktualnej aukcji.

  return (
    <div className={styles.bids} ref={sectionRef}>
      <CurrentPriceSection />
      <BidsTable bids={bids} priceStep={auctionMetric.priceStep} />
      {/* Odkomentować poniższą linijke, po fixie nazw firm */}
      {/* {auctionTypes.nonPublic === auctionMetric.auctionTypeId && (
        <BidsParticipantsTable auctionId={props.auctionId} />
      )} */}
    </div>
  );
};

BidsSection.propTypes = propTypes;
export default BidsSection;
