"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useInterval = useInterval;

var _react = require("react"); // TODO Vorbert -> ujednolicić z tymi z constów


function useInterval(callback, delay) {
  var savedCallback = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    savedCallback.current = callback;
  }, [callback]);
  (0, _react.useEffect)(function () {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      var id = setInterval(tick, delay);
      return function () {
        return clearInterval(id);
      };
    }

    return undefined;
  }, [delay]);
}