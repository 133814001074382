"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactAvatarEdit = _interopRequireDefault(require("react-avatar-edit"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  onClose: _propTypes.default.func,
  onChange: _propTypes.default.func.isRequired
};
var defaultProps = {
  onClose: function onClose() {}
};

function FoxAvatarEdit(_ref) {
  var onClose = _ref.onClose,
      onChange = _ref.onChange;

  var crop = function crop(cropped) {
    var fakeE = {
      target: {
        value: cropped
      }
    };
    onChange(fakeE);
  };

  return _react.default.createElement(_reactAvatarEdit.default, {
    width: 200,
    height: 200,
    onCrop: crop,
    onClose: onClose
  });
}

FoxAvatarEdit.propTypes = propTypes;
FoxAvatarEdit.defaultProps = defaultProps;
var _default = FoxAvatarEdit;
exports.default = _default;