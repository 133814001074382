"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _kendoReactGrid = require("@progress/kendo-react-grid");

var FoxGridColumn = _kendoReactGrid.GridColumn;
var _default = FoxGridColumn;
exports.default = _default;