import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import icons from 'hh-shared/dist/consts/icons';
import lang from 'hh-shared/dist/language/services/languageService';
import NumericCell from 'hh-shared/dist/components/grid/NumericCell';
import AddressCell from 'hh-shared/dist/components/grid/AddressCell';
import DateTimeColumnCell from 'hh-shared/dist/components/grid/DateTimeColumnCell';
import DropdownLinkCell from 'hh-shared/dist/components/commons/Dropdown/DropdownLinkCell';
import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import { downloadOrderPDF } from 'hh-shared/dist/utils/downloadMethods';
import PhoneNumberCell from 'hh-shared/dist/components/grid/PhoneNumberCell';

import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import gridNames from 'common/gridNames';
import columnNames from 'common/columnNames';
import OrderStatusClickableCell from 'commons/OrderStatusClickableCell';

import { getForGrid } from './actions';
import TransportOrdersSrv from './TransportOrdersSrv';

const propTypes = { match: PropTypes.object.isRequired };

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.transportOrders);

function TransportOrdersPage({ match }) {
  const dispatch = useDispatch();
  const onLoadData = dataState => dispatch(getForGrid(dataState));
  const [showSpinner, setShowSpinner] = useState(false);

  const downloadPdf = (id, orderNumber) => {
    setShowSpinner(true);

    TransportOrdersSrv.getCompletedTransportOrderPdf(id)
      .then(orderPDF => downloadOrderPDF(orderPDF, orderNumber))
      .finally(() => setShowSpinner(false));
  };

  return (
    <InnerContentWrapper>
      <div className={showSpinner ? 'fox_spinner' : undefined}>
        <FoxGridContainer onLoadData={onLoadData}>
          <FoxGridColumn
            field={columnNames.transportOrders.number}
            title={lang.labels.TransportOrdersManagement_Columns_Number()}
            filter="text"
            locked
            cell={({ dataItem, className }) => (
              <DropdownLinkCell
                linkTitle={lang.labels.Preview()}
                text={dataItem.number}
                link={`${match.url}/${dataItem.id}`}
                className={className}
                buttons={[
                  {
                    icon: icons.solidDownload,
                    label: lang.labels.DownloadPdf(),
                    onClick: () => downloadPdf(dataItem.id, dataItem.number),
                  },
                ]}
              />
            )}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.status}
            title={lang.labels.TransportOrdersManagement_Columns_Status()}
            filter="text"
            cell={({ dataItem }) => (
              <OrderStatusClickableCell
                text={dataItem.status}
                statusId={dataItem.statusId}
              />
            )}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.value}
            title={lang.labels.TransportOrdersManagement_Columns_Value()}
            filter="numeric"
            cell={({ dataItem }) => <NumericCell value={dataItem.value} />}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.loadingTime}
            title={lang.labels.TransportOrdersManagement_Columns_LoadingTime()}
            filter="date"
            filterable={false}
            cell={DateTimeColumnCell}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.loadingAddress}
            title={lang.labels.TransportOrdersManagement_Columns_LoadingAddress()}
            filter="text"
            cell={AddressCell}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.unloadingTime}
            title={lang.labels.TransportOrdersManagement_Columns_UnloadingTime()}
            filter="date"
            filterable={false}
            cell={DateTimeColumnCell}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.unloadingAddress}
            title={lang.labels.TransportOrdersManagement_Columns_UnloadingAddress()}
            filter="text"
            cell={AddressCell}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.driver}
            title={lang.labels.TransportOrdersManagement_Columns_Driver()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.transportOrders.authorizedPickupPerson}
            title={lang.labels.TransportOrdersManagement_Columns_AuthorizedPickupPerson()}
            filter="text"
          />
          <FoxGridColumn
            field={
              columnNames.transportOrders.authorizedPickupPersonPhoneNumber
            }
            title={lang.labels.TransportOrdersManagement_Columns_AuthorizedPickupPersonPhoneNumber()}
            filter="text"
            cell={({ dataItem }) => (
              <PhoneNumberCell
                phoneNumber={dataItem.authorizedPickupPersonPhoneNumber}
              />
            )}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.distance}
            title={lang.labels.TransportOrdersManagement_Columns_Distance()}
            filter="numeric"
            cell={({ dataItem }) => <NumericCell value={dataItem.distance} />}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.car}
            title={lang.labels.TransportOrdersManagement_Columns_Car()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.transportOrders.capacity}
            title={lang.labels.TransportOrdersManagement_Columns_Capacity()}
            filter="numeric"
            cell={({ dataItem }) => <NumericCell value={dataItem.capacity} />}
          />
          <FoxGridColumn
            field={columnNames.transportOrders.loadingMethod}
            title={lang.labels.TransportOrdersManagement_Columns_LoadingMethod()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.transportOrders.unloadingMethod}
            title={lang.labels.TransportOrdersManagement_Columns_UnloadingMethod()}
            filter="text"
          />
          <FoxGridColumn
            field={columnNames.transportOrders.auctionNumber}
            title={lang.labels.TransportOrdersManagement_Columns_AuctionNumber()}
            filter="text"
          />
        </FoxGridContainer>
      </div>
    </InnerContentWrapper>
  );
}

TransportOrdersPage.propTypes = propTypes;
TransportOrdersPage.defaultProps = defaultProps;

export default TransportOrdersPage;
