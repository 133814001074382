"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactDropdowns = require("@progress/kendo-react-dropdowns");

var _kendoDataQuery = require("@progress/kendo-data-query");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  placeholder: _propTypes.default.string,
  value: _propTypes.default.any,
  onChange: _propTypes.default.func.isRequired,
  items: _propTypes.default.array.isRequired,
  autocompleteMinLength: _propTypes.default.number,
  invalid: _propTypes.default.bool,
  name: _propTypes.default.string.isRequired,
  itemRender: _propTypes.default.func,
  textField: _propTypes.default.string
};
var defaultProps = {
  placeholder: '',
  autocompleteMinLength: 2,
  invalid: false,
  value: undefined,
  itemRender: undefined,
  textField: undefined
};
var timeout;

function filterData(value, source, field) {
  var data = source;
  var filter = {
    value: value,
    operator: 'contains',
    ignoreCase: true,
    field: field
  };
  return (0, _kendoDataQuery.filterBy)(data, filter);
}

function FoxAutocomplete(_ref) {
  var value = _ref.value,
      onChange = _ref.onChange,
      items = _ref.items,
      placeholder = _ref.placeholder,
      autocompleteMinLength = _ref.autocompleteMinLength,
      invalid = _ref.invalid,
      name = _ref.name,
      itemRender = _ref.itemRender,
      textField = _ref.textField;

  var _useState = (0, _react.useState)(items),
      _useState2 = _slicedToArray(_useState, 2),
      data = _useState2[0],
      setData = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = _slicedToArray(_useState3, 2),
      loading = _useState4[0],
      setLoading = _useState4[1];

  var handleChange = function handleChange(e) {
    onChange(e);
    clearTimeout(timeout);

    if (e.target.value && e.target.value.length >= autocompleteMinLength) {
      timeout = setTimeout(function () {
        setData(filterData(e.target.value, items, textField));
        setLoading(false);
      }, 500);
      setLoading(true);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  var className = (0, _classnames.default)({
    'k-state-invalid': invalid
  });
  return _react.default.createElement(_kendoReactDropdowns.AutoComplete, {
    data: data,
    value: value,
    onChange: handleChange,
    placeholder: placeholder,
    loading: loading,
    className: className,
    name: name,
    itemRender: itemRender,
    textField: textField
  });
}

FoxAutocomplete.propTypes = propTypes;
FoxAutocomplete.defaultProps = defaultProps;
var _default = FoxAutocomplete;
exports.default = _default;