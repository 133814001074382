"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeJwt = decodeJwt;
exports.isExpired = isExpired;

var _jwtDecode = _interopRequireDefault(require("jwt-decode"));

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // eslint-disable-next-line camelcase
// eslint-disable-next-line import/prefer-default-export


function decodeJwt(token) {
  return (0, _jwtDecode.default)(token);
}

function getUnitNowTimeStamp() {
  return Math.floor(_moment.default.now() / 1000);
}

function isExpired(tokenExp) {
  return tokenExp < getUnitNowTimeStamp();
}