import React from 'react';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { reduxForm } from 'redux-form';
import formNames from 'common/formNames';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import PropTypes from 'prop-types';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import icons from 'hh-shared/dist/consts/icons';
import phoneMask from 'hh-shared/dist/utils/phoneMask';
import FoxMaskedInputTextField from 'fox-react/dist/reduxFormFields/FoxMaskedInputTextField';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

const defaultProps = {};

function DriverForm({ handleSubmit, submitting, title }) {
  return (
    <div className="form-container">
      <Form handleSubmit={handleSubmit}>
        <fieldset>
          <SectionWrapper>
            <FormSectionCard title={title} titleIcon={icons.driver}>
              <FoxInputTextField
                label={lang.labels.FirstName()}
                placeholder={lang.labels.FirstName()}
                name="firstName"
                validate={isRequiredFieldValidator}
                required
              />

              <FoxInputTextField
                label={lang.labels.LastName()}
                placeholder={lang.labels.LastName()}
                name="lastName"
                validate={isRequiredFieldValidator}
                required
              />

              <FoxMaskedInputTextField
                {...phoneMask}
                label={lang.labels.PhoneNumber()}
                placeholder={lang.labels.PhoneNumber()}
                name="phoneNumber"
              />
            </FormSectionCard>
          </SectionWrapper>
        </fieldset>
        <FoxButton primary disabled={submitting}>
          {lang.buttons.Save()}
        </FoxButton>
      </Form>
    </div>
  );
}

DriverForm.propTypes = propTypes;
DriverForm.defaultProps = defaultProps;

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedDriver(),
    lang.labels.SuccessfullyCreatedDriver(),
  );

export default reduxForm({
  form: formNames.driverForm,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(DriverForm));
