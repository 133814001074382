"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _buttonsResources = _interopRequireDefault(require("../resources/buttonsResources"));

var _errorsResources = _interopRequireDefault(require("../resources/errorsResources"));

var _labelsResources = _interopRequireDefault(require("../resources/labelsResources"));

var _validationMessagesResources = _interopRequireDefault(require("../resources/validationMessagesResources"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _default = {
  buttons: _buttonsResources.default,
  errors: _errorsResources.default,
  labels: _labelsResources.default,
  validationMessages: _validationMessagesResources.default
};
exports.default = _default;