import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { isRequiredArray } from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FormField from 'fox-react/dist/components/FormField';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxInputPasswordField from 'fox-react/dist/reduxFormFields/FoxInputPasswordField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import lang from 'hh-shared/dist/language/services/languageService';
import styles from './LoginForm.module.scss';
import './LoginFormTheming.scss';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

function LoginForm({ handleSubmit, submitting }) {
  return (
    <div
      className={['login_form_container', styles.login_form_container].join(
        ' ',
      )}
    >
      <Form handleSubmit={handleSubmit}>
        <FoxInputTextField
          label={lang.labels.EmailOrLogin()}
          placeholder={lang.labels.EmailOrLogin()}
          name={fieldNames.loginForm.emailOrLogin}
        />

        <FoxInputPasswordField
          label={lang.labels.Password()}
          placeholder={lang.labels.Password()}
          name={fieldNames.loginForm.password}
        />

        <FormField>
          <Link to="/reset-password">
            <span className="k-icon k-i-lock" />
            {lang.labels.LoginPage_ForgotPassword()}
          </Link>
        </FormField>

        <FoxButton primary disabled={submitting}>
          {lang.buttons.LogIn()}
        </FoxButton>
      </Form>
    </div>
  );
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [fieldNames.loginForm.emailOrLogin, fieldNames.loginForm.password],
    lang.validationMessages.FieldRequired(),
  );

  return errors;
};

export default reduxForm({
  form: formNames.loginForm,
  validate,
})(LoginForm);
