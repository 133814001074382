import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';

import lang from 'hh-shared/dist/language/services/languageService';
import icons from 'hh-shared/dist/consts/icons';
import Column from 'hh-shared/dist/components/layout/Column';
import Row from 'hh-shared/dist/components/layout/Row';
import { getForGrid as getForGridTransportOrder } from 'transport-orders/actions';
import transporOrderActionTypes from 'transport-orders/actionTypes';
import { transportOrderPlacedFilter } from 'transport-orders/filters';

import OrderCardBoxes from './OrderCardBoxes';
import styles from './DashboardCard.module.scss';

const propTypes = {
  ordersData: PropTypes.shape({
    placedCount: PropTypes.number,
    acceptedCount: PropTypes.number,
    loadedCount: PropTypes.number,
    realizedCount: PropTypes.number,
  }),
  className: PropTypes.string,
};

const defaultProps = {
  ordersData: undefined,
  className: '',
};

const OrdersDashboardCard = ({ ordersData, className }) => {
  const cardContainerClassName = cx(styles.card_container, className);
  const timeLeftClassName = cx(styles.event_time_left, 'align-items-end');

  const dispatch = useDispatch();
  const dataState = useSelector((state) => state.transportOrders.dataState);

  const hasActiveCompany = useSelector((state) =>
    get(state, 'auth.currentUser.isCompanyActive'),
  );

  const changeFilter = (filter) => {
    dispatch(
      getForGridTransportOrder({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: transporOrderActionTypes.TRANSPORT_ORDER_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchPlaced = () => changeFilter(transportOrderPlacedFilter);

  const getQuantityForActiveCompany = (value) => (hasActiveCompany ? value : 0);

  const placedTodayCount =
    getQuantityForActiveCompany(ordersData && ordersData.placedCount) || 0;

  return (
    <Column className={cardContainerClassName}>
      <Row className="align-items-center">
        <FontAwesomeIcon
          icon={icons.order}
          title={lang.labels.Orders()}
          className={styles.icon}
        />
        <div className={styles.title}>{lang.labels.Orders()}</div>
      </Row>
      <Column className={styles.event}>
        <div className={styles.event_title}>
          {lang.labels.ForTodayYouHavePlaced()}:
        </div>
        <Link
          title={lang.labels.GoToTheEvent()}
          className={timeLeftClassName}
          onClick={dispatchPlaced}
          to="/transport-orders"
        >
          {`${placedTodayCount} ${
            placedTodayCount !== 1
              ? lang.labels.OrdersDashboard()
              : lang.labels.Order()
          }`}
          <FontAwesomeIcon
            icon={icons.arrowRight}
            title={lang.labels.GoToTheEvent()}
            className={styles.event_link_arrow}
          />
        </Link>
      </Column>
      <OrderCardBoxes {...ordersData} />
    </Column>
  );
};

OrdersDashboardCard.propTypes = propTypes;
OrdersDashboardCard.defaultProps = defaultProps;

export default OrdersDashboardCard;
