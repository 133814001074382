"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  label: _propTypes.default.string,
  value: _propTypes.default.string.isRequired,
  placeholder: _propTypes.default.string,
  onChange: _propTypes.default.func.isRequired,
  onBlur: _propTypes.default.func,
  required: _propTypes.default.bool,
  className: _propTypes.default.string,
  disabled: _propTypes.default.bool
};
var defaultProps = {
  label: '',
  placeholder: undefined,
  required: false,
  className: '',
  disabled: false,
  onBlur: function onBlur() {}
};

function FoxInputTextarea(_ref) {
  var label = _ref.label,
      value = _ref.value,
      placeholder = _ref.placeholder,
      onChange = _ref.onChange,
      _onBlur = _ref.onBlur,
      required = _ref.required,
      className = _ref.className,
      disabled = _ref.disabled;
  var textareaClassName = (0, _classnames.default)('k-textarea', className);
  return _react.default.createElement("textarea", {
    className: textareaClassName,
    label: label,
    value: value,
    placeholder: placeholder,
    onChange: onChange,
    onBlur: function onBlur() {
      return _onBlur();
    },
    required: required,
    disabled: disabled
  });
}

FoxInputTextarea.propTypes = propTypes;
FoxInputTextarea.defaultProps = defaultProps;
var _default = FoxInputTextarea;
exports.default = _default;