import React from 'react';
import PropTypes from 'prop-types';
import Row from 'layout/Row';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import styles from './NotificationSectionTitle.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
};

const defualtProps = {};

const NotificationSectionTitle = ({ title, icon }) => {
  return (
    <Row className={`${styles.section_title} align-items align-items-end`}>
      <FontAwesomeIcon icon={icon} title={title} className={styles.icon} />
      <div className={styles.title}>{title}</div>
    </Row>
  );
};

NotificationSectionTitle.propTypes = propTypes;
NotificationSectionTitle.defualtProps = defualtProps;

export default NotificationSectionTitle;
