"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactRedux = require("react-redux");

var _toastrActions = require("../../toastr/toastrActions");

var _ToastrTypes = _interopRequireDefault(require("../../toastr/ToastrTypes"));

var _FontAwesomeIcon = _interopRequireDefault(require("./FontAwesomeIcon/FontAwesomeIcon"));

var _Row = _interopRequireDefault(require("../layout/Row"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _AttachmentModule = _interopRequireDefault(require("./Attachment.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var propTypes = {
  fileName: _propTypes.default.string.isRequired,
  onDownload: _propTypes.default.func.isRequired,
  onRemove: _propTypes.default.func
};
var defaultProps = {
  onRemove: undefined
};

var Attachment = function Attachment(_ref) {
  var fileName = _ref.fileName,
      onDownload = _ref.onDownload,
      onRemove = _ref.onRemove;
  var dispatch = (0, _reactRedux.useDispatch)();
  var downloadIconClassName = (0, _classnames.default)(_AttachmentModule.default.attachment_icon, _AttachmentModule.default.attachment_icon_download);
  var removeIconClassName = (0, _classnames.default)(_AttachmentModule.default.attachment_icon, _AttachmentModule.default.attachment_icon_remove);

  var handleDownload = function handleDownload() {
    onDownload();
    dispatch((0, _toastrActions.displayToastr)("".concat(_languageService.default.labels.SuccessfullyDownloadedAttachment(), ": \"").concat(fileName, "\""), _ToastrTypes.default.info));
  };

  var handleRemove = function handleRemove() {
    onRemove();
    dispatch((0, _toastrActions.displayToastr)("".concat(_languageService.default.labels.SuccessfullyRemovedAttachment(), ": \"").concat(fileName, "\""), _ToastrTypes.default.info));
  };

  return _react.default.createElement(_Row.default, {
    className: _AttachmentModule.default.attachment
  }, _react.default.createElement("div", {
    className: _AttachmentModule.default.attachment_name
  }, fileName), _react.default.createElement("div", {
    className: _AttachmentModule.default.attachment_icon_container
  }, _react.default.createElement(_FontAwesomeIcon.default, {
    icon: _icons.default.download,
    title: _languageService.default.buttons.Download(),
    className: downloadIconClassName,
    onClick: handleDownload
  }), onRemove && _react.default.createElement(_FontAwesomeIcon.default, {
    icon: _icons.default.trash,
    title: _languageService.default.buttons.RemoveCompanyAttachment(),
    className: removeIconClassName,
    onClick: handleRemove
  })));
};

Attachment.propTypes = propTypes;
Attachment.defaultProps = defaultProps;

var _default = (0, _react.memo)(Attachment);

exports.default = _default;