"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ProgressBarModule = _interopRequireDefault(require("./ProgressBar.module.scss"));

var _ProgessBarFilling = _interopRequireDefault(require("./ProgessBarFilling"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  className: _propTypes.default.string,
  fillnessPercentage: _propTypes.default.number.isRequired,
  color: _propTypes.default.oneOf(['orange', 'red', 'green', 'blue'])
};
var defaultProps = {
  className: '',
  color: 'blue'
};

var ProgressBar = function ProgressBar(_ref) {
  var className = _ref.className,
      fillnessPercentage = _ref.fillnessPercentage,
      color = _ref.color;
  return _react.default.createElement("div", {
    className: "".concat(_ProgressBarModule.default.progress_bar_container, " ").concat(className)
  }, _react.default.createElement(_ProgessBarFilling.default, {
    fillnessPercentage: fillnessPercentage >= 0 ? fillnessPercentage : 0,
    color: color
  }));
};

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;
var _default = ProgressBar;
exports.default = _default;