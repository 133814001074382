"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _kendoReactUpload = require("@progress/kendo-react-upload");

var _kendoReactIntl = require("@progress/kendo-react-intl");

var _pl = _interopRequireDefault(require("./pl.json"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

(0, _kendoReactIntl.loadMessages)(_pl.default, 'pl-PL');
var propTypes = {
  onAdd: _propTypes.default.func.isRequired,
  autoUpload: _propTypes.default.bool,
  batch: _propTypes.default.bool,
  disabled: _propTypes.default.bool,
  multiple: _propTypes.default.bool,
  allowedExtensions: _propTypes.default.array,
  minFileSize: _propTypes.default.number,
  maxFileSize: _propTypes.default.number,
  className: _propTypes.default.string
};
var defaultProps = {
  autoUpload: false,
  batch: true,
  disabled: false,
  multiple: true,
  allowedExtensions: ['.xlsx'],
  minFileSize: 1,
  // 1 byte
  maxFileSize: 100000000,
  // 100MB,
  className: ''
};

function FoxUploadFiles(_ref) {
  var onAdd = _ref.onAdd,
      autoUpload = _ref.autoUpload,
      batch = _ref.batch,
      disabled = _ref.disabled,
      multiple = _ref.multiple,
      allowedExtensions = _ref.allowedExtensions,
      minFileSize = _ref.minFileSize,
      maxFileSize = _ref.maxFileSize,
      className = _ref.className;
  return _react.default.createElement(_kendoReactIntl.LocalizationProvider, {
    language: "pl-PL"
  }, _react.default.createElement(_kendoReactIntl.IntlProvider, {
    locale: "pl"
  }, _react.default.createElement(_kendoReactUpload.Upload, {
    autoUpload: autoUpload,
    batch: batch,
    disabled: disabled,
    multiple: multiple,
    restrictions: {
      allowedExtensions: allowedExtensions,
      minFileSize: minFileSize,
      maxFileSize: maxFileSize
    },
    onAdd: onAdd,
    className: className
  })));
}

FoxUploadFiles.propTypes = propTypes;
FoxUploadFiles.defaultProps = defaultProps;
var _default = FoxUploadFiles;
exports.default = _default;