"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var toastrActionTypes = {
  TOASTR_DISPLAY: 'TOASTR_DISPLAY',
  TOASTR_CLOSE: 'TOASTR_CLOSE'
};
var _default = toastrActionTypes;
exports.default = _default;