"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _buttons = _interopRequireDefault(require("./translations/buttons.pl"));

var _buttons2 = _interopRequireDefault(require("./translations/buttons.en"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _default = {
  pl: _buttons.default,
  en: _buttons2.default
};
exports.default = _default;