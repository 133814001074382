"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scrollToTheBottomOfTheElement = exports.scrollToGrid = exports.scrollTo = void 0;
var scrollingStartDelay = 350;

var scrollTo = function scrollTo(element) {
  var blockAppearing = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'center';
  var scrollDelay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : scrollingStartDelay;
  return element && setTimeout(function () {
    element.scrollIntoView({
      behavior: 'smooth',
      block: blockAppearing
    });
  }, scrollDelay);
};

exports.scrollTo = scrollTo;

var scrollToGrid = function scrollToGrid() {
  var gridActionButton = document.querySelector('.inner-content-wrapper > a');
  var scrollAnchorElement = gridActionButton || document.querySelector('.inner-content-wrapper > div');
  scrollTo(scrollAnchorElement);
};

exports.scrollToGrid = scrollToGrid;

var scrollToTheBottomOfTheElement = function scrollToTheBottomOfTheElement(element) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 350;
  element && setTimeout(function () {
    var scrollDistance = element.scrollHeight;
    element.scrollBy({
      top: scrollDistance,
      left: 0,
      behavior: 'smooth'
    });
  }, delay);
};

exports.scrollToTheBottomOfTheElement = scrollToTheBottomOfTheElement;