"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adjustDateToFormat = exports.toDate = void 0;

var toDate = function toDate(val) {
  if (!val) {
    return undefined;
  }

  var newVal = new Date(val); // eslint-disable-next-line eqeqeq

  if (newVal == 'Invalid Date') {
    return undefined;
  }

  return newVal;
};

exports.toDate = toDate;

var adjustDateToFormat = function adjustDateToFormat(date, format) {
  if (!date) {
    return undefined;
  }

  date = toDate(date);
  var formatContainsSeconds = format.includes('ss');

  if (!formatContainsSeconds) {
    date.setSeconds(0, 0);
  }

  return date;
};

exports.adjustDateToFormat = adjustDateToFormat;