"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _FontAwesomeIcon = _interopRequireDefault(require("./FontAwesomeIcon/FontAwesomeIcon"));

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _customHooks = require("../../consts/customHooks");

var _icons = _interopRequireDefault(require("../../consts/icons"));

var _WinningLabelModule = _interopRequireDefault(require("./WinningLabel.module.scss"));

var _BadgeModule = _interopRequireDefault(require("./Badge.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var propTypes = {
  auctionId: _propTypes.default.string.isRequired,
  isWinning: _propTypes.default.bool.isRequired,
  alignCenter: _propTypes.default.bool,
  ref: _propTypes.default.any
};
var defaultProps = {
  alignCenter: false,
  ref: undefined
};

var WinningLabel = function WinningLabel(_ref) {
  var _cx;

  var auctionId = _ref.auctionId,
      isWinning = _ref.isWinning,
      alignCenter = _ref.alignCenter,
      ref = _ref.ref,
      others = _objectWithoutProperties(_ref, ["auctionId", "isWinning", "alignCenter", "ref"]);

  var labelRef = (0, _react.useRef)(null);
  var prevAuctionId = (0, _customHooks.usePrevious)(auctionId);

  var _useState = (0, _react.useState)(null),
      _useState2 = _slicedToArray(_useState, 2),
      labelRow = _useState2[0],
      setLabelRow = _useState2[1];

  var winningClassName = 'highlight-green';
  var losingClassName = 'highlight-red';

  var updateClassName = function updateClassName() {
    return labelRow && function () {
      labelRow.classList.remove(!isWinning ? winningClassName : losingClassName);
      labelRow.classList.add(isWinning ? winningClassName : losingClassName);
    }();
  };

  var removeCurrentClassName = function removeCurrentClassName() {
    return labelRow && labelRow.classList.remove(isWinning ? winningClassName : losingClassName);
  };

  (0, _react.useEffect)(function () {
    var labelElement = labelRef.current;
    setLabelRow(labelElement.closest('tr'));
  }, [labelRef]);
  (0, _react.useEffect)(function () {
    if (prevAuctionId === auctionId) {
      updateClassName();
      setTimeout(function () {
        removeCurrentClassName();
      }, 1000);
    }
  }, [isWinning]);
  var labelClassName = (0, _classnames.default)(_BadgeModule.default.badge, _BadgeModule.default.smaller, _BadgeModule.default.with_border, _WinningLabelModule.default.badge, (_cx = {}, _defineProperty(_cx, _BadgeModule.default.green, isWinning), _defineProperty(_cx, _BadgeModule.default.red, !isWinning), _defineProperty(_cx, _WinningLabelModule.default['align-center'], alignCenter), _cx));
  var iconClassName = (0, _classnames.default)(_WinningLabelModule.default.icon, _defineProperty({}, _WinningLabelModule.default.winning, isWinning));
  var label = {
    title: isWinning ? _languageService.default.labels.YouAreWinning() : _languageService.default.labels.YouAreLosing(),
    icon: isWinning ? _icons.default.happy : _icons.default.sad
  };
  return _react.default.createElement("div", _extends({}, others, {
    className: labelClassName,
    ref: labelRef
  }), _react.default.createElement("span", null, label.title), _react.default.createElement(_FontAwesomeIcon.default, {
    icon: label.icon,
    title: label.title,
    className: iconClassName
  }));
};

WinningLabel.propTypes = propTypes;
WinningLabel.defaultProps = defaultProps;
var _default = WinningLabel;
exports.default = _default;