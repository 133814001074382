"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _resourceService = _interopRequireDefault(require("../services/resourceService"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _ = function _(path) {
  return function () {
    return _resourceService.default.get(path);
  };
};

var _default = {
  AccessDenied: _('errors.AccessDenied'),
  ValueRequired: _('errors.ValueRequired')
};
exports.default = _default;