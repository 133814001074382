"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downloadOrderPDF = void 0;

var _languageService = _interopRequireDefault(require("../language/services/languageService"));

var _filesHelper = require("hh-shared/dist/utils/filesHelper");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var downloadOrderPDF = function downloadOrderPDF(orderPDF, orderNumber) {
  return (0, _filesHelper.downloadFile)(orderPDF, "[H+H] ".concat(_languageService.default.labels.Order(), " ").concat(orderNumber, ".pdf"));
};

exports.downloadOrderPDF = downloadOrderPDF;