"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ProgessBarFillingModule = _interopRequireDefault(require("./ProgessBarFilling.module.scss"));

var _ProgressBarTooltip = _interopRequireDefault(require("./ProgressBarTooltip"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var propTypes = {
  fillnessPercentage: _propTypes.default.number.isRequired,
  color: _propTypes.default.oneOf(['orange', 'red', 'green', 'blue'])
};
var defaultProps = {
  color: 'blue'
};

var ProgessBarFilling = function ProgessBarFilling(_ref) {
  var fillnessPercentage = _ref.fillnessPercentage,
      color = _ref.color;
  var progressBarFillingClassName = (0, _classnames.default)(_ProgessBarFillingModule.default.filling, [_ProgessBarFillingModule.default["filling_".concat(color)]], _defineProperty({}, _ProgessBarFillingModule.default.filling_green, fillnessPercentage === 100 && color === 'green'));
  return _react.default.createElement("div", {
    className: progressBarFillingClassName,
    style: {
      width: "".concat(fillnessPercentage, "%")
    }
  }, _react.default.createElement(_ProgressBarTooltip.default, {
    fillnessPercentage: fillnessPercentage,
    color: color
  }));
};

ProgessBarFilling.propTypes = propTypes;
ProgessBarFilling.defaultProps = defaultProps;
var _default = ProgessBarFilling;
exports.default = _default;