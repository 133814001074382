import React from 'react';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToasts,
} from 'hh-shared/dist/consts/reduxFormMethods';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { reduxForm } from 'redux-form';
import formNames from 'common/formNames';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import PropTypes from 'prop-types';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import icons from 'hh-shared/dist/consts/icons';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import { toUpper } from 'fox-react/dist/utils/reduxFormUtils';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  carTypes: PropTypes.array.isRequired,
};

const defaultProps = {};

function CarForm({ handleSubmit, submitting, title, carTypes }) {
  return (
    <div className="form-container">
      <Form handleSubmit={handleSubmit}>
        <fieldset>
          <SectionWrapper>
            <FormSectionCard title={title} titleIcon={icons.fastTruck}>
              <FoxInputTextField
                label={lang.labels.RegisterNumber()}
                placeholder={lang.labels.RegisterNumber()}
                name="registerNumber"
                validate={isRequiredFieldValidator}
                required
                format={toUpper}
              />

              <FoxComboBoxField
                label={lang.labels.CarType()}
                name="carType"
                items={carTypes}
                validate={isRequiredFieldValidator}
                required
              />
            </FormSectionCard>
          </SectionWrapper>
        </fieldset>
        <FoxButton primary disabled={submitting}>
          {lang.buttons.Save()}
        </FoxButton>
      </Form>
    </div>
  );
}

CarForm.propTypes = propTypes;
CarForm.defaultProps = defaultProps;

const onSubmitSuccess = (result, dispatch, props) =>
  displayCompletionToasts(
    dispatch,
    !!props.isEditForm,
    lang.labels.SuccessfullyModificatedVehicle(),
    lang.labels.SuccessfullyCreatedVehicle(),
  );

export default reduxForm({
  form: formNames.carForm,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(CarForm));
