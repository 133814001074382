import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import transportOrderStatusesIds from 'hh-shared/dist/consts/transportOrderStatusesIds';
import { getForGrid as getForGridTransportOrder } from 'transport-orders/actions';
import transporOrderActionTypes from 'transport-orders/actionTypes';
import {
  transportOrderCanceledFilter,
  transportOrderAcceptedFilter,
  transportOrderLoadedFilter,
  transportOrderCompletedFilter,
} from 'transport-orders/filters';
import PropTypes from 'prop-types';
import OrderStatusCell from './OrdersStatusCell';

const propTypes = {
  statusId: PropTypes.string.isRequired,
};
const defaultProps = {};

const OrderStatusClickableCell = ({ statusId, ...others }) => {
  const {
    placed,
    accepted,
    loaded,
    realized,
    canceled,
  } = transportOrderStatusesIds;
  const dispatch = useDispatch();

  const transportOrdersDataState = useSelector(
    state => state.transportOrders.dataState,
  );

  const dispatchTransportOrderChangeFilter = filter => {
    dispatch(
      getForGridTransportOrder({
        ...transportOrdersDataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: transporOrderActionTypes.TRANSPORT_ORDER_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchTransportOrderCanceled = () =>
    dispatchTransportOrderChangeFilter(transportOrderCanceledFilter);

  const dispatchTransportOrderAccepted = () =>
    dispatchTransportOrderChangeFilter(transportOrderAcceptedFilter);

  const dispatchTransportOrderLoaded = () =>
    dispatchTransportOrderChangeFilter(transportOrderLoadedFilter);

  const dispatchTransportOrderCompleted = () =>
    dispatchTransportOrderChangeFilter(transportOrderCompletedFilter);

  const getBadgeFilter = () => {
    switch (statusId) {
      case placed:
        return undefined;
      case accepted:
        return dispatchTransportOrderAccepted;
      case loaded:
        return dispatchTransportOrderLoaded;
      case realized:
        return dispatchTransportOrderCompleted;
      case canceled:
        return dispatchTransportOrderCanceled;
      default:
        return undefined;
    }
  };

  const [currentFilter, setCurrentFilter] = useState(() => getBadgeFilter());

  useEffect(() => {
    setCurrentFilter(() => getBadgeFilter());
    // eslint-disable-next-line
  }, [statusId]);

  return (
    <OrderStatusCell onClick={currentFilter} statusId={statusId} {...others} />
  );
};

OrderStatusClickableCell.propTypes = propTypes;
OrderStatusClickableCell.defaultProps = defaultProps;

export default OrderStatusClickableCell;
