"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useInterval = useInterval;
exports.useImmediateInterval = useImmediateInterval;
exports.usePrevious = usePrevious;
exports.default = void 0;

var _react = require("react");

function useInterval(callback, delay) {
  var clearingStatement = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var savedCallback = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    savedCallback.current = callback;
  }, [callback]);
  (0, _react.useEffect)(function () {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      var id = setInterval(tick, delay);
      clearingStatement && clearInterval(id);
      return function () {
        return clearInterval(id);
      };
    }

    return undefined;
  }, [delay, clearingStatement]);
}

function useImmediateInterval(callback, delay) {
  var clearingStatement = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var savedCallback = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    callback(); // eslint-disable-next-line
  }, []);
  (0, _react.useEffect)(function () {
    savedCallback.current = callback;
  }, [callback]);
  (0, _react.useEffect)(function () {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      var id = setInterval(tick, delay);
      clearingStatement && clearInterval(id);
      return function () {
        return clearInterval(id);
      };
    }

    return undefined;
  }, [delay, clearingStatement]);
}

function usePrevious(value) {
  var ref = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    ref.current = value;
  }, [value]);
  return ref.current;
}

var customHooks = {
  useImmediateInterval: useImmediateInterval,
  useInterval: useInterval,
  usePrevious: usePrevious
};
var _default = customHooks;
exports.default = _default;