"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Column = _interopRequireDefault(require("layout/Column"));

var _FormPreviewSectionModule = _interopRequireDefault(require("./FormPreviewSection.module.scss"));

var _FormPreviewSectionTitle = _interopRequireDefault(require("./FormPreviewSectionTitle"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  children: _propTypes.default.any,
  icon: _propTypes.default.any.isRequired,
  title: _propTypes.default.string.isRequired
};
var defaultProps = {
  children: undefined
};

var FormPreviewSection = function FormPreviewSection(_ref) {
  var children = _ref.children,
      icon = _ref.icon,
      title = _ref.title;
  return _react.default.createElement(_Column.default, {
    className: _FormPreviewSectionModule.default.section_container
  }, _react.default.createElement(_FormPreviewSectionTitle.default, {
    icon: icon,
    title: title
  }), children);
};

FormPreviewSection.propTypes = propTypes;
FormPreviewSection.defaultProps = defaultProps;
var _default = FormPreviewSection;
exports.default = _default;