"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _StarToggle = _interopRequireDefault(require("../commons/StarToggle"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  className: _propTypes.default.string,
  value: _propTypes.default.bool.isRequired,
  onChange: _propTypes.default.func.isRequired
};
var defaultProps = {
  className: ''
};

var StarCell = function StarCell(_ref) {
  var className = _ref.className,
      value = _ref.value,
      onChange = _ref.onChange;
  return _react.default.createElement("td", {
    className: className,
    colSpan: "1"
  }, _react.default.createElement(_StarToggle.default, {
    value: value,
    onChange: onChange,
    starClassName: "margin margin-auto",
    small: true
  }));
};

StarCell.propTypes = propTypes;
StarCell.defaultProps = defaultProps;
var _default = StarCell;
exports.default = _default;