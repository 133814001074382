"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var createTemporaryTextArea = function createTemporaryTextArea() {
  var textArea = document.createElement('textarea');
  textArea.classList.add('invisible');
  textArea.style.position = 'absolute';
  textArea.style.left = '-9969px';
  textArea.setAttribute('readonly', 'readonly');
  return textArea;
};

var copyToClipboard = function copyToClipboard(textToCopy) {
  var textArea = createTemporaryTextArea();
  textArea.value = textToCopy;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

var _default = copyToClipboard;
exports.default = _default;