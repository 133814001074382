import actionTypes from './sideMenuActionTypes';

const initialState = {
  isOpened: true,
  countersAreLoading: false,
  onGoingAuctions: 0,
  placedTransportOrders: 0,
};

const sideMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      return { ...state, isOpened: true };
    case actionTypes.MENU_CLOSE:
      return { ...state, isOpened: false };
    case actionTypes.MENU_TOGGLE:
      return { ...state, isOpened: !state.isOpened };
    case actionTypes.SET_SIDEBAR_COUNTERS:
      return {
        ...state,
        ...action.payload,
        countersAreLoading: false,
      };
    case actionTypes.UPDATE_SIDEBAR_COUNTERS:
      return {
        ...state,
        countersAreLoading: true,
      };
    default:
      return state;
  }
};

export default sideMenuReducer;
