import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getForGrid as getForGridAuctionsManagement } from 'auctions/actions';
import auctionActionTypes from 'auctions/actionTypes';
import {
  auctionOngoingFilter,
  auctionWonFilter,
  auctionNotWonFilter,
} from 'auctions/filters';
import lang from 'hh-shared/dist/language/services/languageService';
import Row from 'hh-shared/dist/components/layout/Row';
import DashboardCardBox from './DashboardCardBox';
import styles from './DashboardCardBoxes.module.scss';

const propTypes = {
  liveCount: PropTypes.number,
  endedSuccessfulCount: PropTypes.number,
  endedWithoutSuccessCount: PropTypes.number,
};

const defaultProps = {
  liveCount: 0,
  endedSuccessfulCount: 0,
  endedWithoutSuccessCount: 0,
};

const AuctionCardBoxes = ({
  liveCount,
  endedSuccessfulCount,
  endedWithoutSuccessCount,
}) => {
  const dispatch = useDispatch();
  const dataState = useSelector(state => state.auctions.dataState);
  const boxContainerClassName = cx(
    styles.box_container,
    'justify-content-start',
  );

  const changeFilter = filter => {
    dispatch(
      getForGridAuctionsManagement({
        ...dataState,
        filter,
        skip: 0,
      }),
    );
    dispatch({
      type: auctionActionTypes.AUCTIONS_SET_FILTER,
      payload: filter,
    });
  };

  const dispatchOngoing = () => changeFilter(auctionOngoingFilter);
  const dispatchWon = () => changeFilter(auctionWonFilter);
  const dispatchNotWon = () => changeFilter(auctionNotWonFilter);

  return (
    <Row className={boxContainerClassName}>
      <DashboardCardBox
        quantity={liveCount}
        label={lang.labels.Ongoing()}
        onClick={dispatchOngoing}
        link="/auctions"
      />
      <DashboardCardBox
        quantity={endedSuccessfulCount}
        label={lang.labels.Won()}
        onClick={dispatchWon}
        link="/auctions"
      />
      <DashboardCardBox
        quantity={endedWithoutSuccessCount}
        label={lang.labels.Lost()}
        onClick={dispatchNotWon}
        link="/auctions"
      />
    </Row>
  );
};

AuctionCardBoxes.propTypes = propTypes;
AuctionCardBoxes.defaultProps = defaultProps;

export default AuctionCardBoxes;
