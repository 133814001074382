import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import AuctionPreviewPageLoader from 'hh-shared/dist/components/auction/AuctionPreviewPageLoader';
import Row from 'hh-shared/dist/components/layout/Row';

import AuctionsSrv from './AuctionsSrv';
import auctionActionTypes from './actionTypes';
import './AuctionPage.scss';

import AuctionPageLeftPane from './AuctionPageLeftPane';
import AuctionPageRightPane from './AuctionPageRightPane';
import { getAuctionData, clearAuctionData } from './actions';

const propTypes = {
  match: PropTypes.object.isRequired,
};

const AuctionDetailsPage = ({ match }) => {
  const dispatch = useDispatch();

  const auctionState = useSelector(state => state.auction);
  const { isLoading, auctionMetric } = auctionState;

  const reloadPage = useCallback(() => {
    dispatch(getAuctionData(match.params.id));
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (match.params.id && dispatch) {
      reloadPage();
    }
    return () => {
      dispatch(clearAuctionData());
    };
  }, [match.params.id, dispatch, reloadPage]);

  const isObservedChange = isObserved => {
    dispatch({
      type: auctionActionTypes.AUCTION_CHANGE_IS_OBSERVED,
      payload: isObserved,
    });
    AuctionsSrv.isObservedChange(match.params.id, isObserved);
  };

  return (
    <>
      {isLoading ? (
        <AuctionPreviewPageLoader />
      ) : (
        <Row className="auction_page">
          <AuctionPageLeftPane
            isObservedChange={isObservedChange}
            reloadPage={reloadPage}
            auctionStatus={auctionMetric.status}
            auctionEndTime={auctionMetric.endTime}
            auctionId={match.params.id}
          />
          <AuctionPageRightPane auctionId={match.params.id} />
        </Row>
      )}
    </>
  );
};

AuctionDetailsPage.propTypes = propTypes;

export default AuctionDetailsPage;
