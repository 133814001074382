"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Column = _interopRequireDefault(require("layout/Column"));

var _FormPreviewPaneModule = _interopRequireDefault(require("./FormPreviewPane.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  children: _propTypes.default.any.isRequired,
  className: _propTypes.default.string,
  isNarrowed: _propTypes.default.bool
};
var defaultProps = {
  className: '',
  isNarrowed: false
};
/* eslint-disable indent */

var FormPreviewPane = function FormPreviewPane(_ref) {
  var children = _ref.children,
      className = _ref.className,
      isNarrowed = _ref.isNarrowed;
  var defaultTopOffset = 148;
  var defaultBottomOffset = 57;
  var defaultBorderRadius = '0.75rem';

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      minHeight = _useState2[0],
      setMinHeight = _useState2[1];

  var _useState3 = (0, _react.useState)(defaultTopOffset),
      _useState4 = _slicedToArray(_useState3, 2),
      topOffset = _useState4[0],
      setTopOffset = _useState4[1];

  var _useState5 = (0, _react.useState)(0),
      _useState6 = _slicedToArray(_useState5, 2),
      bottomOffset = _useState6[0],
      setBottomOffset = _useState6[1];

  var getFormHeight = function getFormHeight() {
    var currentFormContainer = document.querySelector('.card-wrapper');
    return currentFormContainer && currentFormContainer.getBoundingClientRect().height;
  };

  var getElementHeight = function getElementHeight(element) {
    return element ? element.getBoundingClientRect().height : undefined;
  };

  var getDefaultTopOffset = function getDefaultTopOffset() {
    var breadcrumbsContainer = document.querySelector('.breadcrumbs');
    var headerContainer = document.querySelector('.header');
    var contentWrapper = document.querySelector('.content-wrapper');
    var breadCrumbsHeight = getElementHeight(breadcrumbsContainer) || 0;
    var headerHeight = getElementHeight(headerContainer) || 0;
    var contentOffset = contentWrapper ? parseFloat(window.getComputedStyle(contentWrapper).getPropertyValue('padding-top')) : 0;
    return breadCrumbsHeight + headerHeight + contentOffset;
  };

  var handleScroll = function handleScroll() {
    var currentTopBorder = getDefaultTopOffset();
    var currentTopOffset = window.pageYOffset || document.documentElement.scrollTop;
    var currentBottomOffset = document.body.offsetHeight - window.pageYOffset - window.innerHeight;
    setTopOffset(currentTopOffset >= currentTopBorder ? 0 : currentTopBorder - currentTopOffset);
    setBottomOffset(currentBottomOffset >= defaultBottomOffset ? 0 : defaultBottomOffset - currentBottomOffset);
  };

  var adjustMinHeight = function adjustMinHeight() {
    return setMinHeight(getFormHeight());
  };

  (0, _react.useEffect)(function () {
    adjustMinHeight();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', adjustMinHeight);
    window.addEventListener('resize', adjustMinHeight);
    return function () {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', adjustMinHeight);
      window.removeEventListener('resize', adjustMinHeight);
    }; // TODO poprawić
    // eslint-disable-next-line
  }, []);
  (0, _react.useEffect)(function () {
    setTimeout(function () {
      handleScroll();
    }, 300); // TODO poprawic
    // eslint-disable-next-line
  }, [isNarrowed]);
  return _react.default.createElement(_Column.default, {
    className: "".concat(_FormPreviewPaneModule.default.preview_pane, " ").concat(className, " ").concat(isNarrowed ? _FormPreviewPaneModule.default.narrowed : ''),
    smallerPreview: true,
    style: {
      height: minHeight ? "".concat(minHeight, "px") : 'auto',
      top: "".concat(topOffset, "px"),
      maxHeight: "calc(100vh - ".concat(topOffset + bottomOffset, "px)"),
      borderTopLeftRadius: "".concat(topOffset === 0 ? 0 : defaultBorderRadius),
      borderBottomLeftRadius: "".concat(bottomOffset <= defaultBottomOffset && topOffset === 0 ? 0 : defaultBorderRadius)
    }
  }, children);
};

FormPreviewPane.propTypes = propTypes;
FormPreviewPane.defaultProps = defaultProps;
var _default = FormPreviewPane;
exports.default = _default;