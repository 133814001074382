"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _FontAwesomeIcon = _interopRequireDefault(require("../../commons/FontAwesomeIcon/FontAwesomeIcon"));

var _Row = _interopRequireDefault(require("layout/Row"));

var _FormPreviewSectionTitleModule = _interopRequireDefault(require("./FormPreviewSectionTitle.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  title: _propTypes.default.string.isRequired,
  icon: _propTypes.default.any.isRequired
};
var defaultProps = {};

var FormPreviewSectionTitle = function FormPreviewSectionTitle(_ref) {
  var title = _ref.title,
      icon = _ref.icon;
  return _react.default.createElement(_Row.default, {
    className: "".concat(_FormPreviewSectionTitleModule.default.section_title, " align-items-end")
  }, _react.default.createElement(_FontAwesomeIcon.default, {
    icon: icon,
    className: _FormPreviewSectionTitleModule.default.icon
  }), _react.default.createElement("div", {
    className: _FormPreviewSectionTitleModule.default.title
  }, title));
};

FormPreviewSectionTitle.propTypes = propTypes;
FormPreviewSectionTitle.defaultProps = defaultProps;
var _default = FormPreviewSectionTitle;
exports.default = _default;