"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getTwoDigitRepresentation = exports.toMoneyFormat = exports.replaceDecimalSeperator = exports.insertThousandsSeparator = exports.isNumber = void 0;

var isNumber = function isNumber(number) {
  return !Number.isNaN(Number("".concat(number).replace(/,/g, '.')));
};

exports.isNumber = isNumber;

var insertThousandsSeparator = function insertThousandsSeparator(number) {
  return number ? number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : '';
};

exports.insertThousandsSeparator = insertThousandsSeparator;

var replaceDecimalSeperator = function replaceDecimalSeperator(number) {
  return Number("".concat(number).replace(/,/g, '.')).toFixed(2).replace('.', ',');
};

exports.replaceDecimalSeperator = replaceDecimalSeperator;

var toMoneyFormat = function toMoneyFormat(number) {
  if (!isNumber(number)) {
    return number;
  }

  number = replaceDecimalSeperator(number);
  number = insertThousandsSeparator(number);
  return number;
};

exports.toMoneyFormat = toMoneyFormat;

var getTwoDigitRepresentation = function getTwoDigitRepresentation(number) {
  return "".concat("".concat(number).length < 2 ? '0' : '').concat(number);
};

exports.getTwoDigitRepresentation = getTwoDigitRepresentation;
var numberFormating = {
  insertThousandsSeparator: insertThousandsSeparator,
  replaceDecimalSeperator: replaceDecimalSeperator,
  toMoneyFormat: toMoneyFormat,
  getTwoDigitRepresentation: getTwoDigitRepresentation
};
var _default = numberFormating;
exports.default = _default;